import { Box } from '@mui/material';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { breakPointMedium } from '../../../utils/MediaBreakPoint';

export const TabTitle = styled(Box)`
  font-size: 24px;
  font-family: Gotham-Bold;
  ${breakPointMedium('font-size: 18px;')}
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
