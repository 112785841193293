import { Box, styled } from '@mui/material';
import { MainColor, White } from '../../Theme/colorsVariables';
import { FontSizeMax } from '../../Theme/screensVariables';

export const StyledBox = styled(Box)`
  cursor: pointer;
  background: ${MainColor};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 8px;
  font-size: ${FontSizeMax} !important;
`;

export const StyledInnerBox = styled(Box)`
  color: ${White};
`;
