import { Dispatch, SetStateAction, useCallback, useContext } from 'react';
import { QuickConfirmationModalContext } from '../context/QuickModal';
import { useToast } from '../hooks/useToast';
import { ApiError } from '../types';

export const useHandleOrganizationErrors = () => {
  const toast = useToast();
  const { setQuickModal } = useContext(QuickConfirmationModalContext);

  const handleOrgError = useCallback(
    ({
      error,
      setCustomFieldError,
    }: {
      error: ApiError;
      setCustomFieldError?: Dispatch<SetStateAction<string>>;
    }) => {
      const errorMessage = error.response.data.message;
      // Duplicate FEIN:
      if (error.response.data.code === 40015) {
        setQuickModal({
          isOpen: true,
          message: errorMessage,
          modalTitle: 'Error',
        });

        setCustomFieldError?.('fein');
        return;
      }

      // Duplicate Physical Address:
      if (error.response.data.code === 40016) {
        setQuickModal({
          isOpen: true,
          message: errorMessage,
          modalTitle: 'Error',
        });

        setCustomFieldError?.('physicalLocation.address1');
        return;
      }
      // Duplicate Phone Number:
      if (error.response.data.code === 40017) {
        setQuickModal({
          isOpen: true,
          message: errorMessage,
          modalTitle: 'Error',
        });

        setCustomFieldError?.('phone.number');
        return;
      }

      if (error.response.data.code === 30050) {
        toast.error(error.response.data.message);
        return;
      }
      toast.error(error.response.data.message);
    },
    [setQuickModal, toast],
  );

  return [handleOrgError];
};
