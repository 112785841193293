import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ListItemText } from '@mui/material';

import { NavItems, NavIcon, Text } from '../styles';
import { NavItemProps, PermissionMap } from './types';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
import { LightSlateGray } from '../../../Theme/colorsVariables';
import { UserContext } from '../../../context/User';
import { UnsavedDataContext } from '../../../context/UnsavedData';

export default function NavItem({ item, isDrawerOpen, activeColor, section }: NavItemProps) {
  const user = useContext(UserContext);
  const { unsavedData, setUnsavedData } = useContext(UnsavedDataContext);
  const isViewRoleAgenciesPermission = usePermissions(PermissionKeys.ReadAgentRoles);
  const isViewRoleProviderPermission = usePermissions(PermissionKeys.ReadProviderRoles);
  const isReadProviderUserPermission = usePermissions(PermissionKeys.ReadProviderUser);
  const isReadAgentUserPermission = usePermissions(PermissionKeys.ReadAgentUser);
  const isViewAgencyPermission = usePermissions(PermissionKeys.ReadAgent);
  const isViewSellerPermission = usePermissions(PermissionKeys.ViewSellers);
  const isReadOrganizationPermission = usePermissions(PermissionKeys.ReadOrganization);
  const isViewProductPermission = usePermissions(PermissionKeys.ViewProducts);
  const isViewAgreementPermission = usePermissions(PermissionKeys.ViewAgreement);
  const isManageSystemPermission = usePermissions(PermissionKeys.SystemAdmin);
  const isViewSystemConfigurationPermission = usePermissions(PermissionKeys.ViewConfiguration);

  const managePermission = () => {
    const permissionMap: PermissionMap = {
      Agents: user?.userType === 'Agency' || !isViewAgencyPermission,
      Sellers: !isViewSellerPermission,
      'Product Lines': !isViewProductPermission,
      'Agreement Templates': !isViewAgreementPermission,
      Roles: !isViewRoleAgenciesPermission && !isViewRoleProviderPermission,
      Users: !isReadProviderUserPermission && !isReadAgentUserPermission,
      'Seller Groups': !isViewSellerPermission || !isReadOrganizationPermission,
      'System Administration': !isManageSystemPermission,
      Configuration: !isViewSystemConfigurationPermission,
    };

    return permissionMap[item.title] || false;
  };

  const handleClickLink = (event: any, data: any) => {
    if (unsavedData.isUnsavedChanges) {
      event.preventDefault();

      setUnsavedData({ ...unsavedData, isPopupOpen: true, returnUrl: data?.to });
    }
  };

  if (managePermission()) {
    return <></>;
  } else {
    return (
      <Link
        style={{ textDecoration: 'none', color: `${LightSlateGray}` }}
        to={item?.to}
        onClick={(event) => handleClickLink(event, item)}
      >
        <NavItems
          sx={{
            alignItems: 'center',
          }}
          active={activeColor}
          section={section}
          current={item.title}
        >
          <NavIcon
            active={activeColor}
            section={section}
            current={item.title}
            dash={!item.icon ? 'true' : ''}
            sx={{ minWidth: 'unset', marginLeft: '3px' }}
          >
            {item.icon}
          </NavIcon>
          {isDrawerOpen && (
            <ListItemText
              primary={
                <Text color="inherit" className={'MenuItemText'}>
                  {item.title}
                </Text>
              }
            />
          )}
        </NavItems>
      </Link>
    );
  }
}
