import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
import { linkColor } from '../../../Theme/colorsVariables';
import { Cell, CellType } from '../../../types/customTable';
import { Order } from '../../../types/query-params';
import { AgreementProductLine } from '../../../types/sellerGroup';
import CustomTable from '../../shared/CustomTable';
import CustomModal from '../Modal';

interface ComponentProps {
  isOpened: boolean;
  setIsOpen: () => void;
  productLines: AgreementProductLine[] | null;
}

export const AssociatedProducts: FC<ComponentProps> = ({ isOpened, setIsOpen, productLines }) => {
  const { t } = useTranslation();
  const isReadProductLinePermission = usePermissions(PermissionKeys.ViewProducts);
  const [orderBy, setOrderBy] = useState('productName');
  const [order, setOrder] = useState(Order.ASC);
  const [customSortedProductLines, setCustomSortedProductLines] = useState<AgreementProductLine[]>(
    productLines || [],
  );

  const tableRows = useMemo<Cell[][]>(() => {
    return (
      customSortedProductLines?.map<Cell[]>((product) => [
        {
          data: (
            <>
              {isReadProductLinePermission && (
                <Link
                  to={`/dashboard/ProductLine/${product.productId}`}
                  target={'_blank'}
                  style={{ color: linkColor }}
                >
                  {product.productName || '-'}
                </Link>
              )}
              {!isReadProductLinePermission && <>{product.productName || '-'}</>}
            </>
          ),
          type: CellType.Action,
        },
        { data: product.description || '-', type: CellType.Info },
        // Status column
        // {
        //   data: (
        //     <ActiveText active={`${product.status}`} className={'statusLabel'}>
        //       {product.status ? (
        //         <span>{t('security.users.userList.active')}</span>
        //       ) : (
        //         <span>{t('security.users.userList.inactive')}</span>
        //       )}
        //     </ActiveText>
        //   ),
        //   type: CellType.Info,
        // },
      ]) || []
    );
  }, [isReadProductLinePermission, customSortedProductLines]);
  const onHandleSetOrder = useCallback(
    (newOrder: Order, newOrderBy: string): void => {
      if (!tableRows) return;

      setOrder(newOrder);
      setOrderBy(newOrderBy);

      const validOrderBy = (newOrderBy || 'productName') as unknown as keyof AgreementProductLine;

      setCustomSortedProductLines((prevState) => {
        if (!Array.isArray(prevState)) return prevState;
        const newOrderValue = newOrder === Order.DESC ? -1 : 1;

        const sortedLines = [...prevState].sort(
          (a, b) =>
            newOrderValue * (a[validOrderBy] as string).localeCompare(b[validOrderBy] as string),
        );

        return sortedLines;
      });
    },
    [tableRows, setOrder, setCustomSortedProductLines],
  );

  useEffect(() => {
    setCustomSortedProductLines(productLines || []);
  }, [productLines]);

  return (
    <CustomModal
      open={isOpened}
      containerWidth="700px"
      modalTitle={'Associated Product Lines'}
      modalProps={{ onClose: setIsOpen }}
      bodyStyles={{ minHeight: '500px' }}
    >
      <>
        <CustomTable
          displayOptions={{ paginationTop: false, paginationBottom: false, tableOnly: true }}
          page={0}
          rowsPerPage={0}
          data={tableRows}
          valueRef={undefined}
          goError={false}
          handlePageChange={() => {}}
          handleRowsPerPageChange={() => {}}
          tableHeadingsArray={[{ Name: 'productName' }, { Description: 'description' }]}
          advancedSearchVisibility={false}
          setAdvancedSearchVisibility={() => {}}
          setPagination={() => {}}
          onSubmitAdvancedSearch={() => {}}
          onResetAdvancedSearch={() => {}}
          searchValue={''}
          handleSearchValue={() => {}}
          setPage={() => {}}
          tableTitle={''}
          placeHolder={t('security.roles.advance.user.search.role')}
          addNewLink={{ link: '', state: undefined }}
          order={order}
          orderBy={orderBy}
          handleRequestSort={onHandleSetOrder}
          InputsComponent={() => <></>}
          isDataLoading={false}
          total={0}
          openAdvancedSearch={() => {}}
          isAdvanceSearch={false}
        />
      </>
    </CustomModal>
  );
};
