import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AppActionButton } from '../../shared/AppActionButton';
import { ModalMessageText } from './styles';
import { SpaceBetweenBox } from '../../shared/FlexBox';
import CustomModal from '../Modal';

interface ComponentProps {
  isOpened: boolean;
  modalTitle: string;
  onDiscardChanges: () => void;
  onSaveChanges: () => void;
  onExit: () => void;
  hideSaveDataButton?: boolean;
}

export const CancelModal: FC<ComponentProps> = ({
  isOpened,
  modalTitle,
  onDiscardChanges,
  onSaveChanges,
  onExit,
  hideSaveDataButton,
}) => {
  const { t } = useTranslation();
  return (
    <CustomModal open={isOpened} containerWidth="500px" modalTitle={modalTitle}>
      <>
        <ModalMessageText>{t('cancel.actions.modal.text')}</ModalMessageText>
        <SpaceBetweenBox mt={2}>
          <AppActionButton variant="outlined" onClick={onDiscardChanges}>
            {t('cancel.actions.discard')}
          </AppActionButton>
          {hideSaveDataButton === true ? null : (
            <AppActionButton variant="outlined" onClick={onSaveChanges}>
              {t('cancel.actions.save')}
            </AppActionButton>
          )}
          <AppActionButton variant="outlined" onClick={onExit}>
            {t('cancel.actions.exit')}
          </AppActionButton>
        </SpaceBetweenBox>
      </>
    </CustomModal>
  );
};
