import { getFormattedNumber } from 'react-phone-hooks';

export const normalizePhone = (val: string): string => {
  let phone = val;

  if (val) {
    phone = phone.replaceAll(/[- ]/g, '');

    if (val.length === 8 || phone.length === 10) {
      return `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6)}`;
    }

    if (val === '' || val.length === 1) {
      return val?.replace(/[- ]/g, '');
    }
    if (val.length < 12) {
      return (
        val
          ?.replace(/[- ]/g, '')
          ?.match(/.{1,3}/g)
          ?.join('-') || ''
      );
    }

    const normalized = val.replace(/[- ]/g, '').match(/.{1,3}/g);
    return (
      `${normalized?.slice(0, -1)?.join('-')}${String(normalized?.slice(-1))}`.substr(0, 12) || ''
    );
  }
  return '';
};

export const normilizeSSN = (val?: string) => {
  if (val && val.length === 9) {
    return val.slice(0, 3) + '-' + val.slice(3, 5) + '-' + val.slice(5);
  } else {
    return val || '';
  }
};

export const pastingCheck = (val: string): string => normalizePhone(val) || '';
