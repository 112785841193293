import styled from 'styled-components';
import { RedOrange } from '../../../Theme/colorsVariables';
import { FlexBox } from '../../shared/FlexBox';
import { breakPointMedium } from '../../../utils';

export const BlockTitle = styled(FlexBox)`
  font-weight: bold;
  margin-right: 20px;
  font-size: 22px;
  ${breakPointMedium('font-size: 18px;')}
`;
export const AsterikSpan = styled.span`
  display: inline-block;
  color: ${RedOrange};
`;
