import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { ApiError } from '../types';
import { useQuery } from './useQuery';

export const defaultToastDuration = 3000;

export enum DocuSignEvent {
  Cancel = 'cancel',
}

export const useUnlockSignatureProcess = () => {
  const req = async (agreementId: number) => {
    const res = await axios.post(`/DocuSign/UnlockSignatureProcess/${agreementId}`);
    return res.data;
  };
  return useMutation<unknown, ApiError, number>('unlockSignatureProcess', req);
};

export const useDocuSignEvents = () => {
  const { t } = useTranslation();
  const [eventName, setEventName] = useState('');
  const [eventDescription, setEventDescription] = useState('');
  const docuSignEvent = useQuery('event');
  const agreementId = useQuery('agreementId');
  const { mutate: unlockSignatureProcess } = useUnlockSignatureProcess();

  useEffect(() => {
    // show info toaster message
    if (docuSignEvent) {
      setEventName(docuSignEvent);
      setEventDescription(t(`docuSign.events.${docuSignEvent.toLowerCase()}`));
      // Info: we need timeout to support old events handling on public signing page
      if (docuSignEvent.toLowerCase() === DocuSignEvent.Cancel) {
        unlockSignatureProcess(Number(agreementId));
      }
      setTimeout(() => {
        window.close();
      }, 2 * defaultToastDuration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docuSignEvent]);

  return {
    eventDescription,
    eventName,
  };
};
