import { useTranslation } from 'react-i18next';
import { NoPermissionMessage } from '../../components/shared/NoPermissionMessage';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import { DuplicateOrgs } from './DuplicateOrgs';
import { useQuery } from '../../hooks/useQuery';
import { MAGResolution } from './MAG';
import { Gates } from '../../types/agreements';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { AgreementsService } from '../../services/agreementsService';

const ProtectedGateResolutionPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate: checkIfReviewPossible, data: isReviewPossible } =
    AgreementsService.useIsReviewPossible({
      onError(error) {
        navigate('/NotFound', { state: { error: error.response.data.message } });
      },
    });

  const reviewType = useQuery('ReviewType') as Gates;
  const { id: orgId } = useParams();

  const isApproveAgrentSellerRelationEnabled = usePermissions(
    PermissionKeys.ApproveAgentSellerRelationship,
  );

  useEffect(() => {
    if (!reviewType) {
      navigate('/NotFound', { state: { error: t('relationships.organisation.reviewtype') } });
    }
  }, [navigate, reviewType, t]);

  useEffect(() => {
    checkIfReviewPossible({ orgId: Number(orgId), reviewType });
  }, [checkIfReviewPossible, orgId, reviewType]);

  if (!isApproveAgrentSellerRelationEnabled) {
    return <NoPermissionMessage message={t('relationships.organisation.view.permission')} />;
  }

  if (reviewType === Gates.DOG && isReviewPossible) {
    return <DuplicateOrgs />;
  }
  if (reviewType === Gates.MAG && isReviewPossible) {
    return <MAGResolution />;
  }
  return <></>;
};

export default ProtectedGateResolutionPage;
