import { FC, useMemo } from 'react';
import { MainColor } from '../../Theme/colorsVariables';
import { IconWrapper } from '../SellerTabs/Agreements/styles';
import AppTooltip from '../shared/AppTooltip';
import AuthService from '../../services/authService';
import { Gates } from '../../types/agreements';

interface ReviewDocumentIconProps {
  onClick: () => void;
  isActionable: boolean;
  signingGroupMessage: string;
  reviewType: Gates;
}

const ReviewDocumentIcon: FC<ReviewDocumentIconProps> = ({
  onClick,
  isActionable,
  signingGroupMessage,
  reviewType,
}) => {
  const { data: gateMessageParametherMAG } = AuthService.useCommonConstantQuery({
    constantName: 'GateMessageParameterMAG',
  });
  const { data: gateMessageParametherDuplicate } = AuthService.useCommonConstantQuery({
    constantName: 'GateMessageParameterDuplicateSeller',
  });

  const tooltipTitle = useMemo(() => {
    const gateMessage =
      reviewType === Gates.MAG
        ? gateMessageParametherMAG?.value
        : gateMessageParametherDuplicate?.value;

    if (isActionable && !signingGroupMessage) {
      return `${gateMessage} Review`;
    }
    if (signingGroupMessage) {
      return signingGroupMessage;
    }
    return `${gateMessage} Review`;
  }, [
    gateMessageParametherDuplicate,
    gateMessageParametherMAG,
    isActionable,
    reviewType,
    signingGroupMessage,
  ]);

  return (
    <AppTooltip title={tooltipTitle}>
      <IconWrapper
        iconlabel="Review Icon"
        onClick={() => {
          if (isActionable && !signingGroupMessage) onClick();
        }}
        isallowed={`${isActionable && !signingGroupMessage}`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" version="1.1" width={24}>
          <defs />
          <path
            d="M 911.143 599.535 C 787.243 610.857 607.462 830.265 551.178 929.619 C 496.283 845.244 457.421 789.146 345.48 751.89 L 448.329 701.115 L 551.178 853.456 C 551.178 853.456 693.128 567.439 885.434 472.596 C 883.314 538.793 888.271 559.389 911.143 599.535 Z M 782.585 244.063 C 783.182 182.864 769.119 168.887 705.445 167.885 L 602.595 167.885 L 602.595 117.109 L 756.862 117.109 C 795.738 117.109 834.002 157.623 834.002 193.287 L 834.002 447.194 C 803.785 476.931 782.585 497.969 782.585 497.969 L 782.585 244.063 Z M 654.027 218.675 C 678.213 219.279 677.959 229.762 679.736 244.063 C 681.707 259.882 679.736 269.45 679.736 269.45 L 319.771 269.45 C 319.771 269.45 320.428 259.985 319.771 244.063 C 318.965 224.734 335.343 217.525 345.48 218.675 L 422.62 218.675 C 422.62 218.675 424.098 173.03 422.62 117.109 C 421.784 85.426 447.045 41.521 499.761 40.931 C 552.91 40.342 576.902 70.211 576.902 117.109 C 576.902 167.604 576.902 218.675 576.902 218.675 L 654.027 218.675 Z M 499.761 117.109 C 485.563 117.109 474.053 128.476 474.053 142.497 C 474.053 156.518 485.563 167.885 499.761 167.885 C 513.959 167.885 525.47 156.518 525.47 142.497 C 525.47 128.476 513.959 117.109 499.761 117.109 Z M 216.922 244.063 L 216.922 828.054 C 216.161 883.267 235.256 905.381 294.063 904.232 C 270.041 904.305 474.038 904.232 474.038 904.232 C 505.613 920.891 507.3 935.974 518.692 955.007 L 242.646 955.007 C 203.292 954.594 165.505 916.557 165.505 877.222 L 165.505 193.287 C 165.505 154.439 202.59 117.596 242.646 117.109 L 396.912 117.109 L 396.912 167.885 L 294.063 167.885 C 242.78 167.546 216.922 203.077 216.922 244.063 Z M 731.168 904.232 C 765.103 904.232 782.585 869.379 782.585 853.456 L 782.585 726.488 C 786.87 702.25 814.713 691.399 834.002 675.713 L 834.002 878.844 C 834.39 916.822 794.618 955.007 756.877 955.007 L 593.518 955.007 C 593.429 936.091 613.613 923.678 628.319 904.232 L 731.168 904.232 Z"
            fill={MainColor}
          />
        </svg>
      </IconWrapper>
    </AppTooltip>
  );
};

export default ReviewDocumentIcon;
