import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from 'react-query';
import { Pagination, User, AuthUser, PayloadAdvanceSearch, ApiError, UserPayload } from '../types';
import { UserAdvancedSearch } from '../pages/UserList/advanced-search-data-interface';
import { useToast } from '../hooks/useToast';

const UserService = {
  useUserQuery: (
    userId: number,
    options?: Omit<UseQueryOptions<User, ApiError>, 'queryKey' | 'queryFn'>,
  ) => {
    const toast = useToast();
    const fetchUserdata = async () => {
      const fetch = await axios.get(`/User/${userId}`);
      return fetch?.data;
    };
    const state = useQuery<User, ApiError>('userInfo', fetchUserdata, {
      onError(err) {
        toast.error(err.response.data.message);
      },
      ...options,
    });
    return state;
  },

  usePasswordLinkQuery: () => {
    const toast = useToast();
    const { t } = useTranslation();
    const changeUserPasswordLink = async ({ mailId }: { mailId: string; userName: string }) => {
      const fetch = await axios.get(`/Account/ResetPassword/${mailId}`);
      return fetch.data;
    };
    return useMutation<string, Error, { mailId: string; userName: string }>(
      'passwordLink',
      changeUserPasswordLink,
      {
        onSuccess: (data, variables) => {
          toast.success(t('toast.reset-password.success', { fullName: variables.userName }));
        },
        onError: () => {
          toast.error(t('toast.default.message.500'));
        },
      },
    );
  },

  useUserAddQuery: (
    options?: Omit<UseMutationOptions<User, ApiError, UserPayload>, 'mutationKey' | 'mutationFn'>,
  ) => {
    const userAdd = async (data: UserPayload) => {
      const res = await axios.post(`/User`, { ...data });
      return res.data;
    };

    return useMutation<User, ApiError, UserPayload>('userAdd', userAdd, options);
  },

  useUserUpdateQuery: (
    options?: Omit<UseMutationOptions<User, ApiError, UserPayload>, 'mutationKey' | 'mutationFn'>,
  ) => {
    const userUpdate = async (data: UserPayload) => {
      const fetch = await axios.patch(`/User`, { ...data });
      return fetch.data;
    };
    return useMutation<User, ApiError, UserPayload>('userUpdate', userUpdate, options);
  },

  useUserAll: (payload: PayloadAdvanceSearch<UserAdvancedSearch>) => {
    const {
      pageNumber,
      name,
      pageSize,
      sortColumn,
      status,
      associatedWith,
      assignedRoles,
      sortDirection,
      advancedSearch,
      shouldMatchAllCriteria,
    } = payload;

    const filterParams = {
      pageNumber,
      pageSize,
      sortColumn,
      sortDirection,
      name,
      firstName: advancedSearch?.firstName || name,
      lastName: advancedSearch?.lastName || name,
      status: status,
      associatedWith: advancedSearch?.entityType || associatedWith,
      associatedWithId: advancedSearch?.associatedWithId,
      associatedWithEntityId: advancedSearch?.entityId,
      businessRoleId: advancedSearch?.businessRoleId,
      assignedRoleId: advancedSearch?.assignedRoleId || assignedRoles,
      email: advancedSearch?.email || name,
      title: advancedSearch?.title,
      shouldMatchAllCriteria: shouldMatchAllCriteria,
    };

    const fetchUserList = async () => {
      const fetch = await axios.post<Pagination<AuthUser>>(`/User/page`, filterParams);
      return fetch.data;
    };
    const state = useQuery<Pagination<AuthUser>, ApiError>(['UserList', payload], fetchUserList);
    return state;
  },
  useUserDeleteQuery: (
    options?: Omit<
      UseMutationOptions<any, ApiError, { userId: number }>,
      'mutationKey' | 'mutationFn'
    >,
  ) => {
    const deleteUser = async ({ userId }: { userId: number }) => {
      const fetch = await axios.delete(`/User/${userId}`);

      return fetch.data;
    };

    return useMutation<any, ApiError, { userId: number }>('deleteUser', deleteUser, options);
  },
};

export default UserService;
