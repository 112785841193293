import React from 'react';

export default function EyeOpenIcon() {
  return (
    <svg
      id="eye_close_icon"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_3084" data-name="Group 3084" transform="translate(-1109 -319)">
        <rect
          id="Rectangle_2170"
          data-name="Rectangle 2170"
          width="24"
          height="24"
          transform="translate(1109 319)"
          fill="none"
        />
        <g id="Group_3054" data-name="Group 3054" transform="translate(1109 299.5)">
          <path
            id="Show"
            d="M12,39C4.149,39,.256,32.156.095,31.864a.75.75,0,0,1,0-.729C.256,30.844,4.149,24,12,24s11.744,6.845,11.906,7.136a.75.75,0,0,1,0,.729C23.744,32.156,19.851,39,12,39ZM1.633,31.5c.892,1.352,4.395,6,10.367,6,5.992,0,9.479-4.645,10.367-6-.892-1.352-4.395-6-10.367-6C6.008,25.5,2.521,30.145,1.633,31.5ZM12,36a4.5,4.5,0,1,1,4.5-4.5A4.505,4.505,0,0,1,12,36Zm0-7.5a3,3,0,1,0,3,3A3,3,0,0,0,12,28.5Z"
          />
        </g>
      </g>
    </svg>
  );
}
