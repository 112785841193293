import { Dispatch, SetStateAction, createContext } from 'react';

export type DeploymentStatus = {
  isDeploymentRunning: boolean;
  setIsDeploymentRunning: Dispatch<SetStateAction<boolean>>;
};

export const DeploymentStatusContext = createContext<DeploymentStatus>({
  isDeploymentRunning: false,
  setIsDeploymentRunning: () => {},
});
