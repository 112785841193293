import { defaultRowsPerPage } from '../hooks/useSaveSearchCriteria';

export const initialSellerSearchValues = {
  pageNumber: 1,
  pageSize: defaultRowsPerPage,
  sortColumn: 'sellerName',
  sortDirection: 'asc',
  agencyId: 1,
  status: '',
  name: '',
  phone: '',
  physicalLocationAddress: '',
  physicalLocationCity: '',
  physicalLocationZip: '',
  mailingAddress: '',
  mailingCity: '',
  mailingZip: '',
  organizationType: { value: null, label: null },
};

export const defaultAgenciesValues = {
  pageNumber: 1,
  pageSize: 10,
  sortColumn: '',
  sortDirection: 'asc',
  name: '',
  phone: '',
  physicalLocationAddress: '',
  physicalLocationCity: '',
  physicalLocationZip: '',
  mailingAddress: '',
  mailingCity: '',
  mailingZip: '',
};

export const defaultUserValues = {
  pageNumber: 1,
  pageSize: 10,
  sortColumn: 'firstName',
  sortDirection: 'asc',
  firstName: '',
  lastName: '',
  status: '',
  title: '',
  entityType: '',
  assignedRoleId: undefined,
  email: '',
};
