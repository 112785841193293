import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 *
 * @param queryKey to get query key
 * @returns value of search query
 */
export function useQuery(queryKey: string): string {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search).get(queryKey) || '', [search, queryKey]);
}
