import styled from 'styled-components';
import { Typography } from '@mui/material';

import { Black } from '../../../Theme/colorsVariables';

export const ModalTitle = styled(Typography)({
  marginTop: '10px',
  color: `${Black}`,
  fontWeight: 'bold',
  textAlign: 'center',
});
