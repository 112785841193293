import styled from 'styled-components';
import { Red } from '../../../Theme/colorsVariables';
import { breakPointMedium } from '../../../utils';

export const AppWarningParagraph = styled('p')<{ $align?: string }>`
  color: ${Red};
  font-size: 14px;
  font-family: Gotham-Book;
  margin: 0;
  text-align: ${(props) => (!!props.$align ? props.$align : 'right')};
  ${breakPointMedium('font-size: 12px;')}
`;
