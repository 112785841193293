import { useCallback, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppCancelButton } from '../../components/shared/AppCancelButton';
import PageHeaderContentHost from '../../components/shared/AppPageHeaderContentHost';
import { PageHeaderContainer } from '../../components/shared/AppPageHeaderContentHost/styles';
import { AppResetButton } from '../../components/shared/AppResetButton';
import { AppSaveButton } from '../../components/shared/AppSaveButton';
import { FlexBox } from '../../components/shared/FlexBox';
import { User, UserPayload } from '../../types';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import { UserForm } from '../../components/Forms/UserForm';
import { StyledTypography } from '../../components/Forms/RoleForm/noPermissionsText';
import { Grid } from '@mui/material';
import UserService from '../../services/userService';
import { useToast } from '../../hooks/useToast';
import { useNavigate } from 'react-router-dom';
import { StickyContainer } from '../../Theme/oldStylesInUse/oldAddSellerStyles';
import { useDataloss } from '../../hooks/useDataloss';
import { UserInvitationService } from '../../services/userServices';
import { QuickConfirmationModalContext } from '../../context/QuickModal';
import { useNavigateAfterDatalossDecision } from '../../hooks/useNavigateAfterDatalossDecision';

export const AddUser = () => {
  const { t } = useTranslation();
  const toast = useToast();

  const navigate = useNavigate();
  const { navigateAfterDatalossDecision } = useNavigateAfterDatalossDecision();

  const isCreateProviderUserPermission = usePermissions(PermissionKeys.CreateProviderUser);
  const isCreateAgentUserPermission = usePermissions(PermissionKeys.CreateAgentUser);

  const [isUnsavedData, setIsUnsavedData] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [cancelPopup, setCancelPopUp] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  const onInviteSuccess = (response: boolean, variables: number | string) => {
    if (response) {
      toast.success(t('toast.uset-form.success'));
    } else {
      toast.error(t('toast.uset-form.error.invite'));
    }
    if (!navigateAfterDatalossDecision()) {
      navigate(`/dashboard/User/${variables}`);
    }
  };

  const { mutate: SendUserInvite } = UserInvitationService({
    onSuccess: onInviteSuccess,
  });

  const onSuccessCreateUser = (data: User) => {
    toast.success(t('toast.add-user.success'));
    SendUserInvite(data?.userId?.toString());
  };

  const { setQuickModal } = useContext(QuickConfirmationModalContext);

  const { mutate: addUser } = UserService.useUserAddQuery({
    onSuccess: onSuccessCreateUser,
    onError(error, variables) {
      if (error.response.data.code === 40014) {
        setQuickModal({
          message: `User ${variables.firstName} ${variables.lastName} already exists.`,
          modalTitle: 'Duplicates!',
          isOpen: true,
        });
      } else {
        toast.error(error.response.data.message);
      }
    },
  });

  const handleFormSubmit = (data: UserPayload) => {
    addUser(data);
  };

  const onDiscardChanges = useCallback(() => {
    setCancelPopUp(false);
    navigate('/dashboard/User');
  }, [navigate]);

  const onSaveChanges = useCallback(() => {
    setCancelPopUp(false);
    if (formRef && formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }, []);

  const onExit = useCallback(() => {
    setCancelPopUp(false);
  }, []);

  useDataloss(isUnsavedData, onDiscardChanges, onSaveChanges, onExit);

  if (!isCreateProviderUserPermission && !isCreateAgentUserPermission) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <StyledTypography mt={10}>{t('dashboard.products.title')}</StyledTypography>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <StickyContainer>
        <PageHeaderContainer>
          <PageHeaderContentHost name={'Add User'} showRequiredLabel={true} />

          <FlexBox>
            <AppCancelButton
              onClick={() => {
                if (isUnsavedData) {
                  setCancelPopUp(true);
                } else {
                  navigate('/dashboard/User');
                }
              }}
            />
            <AppResetButton
              onClick={() => {
                formRef.current?.dispatchEvent(
                  new Event('reset', { cancelable: true, bubbles: true }),
                );
              }}
            />
            <AppSaveButton
              disabled={isSaveEnabled}
              onClick={() => {
                formRef.current?.dispatchEvent(
                  new Event('submit', { cancelable: true, bubbles: true }),
                );
              }}
            />
          </FlexBox>
        </PageHeaderContainer>
      </StickyContainer>
      <UserForm
        isEdit={true}
        setIsEdit={() => {}}
        onSubmit={handleFormSubmit}
        formRef={formRef}
        isSaveEnabled={isSaveEnabled}
        setIsSaveEnabled={setIsSaveEnabled}
        isUnsavedData={isUnsavedData}
        setIsUnsavedData={setIsUnsavedData}
        userStatus={true}
        setUserStatus={() => {}}
        cancelPopup={cancelPopup}
        setCancelPopup={setCancelPopUp}
      />
    </>
  );
};
