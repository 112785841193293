import axios from 'axios';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query';
import { Pagination, ApiError } from '../types';
import { Agreement } from '../types/sellerGroup';
import { ReviewPossiblePayload } from '../types/agreements';

export interface MAGAgreementsPayload {
  orgId: number | string;
  payload: {
    pageNumber: number;
    pageSize: number;
    sortColumn?: string;
    sortDirection?: string;
    shouldMatchAllCriteria: boolean;
  };
  options?: Omit<UseQueryOptions<Pagination<Agreement>, ApiError>, 'queryKey' | 'queryFn'>;
}

export const AgreementsService = {
  useExistingAgreements: ({ orgId, payload, options }: MAGAgreementsPayload) => {
    const getAgreements = async () => {
      const res = await axios.post(`Agreement/${orgId}/existing-relationships`, payload);
      return res.data;
    };
    return useQuery<Pagination<Agreement>, ApiError>(
      ['useExistingAgreements', payload, orgId],
      getAgreements,
      options,
    );
  },
  useNewRelationshipRequests: ({ orgId, payload, options }: MAGAgreementsPayload) => {
    const getAgreements = async () => {
      const res = await axios.post(`Agreement/${orgId}/new-relationships`, payload);
      return res.data;
    };
    return useQuery<Pagination<Agreement>, ApiError>(
      ['useNewRelationshipRequests', payload, orgId],
      getAgreements,
      options,
    );
  },
  useApprove: (
    options?: Omit<
      UseMutationOptions<
        string,
        ApiError,
        {
          agreementId: number;
        }
      >,
      'mutationKey' | 'mutationFn'
    >,
  ) => {
    const approve = async ({ agreementId }: { agreementId: number }) => {
      const res = await axios.post(`Agreement/${agreementId}/approve`);
      return res.data;
    };
    return useMutation<string, ApiError, { agreementId: number }>('useApprove', approve, options);
  },
  useReject: (
    options?: Omit<
      UseMutationOptions<
        string,
        ApiError,
        {
          agreementId: number;
          rejectReason: string;
        }
      >,
      'mutationKey' | 'mutationFn'
    >,
  ) => {
    const reject = async (payload: { agreementId: number; rejectReason: string }) => {
      const res = await axios.post(`Agreement/${payload.agreementId}/reject`, {
        reason: payload.rejectReason,
      });
      return res.data;
    };
    return useMutation<string, ApiError, { agreementId: number; rejectReason: string }>(
      'useReject',
      reject,
      options,
    );
  },
  useIsReviewPossible: (
    options?: Omit<
      UseMutationOptions<boolean, ApiError, ReviewPossiblePayload>,
      'mutationKey' | 'mutationFn'
    >,
  ) => {
    const req = async ({ orgId, reviewType }: ReviewPossiblePayload) => {
      const res = await axios.get(`Agreement/${orgId}/review-possible`, { params: { reviewType } });
      return res.data;
    };

    return useMutation<boolean, ApiError, ReviewPossiblePayload>('isReviewPossible', req, options);
  },
};
