import axios from 'axios';
import { useContext } from 'react';
import { useMutation, UseMutationOptions } from 'react-query';
import { UserContext } from '../context/User';
import { AcceptTermsType, ApiError, MyProfilePayload, UserInfo } from '../types';

export const useAcceptTermsOfUse = (
  options?: Omit<
    UseMutationOptions<unknown, ApiError, AcceptTermsType>,
    'mutationKey' | 'mutationFn'
  >,
) => {
  const acceptTerms = async (payload: AcceptTermsType) => {
    const response = await axios.post(`/Account/AgreedTerms`, payload);
    return response.data;
  };

  return useMutation<unknown, ApiError, AcceptTermsType>('accept', acceptTerms, options);
};

export const UserInvitationService = (
  options?: Omit<
    UseMutationOptions<boolean, ApiError, string | number>,
    'mutationKey' | 'mutationFn'
  >,
) => {
  const inviteQuery = async (userId: string | number) => {
    const fetch = await axios.get(`/user/resendInvite/${userId}`);
    return fetch.data;
  };

  const state = useMutation<boolean, ApiError, string | number>('invite', inviteQuery, options);

  return state;
};

export const useMyProfileUpdate = (
  options?: Omit<
    UseMutationOptions<UserInfo, ApiError, MyProfilePayload>,
    'mutationKey' | 'mutationFn'
  >,
) => {
  const user = useContext(UserContext);
  const updateMyProfile = async (data: MyProfilePayload) => {
    const res = await axios.put(`/Account`, { ...user, ...data });
    return res.data;
  };

  const state = useMutation<UserInfo, ApiError, MyProfilePayload>(
    'invite',
    updateMyProfile,
    options,
  );

  return state;
};
