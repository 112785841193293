import { createBrowserRouter } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import PublicRoutes from './PublicRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import { NotFoundPage } from '../pages/NotFound';
import App from '../App';

// ==============================|| ROUTING RENDER ||============================== //

export const router = createBrowserRouter([
  { path: '/', element: <App />, children: [MainRoutes, PublicRoutes, AuthenticationRoutes] },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]);
