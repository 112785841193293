import { ConfigurationService } from '../../../services/configurationService';
import { Box } from '@mui/material';
import PageHeaderContentHost from '../../../components/shared/AppPageHeaderContentHost';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
import { formatUtcToLocalTime } from '../../../utils/formatUtcToLocalTime';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  DataGrid,
  GridColDef,
  useGridApiRef,
  GridCellEditStopParams,
  MuiEvent,
  GridCellEditStopReasons,
} from '@mui/x-data-grid';
import { useToast } from '../../../hooks/useToast';

export default function TenantSetupPage() {
  const apiRef = useGridApiRef();
  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isReadEnabled = usePermissions(PermissionKeys.ViewConfiguration);
  const isModifyEnabled = usePermissions(PermissionKeys.ModifyConfiguration);
  const { data: tenantSetupInfo, refetch } = ConfigurationService.useTenantConfiguration({
    enabled: isReadEnabled,
  });
  const { mutate: updateValue } = ConfigurationService.useUpdateTenantConfiguration({
    onSuccess() {
      toast.success('Updated Successfully.');
      refetch();
    },
    onError(err) {
      toast.error(err.response.data.message);
    },
  });

  const rows = useMemo(() => {
    return (
      tenantSetupInfo?.map((item) => ({
        ...item,
        createdOn: formatUtcToLocalTime(item.createdOn || ''),
        createdBy: item.createdBy?.name,
        updatedOn: formatUtcToLocalTime(item.updatedOn || ''),
        updatedBy: item.updatedBy?.name,
        id: item.systemConfigureConstantId,
      })) || []
    );
  }, [tenantSetupInfo]);

  const columns: GridColDef[] = [
    { field: 'constantType', headerName: 'Constant Type', flex: 1 },
    { field: 'description', headerName: 'Description', flex: 1 },
    {
      field: 'constantValue',
      headerName: 'Constant Value',
      editable: isModifyEnabled,
      flex: 1,
    },
    { field: 'createdOn', headerName: 'Created On', flex: 1 },
    { field: 'createdBy', headerName: 'Created By', flex: 1 },
    { field: 'updatedOn', headerName: 'Updated By', flex: 1 },
    { field: 'updatedBy', headerName: 'Updated By', flex: 1 },
  ];

  useEffect(() => {
    if (!isReadEnabled) {
      navigate('/NotFound', { state: { error: t('restrictions.tooltip') } });
    }
  }, [isReadEnabled, navigate, t]);

  return (
    <>
      <Box my={1}>
        <PageHeaderContentHost name="Tenant Setup" />
      </Box>

      <DataGrid
        sx={{
          '& .MuiDataGrid-cell': {
            padding: '10px',
          },
        }}
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        autoPageSize
        getRowHeight={() => 'auto'}
        onCellEditStop={(params: GridCellEditStopParams, event: MuiEvent) => {
          if (params.reason === GridCellEditStopReasons.cellFocusOut) {
            event.defaultMuiPrevented = true;
          }
          const currentValue = params.row.constantValue;
          const updatedValue = (event as any)?.target?.value;

          if (updatedValue && currentValue !== updatedValue) {
            updateValue({
              systemConfigureConstantId: params.id as number,
              constantValue: updatedValue,
            });
          }
        }}
      />
    </>
  );
}
