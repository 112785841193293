/**
 * Prompts a user when they exit the page
 */

import { useContext, useEffect } from 'react';
import { UnsavedDataContext } from '../context/UnsavedData';

export function useDataloss(
  isUnsavedData: boolean,
  onDiscardChanges: () => void,
  onSaveChanges: () => void,
  onExit: () => void,
  hideSaveDataButton?: boolean,
) {
  const { setUnsavedData } = useContext(UnsavedDataContext);

  useEffect(() => {
    if (isUnsavedData) {
      window.onpopstate = function () {
        window.history.go(1);

        setUnsavedData({
          isUnsavedChanges: isUnsavedData,
          isPopupOpen: true,
          onDiscardChanges,
          onSaveChanges,
          onExit,
          returnUrl: 'back',
          hideSaveDataButton,
        });
      };

      setUnsavedData((prevValue) => ({
        ...prevValue,
        isUnsavedChanges: isUnsavedData,
        isPopupOpen: false,
        onDiscardChanges,
        onSaveChanges,
        onExit,
        hideSaveDataButton,
      }));
    }
    return () => {
      window.onpopstate = () => {};

      setUnsavedData((prevValue) => ({
        ...prevValue,
        isUnsavedChanges: false,
        isPopupOpen: false,
        onDiscardChanges,
        onSaveChanges,
        onExit,
        hideSaveDataButton,
      }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUnsavedData, onDiscardChanges, onExit, onSaveChanges, setUnsavedData, hideSaveDataButton]);
}
