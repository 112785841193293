import { Chip } from '@mui/material';
import styled from 'styled-components';
import { Gray, Leaden } from '../../../Theme/colorsVariables';
import { breakPointMedium } from '../../../utils';

export const ChipStyle = styled(Chip)`
  font-size: 15px;
  font-family: Roboto;
  color: ${Leaden};
  opacity: 1;
  font-weight: normal;
  background: ${Gray};
  border-radius: 25px;
  border: 1px solid ${Gray};
  ${breakPointMedium('font-size: 12px;')}
`;
