import { useEffect, useState } from 'react';

/**
 * Custom hook to call react query with some delay and condion.
 * @constructor
 * @function refetchMethod - The refetchMethod of the useQuery hook.
 * @param {boolean} condition - The condition to prevent API call.
 * @param {number} delay - The delay in ms.
 */
function useDebouncedQuery(refetchMethod: () => void, condition: boolean, delay = 1000) {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    let handler: NodeJS.Timeout | undefined = undefined;
    if (condition) {
      handler = setTimeout(() => {
        refetchMethod();
        setIsLoading(false);
      }, delay);
    }

    return () => {
      clearTimeout(handler);
    };
  }, [condition, delay, refetchMethod]);
  return { isLoading };
}

export default useDebouncedQuery;
