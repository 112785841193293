import dayjs from 'dayjs';

// example time strings 2023-10-12T16:10:53.377 or 2023-10-12T16:10:53
export function formatUtcToLocalTime(utcDate: string, time = true) {
  if (!utcDate) return '';

  if (!time) {
    return dayjs(utcDate.slice(0, 10)).format('MMM DD, YYYY');
  }
  const zeroTime =
    utcDate.slice(0, utcDate.lastIndexOf('.') !== -1 ? utcDate.lastIndexOf('.') : utcDate.length) +
    '.000Z';
  const date = new Date(zeroTime).toLocaleDateString('en-US', { timeZoneName: 'short' });

  return (
    dayjs(zeroTime).format('MMM DD, YYYY hh:mm:ss A') + ' ' + date.slice(date.lastIndexOf(', ') + 2)
  );
}
