import DeleteIcon from '../../assets/DeleteIcon';
import { AppActionButton, AppActionButtonProps } from '../AppActionButton';
import { useTranslation } from 'react-i18next';

export const AppDeleteButton: React.FC<AppActionButtonProps> = (props) => {
  const { t } = useTranslation();
  return (
    <AppActionButton {...props} variant="outlined" startIcon={<DeleteIcon />}>
      {t('app.action.button.delete')}
    </AppActionButton>
  );
};
