import styled from 'styled-components';
import { Menu } from '@mui/material';
import { HoverColor, MainColor, White } from '../../Theme/colorsVariables';
import { Link } from 'react-router-dom';
import { AppActionButton } from '../shared/AppActionButton';

export const CustomMenu = styled(Menu)`
  div:nth-child(3) {
    border-radius: 0;
    background-color: ${MainColor};
    color: ${White};
    padding: 0;
  }
  ul {
    border-radius: 0;
    li {
      transition: 0.2s all;
      &:hover {
        background-color: ${HoverColor};
      }
      a {
        text-decoration: none !important;
        color: ${White} !important;
      }
    }
  }
`;

export const QuickMenuLink = styled(Link)`
  color: ${White};
  text-decoration: none;
`;

export const AddEntityTypeSelect = styled(AppActionButton)({
  '@media only screen and (max-width: 1500px)': {
    maxHeight: 'unset',
    height: '100% !important',

    minWidth: '30px !important',
    fontSize: '14px !important',
    borderRadius: '14px !important',
    'span svg': {
      width: '12px !important',
      height: '12px !important',
    },
  },
});
