import React, { useEffect } from 'react';
import CustomModal from '../Modal';
import { AppActionButton } from '../../shared/AppActionButton';
import { ModalBox, ModalButtonsBox } from '../Modal/styles';
import { AppTextField } from '../../shared/AppTextField';
import { Box } from '@mui/material';
import { MainRed } from '../../../Theme/colorsVariables';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { object } from 'yup';
import { trimmedString } from '../../../Resolvers';
import { getCommonErrorMessages } from '../../../utils';
import { getCommonMaxLengthValue } from '../../../utils/inputMaxLength';

interface PropType {
  popup: boolean;
  confirm: (rejectReason: string) => void;
  cancel: () => void;
}

export default function RejectModal(props: PropType) {
  const { popup, confirm, cancel } = props;
  const {
    control,
    formState: { errors },
    watch,
    clearErrors,
    setError,
  } = useForm<{ rejectReason: string }>({
    mode: 'onChange',
    resolver: yupResolver(
      object({
        rejectReason: trimmedString.max(
          getCommonMaxLengthValue('rejectReason'),
          getCommonErrorMessages('maxLength') + getCommonMaxLengthValue('rejectReason'),
        ),
      }),
    ),
  });
  const { rejectReason } = watch();

  useEffect(() => {
    if (rejectReason && rejectReason.length < getCommonMaxLengthValue('rejectReason')) {
      clearErrors('rejectReason');
    }
  }, [clearErrors, rejectReason]);

  return (
    <ModalBox>
      <CustomModal open={popup} containerWidth="500px" modalTitle={'Rejection Note to Seller'}>
        <>
          <Box mt={2}></Box>
          <Controller
            control={control}
            name="rejectReason"
            render={({ field }) => (
              <AppTextField
                {...field}
                multiline
                focused
                minRows={2}
                required
                error={!!errors.rejectReason?.message}
                errorMessage={errors.rejectReason?.message}
                label={'Please provide a reason for rejection to Seller'}
              />
            )}
          />
          <ModalButtonsBox>
            <AppActionButton
              background={MainRed}
              variant="outlined"
              onClick={() => {
                if (!rejectReason) {
                  setError('rejectReason', {
                    type: 'required',
                    message: getCommonErrorMessages('required'),
                  });
                  return;
                }
                confirm(rejectReason);
              }}
              disabled={!rejectReason}
            >
              Reject
            </AppActionButton>
            <AppActionButton variant="outlined" onClick={cancel}>
              Cancel
            </AppActionButton>
          </ModalButtonsBox>
        </>
      </CustomModal>
    </ModalBox>
  );
}
