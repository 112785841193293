import { FC, useEffect, useRef } from 'react';
import { useAgreementActivity } from '../../../services/dashboard';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { AlignCenterBox } from '../../../components/shared/FlexBox';
import { ActivityContainer, ActivityDetail, IconStyle } from '../styles';
import { AgreementsActivity } from '../../../types/dashboard';

const AgreementActivityForAgent: FC = () => {
  const {
    data: activityData,
    refetch: fetchActivity,
    isError: activityError,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useAgreementActivity();

  const activityRef = useRef<null | HTMLElement>(null);
  const activityTopRef = useRef<null | HTMLElement>(null);

  const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { scrollHeight, scrollTop, offsetHeight } = event.currentTarget;
    const scrollLength = scrollHeight - offsetHeight - 100;

    if (!isFetching && scrollTop >= scrollLength && hasNextPage) {
      fetchNextPage();
    }
  };

  const renderDetails = ({
    agreementType,
    orgId,
    orgName,
    orgType,
    event,
    actionBy,
  }: AgreementsActivity) => {
    if (!orgType) {
      return <>unknown OrgType</>;
    }

    const agreementsLink = (
      <Link to={`/dashboard/${orgType}/${orgId}/Agreement`} target="_blank">
        {orgName}
      </Link>
    );

    const entityName = orgType === 'SellerGroup' ? 'Seller Group' : orgType;

    switch (event) {
      case 'Sent':
        return (
          <>
            {agreementType} sent to {entityName} {agreementsLink} was sent to {actionBy} for
            signature
          </>
        );

      case 'Voided':
        return (
          <>
            {agreementType} sent to {entityName} {agreementsLink} was voided by {actionBy}
          </>
        );
      case 'Waiting for Others':
        return (
          <>
            {agreementType} sent to {entityName} {agreementsLink} was signed by {actionBy}
          </>
        );
      case 'Completed':
        return (
          <>
            {agreementType} sent to {entityName} {agreementsLink} was signed by everyone
          </>
        );
      case 'Review Approved':
        return (
          <>
            {agreementType} sent to {entityName} {agreementsLink} was approved by {actionBy}
          </>
        );
      case 'Review Declined':
        return (
          <>
            {agreementType} sent to {entityName} {agreementsLink} was declined by {actionBy}
          </>
        );
      case 'Declined':
        return (
          <>
            {agreementType} sent to {entityName} {agreementsLink} was declined by {actionBy}
          </>
        );

      default:
        return <>unknown event: {event}</>;
    }
  };

  useEffect(() => {
    fetchActivity();
  }, [fetchActivity]);

  if (activityError) {
    return <div>Error loading activity, please try sometime later</div>;
  }

  return (
    <ActivityContainer onScroll={(e) => onScroll(e)}>
      <Box ref={activityTopRef}></Box>
      {(activityData?.pages ?? []).map((page) =>
        page.data.map((item) => (
          <AlignCenterBox key={item.agreementId + item.lastModifiedOn} mb={3}>
            <Box mt="4px">
              <IconStyle />
            </Box>
            <ActivityDetail ml={1}>{renderDetails(item)}</ActivityDetail>
          </AlignCenterBox>
        )),
      )}
      <Box ref={activityRef}></Box>
      {activityData && !hasNextPage && <Box textAlign="center">No more data</Box>}
    </ActivityContainer>
  );
};

export default AgreementActivityForAgent;
