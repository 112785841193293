import styled from 'styled-components';
import { Box, Menu } from '@mui/material';
import { Black, MainColor, MainTextColor, White } from '../../Theme/colorsVariables';

export const MenuButton = styled(Box)`
  cursor: pointer;
  margin-right: 8px;
  width: 40px;
  height: 40px;

  svg {
    width: 100%;
    height: 100%;
    margin: auto;
    path {
      fill: ${MainColor};
    }
  }
`;

export const CustomMenu = styled(Menu)`
  div:nth-child(3) {
    border-radius: 0;
    background-color: ${White};
    color: ${MainTextColor};
    box-shadow: 0px 6px 20px #00000033;
  }
  ul {
    padding: 0;
    li {
      padding: 15px !important;
      flex-direction: column !important;
      align-items: flex-end !important;
      cursor: unset;
      &:first-child {
        padding-bottom: 7px !important;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
      }
      a {
        color: ${MainTextColor};
        text-decoration: none;
      }
      span {
        margin-bottom: 5px;
        a {
          display: flex;
          color: ${MainTextColor};
          svg {
            background: ${MainColor};
            padding: 5px;
            border-radius: 25px;
            fill: ${White};
            margin-left: 10px;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      &:last-child {
        padding-top: 7px !important;
      }
      &:hover {
        background: ${White} !important;
      }
    }
  }
`;
