import { createContext, Dispatch, SetStateAction } from 'react';

export interface IUnsavedData {
  onDiscardChanges: () => void;
  onSaveChanges: () => void;
  onExit: () => void;
  isPopupOpen: boolean;
  isUnsavedChanges: boolean;
  returnUrl: string;
  hideSaveDataButton?: boolean;
}

interface IUnsavedDataContext {
  unsavedData: IUnsavedData;
  setUnsavedData: Dispatch<SetStateAction<IUnsavedData>>;
}

export const UnsavedDataContext = createContext<IUnsavedDataContext>({
  unsavedData: {
    isPopupOpen: false,
    isUnsavedChanges: false,
    onDiscardChanges: () => {},
    onSaveChanges: () => {},
    onExit: () => {},
    returnUrl: '',
    hideSaveDataButton: false,
  },
  setUnsavedData: () => {},
});
