import { Gray } from '../../Theme/colorsVariables';
import { Card } from '@mui/material';
import styled from 'styled-components';

export const CardStyle = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 5px;

  border-radius: 0;
  border: 1px solid ${Gray};
  box-shadow: none;
  font-size: 16px;
  font-family: Gotham-Bold;
  font-weight: bold;
  word-break: break-word;
  text-align: center;
`;
