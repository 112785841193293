import React from 'react';
import DocumentPreview from '../layout/DocumentPreview';
import PublicLayout from '../layout/PublicLayout';
import Registration from '../layout/Registration';
import DocusignRedirectPage from '../pages/DocusignRedirectPage';

type Routes = {
  path: string;
  element: JSX.Element;
  children: {
    path: string;
    element: JSX.Element;
  }[];
};

const PublicRoutes: Routes = {
  path: '/public',
  element: <PublicLayout />,
  children: [
    {
      path: '/public/document-preview',
      element: <DocumentPreview />,
    },
    {
      path: '/public/register',
      element: <Registration />,
    },
    {
      path: '/public/DocuSign',
      element: <DocusignRedirectPage />,
    },
  ],
};

export default PublicRoutes;
