import { FormControlLabel } from '@mui/material';
import styled from 'styled-components';
import { FontSizeLowestMin, FontSizeMed } from '../../Theme/screensVariables';
import { AppActionButton } from '../shared/AppActionButton';
import { breakPointMedium } from '../../utils';
import { MainTextColor } from '../../Theme/colorsVariables';

export const ChangePassButton = styled(AppActionButton)`
  text-transform: unset !important;
`;

export const CheckboxMain = styled(FormControlLabel)`
  padding: 8px;
  .MuiFormControlLabel-label {
    font-family: Gotham-Medium;
    font-size: ${FontSizeMed};
    ${breakPointMedium(`font-size: ${FontSizeLowestMin}`)}
  }
  .MuiFormControlLabel-label.Mui-disabled {
    color: ${MainTextColor};
  }
`;
