import React, { ReactNode } from 'react';
import { CustomTable, CustomTableContainer, TableGrowContainer } from './styles';

export default function DataTable({
  children,
  tableMaxHeight,
}: {
  children: ReactNode;
  tableMaxHeight: string;
}) {
  return (
    <TableGrowContainer id={'tableGrowContainer'}>
      <CustomTableContainer height={tableMaxHeight}>
        <CustomTable stickyHeader style={{ tableLayout: 'auto' }}>
          {children}
        </CustomTable>
      </CustomTableContainer>
    </TableGrowContainer>
  );
}
