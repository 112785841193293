import { Grid } from '@mui/material';
import { StyledTypography } from '../../Forms/RoleForm/noPermissionsText';

export const NoPermissionMessage = ({ message }: { message: string }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <StyledTypography>{message}</StyledTypography>
      </Grid>
    </Grid>
  );
};
