import styled from 'styled-components';
import { SpaceBetweenBox } from '../../components/shared/FlexBox';
import { SecondaryBackground, White } from '../../Theme/colorsVariables';
import { AppActionButton } from '../../components/shared/AppActionButton';

export const SecondHeaderBox = styled(SpaceBetweenBox)`
  padding: 5px 10px;
  background: ${SecondaryBackground};
  .pageTitle {
    color: ${White};
    font-size: 20px;
  }
  .pageName {
    font-size: 20px;
  }
  .statusLabel {
    font-size: 16px;
  }
`;

export const AppStatusButton = styled(AppActionButton)`
  margin-right: auto;
`;
