const RemoveDocumentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      height="22px"
      width="22px"
      viewBox="0 0 104.69 122.88"
    >
      <g>
        <path d="M98.38,88.41l-9.79,10.48L98.38,88.41L98.38,88.41z M84.3,103.48l-9.79,10.48L84.3,103.48L84.3,103.48z M56.85,116.58 c1.74,0,3.15,1.41,3.15,3.15c0,1.74-1.41,3.15-3.15,3.15H7.33c-2.02,0-3.85-0.82-5.18-2.15C0.82,119.4,0,117.57,0,115.55V7.33 c0-2.02,0.82-3.85,2.15-5.18C3.48,0.82,5.31,0,7.33,0h90.02c2.02,0,3.85,0.82,5.18,2.15c1.33,1.33,2.15,3.16,2.15,5.18V72.6 c0,1.74-1.41,3.15-3.15,3.15c-1.74,0-3.15-1.41-3.15-3.15V7.33c0-0.28-0.12-0.54-0.3-0.73c-0.19-0.19-0.45-0.3-0.73-0.3H7.33 c-0.28,0-0.54,0.12-0.73,0.3S6.3,7.05,6.3,7.33v108.21c0,0.28,0.12,0.54,0.3,0.73c0.19,0.19,0.44,0.3,0.73,0.3H56.85L56.85,116.58z M31.54,86.95c-1.74,0-3.16-1.43-3.16-3.19c0-1.76,1.41-3.19,3.16-3.19h20.5c1.74,0,3.16,1.43,3.16,3.19 c0,1.76-1.41,3.19-3.16,3.19H31.54L31.54,86.95z M31.54,42.27c-1.74,0-3.15-1.41-3.15-3.15c0-1.74,1.41-3.15,3.15-3.15h41.61 c1.74,0,3.15,1.41,3.15,3.15c0,1.74-1.41,3.15-3.15,3.15H31.54L31.54,42.27z M31.54,64.59c-1.74,0-3.15-1.41-3.15-3.15 c0-1.74,1.41-3.15,3.15-3.15h41.61c1.74,0,3.15,1.41,3.15,3.15c0,1.74-1.41,3.15-3.15,3.15H31.54L31.54,64.59z M71.52,91.55 c-1.27-1.18-1.34-3.17-0.16-4.44c1.18-1.27,3.17-1.34,4.44-0.16l10.49,9.79l9.78-10.48c1.18-1.27,3.17-1.34,4.44-0.16 c1.27,1.18,1.34,3.17,0.16,4.44l-9.79,10.48l10.48,9.78c1.27,1.18,1.34,3.17,0.16,4.44c-1.18,1.27-3.17,1.34-4.44,0.16l-10.48-9.79 l-9.78,10.48c-1.18,1.27-3.17,1.34-4.44,0.16c-1.27-1.18-1.34-3.17-0.16-4.44L82,101.34L71.52,91.55L71.52,91.55z" />
      </g>
    </svg>
  );
};

export default RemoveDocumentIcon;
