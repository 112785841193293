import React from 'react';

export default function UpDown() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 10 15">
      <path
        id="up_down"
        d="M-4087.477,1672.784l-4.325-4.771a.751.751,0,0,1,.473-1.263h8.655a.751.751,0,0,1,.475,1.263l-4.326,4.771a.641.641,0,0,1-.476.216A.642.642,0,0,1-4087.477,1672.784Zm-3.851-8.533a.752.752,0,0,1-.473-1.264l4.325-4.769a.632.632,0,0,1,.952,0l4.326,4.769a.752.752,0,0,1-.475,1.264Z"
        transform="translate(4092 -1658)"
        fill="#77868f"
      />
    </svg>
  );
}
