import { Box } from '@mui/material';
import styled from 'styled-components';
import { breakPointMedium } from '../../../utils/MediaBreakPoint';

const BoxTextRequire = styled(Box)`
  color: rgba(0, 0, 0, 0.6);
  font-family: Gotham-Book;
  font-size: 15px;
  span {
    color: red;
  }
  ${breakPointMedium('font-size: 13px')};
`;

export { BoxTextRequire };
