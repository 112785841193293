import { SidebarType } from '../../../types';

const sidebarItems = (): SidebarType[] => [
  {
    title: 'Agents',
    type: 'item',
    to: '/dashboard/Agent',
    text: 'sidebarRelationshipAgencies',
  },
  {
    title: 'Sellers',
    type: 'item',
    to: '/dashboard/Seller',
    text: 'sidebarRelationshipSeller',
  },
  {
    title: 'Seller Groups',
    type: 'item',
    to: '/dashboard/SellerGroup',
    text: 'sidebarRelationshipSellerGroups',
  },
  {
    title: 'Product Lines',
    type: 'item',
    to: '/dashboard/ProductLine',
    text: 'sidebarproducts',
  },
  {
    title: 'Agreement Templates',
    type: 'item',
    to: '/dashboard/AgreementTemplate',
    text: 'sidebarDocumentTemplates',
  },

  {
    title: 'Users',
    type: 'item',
    to: '/dashboard/User',
    text: 'sidebarSecurityUsers',
  },
  {
    title: 'Roles',
    type: 'item',
    to: '/dashboard/Role',
    text: 'sidebarSecurityRoles',
  },
  {
    title: 'Configuration',
    type: 'item',
    to: '/dashboard/Configuration',
    text: 'sidebarConfiguration',
  },
  {
    title: 'System Administration',
    type: 'item',
    to: '/dashboard/SystemAdmin',
    text: 'sidebarAdmin',
  },
];

export default sidebarItems;
