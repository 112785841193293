import * as signalR from '@microsoft/signalr';
const URL = `${process.env.REACT_APP_API_URL}/system-hub`;
class DeploymentStatus {
  private connection: signalR.HubConnection;

  public events: (onMessageReceived: (message: 'under_maintenance' | null) => void) => void;

  static instance: DeploymentStatus;

  constructor() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(URL)
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build();
    this.connection.start();
    this.events = (onMessageReceived) => {
      this.connection.on('SYSTEN_HUB_MAINTENANCE_MESSAGE', (message) => {
        onMessageReceived(message);
      });
    };
  }

  public static getInstance(): DeploymentStatus {
    if (!DeploymentStatus.instance) DeploymentStatus.instance = new DeploymentStatus();
    return DeploymentStatus.instance;
  }
}
export default DeploymentStatus.getInstance;
