import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { OpenSideBar, PathToActiveColorMap } from './interface';
import SidebarItem from './SidebarItem';

export default function Sidebar({ open }: OpenSideBar) {
  const [activeColor, setActiveColor] = React.useState<string>('');

  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;

    const pathToActiveColorMap: PathToActiveColorMap = {
      '/Agent': 'Agents',
      '/SellerGroup': 'Seller Groups',
      '/Seller': 'Sellers',
      '/ProductLine': 'Product Lines',
      '/AgreementTemplate': 'Agreement Templates',
      '/User': 'Users',
      '/Role': 'Roles',
      '/SystemAdmin': 'System Administration',
      '/dashboard': '',
    };

    const activeCurrentColor =
      Object.keys(pathToActiveColorMap).find((path) => pathname.includes(path)) || '';
    setActiveColor(pathToActiveColorMap[activeCurrentColor]);
  }, [location]);

  return <>{open && <SidebarItem isDrawerOpen={open} activeColor={activeColor} />}</>;
}
