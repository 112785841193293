import { TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EnvelopeAuditLog } from '../../services/DocuSignService';
import { formatUtcToLocalTime } from '../../utils/formatUtcToLocalTime';
import { HeadingBox } from '../shared/CustomTable/styles';
import { CustomTable } from '../table/DataTable/styles';
import { AuditTableContainer, AuditTableRow } from './styles';

interface EnvelopeAuditLogTableProps {
  envelopeAuditLogDetails: EnvelopeAuditLog[];
}

export default function EnvelopeAuditLogTable({
  envelopeAuditLogDetails,
}: EnvelopeAuditLogTableProps) {
  const { t } = useTranslation();
  const auditTableHeaders: string[] = t('envelope.audit.headers', { returnObjects: true });

  return (
    <AuditTableContainer>
      <CustomTable stickyHeader>
        <TableHead>
          <TableRow>
            {auditTableHeaders.map((header) => {
              return (
                <TableCell key={header}>
                  <HeadingBox>{header}</HeadingBox>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {envelopeAuditLogDetails.map((log) => (
            <AuditTableRow key={log.time + log.user}>
              <TableCell>{formatUtcToLocalTime(`${log.time}`)}</TableCell>
              <TableCell>{log.user}</TableCell>
              <TableCell>{log.action}</TableCell>
              <TableCell>{log.activity}</TableCell>
              <TableCell>{log.status}</TableCell>
            </AuditTableRow>
          ))}
        </TableBody>
      </CustomTable>
    </AuditTableContainer>
  );
}
