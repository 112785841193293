import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
import { SidebarType } from '../../../types';
import sidebarItems from './items';
import NavCollapse from '../NavCollapse';
import NavItem from '../NavItem';
import { SidebarItemProps } from './types';
import { SideItemsList } from '../styles';
import { UserContext } from '../../../context/User';

export default function SidebarItem({ isDrawerOpen, activeColor }: SidebarItemProps) {
  const user = useContext(UserContext);
  const allPermission = user?.permissions;
  const [selected, setSelected] = useState<string | null>(null);
  const isViewRoleAgenciesPermission = usePermissions(PermissionKeys.ReadAgentRoles);
  const isViewRoleProviderPermission = usePermissions(PermissionKeys.ReadProviderRoles);
  const isReadProviderUserPermission = usePermissions(PermissionKeys.ReadProviderUser);
  const isReadAgentUserPermission = usePermissions(PermissionKeys.ReadAgentUser);
  const isViewAgencyPermission = usePermissions(PermissionKeys.ReadAgent);
  const isViewSellerPermission = usePermissions(PermissionKeys.ViewSellers);
  const managePermission = (item: any) => {
    return (
      (item.title === 'Security' &&
        (!isViewRoleAgenciesPermission || !isReadAgentUserPermission) &&
        (!isViewRoleProviderPermission || !isReadProviderUserPermission)) ||
      (item.title === 'Relationships' && !isViewAgencyPermission && !isViewSellerPermission)
    );
  };

  const NavItemRender = (item: any) => {
    if (!user || !allPermission || managePermission(item)) {
      return <></>;
    }

    return (
      <NavCollapse
        key={item.text}
        selected={selected}
        activeColor={activeColor}
        setSelected={(rr) => setSelected(rr)}
        setActiveColor={() => {}}
        menu={item}
        level={1}
        isDrawerOpen={isDrawerOpen}
      />
    );
  };

  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;

    if (pathname.endsWith('/dashboard')) {
      setSelected('');
    } else if (pathname.includes('product')) {
      setSelected('sidebarproducts');
    } else if (pathname.includes('agreements')) {
      setSelected('sidebarDocumentTemplates');
    }
  }, [location]);

  return (
    <SideItemsList>
      {sidebarItems().map((item: SidebarType) => {
        const key = item.text;
        if (item.type === 'item') {
          return (
            <NavItem
              section="outer"
              activeColor={activeColor}
              key={key}
              item={item}
              level={1}
              isDrawerOpen={isDrawerOpen}
            />
          );
        } else {
          return <Box key={key}>{NavItemRender(item)}</Box>;
        }
      })}
    </SideItemsList>
  );
}
