import { createContext, Dispatch, SetStateAction } from 'react';
import { IQuickModalProps } from '../QuickModal';

export interface IDecisionModalPropsContext {
  quickDecisionModal: IQuickModalProps;
  setQuickDecisionModal: Dispatch<SetStateAction<IQuickModalProps>>;
}

export const QuickDecisionModalContext = createContext<IDecisionModalPropsContext>({
  quickDecisionModal: {
    isOpen: false,
    message: '',
    modalTitle: '',
    yesCallBack: () => {},
    noCallBack: () => {},
  },
  setQuickDecisionModal: () => {},
});
