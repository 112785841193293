import { AppActionButton, AppActionButtonProps } from '../AppActionButton';
import EditIcon from '../../assets/EditIcon';
import { useTranslation } from 'react-i18next';

export const AppEditButton: React.FC<AppActionButtonProps> = (props) => {
  const { t } = useTranslation();
  return (
    <AppActionButton {...props} variant="outlined" startIcon={<EditIcon />}>
      {t('app.action.button.edit')}
    </AppActionButton>
  );
};
