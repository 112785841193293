import * as TsDiff from 'diff';
import { useMemo } from 'react';
import { Yellow } from '../../../../Theme/colorsVariables';

export const RenderTextDiff = (inputA: string, inputB: string) => {
  const diff = useMemo(() => {
    return TsDiff.diffWords(inputA, inputB, { ignoreCase: true });
  }, [inputA, inputB]);

  const renderResult = (part: TsDiff.Change, index: number) => {
    const spanStyle = {
      backgroundColor: part.added ? Yellow : part.removed ? 'salmon' : '',
    };
    return (
      <span key={index}>
        <span style={spanStyle}>{part.value}</span>
      </span>
    );
  };

  const resultA = useMemo(() => {
    return diff
      .filter((part) => part.removed || !part.added)
      .map((part, index) => renderResult(part, index));
  }, [diff]);

  const resultB = useMemo(() => {
    return diff
      .filter((part) => !part.removed || part.added)
      .map((part, index) => renderResult(part, index));
  }, [diff]);

  return { resultA, resultB };
};
