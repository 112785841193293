import { Typography } from '@mui/material';
import styled from 'styled-components';
import { FlashWhite, MainTextColor } from '../../../Theme/colorsVariables';

export const Main = styled.div`
  background-color: ${FlashWhite};
  width: 100%;
  margin-bottom: 10px;
`;

export const TypographyS = styled(Typography)`
  font-size: 17px;
  font-family: Gotham-Bold;
  color: ${MainTextColor};
  margin-left: 12px;
`;
