import React from 'react';
import { BlockHeader } from './styles';

interface StyledHeaderProps {
  text: string;
}

const StyledProductLineHeader: React.FC<StyledHeaderProps> = ({ text }) => {
  return <BlockHeader>{text}</BlockHeader>;
};

export default StyledProductLineHeader;
