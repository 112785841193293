import React from 'react';

export default function HelpIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
      <path
        id="help_ic"
        d="M-4087.607,1683.606A14.906,14.906,0,0,1-4092,1673a14.906,14.906,0,0,1,4.393-10.606A14.9,14.9,0,0,1-4077,1658a14.906,14.906,0,0,1,10.607,4.393A14.906,14.906,0,0,1-4062,1673a14.919,14.919,0,0,1-1.142,5.741,14.929,14.929,0,0,1-3.252,4.866,14.935,14.935,0,0,1-4.866,3.252A14.943,14.943,0,0,1-4077,1688,14.9,14.9,0,0,1-4087.607,1683.606ZM-4089.5,1673a12.514,12.514,0,0,0,12.5,12.5,12.514,12.514,0,0,0,12.5-12.5,12.431,12.431,0,0,0-.952-4.784,12.41,12.41,0,0,0-2.709-4.054,12.443,12.443,0,0,0-4.055-2.71,12.435,12.435,0,0,0-4.783-.952A12.515,12.515,0,0,0-4089.5,1673Zm11.25,8.751A1.25,1.25,0,0,1-4077,1680.5a1.25,1.25,0,0,1,1.249,1.251A1.25,1.25,0,0,1-4077,1683,1.251,1.251,0,0,1-4078.25,1681.75Zm0-3.75v-3.75A1.25,1.25,0,0,1-4077,1673a3.75,3.75,0,0,0,3.75-3.75,3.749,3.749,0,0,0-3.75-3.749,3.75,3.75,0,0,0-3.751,3.749,1.249,1.249,0,0,1-1.25,1.251,1.251,1.251,0,0,1-1.25-1.251,6.251,6.251,0,0,1,5.832-6.235,6.251,6.251,0,0,1,6.612,5.4,6.251,6.251,0,0,1-4.944,6.961V1678a1.25,1.25,0,0,1-1.249,1.25A1.25,1.25,0,0,1-4078.25,1678Z"
        transform="translate(4092 -1658)"
        fill="#77868f"
      />
    </svg>
  );
}
