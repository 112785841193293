import { Box, Link, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import { AccountOwnerContainer, AccountOwnerContainerSmall } from '../../layout/Topbar/styles';
import { UserContext } from '../../context/User';
import { FetchLookups } from '../../services/sellerServices';

const AccountOwner = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));
  const user = useContext(UserContext);
  const { data: lookups } = FetchLookups();
  return isSmall ? (
    <AccountOwnerContainerSmall>
      <Box>
        <b>
          {lookups?.accountOwnerLabel && lookups.accountOwnerLabel[0].value
            ? lookups.accountOwnerLabel[0].value
            : 'Wise F&I Rep'}
          :
        </b>{' '}
        {user?.accountOwnerLabel || '-'}
      </Box>
      <Box>
        <b>Phone: </b>
        {user?.accountOwnerPhone ? (
          <Link sx={{ textDecoration: 'none' }} href={`tel:${user.accountOwnerPhone}`}>
            {user.accountOwnerPhone}
          </Link>
        ) : (
          '-'
        )}
      </Box>
      <Box>
        <b>Email: </b>
        {user?.accountOwnerEmail ? (
          <Link sx={{ textDecoration: 'none' }} href={`mailto:${user.accountOwnerEmail}`}>
            {user.accountOwnerEmail}
          </Link>
        ) : (
          '-'
        )}
      </Box>
    </AccountOwnerContainerSmall>
  ) : (
    <AccountOwnerContainer>
      <Box>
        <b>
          {lookups?.accountOwnerLabel && lookups.accountOwnerLabel[0].value
            ? lookups.accountOwnerLabel[0].value
            : 'Wise F&I Rep'}
          :
        </b>{' '}
        {user?.accountOwnerLabel || '-'}
      </Box>
      <Box>
        <b>Phone: </b>
        {user?.accountOwnerPhone ? (
          <Link sx={{ textDecoration: 'none' }} href={`tel:${user.accountOwnerPhone}`}>
            {user.accountOwnerPhone}
          </Link>
        ) : (
          '-'
        )}
      </Box>
      <Box>
        <b>Email: </b>
        {user?.accountOwnerEmail ? (
          <Link sx={{ textDecoration: 'none' }} href={`mailto:${user.accountOwnerEmail}`}>
            {user.accountOwnerEmail}
          </Link>
        ) : (
          '-'
        )}
      </Box>
    </AccountOwnerContainer>
  );
};

export default AccountOwner;
