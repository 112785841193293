import { Box } from '@mui/material';
import styled from 'styled-components';
import { CenterCenterBox, FlexBox } from '../../shared/FlexBox';

export const BoxS = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0;
  overflow: hidden;
`;

export const BoxCardS = styled(CenterCenterBox)`
  padding: 10px 15px 30px;
  flex-direction: column;
`;

export const ModalButtonsBox = styled(FlexBox)`
  margin: 20px 0 0;
`;

export const ModalBox = styled(CenterCenterBox)`
  border-radius: 30px;
  cursor: pointer;
  svg {
    width: 18px;
    height: 18px;
  }
`;
