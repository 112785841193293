import { StepButton, Grid, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  AppActionButtonsContainer,
  AppActionButton,
} from '../../components/shared/AppActionButton';
import PageHeaderContentHost from '../../components/shared/AppPageHeaderContentHost';
import { FlexBox, SpaceBetweenBox } from '../../components/shared/FlexBox';
import {
  StickyContainer,
  BoxStyledMain,
  Lable,
  Arrow,
  MainStepper,
  MainSteps,
} from '../../Theme/oldStylesInUse/oldAddSellerStyles';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { CreateOrganizationForm } from '../../components/Forms/CreateOrganizationForm';
import { OrganizationType } from '../../types/query-params';
import { OrganizationService } from '../../services/organizationService';
import { useToast } from '../../hooks/useToast';
import { FeinSource, OrganizationPayload } from '../../types/organization';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import { OrganizationFormType } from '../../components/Forms/CreateOrganizationForm/types';
import { useDataloss } from '../../hooks/useDataloss';
import { OrganizationContactsTab } from '../ContactsTab';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddOrganizationContact from '../AddOrganizationContact';
import { OrganizationContact } from '../../types/sellerGroupContacts';
import { OrganizationContactInfoPage } from '../OrganizationContactInfoPage';
import {
  Organizations,
  UpdateSellerGroupQuery,
  useOrganizationInfo,
} from '../../services/sellerGroupServices';
import { UpdateSellerGroupPayload, UpdateSellerGroupRequestPayload } from '../../types/sellerGroup';
import { SellerList } from '../SellerList';
import { AddProductLines } from '../../components/SellerGroupTabs/ProductLinesTab/AddProductLines';
import { CancelModal } from '../../components/modal/CancelModal';
import { useSellerAll } from '../../services/sellerServices';
import { useOrganizationContacts } from '../../services/sellerGroupContactsService';
import { UnsavedDataContext } from '../../context/UnsavedData';
import { QuickDecisionModalContext } from '../../context/QuickDecisionModal';
import { UserContext } from '../../context/User';
import { getPhonePayload } from '../../components/shared/AppPhoneField';
import { useNavigateAfterDatalossDecision } from '../../hooks/useNavigateAfterDatalossDecision';
import { patchFormatter } from '../../utils/organizationPatchFormatter';
import LabelRequired from '../../components/shared/LabelRequired';

enum AddSellerGroupSteps {
  BusinessInfo = 0,
  Sellers = 1,
  Contacts = 2,
  ProductLines = 3,
}

export const AddFinishSellerGroup = () => {
  const { sellerGroupId } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const toast = useToast();
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const steps: string[] = t('addSellerGroup.steps', { returnObjects: true });
  const { unsavedData, setUnsavedData } = useContext(UnsavedDataContext);
  const { navigateAfterDatalossDecision } = useNavigateAfterDatalossDecision();
  const { setQuickDecisionModal } = useContext(QuickDecisionModalContext);

  const businessInfoFormRef = useRef<HTMLFormElement>(null);
  const contactFormRef = useRef<HTMLFormElement>(null);

  const isAddSellerGroupPermission = usePermissions(PermissionKeys.CreateOrganization);
  const isUpdateSellerGroupPermission = usePermissions(PermissionKeys.UpdateOrganization);
  const isViewPagePermission = useMemo(() => {
    if (location.pathname.includes('/Finish')) {
      return isUpdateSellerGroupPermission;
    }
    return isAddSellerGroupPermission;
  }, [isAddSellerGroupPermission, isUpdateSellerGroupPermission, location.pathname]);

  const [activeStep, setActiveStep] = useState(AddSellerGroupSteps.BusinessInfo);
  const [createdSellerGroupId, setCreatedSellerGroupId] = useState(0);
  const [customFieldError, setCustomFieldError] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [isUnsavedData, setIsUnsavedData] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [nextStep, setNextStep] = useState<AddSellerGroupSteps | null>(null);

  const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(true);
  const [showContactsTable, setShowContactsTable] = useState<boolean>(false);
  const [contactToEdit, setContactToEdit] = useState<OrganizationContact | undefined>(undefined);

  const { data: sellers } = useSellerAll({
    pageNumber: 1,
    pageSize: 5,
    sellerGroupIds: [Number(createdSellerGroupId)],
    isEnabled: !!createdSellerGroupId,
  });

  const { data: organizationContacts, refetch: refetchContactsCount } = useOrganizationContacts({
    pageNumber: 1,
    pageSize: 5,
    orgId: Number(createdSellerGroupId),
    shouldMatchAllCriteria: false,
  });

  const { data: sellerGroupData, refetch: refetchSellerGroup } = useOrganizationInfo({
    id: Number(createdSellerGroupId),
    isEnabled: createdSellerGroupId !== 0,
    organisation: Organizations.SellerGroupOrg,
  });

  const { mutate: createOrganization } = OrganizationService.useCreateSellerGroup({
    setCustomFieldError,
    onSuccess(data, variables) {
      setCreatedSellerGroupId(data);

      if (!navigateAfterDatalossDecision()) {
        setQuickDecisionModal({
          modalTitle: 'Warning!',
          message: `Seller Group ${variables.payload.legalName} added.</br>Would you like to add a Seller?`,
          isOpen: true,
          yesCallBack: () => {
            navigate(
              `/dashboard/Seller/Add?sellerGroupId=${data}&sellerGroupName=${variables.payload.legalName}`,
            );
          },
          noCallBack: () => {
            setActiveStep(AddSellerGroupSteps.Sellers);
          },
        });
      }
    },
  });
  const { mutate: updateSellerGroup } = UpdateSellerGroupQuery({
    setCustomFieldError,
    mutationOptions: {
      onSuccess(data, variables) {
        toast.success(t('sellerGroup.update.success', { name: variables.payload.legalName }));
        refetchSellerGroup();

        if (!navigateAfterDatalossDecision()) {
          if (typeof nextStep === 'number') {
            setActiveStep(nextStep);
            setNextStep(null);
            return;
          }
          setActiveStep(AddSellerGroupSteps.Sellers);
        }
      },
    },
  });

  const handleAddUpdateBusinessInfo = async (formValues: OrganizationFormType) => {
    const data: OrganizationPayload = {
      legalName: formValues.legalName,
      legalID: formValues.fein?.replaceAll('-', '') || null,
      legalEntityTypeId: formValues.legalOrganization?.value,
      organizationType: 1,
      doingBusinessAs: formValues.doingBusinessAs || null,
      addresses: [
        {
          addressType: 1,
          streetAddressLine1: formValues.physicalLocation.address1,
          streetAddressLine2: formValues.physicalLocation.address2 || null,
          city: formValues.physicalLocation.city,
          country: 'US',
          stateId: formValues.physicalLocation.state?.value?.addressStateId,
          zipCode: formValues.physicalLocation.zip,
          sameAsAddressTypeId: null,
          googlePlaceId: formValues.physicalLocation.googlePlaceId || '',
        },
        {
          addressType: 2,
          streetAddressLine1: formValues.mailingLocation.address1,
          streetAddressLine2: formValues.mailingLocation.address2 || null,
          city: formValues.mailingLocation.city,
          country: 'US',
          stateId: formValues.mailingLocation.state?.value?.addressStateId,
          zipCode: formValues.mailingLocation.zip,
          sameAsAddressTypeId: formValues.isLocationsTheSame ? 1 : null,
          googlePlaceId: formValues.mailingLocation.googlePlaceId || '',
        },
      ],
      ...getPhonePayload(formValues.phone),
      websiteURL: formValues.website || null,
      fax: formValues.fax?.replaceAll('-', '') || null,
      createdBy: user?.userId || 0,
      updatedBy: user?.userId || 0,
      sellerGroupId: formValues.sellerGroup?.value,
      feinSource: formValues.isSellerGroupFein ? FeinSource.SellerGroup : FeinSource.Seller,
    };
    if (createdSellerGroupId === 0) {
      // Create Business Info (Create Seller Group)
      createOrganization({
        payload: data,
      });
    } else {
      const originalData: Partial<UpdateSellerGroupPayload> = {
        legalName: sellerGroupData?.legalName,
        legalId: sellerGroupData?.fein?.replaceAll('-', '') || '',
        legalEntityTypeId: sellerGroupData?.legalEntityTypeId,
        organizationType: 1,
        doingBusinessAs: sellerGroupData?.doingBusinessAs,
        addresses: [
          {
            addressId: sellerGroupData?.addresses[0].addressId,
            addressType: sellerGroupData?.addresses[0].addressTypeId,
            streetAddressLine1: sellerGroupData?.addresses[0].streetAddressLine1,
            streetAddressLine2: sellerGroupData?.addresses[0].streetAddressLine2,
            city: sellerGroupData?.addresses[0].city,
            stateId: sellerGroupData?.addresses[0].stateId,
            zipCode: sellerGroupData?.addresses[0].zipCode,
            sameAsAddressTypeId: sellerGroupData?.addresses[0].sameAsAddressTypeId,
            googlePlaceId: sellerGroupData?.addresses[0].googlePlaceId || '',
          },
          {
            addressId: sellerGroupData?.addresses[1].addressId,
            addressType: sellerGroupData?.addresses[1].addressTypeId,
            streetAddressLine1: sellerGroupData?.addresses[1].streetAddressLine1,
            streetAddressLine2: sellerGroupData?.addresses[1].streetAddressLine2,
            city: sellerGroupData?.addresses[1].city,
            stateId: sellerGroupData?.addresses[1].stateId,
            zipCode: sellerGroupData?.addresses[1].zipCode,
            sameAsAddressTypeId: sellerGroupData?.addresses[1].sameAsAddressTypeId,
            googlePlaceId: sellerGroupData?.addresses[1].googlePlaceId || '',
          },
        ],
        phoneNumber: sellerGroupData?.addresses[0]?.phoneNumber || null,
        phoneNumberExt: sellerGroupData?.addresses[0]?.phoneNumberExt || null,
        phoneNumberCountryCode: sellerGroupData?.addresses[0]?.phoneNumberCountryCode || null,
        websiteURL: sellerGroupData?.addresses[0].website,
        fax: sellerGroupData?.addresses[0].mainFax?.replaceAll('-', ''),
      };
      const updateDataPayload: Partial<UpdateSellerGroupPayload> = {
        legalName: data.legalName,
        legalId: data.legalID?.replaceAll('-', '') || '',
        legalEntityTypeId: data.legalEntityTypeId,
        organizationType: 1,
        doingBusinessAs: data.doingBusinessAs || null,
        addresses: [
          {
            addressId: sellerGroupData?.addresses[0].addressId,
            addressType: 1,
            streetAddressLine1: data.addresses[0].streetAddressLine1,
            streetAddressLine2: data.addresses[0].streetAddressLine2 || null,
            city: data.addresses[0].city,
            country: 'US',
            stateId: data.addresses[0].stateId,
            zipCode: data.addresses[0].zipCode,
            sameAsAddressTypeId: null,
            googlePlaceId: data?.addresses[0].googlePlaceId || '',
          },
          {
            addressId: sellerGroupData?.addresses[1].addressId,
            addressType: 2,
            streetAddressLine1: data.addresses[1].streetAddressLine1,
            streetAddressLine2: data.addresses[1].streetAddressLine2 || null,
            city: data.addresses[1].city,
            country: 'US',
            stateId: data.addresses[1].stateId,
            zipCode: data.addresses[1].zipCode,
            sameAsAddressTypeId: data.addresses[1].sameAsAddressTypeId,
            googlePlaceId: data?.addresses[1].googlePlaceId || '',
          },
        ],
        phoneNumber: data.phoneNumber,
        phoneNumberExt: data.phoneNumberExt,
        phoneNumberCountryCode: data.phoneNumberCountryCode,
        websiteURL: data.websiteURL || null,
        fax: data.fax?.replaceAll('-', ''),
      };

      const updatePayload: UpdateSellerGroupRequestPayload = {
        id: createdSellerGroupId || 0,
        payload: patchFormatter(
          originalData,
          updateDataPayload,
        ) as Partial<UpdateSellerGroupPayload>,
      };

      updateSellerGroup(updatePayload);
    }
  };

  const onDiscardChanges = useCallback(() => {
    if (sellerGroupId) {
      navigate(`/dashboard/SellerGroup/${sellerGroupId}`);
      return;
    }
    navigate(`/dashboard/SellerGroup`);
  }, [navigate, sellerGroupId]);

  const onSaveChanges = useCallback(() => {
    if (activeStep === AddSellerGroupSteps.BusinessInfo) {
      businessInfoFormRef.current?.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      );
    }
  }, [activeStep]);

  const onDiscardStepChange = useCallback(() => {
    if (typeof nextStep === 'number') setActiveStep(nextStep);
    setNextStep(null);
    setCancelModalOpen(false);
  }, [nextStep]);

  const onSaveStepChange = useCallback(() => {
    setCancelModalOpen(false);
    if (activeStep === AddSellerGroupSteps.BusinessInfo) {
      businessInfoFormRef.current?.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      );
    }
    if (activeStep === AddSellerGroupSteps.Contacts && !showContactsTable) {
      contactFormRef.current?.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      );
    }
  }, [activeStep, showContactsTable]);

  const onExit = useCallback(() => {
    setCancelModalOpen(false);
    setNextStep(null);
  }, [setCancelModalOpen]);

  useDataloss(
    isUnsavedData && activeStep === AddSellerGroupSteps.BusinessInfo,
    onDiscardChanges,
    onSaveChanges,
    onExit,
  );

  useEffect(() => {
    if (sellerGroupId) {
      setCreatedSellerGroupId(Number(sellerGroupId));
      setActiveStep(AddSellerGroupSteps.Sellers);
    }
  }, [location.state, sellerGroupId]);

  useEffect(() => {
    refetchContactsCount();
  }, [refetchContactsCount, showContactsTable]);

  if (!isViewPagePermission) {
    return (
      <Grid container textAlign="center">
        <Grid item md={12} xs={12}>
          <Typography fontFamily="Gotham-Bold" fontSize="20px" mt={10}>
            {t('restrictions.tooltip')}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <StickyContainer>
        <SpaceBetweenBox mt={1}>
          <PageHeaderContentHost
            title={createdSellerGroupId ? 'Seller Group' : ''}
            name={
              sellerGroupData
                ? sellerGroupData.legalName
                : t('relationship.sellers.addsellers.forms.businessinfo.addsellergroup')
            }
          />
        </SpaceBetweenBox>
      </StickyContainer>

      <BoxStyledMain my={1}>
        <MainStepper>
          {steps.map((step, index) => (
            <MainSteps
              props={[activeStep, index]}
              key={(Math.random() + 1).toString(36).substring(7)}
            >
              <StepButton
                onClick={() => {
                  if (
                    isUnsavedData &&
                    activeStep !== AddSellerGroupSteps.Sellers &&
                    !showContactsTable
                  ) {
                    setCancelModalOpen(true);
                    setNextStep(index);
                    return;
                  }
                  if (AddSellerGroupSteps.Contacts === index && organizationContacts?.totalCount) {
                    if (organizationContacts?.totalCount > 0) {
                      setShowContactsTable(true);
                    } else {
                      setShowContactsTable(false);
                    }
                  }
                  setActiveStep(index);
                }}
                disabled={activeStep === AddSellerGroupSteps.BusinessInfo && !createdSellerGroupId}
                style={{
                  cursor:
                    activeStep === AddSellerGroupSteps.BusinessInfo && !createdSellerGroupId
                      ? 'default'
                      : 'pointer',
                }}
              >
                <Lable
                  StepIconComponent={() => <></>}
                  active={activeStep.toString()}
                  position={index.toString()}
                  style={{
                    cursor:
                      activeStep === AddSellerGroupSteps.BusinessInfo && !createdSellerGroupId
                        ? 'default'
                        : 'pointer',
                  }}
                >
                  {index === AddSellerGroupSteps.Sellers
                    ? `Sellers (${sellers?.totalCount || 0})`
                    : index === AddSellerGroupSteps.Contacts
                    ? `Contacts (${organizationContacts?.totalCount || 0})`
                    : step}
                </Lable>
              </StepButton>
              <Arrow len={steps.length} active={activeStep + 1} setpPosition={index + 1} />
            </MainSteps>
          ))}
        </MainStepper>
      </BoxStyledMain>

      {activeStep !== AddSellerGroupSteps.ProductLines && (
        <SpaceBetweenBox>
          {((activeStep === AddSellerGroupSteps.Contacts && !showContactsTable) ||
            (activeStep !== AddSellerGroupSteps.Contacts &&
              activeStep !== AddSellerGroupSteps.Sellers)) && (
            <Box>
              <LabelRequired />
            </Box>
          )}
          <AppActionButtonsContainer>
            <AppActionButton
              onClick={() => {
                if (
                  isUnsavedData &&
                  !showContactsTable &&
                  activeStep !== AddSellerGroupSteps.Sellers
                ) {
                  setUnsavedData({
                    ...unsavedData,
                    returnUrl: sellerGroupId
                      ? `/dashboard/SellerGroup/${sellerGroupId}`
                      : '/dashboard/SellerGroup',
                    isPopupOpen: true,
                  });
                  return;
                }
                if (sellerGroupId) {
                  navigate(`/dashboard/SellerGroup/${sellerGroupId}`);
                  return;
                }
                navigate(`/dashboard/SellerGroup`);
              }}
            >
              {createdSellerGroupId
                ? t('relationship.sellers.addsellers.linearstepper.finishLater')
                : t('relationship.sellers.addsellers.linearstepper.cancel')}
            </AppActionButton>

            <AppActionButton
              sx={{ minWidth: '165px !important' }}
              variant="outlined"
              disabled={
                activeStep === AddSellerGroupSteps.BusinessInfo
                  ? !isFormValid
                  : activeStep === AddSellerGroupSteps.Contacts && !showContactsTable
                  ? isSaveEnabled
                  : activeStep === AddSellerGroupSteps.Contacts && showContactsTable
                  ? false
                  : false
              }
              onClick={() => {
                setUnsavedData({ ...unsavedData, returnUrl: '' });
                if (activeStep === AddSellerGroupSteps.BusinessInfo) {
                  if (isUnsavedData) {
                    businessInfoFormRef.current?.dispatchEvent(
                      new Event('submit', { cancelable: true, bubbles: true }),
                    );
                  } else {
                    setActiveStep(AddSellerGroupSteps.Sellers);
                  }
                }

                if (activeStep === AddSellerGroupSteps.Contacts && !showContactsTable) {
                  contactFormRef.current?.dispatchEvent(
                    new Event('submit', { cancelable: true, bubbles: true }),
                  );
                  setNextStep(AddSellerGroupSteps.ProductLines);
                  navigateAfterDatalossDecision();
                }
                if (activeStep === AddSellerGroupSteps.Contacts && showContactsTable) {
                  setActiveStep(AddSellerGroupSteps.ProductLines);
                }
                if (activeStep === AddSellerGroupSteps.Sellers) {
                  setActiveStep(AddSellerGroupSteps.Contacts);
                  if (organizationContacts?.totalCount && organizationContacts?.totalCount > 0) {
                    setShowContactsTable(true);
                  }
                }
              }}
            >
              {(activeStep === AddSellerGroupSteps.Contacts && showContactsTable) ||
              (!isUnsavedData &&
                [AddSellerGroupSteps.BusinessInfo, AddSellerGroupSteps.Sellers].includes(
                  activeStep,
                ))
                ? t('relationship.sellers.addsellers.linearstepper.continue')
                : t('relationship.sellers.addsellers.linearstepper.saveandcontinue')}
            </AppActionButton>
          </AppActionButtonsContainer>
        </SpaceBetweenBox>
      )}
      {activeStep === AddSellerGroupSteps.ProductLines && (
        <SpaceBetweenBox>
          <Box>
            <LabelRequired />
          </Box>
          <AppActionButtonsContainer>
            <AppActionButton
              onClick={() => {
                if (isUnsavedData) {
                  setUnsavedData({
                    ...unsavedData,
                    returnUrl: sellerGroupId
                      ? `/dashboard/SellerGroup/${sellerGroupId}`
                      : '/dashboard/SellerGroup',
                    isPopupOpen: true,
                  });
                  return;
                }
                navigate(
                  sellerGroupId
                    ? `/dashboard/SellerGroup/${sellerGroupId}`
                    : `/dashboard/SellerGroup`,
                );
              }}
              variant="outlined"
            >
              {t('relationship.sellers.addsellers.linearstepper.finishLater')}
            </AppActionButton>
          </AppActionButtonsContainer>
        </SpaceBetweenBox>
      )}

      {AddSellerGroupSteps.Sellers !== activeStep && (
        <Grid container spacing={2} mt={activeStep === AddSellerGroupSteps.ProductLines ? 0 : 1}>
          <Grid item xs={12}>
            {AddSellerGroupSteps.BusinessInfo === activeStep && (
              <CreateOrganizationForm
                editForm
                formRef={businessInfoFormRef}
                onSubmit={handleAddUpdateBusinessInfo}
                setIsFormValid={setIsFormValid}
                setIsUnsavedChanges={setIsUnsavedData}
                setCustomFieldError={setCustomFieldError}
                customFieldError={customFieldError}
                orgType={OrganizationType.SellerGroup}
                orgData={sellerGroupData}
              />
            )}

            {AddSellerGroupSteps.Contacts === activeStep &&
              !showContactsTable &&
              !contactToEdit && (
                <AddOrganizationContact
                  customOrgName={Organizations.SellerGroupOrg}
                  customOrgId={`${createdSellerGroupId}`}
                  customFormRef={contactFormRef}
                  setShowContactsTable={setShowContactsTable}
                  setIsSaveEnabled={setIsSaveEnabled}
                  setCustomUnsavedDataFlag={setIsUnsavedData}
                  callbackOnContactAddSucess={() => {
                    if (typeof nextStep === 'number') {
                      setActiveStep(nextStep);
                      setNextStep(null);
                    }
                  }}
                  nextStep={nextStep}
                  refetchParentContacts={refetchContactsCount}
                />
              )}
            {AddSellerGroupSteps.Contacts === activeStep && !showContactsTable && contactToEdit && (
              <OrganizationContactInfoPage
                customContactId={`${contactToEdit.contactId}`}
                customOrgId={`${createdSellerGroupId}`}
                setShowContactsTable={setShowContactsTable}
                setContactToEdit={(val) => {
                  setContactToEdit(val);
                  setTimeout(() => {
                    refetchContactsCount();
                  }, 1000);
                }}
                customFormRef={contactFormRef}
                customOrganization={Organizations.SellerGroupOrg}
              />
            )}
            {AddSellerGroupSteps.ProductLines === activeStep && (
              <AddProductLines
                customOrgId={`${createdSellerGroupId}`}
                setIsUnsavedData={setIsUnsavedData}
              />
            )}
          </Grid>
        </Grid>
      )}

      {AddSellerGroupSteps.Sellers === activeStep && (
        <>
          <FlexBox sx={{ justifyContent: 'end', width: '100%' }} mt={1}>
            <AppActionButton
              variant="outlined"
              sx={{ maxHeight: '34px', minWidth: '100px !important' }}
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => {
                navigate(
                  `/dashboard/Seller/Add?sellerGroupId=${createdSellerGroupId}&sellerGroupName=${sellerGroupData?.legalName}`,
                );
              }}
            >
              {t('app.action.button.add')}
            </AppActionButton>
          </FlexBox>
          <SellerList orgId={createdSellerGroupId} />
        </>
      )}
      {AddSellerGroupSteps.Contacts === activeStep && showContactsTable && (
        <>
          <FlexBox sx={{ justifyContent: 'end', width: '100%' }}>
            <AppActionButton
              variant="outlined"
              sx={{ maxHeight: '34px', minWidth: '100px !important' }}
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => {
                setShowContactsTable(false);
                setContactToEdit(undefined);
              }}
            >
              {t('app.action.button.add')}
            </AppActionButton>
          </FlexBox>
          <OrganizationContactsTab
            orgId={createdSellerGroupId}
            viewContactOptionalCallback={(contact) => {
              setShowContactsTable(false);
              setContactToEdit(contact);
            }}
          />
        </>
      )}
      <CancelModal
        isOpened={cancelModalOpen}
        modalTitle={t('security.roles.roleForm.role.warning')}
        onDiscardChanges={onDiscardStepChange}
        onSaveChanges={onSaveStepChange}
        onExit={onExit}
        hideSaveDataButton={activeStep === AddSellerGroupSteps.ProductLines}
      />
    </>
  );
};
