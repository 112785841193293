import { array, boolean, object } from 'yup';
import { trimmedString } from '.';
import { getCommonErrorMessages } from '../utils';
import { getCommonMaxLengthValue } from '../utils/inputMaxLength';

export const dealershipInfoValidationSchema = object().shape(
  {
    dealerLicenseId: trimmedString.max(
      getCommonMaxLengthValue('dealerLicenseId'),
      getCommonErrorMessages('charExceeded'),
    ),
    showOems: boolean(),
    dealerTypeGroup: object().notRequired().nullable(),
    assetMakeIds: array().when('showOems', {
      is: true,
      then: array().min(1, getCommonErrorMessages('required')),
    }),
    showOtherDms: boolean(),
    otherDmsAdditionalError: boolean().oneOf([false], getCommonErrorMessages('exists')),
    dmsIds: array(),
    dmsOtherName: trimmedString.when('showOtherDms', {
      is: true,
      then: trimmedString.required(getCommonErrorMessages('required')),
    }),
    showOtherMenu: boolean(),
    otherMenuAdditionalError: boolean().oneOf([false], getCommonErrorMessages('exists')),
    fiMenuIds: array(),
    fiMenuOtherName: trimmedString.when('showOtherMenu', {
      is: true,
      then: trimmedString.required(getCommonErrorMessages('required')),
    }),
    integrationPartner: trimmedString.max(
      getCommonMaxLengthValue('dealerLicenseId'),
      getCommonErrorMessages('charExceeded'),
    ),
  },
  [],
);
