const getDays = (activityDate: string) => {
  const currentDate = new Date();
  const actionDate = new Date(activityDate);
  const interval = currentDate.getTime() - actionDate.getTime();

  return interval / (1000 * 3600 * 24);
};

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

function getTimeLine(someDateTimeStamp: Date) {
  const today = new Date();

  const dt = new Date(someDateTimeStamp),
    date = dt.getDate(),
    month = months[dt.getMonth()],
    diffDays = today.getDate() - date,
    diffMonths = today.getMonth() - dt.getMonth(),
    diffYears = today.getFullYear() - dt.getFullYear();

  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const lastSevenDays = new Date(today);
  lastSevenDays.setDate(lastSevenDays.getDate() - 7);
  const lastThreeMonths = new Date(today);
  lastThreeMonths.setMonth(lastThreeMonths.getMonth() - 3);

  if (diffYears === 0 && diffDays === 0 && diffMonths === 0) {
    return 'Today';
  } else if (
    dt.getDate() === yesterday.getDate() &&
    dt.getMonth() === yesterday.getMonth() &&
    dt.getFullYear() === yesterday.getFullYear()
  ) {
    return 'Yesterday';
  } else if (dt > lastSevenDays) {
    return 'Last week';
  } else if (dt <= lastSevenDays && diffMonths === 0) {
    return 'This month';
  } else if (dt <= lastSevenDays && diffMonths === 1) {
    return 'Last month';
  } else if (dt >= lastThreeMonths) {
    return month + ' ' + dt.getFullYear();
  } else {
    return 'Older than 3 months';
  }
}

const getActivityLink = (entity: string, id: number, message: string) => {
  switch (entity) {
    case 'Product':
      return `/dashboard/ProductLine/${id}`;
    case 'Agent':
      return `/dashboard/Agent/${id}`;
    case 'Seller':
      return `/dashboard/Seller/${id}`;
    case 'Provider user':
    case 'Agency user':
    case 'Seller user':
      return `/dashboard/User/${id}`;
    default:
      return '';
  }
};

export { getDays, getTimeLine, getActivityLink };
