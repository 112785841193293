import { UseQueryOptions } from 'react-query';
import { Pagination } from './agent';
import { ApiError } from '.';

export interface OrganizationAddress {
  addressType: number;
  streetAddressLine1: string;
  streetAddressLine2: string | null;
  city: string;
  country: string;
  stateId?: number;
  zipCode: string;
  sameAsAddressTypeId: number | null;
  googlePlaceId: string;
}

export enum FeinSource {
  Seller = 0,
  SellerGroup = 1,
}

export interface OrganizationPayload {
  legalName: string;
  legalID: string | null;
  organizationType: number;
  legalEntityTypeId?: number;
  doingBusinessAs: string | null;
  addresses: OrganizationAddress[];
  phoneNumber: string | null;
  phoneNumberExt: string | null;
  phoneNumberCountryCode: string | null;
  websiteURL: string | null;
  fax: string | null;
  createdBy: number;
  updatedBy: number;
  sellerGroupId?: number | null;
  feinSource?: FeinSource;
}
export interface OrganizationPayloadExtended {
  payload: OrganizationPayload;
}
export interface OrgDuplicate {
  entityType: string;
  entityName: string;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  zip: string;
  fein: string;
  phoneNumberCountryCode: string;
  phoneNumber: string;
  duplicationFlags: DuplicationFlags;
}
export interface OrgDuplicatePayload {
  orgId: number | string;
  payload: {
    pageNumber: number;
    pageSize: number;
    sortColumn?: string;
    sortDirection?: string;
    shouldMatchAllCriteria: boolean;
  };
  options?: Omit<
    UseQueryOptions<Pagination<OrgDuplicate & { agreementId?: number }>, ApiError>,
    'queryKey' | 'queryFn'
  >;
}

export enum DuplicationFlags {
  None = 0,
  PhoneNumber = 1,
  PhysicalAddress = 2,
  PhoneAndAddress = 3,
  Fein = 4,
  PhoneAndFein = 5,
  AddressAndFein = 6,
  All = 7,
}
