import { Box, Pagination, TextField } from '@mui/material';
import styled from 'styled-components';
import { Gray, LightOrange, LightSlateGray, Silver, White } from '../../../Theme/colorsVariables';
import { breakPointSmall } from '../../../utils/MediaBreakPoint';
import { CenterCenterBox } from '../../shared/FlexBox';

export const StyledInputMedia = `
height: 30px;
width: 30px;
`;

export const StyledBoxMedia = `
font-size: 15px;
line-height: 17px;
`;

export const StyledButtonBoxMedia = `
width: 30px;
height: 30px;
`;

export const StyledInput = styled(TextField)`
  border: 1px solid ${Gray};
  border-radius: 8px;
  ${breakPointSmall(StyledInputMedia)}
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
  div {
    height: 100%;
  }
  input {
    background: ${White};
    border-radius: 8px;
    color: ${LightSlateGray};
    padding: 0;
    height: 100%;
    text-align: center;
  }
`;

export const StyledPagination = styled(Pagination)`
  ul {
    li {
      width: 30px;
      height: 30px;
      button {
        color: ${Silver};
        font-size: 18px;

        &:hover {
          border-radius: 8px;
        }
      }
      [aria-current]:not([aria-current='false']) {
        color: ${LightOrange} !important;
        background: ${White};
        font-weight: 600;
        border-radius: 8px;
      }
      svg {
        fill: ${LightOrange};
      }
    }
  }
`;

export const IconDiv = styled(Box)`
  cursor: pointer;
  padding-left: 6px;
`;

export const TableCountRowsText = styled(Box)`
  font-size: 18px;
  color: ${Silver};
  font-weight: 600;
  ${breakPointSmall(StyledBoxMedia)}
`;

export const StyledButtonBox = styled(CenterCenterBox)`
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: ${LightOrange};
  color: ${White};
  font-size: 14px;
  margin-left: 10px;
  ${breakPointSmall(StyledButtonBoxMedia)}
`;

export const StyledPaginationBox = styled(Box)`
  width: 40px;
  height: 40px;
  ${breakPointSmall(StyledButtonBoxMedia)}
`;
export const BoxForPage = styled(Box)`
  color: ${LightSlateGray};
  font-size: 14px;
  display: inline-block;
`;
