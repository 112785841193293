import React from 'react';
import { useTranslation } from 'react-i18next';
import { FetchTerms } from '../../services/utilsService';
import { Iframe } from './style';
import { FlexBox } from '../shared/FlexBox';

export default function Terms() {
  const { t } = useTranslation();
  const { data: terms } = FetchTerms();

  return (
    <FlexBox flexDirection={'column'} flex={'1 1 auto'}>
      <h2>{t('terms.header')}</h2>
      <Iframe
        style={{ minHeight: 'unset', height: '100%' }}
        id="terms_frame_id"
        src={terms?.contentUrl}
      ></Iframe>
    </FlexBox>
  );
}
