import axios from 'axios';
import { useQuery } from 'react-query';
import { ApiError } from '../../types';

export type EntityType =
  | 'all'
  | 'Agent'
  | 'AgencyContact'
  | 'Contact'
  | 'Seller'
  | 'SellerGroupContact';
interface SearchPayload {
  searchTerm: string;
  entityType: EntityType;
}

export interface SearchEntity {
  id: string;
  isDeleted: boolean;
  name: string;
  searchEntityType:
    | 'Seller'
    | 'Agency'
    | 'SellerContact'
    | 'AgencyContact'
    | 'SellerGroupContact'
    | 'SellerGroup';
  additionalIdentifier: string;
  orgId: number;
  orgName: string;
  orgType: 'Seller' | 'Agency';
}

export const GetSearchEntity = ({ searchTerm, entityType }: SearchPayload) => {
  const fetchLink = async () => {
    const fetch = await axios.post(`/search`, {
      searchTerm,
      entityType,
    });

    return fetch?.data;
  };
  return useQuery<SearchEntity[], ApiError>(
    ['GetSearchResults', searchTerm, entityType],
    fetchLink,
    { enabled: searchTerm.length > 2 },
  );
};
