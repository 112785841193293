import { object } from 'yup';
import { trimmedString } from '.';
import { getCommonErrorMessages } from '../utils';
import { getCommonMaxLengthValue } from '../utils/inputMaxLength';

export const productLineValidationSchema = object({
  productName: trimmedString
    .max(getCommonMaxLengthValue('productName'), getCommonErrorMessages('charExceeded'))
    .required(getCommonErrorMessages('required')),
  description: trimmedString.max(
    getCommonMaxLengthValue('description'),
    getCommonErrorMessages('charExceeded'),
  ),
});
