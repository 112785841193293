import { Tooltip, TooltipProps } from '@mui/material';
import { FC } from 'react';
import { TooltipMessage } from '../../InfoTooltip/TooltipMessage';

const AppTooltip: FC<TooltipProps> = (props) => {
  return (
    <Tooltip {...props} title={props.title ? <TooltipMessage message={props.title} /> : null} />
  );
};

export default AppTooltip;
