import { Box } from '@mui/material';
import styled from 'styled-components';
import { WhiteSmoke } from '../../../Theme/colorsVariables';

const Layover = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1298;
  width: 100%;
  height: 100%;
  background: ${WhiteSmoke};
`;

export { Layover };
