import { AvailableRole, ParticularRoleData, RolesData, RolesPayload } from '../types/role';
import axios, { AxiosResponse } from 'axios';
import { useContext } from 'react';
import { useQuery, useMutation, UseMutationOptions, UseQueryOptions } from 'react-query';
import { Permission, PaginationResponse, ApiError, OrgType, SelectItem } from '../types';
import { PermissionSet } from '../types/permission';
import { useToast } from '../hooks/useToast';
import { UserContext } from '../context/User';
export interface RolePayload {
  associatedWith: string;
  roleType: number;
  roleName: string;
  roleDescription: string;
  roleId?: number;
  isActive: boolean;
  permissionSetIds: number[];
  restrictAccessToOwnAgents: boolean;
}

export const PermissionService = {
  useRolePermissions: ({ orgType }: { orgType: OrgType }) => {
    const user = useContext(UserContext);
    const fetchRolePermission = async () => {
      const fetch = await axios.get(`/Permission`, { params: { orgType } });
      return fetch?.data;
    };
    const state = useQuery<Permission[], Error>(['Permission', orgType], fetchRolePermission, {
      retry: true,
      cacheTime: 900000,
      enabled: !!user && !!orgType,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity,
    });
    return state;
  },
};

export const RoleService = {
  useAll: (
    payload: RolesPayload,
    options?: Omit<
      UseQueryOptions<PaginationResponse<RolesData>, ApiError>,
      'queryKey' | 'queryFn'
    >,
  ) => {
    const toast = useToast();
    const fetchRoles = async () => {
      const fetch = await axios.post<PaginationResponse<RolesData>>(`/Role/page`, payload);
      return fetch.data;
    };

    const state = useQuery<PaginationResponse<RolesData>, ApiError>(
      ['RolesData', payload],
      fetchRoles,
      {
        onError(err) {
          toast.error(err.response.data.message);
        },
        ...options,
      },
    );
    return state;
  },
};

export const useAddRole = (
  handleSuccess: (data: AxiosResponse) => void,
  handleError: (data: ApiError) => void,
) => {
  const addCreate = async (data: RolePayload) => {
    const fetch = await axios.post(`/Role`, { ...data });
    return fetch.data;
  };

  return useMutation<any, ApiError, RolePayload>('addCreate', addCreate, {
    onSuccess: (data: AxiosResponse) => {
      handleSuccess(data);
    },
    onError(error) {
      handleError(error);
    },
  });
};

export const useCheckRolePermissionQuery = (
  options?: Omit<UseMutationOptions<boolean, ApiError, RolePayload>, 'mutationKey' | 'mutationFn'>,
) => {
  const checkRolePermission = async (data: RolePayload) => {
    const fetch = await axios.post(`/Role/CheckRolePermission`, { ...data });
    return fetch.data;
  };
  return useMutation<boolean, ApiError, RolePayload>(
    'checkRolePermission',
    checkRolePermission,
    options,
  );
};

export const useUpdateRole = (
  options?: Omit<
    UseMutationOptions<ParticularRoleData, ApiError, RolePayload>,
    'mutationKey' | 'mutationFn'
  >,
) => {
  const addUpdate = async (data: RolePayload) => {
    const fetch = await axios.patch(`/Role`, { ...data });
    return fetch.data;
  };
  return useMutation<ParticularRoleData, ApiError, RolePayload>('addUpdate', addUpdate, options);
};

export const useDeleteRole = (
  options?: Omit<
    UseMutationOptions<unknown, ApiError, number | string>,
    'mutationKey' | 'mutationFn'
  >,
) => {
  const deleteCall = async (roleId: number | string) => {
    const fetch = await axios.delete(`/Role/${roleId}`);
    return fetch.data;
  };
  return useMutation<unknown, ApiError, number | string>('deleteRole', deleteCall, options);
};

export const usePermissionSets = ({ orgType }: { orgType: OrgType }) => {
  const getPermissionSets = async () => {
    const fetch = await axios.get<PermissionSet[]>(`/PermissionSet`, { params: { orgType } });
    return fetch.data;
  };
  return useQuery<PermissionSet[], ApiError>(['permissions-sets', orgType], getPermissionSets, {
    enabled: !!orgType,
  });
};

export const useRoleInfo = (
  roleId: number,
  options?: Omit<UseQueryOptions<ParticularRoleData, ApiError>, 'queryKey' | 'queryFn'>,
) => {
  const toast = useToast();
  const fetchRole = async () => {
    const res = await axios.get(`/Permission/GetAllRolePermission?roleId=${roleId}`);
    return res.data;
  };
  const state = useQuery<ParticularRoleData, ApiError>('userInfo', fetchRole, {
    onError(err) {
      toast.error(err.response.data.message);
    },
    ...options,
  });
  return state;
};

export const useAvailableRoles = ({
  userId,
  assosiatedWith,
}: {
  userId?: number;
  assosiatedWith: SelectItem<string> | null;
}) => {
  const fetchRoles = async () => {
    const res = await axios.get(`/Role/AvailableRoles`, { params: { userId } });
    return res.data;
  };
  return useQuery<AvailableRole[], ApiError>(
    ['availableRoles', userId, assosiatedWith],
    fetchRoles,
  );
};
