import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import QuickConfirmationModal from '../../components/modal/QuickConfirmationModal';
import QuickDecisionModal from '../../components/modal/QuickDecisionModal';
import { QuickDecisionModalContext } from '../../context/QuickDecisionModal';
import { IQuickModalProps } from '../../context/QuickModal';
import { IUnsavedData, UnsavedDataContext } from '../../context/UnsavedData';
import { UserContext } from '../../context/User';

import { PermissionKeys, permissionsDictionary } from '../../hooks/usePermissions';
import { UserInfo } from '../../types';
import useUserQuery from '../../useQuery/useUserQuery';
import AppContent from './AppContent';
import { BearerInterceptor } from '../../interceptors/BearerInterceptor';
import { UnauthorizedResponseInterceptor } from '../../interceptors/UnauthorizedResponseInterceptor';
import SessionExpireModal from '../../components/modal/SessionExpireModal';
import { AppLoader } from '../../components/shared/AppLoader';
import { IsDataLoadingContext } from '../../context/IsLoading';
import ModalTerms from '../../components/modal/ModalTerms';
import DataLossModal from '../../components/modal/DataLossModal';

const AppContentWrapper = ({
  showLoader,
  children,
}: {
  showLoader: boolean;
  children: ReactNode;
}) => (
  <div
    style={{
      pointerEvents: showLoader ? 'none' : 'auto', // Block interactions when loader is visible
    }}
  >
    {children}
  </div>
);

export default function MainLayout() {
  BearerInterceptor();
  UnauthorizedResponseInterceptor();
  const { data: userData, refetch } = useUserQuery();

  const [userContextData, setUserContextData] = useState<UserInfo>();
  const [isDataLoading, setIsDataLoading] = useState(false);

  useEffect(() => {
    if (userData) {
      const dictionary: Record<PermissionKeys, boolean> = {
        'Add Agreement': false,
        'View Agreement': false,
        'Modify Agreement': false,
        'Delete Agreement': false,
        'Add Products': false,
        'View Product Lines': false,
        'Modify Products': false,
        'Delete Products': false,
        'Add Sellers': false,
        'View Sellers': false,
        'Modify Sellers': false,
        'Delete Sellers': false,
        'Read Agent Roles': false,
        'Create Agent Roles': false,
        'Update Agent Roles': false,
        'Delete Agent Roles': false,
        'Read Provider Roles': false,
        'Create Provider Roles': false,
        'Update Provider Roles': false,
        'Delete Provider Roles': false,
        'Read Agent User': false,
        'Create Agent User': false,
        'Update Agent User': false,
        'Delete Agent User': false,
        'Read Provider User': false,
        'Create Provider User': false,
        'Update Provider User': false,
        'Delete Provider User': false,
        'Signer - Seller Agreements': false,
        'Read Organization': false,
        'Create Organization': false,
        'Update Organization': false,
        'Delete Organization': false,
        'Read Contact': false,
        'Create Contact': false,
        'Update Contact': false,
        'Delete Contact': false,
        'Send For Signatures': false,
        'Read Agent': false,
        'Approve Agent-Seller Relationship': false,
        'System Admin': false,
        'View Configuration': false,
        'Modify Configuration': false,
      };
      Object.keys(permissionsDictionary).forEach((el) => {
        const key: PermissionKeys = el as PermissionKeys;
        const permissionValue = permissionsDictionary[key] ?? [];

        if (userData?.permissions && typeof permissionValue !== 'boolean') {
          dictionary[key] = userData?.permissions?.some((permission) =>
            permissionValue.some((per2) => per2 === permission.userPolicy),
          );
        }
      });
      setUserContextData({ ...userData, allowedPermissions: dictionary, refetchUserData: refetch });
    }
  }, [refetch, userData]);
  const [unsavedData, setUnsavedData] = useState<IUnsavedData>({
    isPopupOpen: false,
    isUnsavedChanges: false,
    onDiscardChanges: () => {},
    onSaveChanges: () => {},
    onExit: () => {},
    returnUrl: '',
  });

  const [quickDecisionModal, setQuickDecisionModal] = useState<IQuickModalProps>({
    isOpen: false,
    message: '',
    modalTitle: '',
    yesCallBack: () => {},
    noCallBack: () => {},
  });

  const unsavedDataContext = useMemo(() => ({ unsavedData, setUnsavedData }), [unsavedData]);
  const quickDecisionModalProps = useMemo(
    () => ({ quickDecisionModal, setQuickDecisionModal }),
    [quickDecisionModal],
  );
  const isDataLoadingContextValue = useMemo(
    () => ({ isDataLoading, setIsDataLoading }),
    [isDataLoading],
  );

  if (!userContextData) return <></>;

  return (
    <UserContext.Provider value={userContextData}>
      <UnsavedDataContext.Provider value={unsavedDataContext}>
        <QuickDecisionModalContext.Provider value={quickDecisionModalProps}>
          <IsDataLoadingContext.Provider value={isDataLoadingContextValue}>
            <AppLoader />
            <AppContentWrapper showLoader={isDataLoading}>
              <AppContent />
            </AppContentWrapper>
            <SessionExpireModal />
            <QuickConfirmationModal />
            <QuickDecisionModal />
            <ModalTerms />
            <DataLossModal />
          </IsDataLoadingContext.Provider>
        </QuickDecisionModalContext.Provider>
      </UnsavedDataContext.Provider>
    </UserContext.Provider>
  );
}
