import { VisibilityOff, Visibility } from '@mui/icons-material';
import { IconButton, InputAdornment, InputLabel, InputProps, OutlinedInput } from '@mui/material';
import React, { forwardRef } from 'react';
import { FC } from 'react';
import { IMaskInput } from 'react-imask';
import { StyledFormControl } from '../AppPhoneField';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(function TextMaskCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00-0000000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const TextSsnMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextSsnMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="000-00-0000"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);
interface PhoneFieldProps extends InputProps {
  label: string;
  errorMessage?: string;
}

export const AppFeinField: FC<PhoneFieldProps> = forwardRef((props, ref) => {
  const { errorMessage, ...otherProps } = props;

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <StyledFormControl
      variant="outlined"
      fullWidth
      error={!!errorMessage}
      disabled={props.disabled}
    >
      <InputLabel htmlFor="formatted-text-mask-input">
        {errorMessage ? `${props.label} - ${errorMessage}` : props.label}
      </InputLabel>
      <OutlinedInput
        {...otherProps}
        ref={ref}
        sx={!showPassword ? { '-webkit-text-security': 'disc' } : {}}
        inputComponent={TextMaskCustom as any}
        label={errorMessage ? `${props.label} - ${errorMessage}` : props.label}
        type={'text'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    </StyledFormControl>
  );
});

export const AppSsnField: FC<PhoneFieldProps> = forwardRef((props, ref) => {
  const { errorMessage, ...otherProps } = props;
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <StyledFormControl
      variant="outlined"
      fullWidth
      error={!!errorMessage}
      disabled={props.disabled}
    >
      <InputLabel htmlFor="formatted-text-mask-input">
        {errorMessage ? `${props.label} - ${errorMessage}` : props.label}
      </InputLabel>
      <OutlinedInput
        {...otherProps}
        ref={ref}
        sx={!showPassword ? { '-webkit-text-security': 'disc' } : {}}
        inputComponent={TextSsnMaskCustom as any}
        label={errorMessage ? `${props.label} - ${errorMessage}` : props.label}
        type={'text'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    </StyledFormControl>
  );
});
