import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomModal from '../Modal';
import { AppActionButton } from '../../shared/AppActionButton';
import { ModalButtonsBox } from '../Modal/styles';
import { ModalMessageText } from '../CancelModal/styles';

interface RoleHasDuplicatePermProps {
  popUp: boolean;
  handleConfirm: () => void;
  handleModal: () => void;
}

export default function RoleHasDuplicatePermModal(props: RoleHasDuplicatePermProps) {
  const { t } = useTranslation();
  const { popUp, handleModal, handleConfirm } = props;
  return (
    <CustomModal
      open={popUp}
      containerWidth="400px"
      modalTitle={t('security.roles.roleForm.role.warning')}
    >
      <>
        <ModalMessageText
          dangerouslySetInnerHTML={{
            __html: t('security.roles.roleForm.role.permission.validation'),
          }}
        />
        <ModalButtonsBox>
          <AppActionButton
            style={{ marginRight: '15px' }}
            variant="outlined"
            onClick={handleConfirm}
          >
            {t('security.users.userList.yes')}
          </AppActionButton>
          <AppActionButton variant="outlined" onClick={handleModal}>
            {t('security.users.userList.no')}
          </AppActionButton>
        </ModalButtonsBox>
      </>
    </CustomModal>
  );
}
