import styled from 'styled-components';
import { Box, Toolbar } from '@mui/material';
import { linkColor, MainBackground, MainTextColor } from '../../Theme/colorsVariables';
import { AlignCenterBox } from '../../components/shared/FlexBox';
import { breakPointMedium } from '../../utils';

export const RootContainer = styled(Box)`
  flex-grow: 1;
  position: fixed;
  width: 100%;
  z-index: 1200;
`;

export const StyledToolBar = styled(Toolbar)`
  display: flex;
  position: relative;
  background: ${MainBackground};
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
`;
export const StyledToolBarSmall = styled(Toolbar)`
  display: flex;
  row-gap: 10px;
  flex-direction: column;
  position: relative;
  padding: 10px 0;
  background: ${MainBackground};
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
`;
export const ShadowContainer = styled(Box)`
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
`;
export const StyledHyperLink = styled(Box)`
  color: ${linkColor};
  font-family: Gotham-medium, sans-serif;
`;

export const LogoutContainer = styled(Box)`
  height: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const AccountOwnerContainer = styled(Box)`
  ${breakPointMedium(`
    font-size: 12px;
    margin: 0 20px;
  `)}
  flex-grow: 3;
  font-size: 12px;
  color: ${MainTextColor};
  word-break: break-word;
  min-width: 280px;
  margin: 0 20px;
`;
export const AccountOwnerContainerSmall = styled(Box)`
  ${breakPointMedium(`
    font-size: 12px;
    margin: 0 20px;
  `)}
  font-size: 12px;
  color: ${MainTextColor};
  word-break: break-word;
  margin: 0 20px;
`;

export const AgentInfoContainer = styled(Box)`
  ${breakPointMedium(`
    font-size: 12px;
    margin: 0 20px;
    min-width: 180px;
  `)}
  flex-grow: 2;
  font-size: 16px;
  color: ${MainTextColor};
  min-width: 240px;
  max-height: 70px;
  word-break: break-word;
  margin: 0 20px;
`;
export const AgentInfoContainerSmall = styled(Box)`
  ${breakPointMedium(`
    font-size: 12px;
    margin: 0 20px;
    min-width: 180px;
  `)}
  font-size: 16px;
  color: ${MainTextColor};
  word-break: break-word;
  margin: 0 20px;
`;

export const LogoContainer = styled(AlignCenterBox)`
  ${breakPointMedium(`
    width: 130px;
    & #home_icon {
      width: 24px;
    }
  `)}
`;
