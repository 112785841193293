import React from 'react';
import Login from '../layout/Login';

const AuthenticationRoutes = {
  path: '/',
  element: <Login />,
  children: [
    {
      path: '/login',
      element: <Login />,
    },
  ],
};

export default AuthenticationRoutes;
