import { MenuItem } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { AddEntityTypeSelect, CustomMenu, QuickMenuLink } from './styles';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import { UserContext } from '../../context/User';
import { UnsavedDataContext } from '../../context/UnsavedData';

export default function QuickMenu() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const user = useContext(UserContext);
  const { unsavedData, setUnsavedData } = useContext(UnsavedDataContext);

  // ProductLines permissions
  const isAddProductPermission = usePermissions(PermissionKeys.AddProducts);
  const isCreateProductLinesPermission = usePermissions(PermissionKeys.AddProducts);

  // Seller permissions
  const isAddSellerPermission = usePermissions(PermissionKeys.AddSellers);
  const isCreateOrganizationPermission = usePermissions(PermissionKeys.CreateOrganization);

  // User permissions
  const isCreateProviderUserPermission = usePermissions(PermissionKeys.CreateProviderUser);
  const isCreateAgentUserPermission = usePermissions(PermissionKeys.CreateAgentUser);

  // Roles permissions
  const isCreateRoleAgenciesPermission = usePermissions(PermissionKeys.CreateAgentRoles);
  const isCreateRoleProviderPermission = usePermissions(PermissionKeys.CreateProviderRoles);

  const isUserProvider = user?.isProvider && user?.userType === 'Provider';

  const addProductLinePermissions =
    isUserProvider && (isAddProductPermission || isCreateProductLinesPermission);
  const addSellerGroupPermissions = isCreateOrganizationPermission || isAddSellerPermission;
  const addUserPermissions =
    isUserProvider && (isCreateProviderUserPermission || isCreateAgentUserPermission);
  const addRolePermission =
    isUserProvider && (isCreateRoleAgenciesPermission || isCreateRoleProviderPermission);
  const addSellerPermission = isCreateOrganizationPermission || isAddSellerPermission;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, url: string) => {
    setAnchorEl(null);
    if (unsavedData.isUnsavedChanges) {
      event.preventDefault();
      setUnsavedData((prevValue) => ({ ...prevValue, isPopupOpen: true, returnUrl: url }));
    }
  };

  useEffect(() => {
    if (unsavedData && unsavedData?.isUnsavedChanges && unsavedData?.isPopupOpen === false) {
      setAnchorEl(null);
    }
  }, [unsavedData]);

  return (
    <>
      <AddEntityTypeSelect
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        startIcon={<AddCircleOutlineIcon />}
        endIcon={anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        disabled={
          !addProductLinePermissions &&
          !addRolePermission &&
          !addSellerGroupPermissions &&
          !addSellerPermission &&
          !addUserPermissions
        }
      >
        Add
      </AddEntityTypeSelect>

      <CustomMenu
        id="simple-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {addProductLinePermissions && (
          <QuickMenuLink
            to="/dashboard/ProductLine/Add"
            onClick={(event) => handleClose(event, '/dashboard/ProductLine/Add')}
          >
            <MenuItem>Product Line</MenuItem>
          </QuickMenuLink>
        )}

        {addSellerPermission && (
          <QuickMenuLink
            to="/dashboard/Seller/Add"
            onClick={(event) => handleClose(event, '/dashboard/Seller/Add')}
          >
            <MenuItem>Seller</MenuItem>
          </QuickMenuLink>
        )}

        {addSellerGroupPermissions && (
          <QuickMenuLink
            to="/dashboard/SellerGroup/Add"
            onClick={(event) => handleClose(event, '/dashboard/SellerGroup/Add')}
          >
            <MenuItem>Seller Group</MenuItem>
          </QuickMenuLink>
        )}

        {addUserPermissions && (
          <QuickMenuLink
            to="/dashboard/User/Add"
            onClick={(event) => handleClose(event, '/dashboard/User/Add')}
          >
            <MenuItem>User </MenuItem>
          </QuickMenuLink>
        )}
        {addRolePermission && (
          <QuickMenuLink
            to={'/dashboard/Role/Add'}
            onClick={(event) => handleClose(event, '/dashboard/Role/Add')}
          >
            <MenuItem>Role </MenuItem>
          </QuickMenuLink>
        )}
      </CustomMenu>
    </>
  );
}
