import React from 'react';
import { useReleaseVersion } from '../../services/utilsService';
import FooterStyle from './styles';

export default function Footer() {
  const { data: releaseVersion } = useReleaseVersion();
  return (
    <FooterStyle>
      Powered by enginuity, LLC | {releaseVersion} | © 2021-{new Date().getFullYear()} All Rights
      Reserved
    </FooterStyle>
  );
}
