import { useEffect, useState, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  AppActionButton,
  AppActionButtonsContainer,
} from '../../components/shared/AppActionButton';
import { AppDeleteButton } from '../../components/shared/AppDeleteButton';
import PageHeaderContentHost from '../../components/shared/AppPageHeaderContentHost';
import { FlexBox } from '../../components/shared/FlexBox';
import { StickyContainer } from '../../Theme/oldStylesInUse/oldAddSellerStyles';
import { BoxStepper, Label, MainStepper, MainSteps } from '../AgencyInfo/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import { StyledTypography } from '../../components/Forms/RoleForm/noPermissionsText';
import { Grid } from '@mui/material';
import { PageHeaderContainer } from '../../components/shared/AppPageHeaderContentHost/styles';
import DeleteEntityModal from '../../components/modal/DeleteEntityModal';
import { ApiError } from '../../types';
import { useToast } from '../../hooks/useToast';
import {
  useDealershipInfo,
  useSellerAgreements,
  useSellerDelete,
  useSellerInfo,
} from '../../services/sellerServices';
import { AgreementStatus } from '../../types/agreements';
import { useCheckOrgHasInProgressAgreements } from '../../services/organization';
import { useOrganizationContacts } from '../../services/sellerGroupContactsService';
import { UnsavedDataContext } from '../../context/UnsavedData';

enum SellerSteps {
  BusinessInfo = 'Business Information',
  DealershipInfo = 'Dealership Information',
  Contacts = 'Contacts',
  ProductLines = 'Product Lines',
  Agreements = 'Agreements',
}

export const SellerPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { unsavedData, setUnsavedData } = useContext(UnsavedDataContext);

  const toast = useToast();
  const isReadSellerOrganization = usePermissions(PermissionKeys.ViewSellers);
  const isDeleteSeller = usePermissions(PermissionKeys.DeleteSellers);
  const isCreateContactPermission = usePermissions(PermissionKeys.CreateContact);
  const isViewSellerPermission = usePermissions(PermissionKeys.ViewSellers);
  const isUpdateOrganizationPermission = usePermissions(PermissionKeys.UpdateOrganization);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [headerContainerRef, setHeaderContainerRef] = useState<HTMLDivElement | null>(null);
  const [contactsCount, setContactsCount] = useState(0);

  const steps = t('newSellerSigner.steps', { returnObjects: true }) as string[];

  const { data: sellerData, refetch: refetchSellerData } = useSellerInfo(Number(id), {
    enabled: isReadSellerOrganization,
    retry: false,
    onError(err) {
      toast.error(err.response.data.message);
    },
  });

  const { data: isAgreementInProcess, refetch: refetchIsAgreementsInProgress } =
    useCheckOrgHasInProgressAgreements(Number(id));

  const { data: agreementsResponse, refetch: refetchAgreementsList } = useSellerAgreements({
    sellerId: Number(id),
    payload: {
      pageNumber: 1,
      pageSize: 100,
      shouldMatchAllCriteria: true,
    },
  });

  const { data: dealershipInfo } = useDealershipInfo(Number(id));

  const { data: sellerContacts } = useOrganizationContacts({
    pageNumber: 1,
    pageSize: 10,
    orgId: Number(id),
  });

  const isCompletedAgreement = useMemo(() => {
    return agreementsResponse?.data.some(
      (agreement) => agreement.status === AgreementStatus.Completed,
    );
  }, [agreementsResponse?.data]);

  const isDealershipFinished = useMemo(() => {
    if (
      !!dealershipInfo?.dealerLicenseId &&
      dealershipInfo.averageAssetInventoryRangeId &&
      !!dealershipInfo.dealerTypeGroupIds.length &&
      dealershipInfo.assetMakeIds.length &&
      dealershipInfo.dmsIds.length &&
      dealershipInfo.dmsOtherName &&
      dealershipInfo.fiMenuIds.length &&
      dealershipInfo.fiMenuOtherName &&
      dealershipInfo.preferredIntegrationPartner
    ) {
      return true;
    }
    return false;
  }, [dealershipInfo]);

  const [activeStep, setActiveStep] = useState(SellerSteps.BusinessInfo);

  const navigateSwitcher: Record<SellerSteps, (sellerGroupId: number) => void> = {
    [SellerSteps.BusinessInfo]: (sellerId: number) => {
      if (unsavedData.isUnsavedChanges) {
        setUnsavedData({
          ...unsavedData,
          isPopupOpen: true,
          returnUrl: `/dashboard/Seller/${sellerId}`,
        });
      } else {
        navigate(`/dashboard/Seller/${sellerId}`);
      }
    },
    [SellerSteps.DealershipInfo]: (sellerId: number) => {
      if (unsavedData.isUnsavedChanges) {
        setUnsavedData({
          ...unsavedData,
          isPopupOpen: true,
          returnUrl: `/dashboard/Seller/${sellerId}/DealershipInformation`,
        });
      } else {
        navigate(`/dashboard/Seller/${sellerId}/DealershipInformation`);
      }
    },
    [SellerSteps.ProductLines]: (sellerId: number) => {
      if (unsavedData.isUnsavedChanges) {
        setUnsavedData({
          ...unsavedData,
          isPopupOpen: true,
          returnUrl: `/dashboard/Seller/${sellerId}/ProductLine`,
        });
      } else {
        navigate(`/dashboard/Seller/${sellerId}/ProductLine`);
      }
    },
    [SellerSteps.Contacts]: (sellerId: number) => {
      if (unsavedData.isUnsavedChanges) {
        setUnsavedData({
          ...unsavedData,
          isPopupOpen: true,
          returnUrl: `/dashboard/Seller/${sellerId}/Contact`,
        });
      } else {
        navigate(`/dashboard/Seller/${sellerId}/Contact`);
      }
    },
    [SellerSteps.Agreements]: (sellerId: number) => {
      if (unsavedData.isUnsavedChanges) {
        setUnsavedData({
          ...unsavedData,
          isPopupOpen: true,
          returnUrl: `/dashboard/Seller/${sellerId}/Agreement`,
        });
      } else {
        navigate(`/dashboard/Seller/${sellerId}/Agreement`);
      }
    },
  };

  useEffect(() => {
    if (location.pathname.includes('/Contact')) {
      setActiveStep(SellerSteps.Contacts);
    } else if (location.pathname.includes('/ProductLine')) {
      setActiveStep(SellerSteps.ProductLines);
    } else if (location.pathname.includes('/Agreement')) {
      setActiveStep(SellerSteps.Agreements);
    } else if (location.pathname.includes('/DealershipInformation')) {
      setActiveStep(SellerSteps.DealershipInfo);
    } else {
      setActiveStep(SellerSteps.BusinessInfo);
    }
  }, [location]);

  const onDeleteSuccess = () => {
    navigate('/dashboard/Seller');
    toast.success(t('success.delete', { entityType: 'Seller', entityName: sellerData?.legalName }));
  };

  const onDeleteError = (error: ApiError) => {
    setIsDeleteModalOpen(false);
    toast.error(error.response.data.message);
    navigate('/dashboard/Seller');
  };

  const { mutate: deleteSeller } = useSellerDelete({
    onSuccess: onDeleteSuccess,
    onError: onDeleteError,
  });

  const deleteSellerHandler = () => {
    deleteSeller(Number(id));
  };

  useEffect(() => {
    setContactsCount(sellerContacts?.totalCount || 0);
  }, [sellerContacts?.totalCount]);

  return isViewSellerPermission ? (
    <>
      <StickyContainer sx={{ flex: '0 1 auto' }} mb={1} ref={setHeaderContainerRef}>
        <PageHeaderContainer>
          <PageHeaderContentHost
            title={'Seller'}
            name={
              sellerData?.sellerGroupName
                ? `${sellerData?.legalName} - for Seller Group ${sellerData?.sellerGroupName}`
                : sellerData?.legalName
            }
            status={sellerData?.status}
          />

          <AppActionButtonsContainer>
            {!isCompletedAgreement && !isAgreementInProcess && (
              <Link
                to={`/dashboard/Seller/${id}/Finish`}
                state={
                  !isDealershipFinished
                    ? { step: 1 }
                    : sellerContacts?.totalCount === 0
                    ? { step: 2, isTable: false }
                    : {}
                }
              >
                <AppActionButton variant="outlined" disabled={!isUpdateOrganizationPermission}>
                  {t('app.action.button.finish')}
                </AppActionButton>
              </Link>
            )}
            <AppDeleteButton
              disabled={!isDeleteSeller}
              onClick={() => {
                setIsDeleteModalOpen(true);
              }}
            />
          </AppActionButtonsContainer>

          <DeleteEntityModal
            entityName={sellerData?.legalName || ''}
            entityType={'Seller'}
            onConfirm={deleteSellerHandler}
            open={isDeleteModalOpen}
            setOpen={setIsDeleteModalOpen}
          />
        </PageHeaderContainer>
        <BoxStepper mt={1}>
          <MainStepper connector={null}>
            {steps?.map((step, index) => {
              return (
                <MainSteps
                  props={[steps.indexOf(activeStep), index]}
                  onClick={() => {
                    navigateSwitcher[step as unknown as SellerSteps](Number(id));
                  }}
                  key={(Math.random() + 1).toString(36).substring(7)}
                  id={`tab-${step}`}
                >
                  <Label isactive={activeStep === step ? 'true' : ''}>
                    {step === 'Contacts' ? `Contacts (${contactsCount})` : step}
                  </Label>
                </MainSteps>
              );
            })}
          </MainStepper>
        </BoxStepper>
      </StickyContainer>

      {activeStep === SellerSteps.Contacts && (
        <FlexBox sx={{ justifyContent: 'end', width: '100%' }}>
          <AppActionButton
            disabled={!isCreateContactPermission}
            variant="outlined"
            sx={{ maxHeight: '34px', minWidth: '100px !important' }}
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => {
              navigate(`/dashboard/Seller/${id}/AddContact`);
            }}
            id="add-contact"
          >
            {t('app.action.button.add')}
          </AppActionButton>
        </FlexBox>
      )}
      <Outlet
        context={{
          sellerInformation: sellerData,
          refetchSellerData,
          isCompletedAgreement,
          refetchAgreementsList,
          isAgreementInProcess,
          refetchIsAgreementsInProgress,
          headerContainerHeight: headerContainerRef?.getBoundingClientRect().height,
          setContactsCount,
          contactsCount,
        }}
      />
    </>
  ) : (
    <Grid container>
      <Grid item xs={12}>
        <StyledTypography>{t('relationships.organisation.view.permission')}</StyledTypography>
      </Grid>
    </Grid>
  );
};
