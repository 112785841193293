import { boolean, object } from 'yup';
import { trimmedString } from '.';
import { getCommonErrorMessages } from '../utils';
import { getCommonMaxLengthValue } from '../utils/inputMaxLength';

export const contactValidationSchema = object().shape(
  {
    firstName: trimmedString
      .max(
        getCommonMaxLengthValue('firstName'),
        getCommonErrorMessages('maxLength') + getCommonMaxLengthValue('firstName'),
      )
      .required(getCommonErrorMessages('required')),
    lastName: trimmedString
      .max(
        getCommonMaxLengthValue('lastName'),
        getCommonErrorMessages('maxLength') + getCommonMaxLengthValue('lastName'),
      )
      .required(getCommonErrorMessages('required')),
    email: trimmedString
      .required(getCommonErrorMessages('required'))
      .email(getCommonErrorMessages('email')),
    title: trimmedString
      .max(
        getCommonMaxLengthValue('title'),
        getCommonErrorMessages('maxLength') + getCommonMaxLengthValue('title'),
      )
      .max(getCommonMaxLengthValue('title')),
    phone: object().shape({
      number: trimmedString,
      countryCode: trimmedString,
      ext: trimmedString,
      isValid: boolean().oneOf([true], getCommonErrorMessages('required')),
    }),
  },
  [],
);
