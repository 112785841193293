import { AnySchema, array, boolean, date, number, object, ObjectSchema, string } from 'yup';
import { commonSelectSchema } from '.';
import { AddProductLineFormValues } from '../types/sellerGroup';
import { getCommonErrorMessages } from '../utils';

export const addProductLineValidationSchema: ObjectSchema<
  Record<keyof AddProductLineFormValues, AnySchema>
> = object().shape(
  {
    signer: commonSelectSchema,
    productLines: array(number()).when('legacyProductLinesFlag', {
      is: false,
      then: array(number())
        .min(1, getCommonErrorMessages('productLine'))
        .required(getCommonErrorMessages('required')),
      otherwise: array(number()),
    }),
    legacyProductLines: array(number()),
    legacyProductLinesFlag: boolean(),
    message: string(),
    agent: commonSelectSchema,
    agentRelationshipOwner: commonSelectSchema,
    effectiveDate: date().required(getCommonErrorMessages('required')),
  },
  [],
);
