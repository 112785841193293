export const getSearchString = (val: string | undefined): string => {
  if (val === undefined || val.trim() === '') {
    return '';
  }
  return val.length > 2 ? val : '';
};

export const isNumber = (val: string) => {
  if (val.length < 3) {
    return '';
  }
  const numberVal = Number(val);
  return typeof numberVal === 'number' && isFinite(numberVal) && numberVal !== 0;
};
