import axios from 'axios';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UserContext } from '../context/User';
import { ApiError } from '../types';
import { Terms } from '../types/utils';

export const FetchTerms = () => {
  const user = useContext(UserContext);
  const fetchTerms = async () => {
    const fetch = await axios.get(`/Account/GetTermsOfUse/${user?.orgId}`);
    return fetch.data;
  };

  const state = useQuery<Terms, ApiError>('fetchterms', fetchTerms);
  return state;
};

export const useReleaseVersion = () => {
  const fetchReleaseVersion = async () => {
    const fetch = await axios.get<string>(`/system/release-version`);
    return fetch.data;
  };
  return useQuery<string, ApiError>('fetchReleaseVersion', fetchReleaseVersion);
};
