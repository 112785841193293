import * as signalR from '@microsoft/signalr';
const URL = `${process.env.REACT_APP_API_URL}/notification-hub`; //or whatever your backend port is
class NotificationHub {
  private connection: signalR.HubConnection;

  public events: (onMessageReceived: (documentId: number) => void) => void;

  static instance: NotificationHub;

  constructor() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(URL)
      .withAutomaticReconnect()
      .build();
    this.connection.start();
    this.events = (onMessageReceived) => {
      this.connection.on('NOTIFICATION_HUB_DOCUMENT_DELETED', (documentId) => {
        onMessageReceived(documentId);
      });
    };
  }

  public static getInstance(): NotificationHub {
    if (!NotificationHub.instance) NotificationHub.instance = new NotificationHub();
    return NotificationHub.instance;
  }
}
export default NotificationHub.getInstance;
