import styled from 'styled-components';
import PhoneInput from 'react-phone-input-material-ui';
import { MainTextColor, White } from '../../../Theme/colorsVariables';

export const StyledReactPhoneInput = styled(PhoneInput)<{ disabled: boolean }>`
  height: 100%;
  ${({ disabled }) => (disabled ? 'pointer-events: none;' : '')}
  &.MuiInputBase-root {
    height: 100%;
  }
`;
export const StyledReactPhoneInputViewOnly = styled(PhoneInput)<{ disabled: boolean }>`
  pointer-events: none;
  min-width: 180px;
  .MuiInputBase-input {
    background: ${White} !important;
    color: ${MainTextColor};
    font-family: Gotham-MonoSpace-Numbers, sans-serif;
    font-weight: 400;
    padding: 0 !important;
  }
`;
