export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

export enum Status {
  All = 'All',
  Any = 'Any',
  Active = 'Active',
  Inactive = 'Inactive',
  Default = '',
  Signed = 'Signed',
  Prospect = 'Prospect',
}

export enum EntityType {
  All = 'All',
  Any = 'Any',
  Provider = 'Provider',
  Agency = 'Agency',
  Agent = 'Agent',
  Default = '',
}

export interface PaginationParams {
  name?: string;
  pageNumber: number;
  pageSize: number;
  sortColumn: string;
  sortDirection: Order;
}

export enum OrganizationType {
  Provider = 1,
  Agency = 2,
  Seller = 3,
  SellerGroup = 4,
}
