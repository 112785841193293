import React from 'react';

export default function LogoIcon() {
  return (
    <img
      style={{ objectFit: 'contain', width: '100%' }}
      src={`${process.env.REACT_APP_PUBLIC_STORAGE_URL}/logos/customer-logo-black.svg`}
    />
  );
}
