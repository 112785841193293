import { Box, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { ReactChild } from 'react';

import { CustomTable } from '../DataTable/styles';

interface TableProps {
  children?: ReactChild | JSX.Element[];
  tableHeadingsArray?: string[];
}

export default function CustomTableFixedHeight({ children, tableHeadingsArray }: TableProps) {
  return (
    <>
      <CustomTable stickyHeader>
        <>
          <TableHead>
            <TableRow>
              {tableHeadingsArray?.map((header: string) => (
                <TableCell key={header} align="left" sx={{ padding: '4px 16px;' }}>
                  <Box display="flex" alignItems="baseline">
                    {header}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{children && children}</TableBody>
        </>
      </CustomTable>
    </>
  );
}
