import React, { useEffect } from 'react';
import { useQuery } from '../../hooks/useQuery';
import { AgreementContainer } from '../../layout/DocumentPreview/styles';
import { GenerateReadOnlyView } from '../../services/DocuSignService';

export default function ReadOnlyAgreement() {
  const agreementId = useQuery('agreementId');

  // Info: gets docusign agreement link
  const { mutate: generateReadOnlyView, data: readOnlyData } = GenerateReadOnlyView();

  useEffect(() => {
    if (readOnlyData) {
      window.location.replace(readOnlyData);
    }
  }, [readOnlyData]);

  useEffect(() => {
    if (agreementId) {
      generateReadOnlyView({
        agreementId: +agreementId,
        redirectUrl: process.env.REACT_APP_DOCUSIGN_REDIRECT_URL,
      });
    }
  }, [agreementId, generateReadOnlyView]);

  return <AgreementContainer></AgreementContainer>;
}
