import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CreateOrganizationForm } from '../../../components/Forms/CreateOrganizationForm';
import { useAgentById } from '../../../services/agentService';
import { OrganizationType } from '../../../types/query-params';
import { CommonOrganization } from '../../../types/sellerGroup';

export const AgentInfoTab = () => {
  const { id } = useParams();
  const { data: agentInfo } = useAgentById(Number(id));

  const data = useMemo<CommonOrganization>((): CommonOrganization => {
    if (agentInfo) {
      const formattedObject: CommonOrganization = {
        sellerGroupId: agentInfo.orgId,
        legalName: agentInfo.orgName,
        legalEntityTypeId: agentInfo.legalEntityTypeId,
        doingBusinessAs: agentInfo.businessName,
        fein: agentInfo.fein,
        status: false,
        agentId: agentInfo.customerOrgId,
        addresses: [
          {
            addressId: agentInfo.addresses[0].addressId,
            addressTypeId: agentInfo.addresses[0].orgAddressTypeId,
            streetAddressLine1: agentInfo.addresses[0].address1,
            streetAddressLine2: agentInfo.addresses[0].address2,
            city: agentInfo.addresses[0].city,
            stateId: agentInfo.addresses[0].stateId,
            zipCode: agentInfo.addresses[0].zip,
            mainFax: agentInfo.addresses[0].fax,
            website: agentInfo.addresses[0].website,
            sameAsAddressTypeId: agentInfo.addresses[0].sameAsAddressTypeId,
            phoneNumber: agentInfo.addresses[0].phoneNumber,
            phoneNumberCountryCode: agentInfo.addresses[0].phoneNumberCountryCode ?? '1',
            phoneNumberExt: agentInfo.addresses[0].phoneNumberExt,
            googlePlaceId: null,
          },
          {
            addressId: agentInfo.addresses[1]?.addressId,
            addressTypeId: agentInfo.addresses[1]?.orgAddressTypeId,
            streetAddressLine1: agentInfo.addresses[1]?.address1,
            streetAddressLine2: agentInfo.addresses[1]?.address2,
            city: agentInfo.addresses[1]?.city,
            stateId: agentInfo.addresses[1]?.stateId,
            zipCode: agentInfo.addresses[1]?.zip,
            mainFax: agentInfo.addresses[1]?.fax,
            website: agentInfo.addresses[1]?.website,
            sameAsAddressTypeId: agentInfo.addresses[1]?.sameAsAddressTypeId,
            phoneNumber: null,
            phoneNumberCountryCode: null,
            phoneNumberExt: null,
            googlePlaceId: null,
          },
        ],
        accountOwner: agentInfo.accountOwner,
        createdOn: agentInfo.createdOn,
        createdBy: agentInfo.createdBy,
        updatedOn: agentInfo.updatedOn,
        updatedBy: agentInfo.updatedBy,
      };
      return formattedObject;
    }
    return {
      sellerGroupId: 0,
      legalName: '',
      legalEntityTypeId: 0,
      doingBusinessAs: '',
      fein: '',
      status: false,
      addresses: [],
      agentId: '',
      createdOn: null,
      createdBy: null,
      updatedOn: null,
      updatedBy: null,
    };
  }, [agentInfo]);

  return <CreateOrganizationForm orgData={data} orgType={OrganizationType.Agency} />;
};
