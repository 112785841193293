import { Status } from '../../types/query-params';

type MenuItemsValues = 'value' | 'text';

export const menuItems: Record<MenuItemsValues, string>[] = [
  { value: Status.Any, text: 'Any' },
  { value: Status.Active, text: 'Active' },
  { value: Status.Inactive, text: 'Inactive' },
];

export interface RoleAdvancedSearchData {
  roleName: string;
  isActive?: boolean;
}
