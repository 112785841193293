import React, { useRef, ChangeEvent, useState, useEffect } from 'react';
import { Box, Modal, Chip, Grid, useMediaQuery, useTheme } from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useForm, SubmitHandler } from 'react-hook-form';
import CloseIcon from '../../assets/CloseIcon';
import {
  AddTemplateLabel,
  ModalClose,
  JsonFileChip,
  ErrorMessageLabel,
  boxSxStyle,
} from './styles';
import { AddTemplateProps, AgreementTemplateNew, FormValues } from './types';
import { useTranslation } from 'react-i18next';
import { AppTextField } from '../../shared/AppTextField';
import { AppActionButton } from '../../shared/AppActionButton';
import { allowNumbersOnly } from '../../../utils';
import { getCommonMaxLengthValue } from '../../../utils/inputMaxLength';

export default function AddTemplate({
  open,
  onClose,
  documentData,
  onHandleFormSubmit,
  isLoading,
}: AddTemplateProps) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));
  const { t } = useTranslation();
  const { handleSubmit, reset } = useForm<FormValues>();
  const [jsonFileContent, setFileContent] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorMessageFlag, setErrorMessageFlag] = useState<boolean>(false);
  const [jsonFileName, setFileName] = useState<string>('');
  const [documentTemplateId, setDocumentTemplateId] = useState<string>('');
  const inputRef = useRef<any>(null);
  let fileReader: any;
  const [file, setFile] = useState<string>('');

  const handleDeleteJsonFile = () => {
    setFileName('');
    setFileContent('');
    setErrorMessage('');
    setErrorMessageFlag(false);
  };

  const handleCloseHandler = () => {
    reset();
    onClose();
    handleDeleteJsonFile();
    setDocumentTemplateId('');
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (!jsonFileContent) {
      setErrorMessage('Please attach document template file!');
      setErrorMessageFlag(true);
      setFileName('');
      return;
    }
    let templateName = '';
    if (documentData?.product) {
      templateName = `${documentData?.product} - ${documentData['document type']} - ${documentData['used for entity']}`;
    } else {
      templateName = `${documentData['document type']} - ${documentData['used for entity']}`;
    }
    const dataToPost: AgreementTemplateNew = {
      templateId: data.templateId,
      documentId: documentData.documentId,
      documentTypeId: documentData.documentTypeId.toString(),
      documentOrgId: Number(documentTemplateId) || null,
      usedForEntity: documentData['used for entity'],
      templateJson: file,
      documentUrl: '',
      fileName: templateName,
      productId: documentData.productId,
    };

    onHandleFormSubmit(dataToPost);
    setDocumentTemplateId('');
  };

  const handleFileRead = (e: any) => {
    const content: string = fileReader.result;
    setFileContent(content);
  };

  const fileToBase64 = (fileuploded: any, cb: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileuploded);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    fileReader = new FileReader();
    const fileObj: any = event.target.files && event.target.files[0];
    const fileExtn = fileObj.name.substr(fileObj.name.lastIndexOf('.'));
    if (!fileObj) {
      return;
    }
    // file type json check
    if (fileObj && fileExtn !== '.pdf') {
      setFileName(fileObj.name);
      setErrorMessage('Attach file in pdf file format.');
      setErrorMessageFlag(true);
      return;
    }

    // file size check--- file less than 100kb 102400
    // 2,097,152 == 2mb

    if (fileObj && fileObj.size >= 2097152) {
      setFileName(fileObj.name);
      setErrorMessage('File size must be less than 2MB.');
      setErrorMessageFlag(true);
      return;
    }
    setFileName(fileObj.name);
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(fileObj);
    inputRef.current.value = '';

    if (fileObj.type !== 'application/pdf') {
      return;
    }

    fileToBase64(fileObj, (err: any, result: any) => {
      if (result) {
        setFile(result);
        // if (event?.target?.files) {
        //   setFileName(event.target.files[0]);
        // }
      }
    });
  };

  const truncateFileName = (fileName: string) => {
    const limit = 30;
    const dots = '...';
    return fileName.length > limit ? fileName.substring(0, limit) + dots : fileName;
  };

  useEffect(() => {
    handleDeleteJsonFile();
  }, [isLoading]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseHandler}
        aria-labelledby="modal-modal-title"
        disableAutoFocus
      >
        <Box sx={boxSxStyle}>
          <AddTemplateLabel id="modal-modal-title" variant="h6">
            {t('agreement.add.modal.title')}
            <ModalClose onClick={handleCloseHandler}>
              <CloseIcon />
            </ModalClose>
          </AddTemplateLabel>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={isSmall ? 1 : 2} p={2}>
              {documentData?.product && (
                <Grid item xs={6}>
                  <AppTextField
                    type="text"
                    disabled
                    label="Product Line"
                    variant="outlined"
                    value={documentData?.product || ''}
                  />
                </Grid>
              )}

              <Grid item xs={6}>
                <AppTextField
                  type="text"
                  disabled
                  label={'Used for Entity'}
                  variant="outlined"
                  value={documentData?.['used for entity'] || ''}
                />
              </Grid>
              <Grid item xs={6}>
                <AppTextField
                  type="text"
                  disabled
                  label={'Agreement Type'}
                  variant="outlined"
                  value={documentData?.['document type'] || null}
                />
              </Grid>
              <Grid item xs={6}>
                <AppTextField
                  type="text"
                  label={t('documents.advancedSearchInputParams.label.agreementOrgId')}
                  variant="outlined"
                  onKeyPress={allowNumbersOnly}
                  value={documentTemplateId}
                  error={documentTemplateId.length > getCommonMaxLengthValue('documentOrgId')}
                  helperText={
                    documentTemplateId.length > getCommonMaxLengthValue('documentOrgId')
                      ? `Must be ${getCommonMaxLengthValue('documentOrgId')} digits or less`
                      : ''
                  }
                  onChange={(e) => {
                    setDocumentTemplateId(e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Box mt={2} display="flex" alignItems="center">
                  <AppActionButton
                    id="jsonInputfile"
                    variant="outlined"
                    size="large"
                    color="error"
                    disabled={Boolean(jsonFileName)}
                    startIcon={<AttachFileIcon />}
                    onClick={() => inputRef.current.click()}
                  >
                    Attach File
                  </AppActionButton>
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    id="jsonInputfile"
                    name="jsonInputfile"
                    onChange={(e) => handleFileChange(e)}
                    ref={inputRef}
                    accept="application/pdf"
                  />
                  {jsonFileName ? (
                    <JsonFileChip>
                      <Chip
                        label={truncateFileName(jsonFileName)}
                        onDelete={handleDeleteJsonFile}
                      />
                    </JsonFileChip>
                  ) : null}
                </Box>
              </Grid>

              {errorMessage ? (
                <ErrorMessageLabel variant="body2" mt={1}>
                  {errorMessage}
                </ErrorMessageLabel>
              ) : null}

              <Grid item xs={4}>
                <AppActionButton
                  variant="outlined"
                  size="large"
                  type="submit"
                  startIcon={<CheckOutlinedIcon />}
                  disabled={isLoading || errorMessageFlag || !jsonFileContent}
                >
                  Submit
                </AppActionButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
