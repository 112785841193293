import { useCallback, useEffect, useMemo, useState } from 'react';
import { AddressState } from '../types';
import { EntityType, Order } from '../types/query-params';
import usePermissions, { PermissionKeys } from './usePermissions';

export const defaultRowsPerPage = 40;

export type AllowedSearchKeys =
  | 'sellerList.search'
  | 'agentList.search'
  | 'sellerGroupList.search'
  | 'productLineList.search'
  | 'agreementTemplateList.search'
  | 'userList.search'
  | 'roleList.search'
  | 'sellerGroupContactList.search'
  | 'agentContactList.search'
  | 'sellerContactList.search'
  | 'sellerGroupAgreementList.search'
  | 'sellerAgreementList.search'
  | 'addSellerContactList.search';

type SaveSearchCriteriaProps = {
  searchKey?: AllowedSearchKeys;
};

type SingleFilterSelect = { label: string; value: number | string | null } | null;

type SearchCriteria = {
  searchTerm: string;
  page: number;
  rowsPerPage: number;
  order: Order;
  orderBy: string;
  status: SingleFilterSelect;
  sellerGroup: SingleFilterSelect[];
  availableInStates: AddressState[];
  assosiatedWith: SingleFilterSelect;
  productLineType: SingleFilterSelect;
  usedForEntity: SingleFilterSelect;
  productLine: SingleFilterSelect;
  agent: SingleFilterSelect;
  accountOwner: SingleFilterSelect;
};

const defaultOrderByValue: Record<AllowedSearchKeys, string> = {
  'sellerList.search': 'sellerName',
  'agentList.search': 'orgName',
  'sellerGroupList.search': 'sellerGroupName',
  'productLineList.search': 'productName',
  'agreementTemplateList.search': 'agreementtype',
  'userList.search': 'firstName',
  'roleList.search': 'name',
  'sellerGroupContactList.search': 'firstName',
  'agentContactList.search': 'firstName',
  'sellerContactList.search': 'firstName',
  'sellerGroupAgreementList.search': 'sendOn',
  'sellerAgreementList.search': 'sendOn',
  'addSellerContactList.search': 'firstName',
};

export const useSaveSearchCriteria = ({ searchKey }: SaveSearchCriteriaProps) => {
  const isReadProviderUserPermission = usePermissions(PermissionKeys.ReadProviderUser);
  const isReadAgentUserPermission = usePermissions(PermissionKeys.ReadAgentUser);

  const savedSearchCriteria = useMemo<SearchCriteria>(() => {
    if (searchKey) {
      const storageValue = window.localStorage.getItem(searchKey);
      if (storageValue) {
        return JSON.parse(storageValue);
      }
    }
    const defaultSearchCriteria: SearchCriteria = {
      searchTerm: '',
      page: 0,
      rowsPerPage: defaultRowsPerPage,
      order:
        searchKey === 'sellerGroupAgreementList.search' ||
        searchKey === 'sellerAgreementList.search'
          ? Order.DESC
          : Order.ASC,
      orderBy: searchKey ? defaultOrderByValue[searchKey] : '',
      status: { label: EntityType.Any, value: EntityType.Any },
      availableInStates: [],
      assosiatedWith: { label: EntityType.Any, value: EntityType.Any },
      productLineType: { label: EntityType.Any, value: EntityType.Any },
      usedForEntity: { label: EntityType.Any, value: EntityType.Any },
      productLine: { label: EntityType.Any, value: EntityType.Any },
      sellerGroup: [{ label: EntityType.Any, value: EntityType.Any }],
      agent: { label: EntityType.Any, value: EntityType.Any },
      accountOwner: { label: EntityType.Any, value: EntityType.Any },
    };
    return defaultSearchCriteria;
  }, [searchKey]);

  const [searchTerm, setSearchTerm] = useState(savedSearchCriteria.searchTerm);
  const [page, setPage] = useState(savedSearchCriteria.page);
  const [rowsPerPage, setRowsPerPage] = useState(savedSearchCriteria.rowsPerPage);
  const [order, setOrder] = useState(savedSearchCriteria.order);
  const [orderBy, setOrderBy] = useState(savedSearchCriteria.orderBy);
  const [status, setStatus] = useState(savedSearchCriteria.status);
  const [availableInStates, setAvailableInStates] = useState(savedSearchCriteria.availableInStates);
  const [assosiatedWith, setAssosiatedWith] = useState(savedSearchCriteria.assosiatedWith);
  const [productLineType, setProductLineType] = useState(savedSearchCriteria.productLineType);
  const [usedForEntity, setUsedForEntity] = useState(savedSearchCriteria.usedForEntity);
  const [productLine, setProductLine] = useState(savedSearchCriteria.productLine);
  const [sellerGroup, setSellerGroup] = useState(savedSearchCriteria.sellerGroup);
  const [agent, setAgent] = useState(savedSearchCriteria.agent);
  const [accountOwner, setAccountOwner] = useState(savedSearchCriteria.accountOwner);

  const resetSearch = useCallback(() => {
    setSearchTerm('');
    setPage(0);
    setRowsPerPage(defaultRowsPerPage);
    setOrder(
      searchKey === 'sellerGroupAgreementList.search' || searchKey === 'sellerAgreementList.search'
        ? Order.DESC
        : Order.ASC,
    );
    setOrderBy(searchKey ? defaultOrderByValue[searchKey] : '');
    setAvailableInStates([]);
    setAssosiatedWith(
      isReadAgentUserPermission && !isReadProviderUserPermission
        ? { label: EntityType.Agent, value: EntityType.Agency }
        : !isReadAgentUserPermission && isReadProviderUserPermission
        ? { label: EntityType.Provider, value: EntityType.Provider }
        : { label: EntityType.Any, value: EntityType.Any },
    );
    setStatus({ label: EntityType.Any, value: EntityType.Any });
    setProductLineType({ label: EntityType.Any, value: EntityType.Any });
    setUsedForEntity({ label: EntityType.Any, value: EntityType.Any });
    setProductLine({ label: EntityType.Any, value: EntityType.Any });
    setSellerGroup([{ label: EntityType.Any, value: EntityType.Any }]);
    setAgent({ label: EntityType.Any, value: EntityType.Any });
    setAccountOwner({ label: EntityType.Any, value: EntityType.Any });
  }, [isReadAgentUserPermission, isReadProviderUserPermission, searchKey]);

  useEffect(() => {
    if (searchKey) {
      const payload: SearchCriteria = {
        searchTerm,
        page,
        rowsPerPage,
        order,
        orderBy,
        status,
        availableInStates,
        assosiatedWith,
        productLineType,
        usedForEntity,
        productLine,
        sellerGroup,
        agent,
        accountOwner,
      };
      window.localStorage.setItem(searchKey, JSON.stringify(payload));
    }
  }, [
    assosiatedWith,
    availableInStates,
    order,
    orderBy,
    page,
    rowsPerPage,
    searchKey,
    searchTerm,
    status,
    productLineType,
    usedForEntity,
    productLine,
    sellerGroup,
    agent,
    accountOwner,
  ]);

  return {
    searchTerm,
    page,
    rowsPerPage,
    order,
    orderBy,
    status,
    availableInStates,
    assosiatedWith,
    productLineType,
    usedForEntity,
    productLine,
    sellerGroup,
    agent,
    accountOwner,
    setSearchTerm,
    setPage,
    setRowsPerPage,
    setOrder,
    setOrderBy,
    setStatus,
    setAvailableInStates,
    setAssosiatedWith,
    resetSearch,
    setProductLineType,
    setUsedForEntity,
    setProductLine,
    setSellerGroup,
    setAgent,
    setAccountOwner,
  };
};
