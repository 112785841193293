import { useCallback } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { OrganizationFormType } from '../types';
import { AddressComponent } from '../../../../types/googleAddressValidation';
import { AddressState } from '../../../../types';

export function useFillGoogleAddress({
  setValue,
  states,
}: {
  setValue: UseFormSetValue<OrganizationFormType>;
  states: {
    label: string;
    value: AddressState;
  }[];
}) {
  const fillInAddressFromAutoComplete = useCallback(
    (
      place: google.maps.places.PlaceResult,
      addressType: 'physicalLocation' | 'mailingLocation',
    ) => {
      // Get the place details from the autocomplete object.
      let apt = '';
      let address1 = '';
      let city = '';
      let state: { label: string; value: AddressState } | null = null;
      let postcode = '';

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      if (place.address_components) {
        for (const component of place.address_components) {
          const componentType = component.types[0];

          switch (componentType) {
            case 'street_number': {
              address1 = `${component.long_name} ${address1}`;
              break;
            }

            case 'route': {
              address1 += component.long_name;
              break;
            }

            case 'subpremise': {
              apt = component.long_name;
              break;
            }

            case 'postal_code': {
              postcode = `${component.long_name}${postcode}`;
              break;
            }

            case 'postal_code_suffix': {
              postcode = `${postcode}-${component.long_name}`;
              break;
            }
            case 'locality': {
              city = component.long_name;
              break;
            }
            case 'administrative_area_level_1': {
              state = states.find((item) => item.value.stateCode === component.short_name) || null;
              break;
            }
          }
        }
      }

      setValue(`${addressType}.address1`, address1.trim(), {
        shouldValidate: true,
      });
      setValue(`${addressType}.address2`, apt.trim(), { shouldValidate: true });
      setValue(`${addressType}.city`, city.trim(), { shouldValidate: true });
      setValue(`${addressType}.state`, state, { shouldValidate: true });
      setValue(`${addressType}.zip`, postcode, { shouldValidate: true });
    },
    [setValue, states],
  );

  const fillInAddressFromValidation = useCallback(
    (
      addressComponents: AddressComponent[],
      addressType: 'physicalLocation' | 'mailingLocation',
    ) => {
      // Get the place details from the autocomplete object.
      let apt = '';
      let address1 = '';
      let city = '';
      let state: { label: string; value: AddressState } | null = null;
      let postcode = '';

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      if (addressComponents) {
        for (const component of addressComponents) {
          const componentType = component.componentType;

          switch (componentType) {
            case 'street_number': {
              address1 = `${component.componentName.text} ${address1}`;
              break;
            }

            case 'route': {
              address1 += component.componentName.text;
              break;
            }

            case 'subpremise': {
              apt = !isNaN(parseInt(`${component.componentName.text}`))
                ? `#${`${component.componentName.text}`}`
                : `${`${component.componentName.text}`}`;
              break;
            }

            case 'postal_code': {
              postcode = `${component.componentName.text}${postcode}`;
              break;
            }

            case 'postal_code_suffix': {
              postcode = `${postcode}-${component.componentName.text}`;
              break;
            }
            case 'locality': {
              city = component.componentName.text;
              break;
            }
            case 'administrative_area_level_1': {
              state =
                states.find((item) => item.value.stateCode === component.componentName.text) ||
                null;
              break;
            }
          }
        }
      }

      setValue(`${addressType}.address1`, address1.trim(), {
        shouldValidate: true,
      });
      setValue(`${addressType}.address2`, apt.trim(), { shouldValidate: true });
      setValue(`${addressType}.city`, city.trim(), { shouldValidate: true });
      setValue(`${addressType}.state`, state, { shouldValidate: true });
      setValue(`${addressType}.zip`, postcode, { shouldValidate: true });
    },
    [setValue, states],
  );
  return {
    fillInAddressFromAutoComplete,
    fillInAddressFromValidation,
  };
}
