import React, { useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '../../assets/DeleteIcon';
import CustomModal from '../Modal';
import { useToast } from '../../../hooks/useToast';
import { AppActionButton } from '../../shared/AppActionButton';
import { ModalBox, ModalButtonsBox } from '../Modal/styles';
import { useDeleteRole } from '../../../services/roleService';
import AppTooltip from '../../shared/AppTooltip';

export default function DeleteRole(props: { disable: boolean; assign: number }) {
  const { t } = useTranslation();
  const toast = useToast();

  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { roleId } = useParams();
  const { mutate: deleteRole } = useDeleteRole({
    onSuccess() {
      toast.success(t('security.roles.roleList.delete.successfully'));
      navigate('/dashboard/Role');
    },
    onError() {
      toast.error(t('security.roles.roleList.delete.unsuccessful'));
    },
  });

  const handleModal = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const onDeleteRole = async () => {
    if (props?.assign > 0) {
      setOpen(true);
    } else {
      deleteRole(Number(roleId));
    }
  };
  const deleteRoleConfirmation = async () => {
    setOpen(true);
  };

  return (
    <div>
      <ModalBox onClick={() => !props.disable && handleModal()}>
        <AppTooltip arrow title={props.disable && t('admin.delete.tooltip')}>
          <span>
            <AppActionButton
              variant="outlined"
              disabled={props.disable}
              onClick={() => !props.disable && deleteRoleConfirmation()}
              startIcon={<DeleteIcon />}
            >
              {t('security.roles.roleList.delete')}
            </AppActionButton>
          </span>
        </AppTooltip>
      </ModalBox>
      {props?.assign > 0 && (
        <CustomModal
          open={open}
          containerWidth="400px"
          modalTitle={t('security.roles.roleList.delete.role')}
        >
          <>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              color="#000000"
              fontWeight="bold"
            >
              {t('security.roles.roleList.delete.msg')}
            </Typography>

            <AppActionButton
              style={{ marginRight: '15px' }}
              variant="outlined"
              onClick={handleModal}
            >
              {t('security.roles.roleList.delete.ok')}
            </AppActionButton>
          </>
        </CustomModal>
      )}
      {!props?.assign && (
        <CustomModal
          open={open}
          containerWidth="400px"
          modalTitle={t('security.roles.roleList.delete.role')}
        >
          <>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              color="#000000"
              fontWeight="bold"
            >
              {t('security.roles.roleList.delete.msgdelete')}
            </Typography>
            <ModalButtonsBox>
              <AppActionButton
                style={{ marginRight: '15px' }}
                onClick={() => !props.disable && onDeleteRole()}
                variant="outlined"
              >
                {t('user.actions.confirm')}
              </AppActionButton>
              <AppActionButton variant="outlined" onClick={handleModal}>
                {t('user.actions.cancel')}
              </AppActionButton>
            </ModalButtonsBox>
          </>
        </CustomModal>
      )}
    </div>
  );
}
