import React from 'react';

export default function HomeIcon() {
  return (
    <svg
      style={{ objectFit: 'contain', width: '100%' }}
      id="home_icon"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <rect id="Rectangle_2016" data-name="Rectangle 2016" width="32" height="32" fill="none" />
      <path
        id="Path_2657"
        data-name="Path 2657"
        d="M18.98,30V21.821H13.019V30H7.737a3.3,3.3,0,0,1-3.3-3.3V18.918A.9.9,0,0,1,4.7,18.28L15.363,7.617a.9.9,0,0,1,1.274,0L27.3,18.28a.9.9,0,0,1,.265.638V26.7a3.305,3.305,0,0,1-3.3,3.3ZM.537,16.06a1.834,1.834,0,0,1,0-2.594L12.663,1.34a4.826,4.826,0,0,1,6.671,0l3.611,3.612V3.014a1.834,1.834,0,1,1,3.668,0v5.6l4.85,4.85a1.834,1.834,0,0,1-2.595,2.594L16.741,3.932a1.083,1.083,0,0,0-1.484,0L3.132,16.06a1.833,1.833,0,0,1-2.595,0Z"
        transform="translate(0 1)"
      />
    </svg>
  );
}
