import { FC, useEffect, useRef } from 'react';
import { useInMyCourtQuery } from '../../../services/dashboard';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { FlexBox, AlignCenterBox } from '../../../components/shared/FlexBox';
import { ActivityContainer, ActivityDetail, IconStyle } from '../styles';
import AuthService from '../../../services/authService';
import { Gates } from '../../../types/agreements';

const InMyCourt: FC = () => {
  const {
    data: activityData,
    refetch: fetchActivity,
    isError: activityError,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useInMyCourtQuery();

  const activityRef = useRef<null | HTMLElement>(null);
  const activityTopRef = useRef<null | HTMLElement>(null);

  const { data: gateMessageParametherMAG } = AuthService.useCommonConstantQuery({
    constantName: 'GateMessageParameterMAG',
  });
  const { data: gateMessageParametherDuplicate } = AuthService.useCommonConstantQuery({
    constantName: 'GateMessageParameterDuplicateSeller',
  });

  const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { scrollHeight, scrollTop, offsetHeight } = event.currentTarget;
    const scrollLength = scrollHeight - offsetHeight - 100;

    if (!isFetching && scrollTop >= scrollLength && hasNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    fetchActivity();
  }, [fetchActivity]);

  if (activityError) {
    return <div>Error loading activity, please try sometime later</div>;
  }

  return (
    <ActivityContainer onScroll={(e) => onScroll(e)}>
      <Box ref={activityTopRef}></Box>
      {(activityData?.pages ?? []).map((page) =>
        page.data.map((item) => {
          const entityName = item.entity === 'SellerGroup' ? 'Seller Group' : item.entity;
          return (
            <FlexBox key={item.agreementId + item.type} mb={3}>
              <Box mt="4px">
                <IconStyle />
              </Box>
              <AlignCenterBox ml={1}>
                {item.type === 'Reviewer' && (
                  <ActivityDetail my={0}>
                    {gateMessageParametherMAG?.value} Issue – Review Required for{' '}
                    {item.agreementTypeName} sent by{' '}
                    <Link to={`/dashboard/Agent/${item.agentId}`} target="_blank">
                      {item.agentName}
                    </Link>{' '}
                    to {entityName}{' '}
                    <Link
                      to={`/dashboard/${item.entity}/${item.sellerId}/Agreement/Review?ReviewType=${Gates.MAG}`}
                      target="_blank"
                    >
                      {item.sellerName}
                    </Link>
                  </ActivityDetail>
                )}
                {item.type === 'Duplicate' && (
                  <ActivityDetail my={0}>
                    {gateMessageParametherDuplicate?.value} – Review Required for {entityName}{' '}
                    <Link
                      to={`/dashboard/${item.entity}/${item.sellerId}/Agreement/Review?ReviewType=${Gates.DOG}`}
                      target="_blank"
                    >
                      {item.sellerName}
                    </Link>
                  </ActivityDetail>
                )}
                {item.type === 'Seller' && (
                  <ActivityDetail my={0}>
                    Signature required on {item.agreementTypeName} for {entityName}{' '}
                    <a href={item.dsLink} target="_blank" rel="noopener noreferrer">
                      {item.sellerName}
                    </a>
                  </ActivityDetail>
                )}
              </AlignCenterBox>
            </FlexBox>
          );
        }),
      )}
      <Box ref={activityRef}></Box>
      {activityData && !hasNextPage && <Box textAlign="center">No more data</Box>}
    </ActivityContainer>
  );
};

export default InMyCourt;
