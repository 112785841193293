import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import CustomModal from '../Modal';
import { Layover } from './styles';
import { useAcceptTermsOfUse } from '../../../services/userServices';
import { AppActionButton } from '../../shared/AppActionButton';
import { UserContext } from '../../../context/User';
import { Iframe } from '../../Terms/style';
import { FetchTerms } from '../../../services/utilsService';
import { useToast } from '../../../hooks/useToast';

export default function ModalTerms() {
  const user = useContext(UserContext);
  const toast = useToast();
  const [open, setOpen] = useState<boolean>(false);

  const { data: terms } = FetchTerms();

  const { mutate: agreeOnTermsOfUse } = useAcceptTermsOfUse({
    onError(error) {
      toast.error(error.response.data.message);
    },
    onSuccess() {
      setOpen(false);
      user?.refetchUserData();
    },
  });

  useEffect(() => {
    if (user?.showTerms) {
      setOpen(true);
    }
  }, [user?.showTerms]);

  return (
    <>
      {open && <Layover />}
      <CustomModal containerWidth="80vw" open={open} modalTitle="Platform Terms of Use">
        <>
          <Iframe id="terms_frame_id" src={terms?.contentUrl} sandbox="allow-scripts"></Iframe>
          <Box margin="20px 0 0" display="flex">
            <AppActionButton
              variant="outlined"
              onClick={() => {
                agreeOnTermsOfUse({ userId: user?.userId || 0, isAgreed: true });
              }}
            >
              Accept
            </AppActionButton>
          </Box>
        </>
      </CustomModal>
    </>
  );
}
