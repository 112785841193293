import InfoIcon from '@mui/icons-material/Info';
import { useMemo } from 'react';
import { TooltipMessage } from './TooltipMessage';
import AppTooltip from '../shared/AppTooltip';

export type AgreementReasons = 'voided' | 'reviewed' | 'rejected' | 'declined' | 'deliveryFailure';

export default function InfoTooltip({
  reasons,
}: {
  reasons: Record<AgreementReasons, { firstLine: string; secondLine?: string } | null>;
}) {
  const result = useMemo(() => {
    const res: string[] = [];
    Object.keys(reasons).forEach((reasonKey) => {
      const reason = reasons[reasonKey as unknown as AgreementReasons];
      if (reason) {
        res.push(reason.firstLine);
        if (reason.secondLine) {
          res.push(reason.secondLine);
        }
      }
    });
    return res;
  }, [reasons]);

  return (
    <AppTooltip
      title={
        <div>
          {result.map((line) => (
            <TooltipMessage key={line} message={line} />
          ))}
        </div>
      }
      placement="bottom"
      leaveDelay={500}
    >
      <InfoIcon fontSize="inherit" color="disabled" />
    </AppTooltip>
  );
}
