import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEN from '../locales/en/translation.json';
import commonEN from '../locales/en/common.json';
import translationHE from '../locales/hn/translation.json';

const resources = {
  en: {
    translation: translationEN,
    common: commonEN,
  },
  hn: {
    translation: translationHE,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    ns: ['translation', 'common', 'home', 'profile'],
    defaultNS: 'translation',
    fallbackLng: 'en',
    lng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
