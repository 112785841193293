import { MenuItem } from '@mui/material';
import styled from 'styled-components';
import { White } from '../../../Theme/colorsVariables';
import { breakPointMedium } from '../../../utils';

export const DropdownItemStyled = styled(MenuItem)({
  padding: '3px 0px',
  width: '100%',
});

export const StyledList = styled('ul')`
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-template-areas:
      "header header"
      "main main";
  }
    max-height: 40vh;
    overflow: auto;
    li {
      font-size: 15px;
      padding: 0 !important;
      margin: 0;
      white-space: normal;
      ${breakPointMedium('font-size: 10px;')}
    }
  
`;

export const StyledAdvanced = styled('ul')`
  max-height: 40vh;
  overflow: auto;
`;

export const TypographySpan = styled('span')`
  font-family: Gotham-Book;
  font-size: 16px;
  font-weight: 700;
  background: ${White};
  padding-left: 10px;
  padding-right: 10px;
  ${breakPointMedium('font-size: 12px;')}
`;
