import React, { useContext, useEffect } from 'react';
import { Link } from '@mui/material';
import { Outlet } from 'react-router-dom';
import LogoIcon from '../../components/assets/LogoIcon';
import { AbsoluteBoxS, RootContainer } from '../Login/styles';
import { useAuth0 } from '@auth0/auth0-react';
import { DeploymentStatusContext } from '../../context/DeploymentStatus';
import { useToast } from '../../hooks/useToast';
import { TabsComunicationChannel } from '../../providers';

export default function PublicLayout() {
  const { logout } = useAuth0();
  const toast = useToast();
  const { isDeploymentRunning } = useContext(DeploymentStatusContext);

  useEffect(() => {
    if (isDeploymentRunning) {
      toast.info(
        'The site is undergoing scheduled maintenance. We are sorry for the inconvenience. Please try again in 15 minutes.',
        { persist: true },
      );
      setTimeout(() => {
        TabsComunicationChannel.postMessage('logout');
        logout();
      }, 6000);
    }
  }, [isDeploymentRunning, logout, toast]);

  return (
    <RootContainer maxWidth={false}>
      <AbsoluteBoxS>
        <Link href="/" underline="none">
          <LogoIcon />
        </Link>
      </AbsoluteBoxS>
      <Outlet />
    </RootContainer>
  );
}
