import React from 'react';

export default function KeyIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        id="password_icon"
        d="M88.327,79.788a7.732,7.732,0,0,0-1.884,7.85l-8.71,8.71a.7.7,0,0,0-.206.5v3.978a.7.7,0,0,0,.7.7h3.978a.7.7,0,0,0,.5-.206l.994-.995a.7.7,0,0,0,.2-.578l-.124-1.068,1.48-.139a.7.7,0,0,0,.634-.634l.139-1.48,1.068.124a.692.692,0,0,0,.549-.173.7.7,0,0,0,.235-.525V94.542h1.285a.7.7,0,0,0,.5-.206l1.8-1.779a7.615,7.615,0,0,0,7.8-1.83A7.734,7.734,0,1,0,88.327,79.788Zm8.949,4.972a2.109,2.109,0,1,1,0-2.983A2.111,2.111,0,0,1,97.276,84.76Z"
        transform="translate(-77.527 -77.526)"
        fill="#f90"
      />
    </svg>
  );
}
