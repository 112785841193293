import React from 'react';

export default function MailIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="17.999" viewBox="0 0 24 17.999">
      <path
        id="mail"
        d="M-897.5,4087.083h-21a1.5,1.5,0,0,1-1.5-1.5v-15a1.508,1.508,0,0,1,.014-.206.991.991,0,0,0,.282.544l10.851,10.771a1,1,0,0,0,.7.289,1,1,0,0,0,.7-.289l11.148-11.064a1.007,1.007,0,0,0,.25-.412,1.509,1.509,0,0,1,.045.367v15A1.5,1.5,0,0,1-897.5,4087.083Zm-10.648-7.509h0L-918.3,4069.5a.994.994,0,0,0-.7-.29,1.04,1.04,0,0,0-.126.008,1.492,1.492,0,0,1,.626-.136h20.948a1,1,0,0,0-.152.124l-10.443,10.365Z"
        transform="translate(920 -4069.083)"
        fill="#f90"
      />
    </svg>
  );
}
