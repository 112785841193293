import axios from 'axios';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query';
import { ApiError } from '../types';
import { TermsOfUseEntity } from '../types/termsOfUse';

export const TermsOfUseService = {
  useAll: (
    options?: Omit<UseQueryOptions<TermsOfUseEntity[], ApiError>, 'queryKey' | 'queryFn'>,
  ) => {
    const getAll = async () => {
      const fetch = await axios.get(`/terms-of-use`);
      return fetch?.data;
    };

    const state = useQuery<TermsOfUseEntity[], ApiError>('fetchTermsOfUseAll', getAll, options);

    return state;
  },
  useDownload: (
    options?: Omit<
      UseMutationOptions<string, ApiError, { termsId: number }>,
      'mutationKey' | 'mutationFn'
    >,
  ) => {
    const getOne = async ({ termsId }: { termsId: number }) => {
      const fetch = await axios.get(`/terms-of-use/${termsId}`);
      return fetch?.data;
    };

    const state = useMutation<string, ApiError, { termsId: number }>(
      'fetchTermsOfUseOne',
      getOne,
      options,
    );

    return state;
  },
  useUpdate: (
    options?: Omit<
      UseMutationOptions<unknown, ApiError, { termsId: number; isDisplaying: boolean }>,
      'mutationKey' | 'mutationFn'
    >,
  ) => {
    const update = async ({
      termsId,
      isDisplaying,
    }: {
      termsId: number;
      isDisplaying: boolean;
    }) => {
      const fetch = await axios.patch(
        `/terms-of-use/${termsId}`,
        { isDisplaying },
        {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
        },
      );
      return fetch?.data;
    };

    const state = useMutation<unknown, ApiError, { termsId: number; isDisplaying: boolean }>(
      'updateTermsOfUseById',
      update,
      options,
    );

    return state;
  },
  useUpload: (
    options?: Omit<
      UseMutationOptions<unknown, ApiError, { termsId: number; formData: FormData }>,
      'mutationKey' | 'mutationFn'
    >,
  ) => {
    const upload = async ({ termsId, formData }: { termsId: number; formData: FormData }) => {
      const fetch = await axios.post(`/terms-of-use/${termsId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return fetch?.data;
    };

    const state = useMutation<unknown, ApiError, { termsId: number; formData: FormData }>(
      'uploadTermsOfUseById',
      upload,
      options,
    );

    return state;
  },
  useDelete: (
    options?: Omit<
      UseMutationOptions<unknown, ApiError, { termsId: number }>,
      'mutationKey' | 'mutationFn'
    >,
  ) => {
    const deleteTerms = async ({ termsId }: { termsId: number }) => {
      const fetch = await axios.delete(`/terms-of-use/${termsId}`);
      return fetch?.data;
    };

    const state = useMutation<unknown, ApiError, { termsId: number }>(
      'deleteTermsOfUseById',
      deleteTerms,
      options,
    );

    return state;
  },
};
