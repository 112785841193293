export default function VoidIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={'24px'}
      height={'24px'}
      viewBox="0 0 1024 1024"
      version="1.1"
    >
      <path d="M652.7 139.4L640.2 128H232.5c-44.7 0-81.1 36.2-81.1 80.8v605.1c0 44.5 36.4 80.8 81.1 80.8h205.1c16.5 0 29.9-13.4 29.9-29.9s-13.4-29.9-29.9-29.9H232.5c-11.7 0-21.3-9.4-21.3-21V208.6c0-11.5 9.5-21 21.3-21h365.7v182.8c0 17.3 14.1 31.4 31.4 31.4h185.1v56.6c0 16.5 13.4 29.9 29.9 29.9s29.9-13.4 29.9-29.9v-97.7L652.7 139.4z m5.3 89.7L771.2 342H658V229.1z" />
      <path d="M755.6 727.4l111.8-111.8c11.7-11.7 11.7-30.8 0-42.4-11.6-11.7-30.7-11.7-42.4 0L713.2 685 601.4 573.2c-11.7-11.7-30.8-11.7-42.4 0-11.7 11.6-11.7 30.7 0 42.4l111.8 111.8L559 839.2c-11.7 11.7-11.7 30.8 0 42.4 11.7 11.7 30.8 11.7 42.4 0l111.8-111.8L825 881.6c11.6 11.7 30.7 11.7 42.4 0 11.7-11.6 11.7-30.7 0-42.4L755.6 727.4z" />
    </svg>
  );
}
