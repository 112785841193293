import { TableCell, TableBody, Box, InputAdornment, TableRow } from '@mui/material';
import PageHeaderContentHost from '../../../components/shared/AppPageHeaderContentHost';
import { useNavigate } from 'react-router-dom';
import { SearchIcon } from '../../../components/AppSearch/styles';
import {
  CustomSearch,
  ClearIconEndAdornment,
  SingleSearchBox,
} from '../../../components/shared/CustomTableSingleSearch/styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomTableHead from '../../../components/shared/CustomTableHead';
import { EntityType, Order } from '../../../types/query-params';
import { AlignCenterBox, FlexBox, SpaceBetweenBox } from '../../../components/shared/FlexBox';
import DataTable from '../../../components/table/DataTable';
import InfoIcon from '@mui/icons-material/Info';
import { ConfigurationService } from '../../../services/configurationService';
import { DealerType } from '../../../types/configurations';
import { AppAutocomplete } from '../../../components/shared/AppAutocomplete';
import { AppTextField } from '../../../components/shared/AppTextField';
import { Link } from 'react-router-dom';
import { SelectItem } from '../../../types';
import AppListItem from '../../../components/shared/AppListItem';
import AppTooltip from '../../../components/shared/AppTooltip';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';

export default function DealerTypeGroups() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isEnabled = usePermissions(PermissionKeys.ViewConfiguration);
  const { data: dealerTypeGroups } = ConfigurationService.useDealerTypeGroups({
    enabled: isEnabled,
  });

  const [searchValue, setSearchValue] = useState('');
  const [dealerType, setDealerType] = useState<SelectItem<number | string> | null>({
    label: EntityType.Any,
    value: EntityType.Any,
  });
  const [order, setOrder] = useState(Order.ASC);
  const [orderBy, setOrderBy] = useState('dealerTypeGroupName');
  const [tableContainerRef, setTableContainerRef] = useState<HTMLDivElement | null>(null);

  const tableHeadingsArray = useMemo(() => {
    return [
      { 'Dealer Type Group': 'dealerTypeGroupName' },
      { 'Dealer Type Group Display Name': 'dealerTypeGroupDisplayName' },
      { 'Dealer Type': 'dealerTypeName' },
    ] as Record<string, string>[];
  }, []);

  const onHandleSetOrder = useCallback(
    (newOrder: Order, newOrderBy: string): void => {
      setOrder(newOrder);
      setOrderBy(newOrderBy);
    },
    [setOrder, setOrderBy],
  );

  const dealerTypes = useMemo(() => {
    const res: SelectItem<number | string>[] = [{ label: EntityType.Any, value: EntityType.Any }];
    dealerTypeGroups?.forEach((item) => {
      if (res.find((option) => Number(option.value) === item.dealerTypeId) === undefined) {
        res.push({ label: item.dealerTypeName, value: item.dealerTypeId });
      }
    });
    return res.sort((a, b) => a.label.localeCompare(b.label));
  }, [dealerTypeGroups]);

  const sortedData = useMemo(() => {
    const filteredValuesBySearch =
      searchValue.length > 2
        ? dealerTypeGroups?.filter((value) =>
            value.dealerTypeGroupName.toLowerCase().includes(searchValue.toLowerCase()),
          )
        : dealerTypeGroups;

    const filteredValuesByType =
      dealerType?.value !== EntityType.Any
        ? filteredValuesBySearch?.filter((value) => value.dealerTypeId === dealerType?.value)
        : filteredValuesBySearch;
    const sortedValues = filteredValuesByType?.sort((a, b) => {
      if (order === Order.ASC) {
        return `${a[orderBy as keyof DealerType]}`.localeCompare(
          `${b[orderBy as keyof DealerType]}`,
        );
      }
      if (order === Order.DESC) {
        return `${b[orderBy as keyof DealerType]}`.localeCompare(
          `${a[orderBy as keyof DealerType]}`,
        );
      }
      return `${a[orderBy as keyof DealerType]}`.localeCompare(`${b[orderBy as keyof DealerType]}`);
    });
    return sortedValues;
  }, [dealerType, dealerTypeGroups, order, orderBy, searchValue]);

  const handleFilter = (val: SelectItem<number | string> | null) => {
    setDealerType(val);
  };

  useEffect(() => {
    if (!isEnabled) {
      navigate('/NotFound', { state: { error: t('restrictions.tooltip') } });
    }
  }, [isEnabled, navigate, t]);

  return (
    <>
      <Box my={1}>
        <PageHeaderContentHost name="Dealer Type Groups" />
      </Box>
      <AlignCenterBox mb={2}>
        <SpaceBetweenBox sx={{ width: '100%' }}>
          <SingleSearchBox>
            <CustomSearch
              sx={{ width: '300px' }}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              placeholder={'Search'}
              size="small"
              aria-describedby="outlined-weight-helper-text"
              InputProps={{
                'aria-label': 'weight',
                endAdornment: (
                  <InputAdornment position="end">
                    {searchValue && (
                      <ClearIconEndAdornment
                        onClick={() => {
                          setSearchValue('');
                        }}
                      />
                    )}
                    {!searchValue && <SearchIcon />}
                  </InputAdornment>
                ),
              }}
              error={searchValue.length < 3 && searchValue !== ''}
              helperText={
                searchValue.length < 3 && searchValue !== '' && t('appTextField.helperText')
              }
            />
            <AppTooltip title={'Enter Dealer Type Name to search'} placement="bottom">
              <InfoIcon fontSize="inherit" color="disabled" />
            </AppTooltip>
          </SingleSearchBox>
          <AppAutocomplete
            sx={{ maxWidth: '350px' }}
            fullWidth
            isOptionEqualToValue={(option, value) => {
              return option.value === value.value;
            }}
            disablePortal
            options={dealerTypes}
            forcePopupIcon
            value={dealerType}
            renderInput={(params) => (
              <AppTextField {...params} label={'Dealer Type'} fullWidth size="small" />
            )}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => {
              if (option.label) {
                return (
                  <AppListItem {...props} key={JSON.stringify(option)}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: option.label,
                      }}
                    ></span>
                  </AppListItem>
                );
              }
            }}
            onChange={(e, val) => {
              handleFilter(val);
            }}
          />
        </SpaceBetweenBox>
      </AlignCenterBox>
      <FlexBox
        className="MAINTABLE"
        sx={{ flex: '1 1 auto', flexDirection: 'column', height: '100%' }}
        ref={setTableContainerRef}
      >
        <DataTable tableMaxHeight={`${tableContainerRef?.getBoundingClientRect().height}px`}>
          <CustomTableHead
            heading={tableHeadingsArray}
            order={order}
            orderBy={orderBy}
            onRequestSort={onHandleSetOrder}
          />
          <TableBody>
            {sortedData?.map((item) => (
              <TableRow key={item.dealerTypeGroupId}>
                <TableCell>
                  <Link
                    to={`/dashboard/Configuration/DealerGroupType/${item.dealerTypeGroupId}/AssetMake?dealerTypeGroupName=${item.dealerTypeGroupDisplayName}&dealerType=${item.dealerTypeName}`}
                  >
                    {item.dealerTypeGroupName}
                  </Link>
                </TableCell>
                <TableCell>{item.dealerTypeGroupDisplayName}</TableCell>
                <TableCell>{item.dealerTypeName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </DataTable>
      </FlexBox>
    </>
  );
}
