import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { HttpStatusCode } from '../types/http-status-code.enum';
import { useEffect, useState } from 'react';
import { TabsComunicationChannel } from '../providers';

export const UnauthorizedResponseInterceptor = () => {
  const { logout } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const [navigateToState, setNavigateToState] = useState<{ to: string; state: any }>({
    to: '',
    state: undefined,
  });
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const status = error?.response?.status;
      if (status === HttpStatusCode.Unauthorized) {
        localStorage.clear();
        TabsComunicationChannel.postMessage('logout');
        logout();

        return;
      }

      if (status === HttpStatusCode.NotFound || status === HttpStatusCode.Forbidden) {
        setNavigateToState({
          to: '/NotFound',
          state: { error: error.response.data.message, location },
        });
      }
      return Promise.reject(error);
    },
  );
  useEffect(() => {
    if (navigateToState.to) {
      navigate(navigateToState.to, { state: navigateToState.state });
      setNavigateToState({
        to: '',
        state: undefined,
      });
    }
  }, [navigate, navigateToState, navigateToState.state, navigateToState.to]);
};
