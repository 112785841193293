import { RenderStateChipProps } from './interface';
import { ChipStyle } from './styles';

const RenderStateChip = ({ label, selectedArray, setSelected, ind }: RenderStateChipProps) => {
  return (
    <ChipStyle
      sx={{
        '& .MuiChip-deleteIcon, .MuiChip-deleteIcon:hover': {
          color: '#000000',
        },
      }}
      label={label}
      onDelete={() => {
        const array = [...selectedArray];
        array.splice(ind, 1);
        setSelected([...array]);
      }}
    />
  );
};
export default RenderStateChip;
