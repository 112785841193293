import { object, array, number } from 'yup';
import { commonSelectSchema, trimmedString } from '.';
import { getCommonErrorMessages } from '../utils';

export const roleValidationSchema = object().shape({
  roleName: trimmedString
    .required(getCommonErrorMessages('required'))
    .max(50, getCommonErrorMessages('charExceeded')),
  assosiatedWith: commonSelectSchema,
  roleDescription: trimmedString.max(500, getCommonErrorMessages('charExceeded')),
  permissionSetIds: array(number()),
  fakePermissionArrayForPermissionsValidation: array(number())
    .required(getCommonErrorMessages('required'))
    .min(1, getCommonErrorMessages('permission'))
    .default([]),
});
