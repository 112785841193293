import {
  Black,
  BrightOrange,
  LightOrange,
  LightSlateGray,
  SecondaryBackground,
  Silver,
  White,
} from './../../../Theme/colorsVariables';
import { Pagination, Box, TablePagination, Button } from '@mui/material';
import styled from 'styled-components';
import { breakPointPagination, breakPointMedium } from '../../../utils/MediaBreakPoint';
import { AlignCenterBox } from '../FlexBox';

export const StyledButtonBoxMedia = `
width: 40px;
height: 26px;
`;

export const StyledPaginationButton = `
min-width: 20px;
height: 20px;
font-size: 12px;
`;

export const StyledPagination = styled(Pagination)`
  ul {
    li {
      button {
        margin: 0;
        color: ${Silver};
        font-size: 18px;
        ${breakPointMedium(StyledPaginationButton)}
        &:hover {
          border-radius: 0;
        }
      }
      [aria-current]:not([aria-current='false']) {
        color: ${White} !important;
        background: ${SecondaryBackground};
        font-weight: 600;
        border-radius: 0;
      }
      svg {
        fill: ${Black};
      }
    }
  }
`;

export const StyledBox = styled(AlignCenterBox)`
  justify-content: end;
`;

export const BoxContainer = styled(Box)`
  color: ${LightSlateGray};
  font-size: 14px;
  display: inline-block;
  ${breakPointPagination('font-size: 13px;')}
`;

export const MainBox = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  background: ${LightOrange};
  color: ${White};
  cursor: pointer;
  font-size: 14px;
  min-width: unset;
  margin-left: 10px;
  &:hover {
    background: ${BrightOrange};
  }
  &:disabled {
    background: ${BrightOrange};
  }
  ${breakPointPagination(StyledButtonBoxMedia)}
`;

export const TablePaginationS = styled(TablePagination)`
  border: none;
  padding: 0;
  .MuiTablePagination-displayedRows {
    display: none;
  }
  .MuiTablePagination-actions {
    display: none;
  }
  .MuiTablePagination-toolbar {
    min-height: 34px !important;
  }
  p {
    margin: 0;
  }
  ${breakPointMedium(`
  .MuiTablePagination-toolbar {
    min-height: 30px !important;
    font-size: 12px;
    .MuiTablePagination-selectLabel{
    font-size: 12px;

    }
  }
  `)}
`;

export const StyledBoxPagination = styled(Box)`
  width: 40px;
  height: 34px;
  ${breakPointMedium(StyledButtonBoxMedia)}
`;
