/* Heights */

export const MainHeightMax = '60px';
export const MainHeightMed = '50px';
export const MainHeightMin = '40px';
export const MainHeightLowest = '30px';
export const TextFelidHeightMax = '18px';
export const TextFelidHeightMed = '14px';
export const TextFelidHeightMin = '12px';

/* Fonts */

export const FontSizeUltra = '20px';
export const FontSizeMax = '18px';
export const FontSizeMed = '16px';
export const FontSizeMin = '14px';
export const FontSizeLowestMin = '12px';
export const FontSizeHeighestMax = '20px';
export const PageTitleFontMax = '24px';
export const PageTitleFontMed = '22px';
export const ElevenPx = '11px';

/* BorderRadius */

export const BorderRadiusMaxHeighest = '14px';
export const BorderRadiusMax = '12px';
export const BorderRadiusMed = '10px';
export const BorderRadiusMin = '8px';
export const BorderRadiusMinLowest = '6px';

/* Edit & Delete Button */
export const ButtonHeightMax = '50px';
export const ButtonHeightMed = '40px';
export const ButtonHeightMin = '35px';
