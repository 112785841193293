export const LightOrange = '#ff9900';
export const LightSlateGray = '#77868f';
export const White = '#ffffff';
export const Black = '#000000';
export const Gray = '#dddddd';
export const MainGreen = '#34c759';
export const DeepElectricBlue = '#417aff';
export const Nickel = '#767676';
export const FlashWhite = '#eeeeee';
export const Red = '#ff0000';
export const Platinum = '#e5e5e5';
export const UltimateGray = '#e0e0e0';
export const MainRed = '#cc1100';
export const WhiteSmoke = '#f5f5f5';
export const BrightGreen = '#09BD3C';
export const DarkGray = '#aaaaaa';
export const RedOrange = '#f44336';
export const MurkGray = '#707070';
export const Maroon = '#d32f2f';
export const Darksome = '#d5d3d3';
export const GrotesqueGreen = '#14bf45';
export const MediumGray = '#bebebe';
export const Yellow = '#fef000';
export const Silver = '#717579';
export const Leaden = '#333333';
export const BrightOrange = '#ffae35';
export const ModalBackground = 'rgba(59,59,59,0.2)';
export const linkColor = '#417aff';
export const SeperatorLine = 'rgb(204, 0, 0)';
export const MainTextColor = '#000000';
export const SecondaryTextColor = '#77868f';

export const MainColor = '#ff9900';
export const MainBackground = 'rgb(245 245 245)';
export const SecondaryBackground = '#77868f';
export const ThirdBackground = 'rgb(238 238 238)';
export const HoverColor = '#333333';
