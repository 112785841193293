import { Typography } from '@mui/material';
import styled from 'styled-components';
import { Black } from '../../../Theme/colorsVariables';

const Description = styled(Typography)`
  color: ${Black};
  width: 100%;
`;

export { Description };
