import { TableBody, TableRow, TableCell, Box, useTheme, useMediaQuery } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomTableProps } from '../../../types/customTable';
import DataTable from '../../table/DataTable';
import CustomTableFilters from '../CustomTableFilters';
import CustomTableHead from '../CustomTableHead';
import CustomTablePagination from '../CustomTablePagination';
import CustomTableRows from '../CustomTableRows';
import { FlexBox, SpaceBetweenBox } from '../FlexBox';
import { CustomBoxPagination, TableContainer } from './styles';
import { useSearchParams } from 'react-router-dom';

export default function CustomTable({
  page,
  rowsPerPage,
  data,
  valueRef,
  goError,
  handlePageChange,
  handleRowsPerPageChange,
  rowsPerPageOptions = [],
  setPage,
  tableHeadingsArray,
  advancedSearchVisibility,
  setAdvancedSearchVisibility,
  onSubmitAdvancedSearch,
  onResetAdvancedSearch,
  searchValue,
  handleSearchValue,
  tableTitle,
  placeHolder,
  setPagination,
  addNewLink,
  InputsComponent,
  handleRequestSort,
  openAdvancedSearch,
  order,
  err,
  orderBy,
  selectArray,
  isAdvanceSearch = true,
  isDataLoading,
  total,
  isDataPagination,
  active,
  displayOptions = { paginationTop: true, paginationBottom: true, tableOnly: false },
  noResultsMessage,
  searchInfoMessage,
  hideMainSearch,
  unsortableColumns,
  extraHeightVar,
}: CustomTableProps) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));
  const [searchParams, setSearchParams] = useSearchParams();
  const { paginationTop, paginationBottom, tableOnly } = displayOptions;

  const rowsPerPageOptionsDefault = useMemo(
    () => (rowsPerPageOptions.length ? rowsPerPageOptions : [10, 40, 70, 100]),
    [rowsPerPageOptions],
  );

  const renderEmptyRowWithMessage = useCallback(
    (message: string) => (
      <TableRow>
        <TableCell
          {...{
            style: {
              width: '20%',
            },
          }}
        >
          {message}
        </TableCell>
        {Array.from(Array(tableHeadingsArray.length - 1).keys()).map(() => (
          <TableCell key={(Math.random() + 1).toString(36).substring(7)}></TableCell>
        ))}
      </TableRow>
    ),
    [tableHeadingsArray],
  );

  const { t } = useTranslation();

  const renderBody = useCallback(() => {
    if (isDataLoading) {
      return renderEmptyRowWithMessage(t('table.loading'));
    }
    if (data?.length === 0) {
      return noResultsMessage
        ? renderEmptyRowWithMessage(noResultsMessage)
        : renderEmptyRowWithMessage(t('table.noRecords'));
    }
    if (advancedSearchVisibility && data.length === 0) {
      return noResultsMessage
        ? renderEmptyRowWithMessage(noResultsMessage)
        : renderEmptyRowWithMessage(t('table.noRecords'));
    }
    return (
      <CustomTableRows
        page={page}
        rowsPerPage={rowsPerPage}
        columns={data}
        isDataPagination={isDataPagination}
      />
    );
  }, [
    renderEmptyRowWithMessage,
    data,
    isDataPagination,
    page,
    rowsPerPage,
    t,
    advancedSearchVisibility,
    isDataLoading,
    noResultsMessage,
  ]);

  const getTotal = useMemo(() => {
    const calculationPage = (page + 1) * rowsPerPage;
    if (isDataPagination) {
      if (total < calculationPage) {
        return total;
      }
    } else if (data.length < calculationPage) {
      return data.length;
    }
    return calculationPage;
  }, [data.length, isDataPagination, page, rowsPerPage, total]);

  const [topContainerRef, setTopContainerRef] = useState<HTMLDivElement | null>(null);
  const [bottomContainerRef, setBottomContainerRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isAdvanceSearch) {
      if (searchParams.has('advancedSearchVisibility')) {
        if (advancedSearchVisibility) {
          searchParams.set('advancedSearchVisibility', `true`);
          setAdvancedSearchVisibility(true);
        } else {
          searchParams.set('advancedSearchVisibility', `false`);
          setAdvancedSearchVisibility(false);
        }
      } else {
        searchParams.append('advancedSearchVisibility', `false`);
        setAdvancedSearchVisibility(false);
      }
      setSearchParams(searchParams, { replace: true });
    }
  }, [
    advancedSearchVisibility,
    isAdvanceSearch,
    searchParams,
    setAdvancedSearchVisibility,
    setSearchParams,
  ]);

  return (
    <TableContainer>
      {paginationTop && (
        <Box sx={{ flexGrow: 0 }} ref={setTopContainerRef}>
          <CustomTableFilters
            advancedSearchVisibility={advancedSearchVisibility}
            setAdvancedSearchVisibility={setAdvancedSearchVisibility}
            setPagination={setPagination}
            onSubmitAdvancedSearch={onSubmitAdvancedSearch}
            onResetAdvancedSearch={onResetAdvancedSearch}
            searchValue={searchValue}
            handleSearchValue={handleSearchValue}
            page={page}
            selectArray={selectArray}
            isAdvanceSearch={isAdvanceSearch}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptionsDefault}
            goError={goError}
            total={total}
            pageTotal={getTotal}
            valueRef={valueRef}
            setPage={setPage}
            paginationTop={!!paginationTop}
            tableTitle={tableTitle}
            placeHolder={placeHolder}
            addNewLink={addNewLink}
            InputsComponent={InputsComponent}
            data={data}
            err={err}
            active={active}
            openAdvancedSearch={openAdvancedSearch}
            searchInfoMessage={searchInfoMessage}
            hideMainSearch={hideMainSearch}
          />
        </Box>
      )}
      <FlexBox
        className="MAINTABLE"
        sx={{
          flexDirection: 'column',
          height: !tableOnly
            ? `calc( 100vh - ${isSmall ? 140 : 106}px${
                extraHeightVar ? ` - ${extraHeightVar}px` : ''
              } - ${topContainerRef?.getBoundingClientRect()?.height}px - ${
                bottomContainerRef?.getBoundingClientRect()?.height
              }px)`
            : '100%',
        }}
      >
        <DataTable
          tableMaxHeight={`calc( 100vh - ${isSmall ? 140 : 106}px${
            extraHeightVar ? ` - ${extraHeightVar}px` : ''
          }${topContainerRef ? ` - ${topContainerRef?.getBoundingClientRect()?.height}px` : ''}${
            bottomContainerRef ? ` - ${bottomContainerRef?.getBoundingClientRect()?.height}px` : ''
          })`}
        >
          <CustomTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            heading={tableHeadingsArray}
            unsortableColumns={unsortableColumns}
            tableHeadCellProps={data?.[0]?.map((column) => ({ ...column.tableCellProps })) || []}
          />
          <TableBody>{renderBody()}</TableBody>
        </DataTable>
      </FlexBox>

      {paginationBottom && (
        <SpaceBetweenBox my={1} ref={setBottomContainerRef}>
          <CustomBoxPagination>
            {`${t('shared.tablePagination.showing')} ${
              total === 0 ? 0 : rowsPerPage * page + 1
            } - ${getTotal} ${t('shared.tablePagination.of')} ${total} ${t(
              'shared.tablePagination.records',
            )}`}
          </CustomBoxPagination>
          <CustomTablePagination
            page={page}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptionsDefault}
            goError={goError}
            total={total}
            valueRef={valueRef}
            setPage={setPage}
            data={data}
          />
        </SpaceBetweenBox>
      )}
    </TableContainer>
  );
}
