import React, { useState, useMemo, useEffect, useCallback, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, FormControl, Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import AuthService from '../../services/authService';
import ProductService from '../../services/productService';
import { ApiError, SellerProductsInfo, AddressState } from '../../types';
import RefreshIcon from '../../components/assets/RefreshIcon';
import { ProductLineFormValues, NewProduct } from '../../types/product-types';
import StatesMultipleSelect from '../../components/shared/StatesMultipleSelectChips';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import { useToast } from '../../hooks/useToast';
import { useDataloss } from '../../hooks/useDataloss';
import {
  AppActionButton,
  AppActionButtonsContainer,
} from '../../components/shared/AppActionButton';
import PageHeaderContentHost from '../../components/shared/AppPageHeaderContentHost';
import { AppTextField } from '../../components/shared/AppTextField';
import { PageHeaderContainer } from '../../components/shared/AppPageHeaderContentHost/styles';
import { isEqual } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { productLineValidationSchema } from '../../Resolvers/productLineValidationSchema';
import { CancelModal } from '../../components/modal/CancelModal';
import { AppSaveButton } from '../../components/shared/AppSaveButton';
import { AppCancelButton } from '../../components/shared/AppCancelButton';
import { QuickConfirmationModalContext } from '../../context/QuickModal';
import { useNavigateAfterDatalossDecision } from '../../hooks/useNavigateAfterDatalossDecision';

const defaultFormValues = {
  productName: '',
  description: '',
  states: [],
};

export default function AddProductLinePage() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));

  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const isAddProductPermission = usePermissions(PermissionKeys.AddProducts);

  const { navigateAfterDatalossDecision } = useNavigateAfterDatalossDecision();

  const [cancelPopUp, setCancelPopUp] = useState<boolean>(false);
  const [isUnsavedChanges, setIsUnsavedChanges] = useState<boolean>(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);

  const formRef = useRef<HTMLFormElement>(null);

  const formMethods = useForm<ProductLineFormValues>({
    mode: 'onChange',
    defaultValues: defaultFormValues,
    resolver: yupResolver(productLineValidationSchema),
  });

  const { control, handleSubmit, reset, watch, setValue } = formMethods;

  const { isValid, errors } = formMethods.formState;

  const watchAllFields = watch();
  const { states: selectedStates } = watchAllFields;

  const { data: statesResponse } = AuthService.useStatesQuery();

  const statesArray = useMemo(() => (statesResponse ? statesResponse : []), [statesResponse]);

  const onProductAddSuccess = (response: SellerProductsInfo) => {
    toast.success(t('addproduct.succesfull.message'));
    if (!navigateAfterDatalossDecision()) {
      navigate(`/dashboard/ProductLine/${response.productId}`);
    }
  };

  const { setQuickModal } = useContext(QuickConfirmationModalContext);

  const onProductAddError = (error: ApiError, variables: NewProduct) => {
    if (error.response.data.code === 30050) {
      setQuickModal({
        message: `Product Line ${variables.productName} already exists.`,
        modalTitle: 'Duplicates!',
        isOpen: true,
      });
    } else {
      toast.error(error.response.data.message);
    }
  };

  const { mutate: addProduct } = ProductService.useAddProductQuery(
    onProductAddSuccess,
    onProductAddError,
  );

  const handleCancelAction = () => {
    if (isUnsavedChanges) {
      setCancelPopUp(true);
    } else {
      navigate(`/dashboard/ProductLine`);
    }
  };

  const onSubmit: SubmitHandler<ProductLineFormValues> = async (data) => {
    const productAddressStates = selectedStates.map((item) => {
      const stateObj = { addressStateId: item.addressStateId };
      return stateObj;
    });

    const dataToPost: NewProduct = {
      ...data,
      status: false,
      productId: 0,
      productAddressStates,
      orgProducts: [
        {
          orgId: 1,
          productId: 0,
        },
      ],
    };
    addProduct(dataToPost);
  };

  const onDiscardChanges = useCallback(() => {
    navigate(`/dashboard/ProductLine`);
  }, [navigate]);

  const onSaveChanges = useCallback(() => {
    setCancelPopUp(false);
    if (formRef && formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }, []);

  const onExit = useCallback(() => {
    setCancelPopUp(false);
  }, []);

  useDataloss(isUnsavedChanges, onDiscardChanges, onSaveChanges, onExit);

  useEffect(() => {
    const isValuesEqual = isEqual(defaultFormValues, watchAllFields);

    setIsUnsavedChanges(!isValuesEqual);
    setIsSaveDisabled(!isValid);
  }, [isValid, watchAllFields]);

  if (!isAddProductPermission) {
    return (
      <Grid container textAlign="center">
        <Grid item md={12} xs={12}>
          <Typography fontFamily="Gotham-Bold" fontSize="20px" mt={10}>
            {t('restrictions.tooltip')}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <PageHeaderContainer>
          <PageHeaderContentHost
            name={t('product.addproduct.addproductline')}
            showRequiredLabel={true}
          />
          <AppActionButtonsContainer>
            <AppCancelButton onClick={handleCancelAction} data-testid="cancel-btn">
              {t('product.addproduct.cancel')}
            </AppCancelButton>
            <AppActionButton
              variant="outlined"
              startIcon={<RefreshIcon />}
              onClick={() => reset()}
              data-testid="reset-btn"
            >
              {t('product.addproduct.reset')}
            </AppActionButton>
            <AppSaveButton type="submit" disabled={isSaveDisabled} data-testid="save-btn">
              {t('product.addproduct.save')}
            </AppSaveButton>
          </AppActionButtonsContainer>
        </PageHeaderContainer>

        <Grid container spacing={isSmall ? 1 : 2} mt={2}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="productName"
              render={({ field }) => (
                <AppTextField
                  {...field}
                  label="Product Line"
                  required
                  onChange={(e) => {
                    setValue('productName', e.target.value, { shouldValidate: true });
                  }}
                  error={!!errors.productName}
                  errorMessage={errors.productName?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <StatesMultipleSelect<AddressState>
                disabled={false}
                originalData={statesArray}
                selectedData={selectedStates}
                handleChangeSelected={(data) => setValue('states', data)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Controller
                control={control}
                name="description"
                render={({ field }) => (
                  <AppTextField
                    {...field}
                    type="text"
                    label="Description"
                    variant="outlined"
                    multiline
                    fullWidth
                    minRows={4}
                    maxRows={4}
                    onChange={(e) => {
                      setValue('description', e.target.value, { shouldValidate: true });
                    }}
                    error={!!errors.description?.message}
                    errorMessage={errors.description?.message}
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>
      </form>

      <CancelModal
        isOpened={cancelPopUp}
        modalTitle={t('security.roles.roleForm.role.warning')}
        onDiscardChanges={onDiscardChanges}
        onSaveChanges={onSaveChanges}
        onExit={onExit}
      />
    </>
  );
}
