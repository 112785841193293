import { UpdateSellerPayload, SellerAddress } from '../types';

export const patchFormatter = (originalData: any, updatedData: any) => {
  const payload: Partial<UpdateSellerPayload> = {};

  Object.keys(originalData).forEach((key) => {
    const oldFieldValue = originalData[key as keyof UpdateSellerPayload];
    const newFieldValue = updatedData[key as keyof UpdateSellerPayload];

    if (
      key === 'addresses' &&
      typeof oldFieldValue === 'object' &&
      typeof newFieldValue === 'object' &&
      oldFieldValue &&
      newFieldValue
    ) {
      const oldPhysicalAddress = oldFieldValue[0];
      const oldMailingAddress = oldFieldValue[1];
      const newPhysicalAddress = newFieldValue[0];
      const newMailingAddress = newFieldValue[1];

      const physicalAddress: Partial<SellerAddress> = {};
      const mailingAddress: Partial<SellerAddress> = {};

      // Compare Physical Address
      Object.keys(oldPhysicalAddress).forEach((addressKey) => {
        const oldAddressFieldValue = oldPhysicalAddress[addressKey as keyof SellerAddress];
        const newAddressFieldValue = newPhysicalAddress[addressKey as keyof SellerAddress];

        if (addressKey === 'addressId' || addressKey === 'addressType') {
          physicalAddress[addressKey as keyof SellerAddress] = newAddressFieldValue as any;
          return;
        }

        if (addressKey === 'sameAsAddressTypeId' && oldAddressFieldValue !== newAddressFieldValue) {
          physicalAddress[addressKey as keyof SellerAddress] = (newAddressFieldValue || 0) as any;
          return;
        }

        if (
          oldAddressFieldValue !== newAddressFieldValue &&
          !(!oldAddressFieldValue && !newAddressFieldValue)
        ) {
          physicalAddress[addressKey as keyof SellerAddress] = (
            !!oldAddressFieldValue && !newAddressFieldValue
              ? ''
              : !oldAddressFieldValue && !newAddressFieldValue
              ? null
              : newAddressFieldValue ?? null
          ) as any;
        }
      });

      // Compare Mailing Address
      Object.keys(oldMailingAddress).forEach((addressKey) => {
        const oldAddressFieldValue = oldMailingAddress[addressKey as keyof SellerAddress];
        const newAddressFieldValue = newMailingAddress[addressKey as keyof SellerAddress];

        if (addressKey === 'addressId' || addressKey === 'addressType') {
          mailingAddress[addressKey as keyof SellerAddress] = newAddressFieldValue as any;
          return;
        }

        if (addressKey === 'sameAsAddressTypeId' && oldAddressFieldValue !== newAddressFieldValue) {
          mailingAddress[addressKey as keyof SellerAddress] = (newAddressFieldValue || 0) as any;
          return;
        }

        if (
          oldAddressFieldValue !== newAddressFieldValue &&
          !(!oldAddressFieldValue && !newAddressFieldValue)
        ) {
          mailingAddress[addressKey as keyof SellerAddress] = (
            !!oldAddressFieldValue && !newAddressFieldValue
              ? ''
              : !oldAddressFieldValue && !newAddressFieldValue
              ? null
              : newAddressFieldValue ?? null
          ) as any;
        }
      });

      if (Object.keys(physicalAddress).length > 2) {
        if (!payload.addresses?.length) {
          payload.addresses = [];
        }
        payload.addresses.push(physicalAddress);
      }
      if (Object.keys(mailingAddress).length > 2) {
        if (!payload.addresses?.length) {
          payload.addresses = [];
        }

        payload.addresses.push(mailingAddress);
      }
      return;
    }

    if (key === 'sellerGroupId' && oldFieldValue && !newFieldValue) {
      payload[key as keyof UpdateSellerPayload] = 0 as any;

      return;
    }

    if (key === 'feinSource' && oldFieldValue !== newFieldValue) {
      payload[key as keyof UpdateSellerPayload] = newFieldValue;

      return;
    }

    if (oldFieldValue !== newFieldValue && !(!oldFieldValue && !newFieldValue)) {
      payload[key as keyof UpdateSellerPayload] = (
        !!oldFieldValue && !newFieldValue
          ? ''
          : !oldFieldValue && !newFieldValue
          ? null
          : newFieldValue ?? null
      ) as any;
    }
  });

  return payload;
};
