import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import Cookies from 'universal-cookie';

export const BearerInterceptor = () => {
  const { getAccessTokenSilently } = useAuth0();
  axios.interceptors.request.use(
    async (request) => {
      if (request.headers && !window.location.href.includes('/public')) {
        const token = await getAccessTokenSilently();

        const cookie = new Cookies();
        cookie.set('accessToken', token, { path: '/' });
        request.headers.Authorization = `Bearer ${token}`;
      }

      return request;
    },
    async (error) => {
      return Promise.reject(error);
    },
  );
};
