import styled from 'styled-components';
import { MainTextColor } from '../../../Theme/colorsVariables';
import { breakPointMedium } from '../../../utils';

export const StyledList = styled('ul')`
  max-height: 20vh;
  overflow: auto;
  li {
    font-size: 15px;
    padding: 5px 0 5px 10px !important;
    margin: 0;
    white-space: normal;
  }
`;

export const StyledAdvanced = styled('ul')`
  max-height: 20vh;
  overflow: auto;
`;

export const TypographySpan = styled('span')`
  font-family: Gotham-Book;
  font-size: 16px;
  font-weight: 700;
  margin-left: 6px;
  color: ${MainTextColor};
  ${breakPointMedium('font-size: 12px;')}
`;
