import { AppTextFieldDelayedHelperText } from '../AppTextFieldDelayedHelperText/index';
import { BoxProps } from '@mui/material';
import styled from 'styled-components';
import { MainColor, MainRed, White } from '../../../Theme/colorsVariables';
import { CenterCenterBox, FlexBox } from '../FlexBox';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { breakPointMedium } from '../../../utils';

export const CustomSearch = styled(AppTextFieldDelayedHelperText)`
  min-width: 400px;
  ${breakPointMedium('min-width: 250px;')}
`;

export const AdvancedSearchLabel = styled(CenterCenterBox)<{ advancedsearchvisibility: string }>`
  color: ${(props) => (props.advancedsearchvisibility === 'true' ? White : MainColor)};
  cursor: pointer;
  text-decoration: underline;
  padding: 15px;
  background: ${(props) => (props.advancedsearchvisibility === 'true' ? MainColor : White)};
  border: ${(props) =>
    props.advancedsearchvisibility === 'false'
      ? `1px solid ${MainColor}`
      : `1px solid ${MainColor}`};
  border-radius: 10px;
  width: 20px;
  height: 20px;
  transition: all 0.125s ease-in;
  &:hover {
    ${(props) =>
      props.advancedsearchvisibility === 'true'
        ? `color: ${MainColor}; background: ${White}; border: 1px solid ${MainColor};`
        : `color: ${White}; background: ${MainColor}; border: 1px solid ${MainColor};`}
  }
  ${breakPointMedium(`
  width: 16px;
  height: 16px;
  `)}
`;

export const SingleSearchBox = styled(FlexBox)`
  flex-direction: row;
  align-items: center;
`;

const ClearIconContainer = styled(CenterCenterBox)`
  background: ${MainRed} !important;
  border-radius: 50% !important;
  width: 24px !important;
  height: 24px !important;
  cursor: pointer;

  @media only screen and (max-width: 1500px) {
    width: 18px !important;
    height: 18px !important;
    svg {
      width: 18px !important;
      height: 18px !important;
    }
  }
`;

export const ClearIconEndAdornment = (props: BoxProps) => (
  <ClearIconContainer {...props}>
    <ClearOutlinedIcon fontSize="small" sx={{ fill: White }} />
  </ClearIconContainer>
);
