export const normalizeFein = (val: string): string => {
  if (typeof val !== 'string' && typeof val !== 'number') {
    return '';
  }

  val = val.toString();

  const normalized = val.replace(/[- ]/g, '').match(/.{1,2}/g);

  if (normalized) {
    return `${normalized?.slice(0, 2)?.join('-') + normalized?.slice(2)?.join('')}` || '';
  } else {
    return val;
  }
};
