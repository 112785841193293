import { StyledReactPhoneInputViewOnly } from '../AppPhoneField/styles';
import { AppTextField } from '../AppTextField';

export const AppPhoneInputViewOnly = ({ phone }: { phone: string }) => {
  return (
    <StyledReactPhoneInputViewOnly
      value={phone.length < 5 ? null : phone}
      country={'us'}
      component={AppTextField}
      placeholder=""
      label=""
      disabled
      disableDropdown
      disableCountryCode={phone.length < 5}
      buttonStyle={{ display: 'none' }}
    />
  );
};
