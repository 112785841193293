import { MenuItem } from '@mui/material';
import React, { useContext } from 'react';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import { CustomMenu, MenuButton } from './styles';
import Help from '../assets/HelpIcon';
import AuthService from '../../services/authService';
import { useTranslation } from 'react-i18next';
import { StyledHyperLink } from '../../layout/Topbar/styles';
import { UnsavedDataContext } from '../../context/UnsavedData';
import AppTooltip from '../shared/AppTooltip';

export default function HelpMenu() {
  const { unsavedData, setUnsavedData } = useContext(UnsavedDataContext);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const { data: commonData } = AuthService.useCommonDetailsQuery();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setAnchorEl(null);
  };

  const handleTermsOfUse = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (unsavedData.isUnsavedChanges) {
      event.preventDefault();
      setUnsavedData({ ...unsavedData, isPopupOpen: true, returnUrl: '/dashboard/terms' });
    }
    setAnchorEl(null);
  };

  const renderContactNumber = () => {
    const contactRecordFound = commonData?.find((item) => item.type === 'TenantSupportPhone');
    return contactRecordFound?.value;
  };

  const renderEmail = () => {
    const contactRecordFound = commonData?.find((item) => item.type === 'TenantSupportEmail');
    return contactRecordFound?.value;
  };

  const { t } = useTranslation();

  return (
    <>
      <AppTooltip title={t('userhelp.tooltip')} placement="bottom">
        <MenuButton
          id={t('userhelp.tooltip')}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <Help />
        </MenuButton>
      </AppTooltip>
      <CustomMenu
        id="simple-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <span>
            <a href={`tel:${renderContactNumber()}`} onClick={handleClose}>
              <StyledHyperLink>{renderContactNumber()}</StyledHyperLink>
              <CallIcon />
            </a>
          </span>
          <span>
            <a href={`mailto:${renderEmail()}`} onClick={handleClose}>
              <StyledHyperLink>{renderEmail()}</StyledHyperLink>
              <EmailIcon />
            </a>
          </span>
        </MenuItem>
        <MenuItem>
          <a href="/dashboard/terms" onClick={(e) => handleTermsOfUse(e)}>
            <StyledHyperLink>Platform Terms of Use</StyledHyperLink>
          </a>
        </MenuItem>
      </CustomMenu>
    </>
  );
}
