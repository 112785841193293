import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomModal from '../Modal';
import { AppActionButton } from '../../shared/AppActionButton';
import { ModalMessageText } from '../CancelModal/styles';
import { ModalButtonsBox } from '../Modal/styles';

export default function DeleteEntityModal({
  entityType,
  entityName,
  onConfirm,
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  entityName: string;
  entityType: string;
  onConfirm: () => void;
}) {
  const { t } = useTranslation();

  return (
    <CustomModal
      open={open}
      containerWidth="400px"
      modalTitle={t('relationship.sellers.sellerinfo.delete') + ` ${entityType}`}
    >
      <>
        <ModalMessageText
          dangerouslySetInnerHTML={{
            __html: t('security.users.userList.msgDelete', { entityName, entityType }),
          }}
        />
        <ModalButtonsBox>
          <AppActionButton style={{ marginRight: '15px' }} variant="outlined" onClick={onConfirm}>
            {t('user.actions.confirm')}
          </AppActionButton>
          <AppActionButton variant="outlined" onClick={() => setOpen(false)}>
            {t('user.actions.cancel')}
          </AppActionButton>
        </ModalButtonsBox>
      </>
    </CustomModal>
  );
}
