import { mixed, object, string } from 'yup';
import { getCommonErrorMessages } from '../utils';

const commonSelectSchema = object({
  label: string().required(getCommonErrorMessages('required')),
  value: mixed().nullable(),
})
  .nullable()
  .required(getCommonErrorMessages('required'));

const trimmedString = string().matches(/^(?!\s+$).*/, { message: 'Empty spaces are not allowed' });

export { commonSelectSchema, trimmedString };
