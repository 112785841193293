import { Autocomplete, AutocompleteProps } from '@mui/material';
import { FC, forwardRef, useState } from 'react';

export const AppAutocomplete: FC<AutocompleteProps<any, boolean, boolean, undefined>> = forwardRef(
  (props, ref) => {
    const [inputValue, setInputValue] = useState('');
    const [open, setOpen] = useState(false);

    return (
      <Autocomplete
        ref={ref}
        {...props}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onInputChange={(e, value, reason) => {
          if (reason === 'clear') {
            setOpen(true);
          }
          setInputValue(value);
        }}
        onClose={() => {
          setOpen(false);
        }}
        autoHighlight
        openOnFocus
        onBlur={(e) => {
          if (props.options.length === 1) {
            return;
          }
          const filtered = props.options?.filter((value) =>
            value.label?.toLowerCase().includes(inputValue?.toLowerCase()),
          );

          if (filtered.length === 1 && props?.onChange) {
            if (props.multiple) {
              props?.onChange(e, filtered, 'selectOption');
              return;
            }
            props?.onChange(e, filtered[0], 'selectOption');
          }
        }}
      />
    );
  },
);
