import axios from 'axios';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { ApiError, UserInfo } from '../types';
import { useToast } from '../hooks/useToast';
import { TabsComunicationChannel } from '../providers';

const useUserQuery = () => {
  const { user, logout } = useAuth0();
  const toast = useToast();

  const fetchUsers = async () => {
    const fetch = await axios.get('/Account/user-info');
    return fetch.data;
  };

  const state = useQuery<UserInfo, ApiError>('user', fetchUsers, {
    enabled: !!user,
    retry: false,
    onError(err) {
      toast.error(err.response.data.message);
      setTimeout(() => {
        TabsComunicationChannel.postMessage('logout');
        logout();
      }, 5000);
    },
  });

  return state;
};

export default useUserQuery;
