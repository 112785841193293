import React from 'react';

export default function EyeCloseIcon() {
  return (
    <svg
      id="eye_close_ic"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_3085" data-name="Group 3085" transform="translate(-1126 -340)">
        <rect
          id="Rectangle_2171"
          data-name="Rectangle 2171"
          width="24"
          height="24"
          transform="translate(1126 340)"
          fill="none"
        />
        <g id="Group_3085-2" data-name="Group 3085" transform="translate(1126 327)">
          <path
            id="Hide"
            d="M14.98,25.2l1.363-1.363a4.484,4.484,0,0,1-5.5,5.5L12.2,27.979A2.987,2.987,0,0,0,14.98,25.2Zm8.926-.566a15.708,15.708,0,0,0-3.958-4.4l-1.091,1.091A15.01,15.01,0,0,1,22.368,25c-.889,1.354-4.376,6-10.368,6a11.021,11.021,0,0,1-2.52-.3L8.247,31.934A12.7,12.7,0,0,0,12,32.5c7.851,0,11.744-6.845,11.905-7.136A.749.749,0,0,0,23.906,24.635ZM20.78,17.28,4.28,33.78a.75.75,0,1,1-1.06-1.06l2.1-2.1A15.737,15.737,0,0,1,.095,25.364a.75.75,0,0,1,0-.729C.256,24.344,4.149,17.5,12,17.5a12.63,12.63,0,0,1,5.292,1.148L19.72,16.22a.75.75,0,1,1,1.06,1.061ZM6.437,29.5l1.9-1.9A4.493,4.493,0,0,1,14.6,21.336L16.139,19.8A11.043,11.043,0,0,0,12,19c-5.992,0-9.479,4.645-10.367,6a14.726,14.726,0,0,0,4.8,4.5Zm2.988-2.988,4.089-4.089A2.966,2.966,0,0,0,12,22a3,3,0,0,0-3,3,2.966,2.966,0,0,0,.425,1.514Z"
            transform="translate(0 0)"
          />
        </g>
      </g>
    </svg>
  );
}
