import React, { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Link } from '@mui/material';
import LogoIcon from '../../components/assets/LogoIcon';
import {
  AbsoluteBoxS,
  CenterTextContainer,
  CustomContainer,
  RootContainer,
  SignInButtonS,
  StyledBox,
  TextS,
} from './styles';
import { White } from '../../Theme/colorsVariables';
import { DeploymentStatusContext } from '../../context/DeploymentStatus';
import { useToast } from '../../hooks/useToast';

export default function Login() {
  const { loginWithRedirect } = useAuth0();
  const toast = useToast();
  const { isDeploymentRunning } = useContext(DeploymentStatusContext);
  const [supportInformation, setSupportInformation] = useState<{
    supportEmail: string;
    supportPhoneNumber: string;
  }>({ supportEmail: '', supportPhoneNumber: '' });
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/configuration/support-information`)
      .then((response) => response.json())
      .then((data: { supportEmail: string; supportPhoneNumber: string }) =>
        setSupportInformation(data),
      )
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (isDeploymentRunning) {
      toast.info(
        'The site is undergoing scheduled maintenance. We are sorry for the inconvenience. Please try again in 15 minutes.',
        { persist: true, key: 'deployment_message' },
      );
    } else {
      toast.closeSnackbar('deployment_message');
    }
  }, [isDeploymentRunning, toast]);

  return (
    <RootContainer maxWidth={false}>
      <AbsoluteBoxS>
        <Link href="./" underline="none">
          <LogoIcon />
        </Link>
      </AbsoluteBoxS>
      <CustomContainer>
        <StyledBox>
          <Box>
            <Link href="./" underline="none">
              <LogoIcon />
            </Link>
          </Box>
          <CenterTextContainer mt={3}>
            <Box sx={{ fontSize: '27px' }}>Welcome</Box>
            <TextS variant="body2" mt={2}>
              Login to ONWise.com
            </TextS>
          </CenterTextContainer>
          <SignInButtonS
            variant="contained"
            disabled={isDeploymentRunning}
            onClick={() => {
              loginWithRedirect();
            }}
          >
            Sign In
          </SignInButtonS>
          {/* use this code when require to enable signUp option */}
          {/* <TextS variant="body2" mt={3}>
          {`Don't have an account?`}
          <ButtonS
            onClick={() => {
              navigate('/public/register');
            }}
          >
            Sign Up
          </ButtonS>
        </TextS> */}
        </StyledBox>
        <Box
          sx={{
            position: 'absolute',
            bottom: '30px',
            color: White,
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box>{`Powered by enginuity.LL | 2021-${new Date().getFullYear()} All Rights Reserved`}</Box>
          <Box>{`${supportInformation?.supportEmail} | ${supportInformation?.supportPhoneNumber}`}</Box>
        </Box>
      </CustomContainer>
    </RootContainer>
  );
}
