import React, { useContext, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomAvatar from '../../../components/Avatar';
import { UserInfoDiv, UserNameStyle, UserCustomMenu, UserEmailStyle, LogOutButton } from './styles';
import { ShadowContainer } from '../../Topbar/styles';
import { UnsavedDataContext } from '../../../context/UnsavedData';
import { UserContext } from '../../../context/User';
import { useAuth0 } from '@auth0/auth0-react';
import { TabsComunicationChannel } from '../../../providers';

const UserMenus = () => {
  const { logout } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const { unsavedData, setUnsavedData } = useContext(UnsavedDataContext);

  const { t } = useTranslation();

  const handleClick = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, url: string) => {
    event.preventDefault();

    if (unsavedData.isUnsavedChanges) {
      setUnsavedData({ ...unsavedData, isPopupOpen: true, returnUrl: url });
      setAnchorEl(null);
    } else {
      setAnchorEl(null);
      navigate(url);
    }
  };

  const handleLogoutClick = () => {
    setAnchorEl(null);
    if (unsavedData.isUnsavedChanges) {
      setUnsavedData({
        ...unsavedData,
        isPopupOpen: true,
        returnUrl: 'logout',
      });
    } else {
      localStorage.clear();
      TabsComunicationChannel.postMessage('logout');
      logout();
    }
  };

  return (
    <>
      <CustomAvatar handleClick={handleClick} />

      <UserCustomMenu
        id="user-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <UserInfoDiv>
          <UserNameStyle>{user?.name}</UserNameStyle>
          <UserEmailStyle>{user?.email}</UserEmailStyle>
        </UserInfoDiv>
        <Link to="/dashboard/MyProfile" onClick={(e) => onLinkClick(e, '/dashboard/MyProfile')}>
          <MenuItem>{t('myProfile.title')}</MenuItem>
        </Link>

        <ShadowContainer>
          <Link
            to="/dashboard/change-password"
            onClick={(e) => onLinkClick(e, '/dashboard/change-password')}
          >
            <MenuItem>{t('userprofile.changepassword.changepassword')}</MenuItem>
          </Link>
        </ShadowContainer>
        <MenuItem onClick={handleLogoutClick} style={{ paddingLeft: '10px' }}>
          <LogOutButton>{t('userprofile.logout')} </LogOutButton>
        </MenuItem>
      </UserCustomMenu>
    </>
  );
};

export default UserMenus;
