import axios from 'axios';
import { useContext } from 'react';
import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from 'react-query';
import { CommonConstant, ApiError, SignUpPayload, AddressState } from '../types';
import { UserContext } from '../context/User';
import { PasswordChangePayload } from '../pages/ChangePassword/types';

const AuthService = {
  useStatesQuery: () => {
    const user = useContext(UserContext);
    const fetchStates = async () => {
      const fetch = await axios.get('/Product/States');
      return fetch.data;
    };
    const state = useQuery<AddressState[], Error>('states', fetchStates, {
      retry: true,
      cacheTime: 900000,
      enabled: !!user,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity,
    });
    return state;
  },

  useChangePasswordQuery: (onSuccess: () => void, onError: (err: ApiError) => void) => {
    const changePassword = async (obj: PasswordChangePayload) => {
      const fetch = await axios.post('/user/changePassword', obj);
      return fetch.data;
    };
    return useMutation<unknown, ApiError, PasswordChangePayload>(
      'change-password',
      changePassword,
      { onSuccess, onError },
    );
  },

  useCommonDetailsQuery: () => {
    const user = useContext(UserContext);
    const orgId = 1;
    const fetchCommonDetails = async () => {
      const fetch = await axios.get(`/Common/${orgId}`);
      return fetch.data;
    };
    const commonDetails = useQuery<CommonConstant[], ApiError>(
      'common-details',
      fetchCommonDetails,
      {
        retry: true,
        cacheTime: 900000,
        enabled: !!user,
        refetchInterval: false,
        refetchIntervalInBackground: false,
        staleTime: Infinity,
      },
    );
    return commonDetails;
  },
  useCommonConstantQuery: ({
    constantName,
  }: {
    constantName: 'GateMessageParameterMAG' | 'GateMessageParameterDuplicateSeller';
  }) => {
    const req = async () => {
      const res = await axios.get(`/Common/constant/${constantName}`);
      return res.data;
    };
    return useQuery<CommonConstant, ApiError>(['common-constant-detail', constantName], req, {
      retry: true,
      cacheTime: 900000,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity,
    });
  },
  useValidateUser: ({
    mailId,
    options,
  }: {
    mailId: string;
    options?: Omit<UseQueryOptions<unknown, ApiError>, 'queryKey' | 'queryFn'>;
  }) => {
    const validate = async () => {
      const res = await axios.get(`/Account/ValidateSignupUser?email=${mailId}`);
      return res.data;
    };

    return useQuery<unknown, ApiError>(['validateUser', mailId], validate, options);
  },
  useSignupUser: (
    options?: Omit<
      UseMutationOptions<
        unknown,
        ApiError,
        {
          email: string;
          password: string;
          confirmPassword: string;
        }
      >,
      'mutationKey' | 'mutationFn'
    >,
  ) => {
    const signUp = async ({ email, password, confirmPassword }: SignUpPayload) => {
      const res = await axios.post('/Account/Signup', {
        email,
        password,
        confirmPassword,
      });
      return res.data;
    };
    return useMutation<unknown, ApiError, SignUpPayload>('signupUser', signUp, options);
  },
};

export default AuthService;
