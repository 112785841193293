import { Typography } from '@mui/material';
import styled from 'styled-components';
import { Red } from '../../../Theme/colorsVariables';
import { breakPointMedium } from '../../../utils';

export const FeinBox = styled(Typography)`
  color: ${Red};
  font-size: 14px;
  font-family: Gotham-Book;
  font-style: italic;
  position: inline;
  ${breakPointMedium('font-size: 12px;')}
`;
