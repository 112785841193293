import React from 'react';
import { createRoot } from 'react-dom/client';
import axios from 'axios';
import { RouterProvider } from 'react-router-dom';
import './index.css';
import './i18n/i18n';
import { router } from './routes';

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;
const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
