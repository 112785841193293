import { CircularProgress, FormControl, MenuItem, Popper, PopperProps, Box } from '@mui/material';
import { Fragment, useContext, useEffect, useMemo, useState, KeyboardEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { EntityType, GetSearchEntity, SearchEntity } from './searchService';
import {
  SearchAutocomplete,
  StyledSelect,
  GroupHeader,
  GroupItems,
  DropdownOption,
} from './styles';
import SearchIcon from '@mui/icons-material/Search';

import { FlexBox } from '../shared/FlexBox';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import useDebounce from '../../hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import { ClearIconEndAdornment } from '../shared/CustomTableSingleSearch/styles';
import { UnsavedDataContext } from '../../context/UnsavedData';
import { AppTextFieldDelayedHelperText } from '../shared/AppTextFieldDelayedHelperText';

const PopperMy = function (props: PopperProps) {
  return <Popper {...props} style={{ width: 600 }} placement="auto" />;
};

export default function AppSearch() {
  const navigate = useNavigate();
  const { unsavedData, setUnsavedData } = useContext(UnsavedDataContext);
  const isReadContactPermission = usePermissions(PermissionKeys.ReadContact);
  const isReadAgentPermission = usePermissions(PermissionKeys.ReadAgent);
  const isReadSellerPermission = usePermissions(PermissionKeys.ViewSellers);

  const [searchValue, setSearchValue] = useState('');
  const [selectedValue, setSelectedValue] = useState<SearchEntity | null>(null);
  const [entityType, setEntityType] = useState<EntityType>('all');
  const searchString = useDebounce(searchValue, 500);
  const { t } = useTranslation();
  const { data: searchResultsResponse, isFetching } = GetSearchEntity({
    searchTerm: searchString.replaceAll('-', ''),
    entityType,
  });

  const sortFunction = (array: { groupName: string }[], sortArray: string[]) => {
    return [...array].sort((a, b) => {
      const indexA = sortArray.findIndex((x) => a.groupName.startsWith(x));
      const indexB = sortArray.findIndex((y) => b.groupName.startsWith(y));

      if (indexA < indexB) return 1;
      if (indexA > indexB) return -1;
      return 0;
    });
  };

  const options = useMemo(() => {
    if (!searchResultsResponse) {
      return [];
    }

    // The order is important. Do not modify it.
    const sortOrder = [
      'Seller Group',
      'Seller',
      'Contact for Seller Group',
      'Contact for Seller',
      'Contact for Agent',
      'Agent',
    ];

    const data = searchResultsResponse
      .map((entity) => {
        let groupName: string = entity.searchEntityType;
        let entityLabel = `<p data-pw="sws-option-${entity.name}">${entity.name}<span class="vl"></span>${entity.additionalIdentifier}</p>`;

        switch (entity.searchEntityType) {
          case 'Seller':
            entityLabel = `<p data-pw="sws-option-${entity.name}">${entity.name}<span class="vl"></span>${entity.additionalIdentifier}</p>`;
            break;
          case 'AgencyContact':
            groupName = `Contact for Agent ${entity.orgName}`;
            entityLabel = `<p data-pw="sws-option-${entity.name}">${entity.name}<span class="vl"></span>${entity.additionalIdentifier}</p>`;
            break;
          case 'SellerContact':
            groupName = `Contact for Seller ${entity.orgName}`;
            entityLabel = `<p data-pw="sws-option-${entity.name}">${entity.name}<span class="vl"></span>${entity.additionalIdentifier}</p>`;
            break;
          case 'SellerGroupContact':
            groupName = `Contact for Seller Group ${entity.orgName}`;
            entityLabel = `<p data-pw="sws-option-${entity.name}">${entity.name}<span class="vl"></span>${entity.additionalIdentifier}</p>`;
            break;
          case 'SellerGroup':
            groupName = 'Seller Group';
            entityLabel = `<p data-pw="sws-option-${entity.name}">${entity.name}<span class="vl"></span>${entity.additionalIdentifier}</p>`;
            break;
          case 'Agency':
            groupName = `Agent`;
            entityLabel = `<p data-pw="sws-option-${entity.name}">${entity.name}<span class="vl"></span>${entity.additionalIdentifier}</p>`;
            break;
        }

        return {
          groupName,
          entityLabel,
          ...entity,
        };
      })
      .sort((a, b) => -b.groupName?.localeCompare(a.groupName));

    return sortFunction(data, sortOrder);
  }, [searchResultsResponse]);

  useEffect(() => {
    if (!selectedValue) {
      return;
    }

    setSelectedValue(null);
    let entityUrl = '';
    if (selectedValue.searchEntityType === 'Seller') {
      entityUrl = `/dashboard/Seller/${selectedValue.id}`;
    }
    if (selectedValue.searchEntityType === 'Agency') {
      entityUrl = `/dashboard/Agent/${selectedValue.id}`;
    }
    if (selectedValue.searchEntityType === 'SellerContact') {
      entityUrl = `/dashboard/Seller/${selectedValue.orgId}/Contact/${selectedValue.id}`;
    }
    if (selectedValue.searchEntityType === 'AgencyContact') {
      entityUrl = `/dashboard/Agent/${selectedValue.orgId}/Contact/${selectedValue.id}`;
    }
    if (selectedValue.searchEntityType === 'SellerGroup') {
      entityUrl = `/dashboard/SellerGroup/${selectedValue.id}`;
    }
    if (selectedValue.searchEntityType === 'SellerGroupContact') {
      entityUrl = `/dashboard/SellerGroup/${selectedValue.orgId}/Contact/${selectedValue.id}`;
    }
    if (unsavedData.isUnsavedChanges) {
      setUnsavedData({ ...unsavedData, isPopupOpen: true, returnUrl: entityUrl });
      return;
    }
    navigate(entityUrl);
  }, [navigate, selectedValue, setUnsavedData, unsavedData]);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const searchEntities = useMemo<{ value: string; name: string }[]>(() => {
    let res: { value: string; name: string }[] = [];
    if (isReadAgentPermission) {
      res.push({ value: 'Agency', name: 'Agent' });
    }
    if (isReadAgentPermission && isReadContactPermission) {
      res.push({ value: 'AgencyContact', name: 'Agent Contact' });
    }
    if (isReadSellerPermission) {
      res.push({ value: 'Seller', name: 'Seller' });
    }
    if (isReadSellerPermission && isReadContactPermission) {
      res.push({ value: 'SellerContact', name: 'Seller Contact' });
    }
    if (isReadSellerPermission) {
      res.push({ value: 'SellerGroup', name: 'Seller Group' });
    }
    if (isReadSellerPermission && isReadContactPermission) {
      res.push({ value: 'SellerGroupContact', name: 'Seller Group Contact' });
    }
    if (res.length > 1) {
      res = [{ value: 'all', name: 'All' }, ...res];
    }
    return res;
  }, [isReadAgentPermission, isReadContactPermission, isReadSellerPermission]);

  useEffect(() => {
    setEntityType('all');
  }, [isReadAgentPermission, isReadContactPermission, isReadSellerPermission]);

  return (
    <FlexBox style={{ width: '100%', maxWidth: '550px' }}>
      <Box style={{ width: '150px' }} mr={1}>
        <FormControl fullWidth>
          <StyledSelect
            value={entityType}
            onChange={(e) => setEntityType(e.target.value as EntityType)}
            disabled={!searchEntities.length}
            data-pw={'sws-filter'}
          >
            {searchEntities.map((entity) => (
              <MenuItem key={entity?.value} value={entity?.value}>
                {entity?.name}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      </Box>
      <Box style={{ width: '100%', maxWidth: '440px', minWidth: '180px' }} position={'relative'}>
        <SearchAutocomplete
          disablePortal
          disabled={!searchEntities.length}
          noOptionsText={t('table.noResults')}
          fullWidth
          loading={searchValue.length >= 3 && isFetching}
          options={options}
          value={selectedValue}
          onInputChange={(e: any) => {
            if (e && e.target.value) {
              setSearchValue(e.target.value);
            } else {
              setSearchValue('');
            }
          }}
          onChange={(event: any, newValue: any) => {
            if (typeof newValue !== 'string') {
              setSelectedValue(newValue);
            } else {
              setSelectedValue(null);
              setSearchValue('');
            }
          }}
          PopperComponent={PopperMy}
          getOptionLabel={(option: any) => {
            return `${option.name} | ${option.additionalIdentifier}`;
          }}
          renderOption={(props, option: any) => {
            return (
              <DropdownOption
                {...props}
                key={(Math.random() + 1).toString(36).substring(7)}
                dangerouslySetInnerHTML={{
                  __html: option.entityLabel,
                }}
              />
            );
          }}
          groupBy={(option: any) => option.groupName}
          renderGroup={(params) => (
            <li key={params.key}>
              <GroupHeader data-pw={`group-${params.group}`} data-pw-1={`searchgroup`}>
                {params.group}
              </GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
          filterOptions={(opt) => opt}
          inputValue={searchValue}
          renderInput={(params) => {
            params.inputProps.onKeyDown = handleKeyDown;
            return (
              <AppTextFieldDelayedHelperText
                {...params}
                label=""
                placeholder="Search"
                onKeyDown={handleKeyDown}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                      {searchValue.length > 0 && (
                        <ClearIconEndAdornment
                          onClick={() => {
                            setSelectedValue(null);
                            setSearchValue('');
                          }}
                        />
                      )}
                      <SearchIcon />
                    </Fragment>
                  ),
                }}
                error={searchValue.length < 3 && searchValue !== ''}
                helperText={
                  searchValue.length < 3 && searchValue !== '' && t('appTextField.helperText')
                }
              />
            );
          }}
        />
      </Box>
    </FlexBox>
  );
}
