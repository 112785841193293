import { Box, Grid } from '@mui/material';
import { useCallback, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';
import { UserContext } from '../../../context/User';
import { useDataloss } from '../../../hooks/useDataloss';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
import { useToast } from '../../../hooks/useToast';
import { useUpdateDealershipInfoQuery, useDealershipInfo } from '../../../services/sellerServices';
import { DealershipInfoFormType } from '../../../types';
import { DealershipInfoForm } from '../../Forms/DealerShipInfoForm';
import { UpdateDealershipinfoRequestPayload } from '../../Forms/DealerShipInfoForm/types';
import { CancelModal } from '../../modal/CancelModal';
import { AppCancelButton } from '../../shared/AppCancelButton';
import { AppEditButton } from '../../shared/AppEditButton';
import { AppResetButton } from '../../shared/AppResetButton';
import { AppSaveButton } from '../../shared/AppSaveButton';
import { JustifyContentRightBox, SpaceBetweenBox } from '../../shared/FlexBox';
import { useNavigateAfterDatalossDecision } from '../../../hooks/useNavigateAfterDatalossDecision';
import LabelRequired from '../../shared/LabelRequired';

export const DealershipInfoTab = () => {
  const { isCompletedAgreement, isAgreementInProcess } = useOutletContext<{
    isCompletedAgreement: boolean;
    isAgreementInProcess: boolean;
  }>();
  const { t } = useTranslation();
  const toast = useToast();
  const { id } = useParams();
  const user = useContext(UserContext);
  const { navigateAfterDatalossDecision } = useNavigateAfterDatalossDecision();
  const formRef = useRef<HTMLFormElement>(null);
  const isUpdateSellerPermission = usePermissions(PermissionKeys.UpdateOrganization);

  const { data: dealershipInfo } = useDealershipInfo(Number(id));

  const [isEdit, setIsEdit] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isUnsavedData, setIsUnsavedData] = useState(false);
  const [cancelPopUp, setCancelPopUp] = useState(false);

  const { mutateAsync: updateDealershipInfo } = useUpdateDealershipInfoQuery();

  const onDiscardChanges = useCallback(() => {
    formRef.current?.dispatchEvent(new Event('reset', { cancelable: true, bubbles: true }));
    setCancelPopUp(false);
  }, []);

  const onSaveChanges = useCallback(() => {
    setCancelPopUp(false);
    setIsEdit(false);
    if (formRef && formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }, []);

  const onExit = useCallback(() => {
    setCancelPopUp(false);
  }, [setCancelPopUp]);

  useDataloss(isUnsavedData && isEdit, onDiscardChanges, onSaveChanges, onExit);

  const onSubmit = async (data: DealershipInfoFormType) => {
    const updatePayload: UpdateDealershipinfoRequestPayload = {
      orgId: dealershipInfo?.orgId || 0,
      payload: {
        dealerLicenseId: data?.dealerLicenseId,
        averageAssetInventoryRangeId: data.averageAssetInventoryRangeId?.value
          ? data.averageAssetInventoryRangeId?.value.averageAssetInventoryRangeId
          : null,
        dealerTypeGroupIds: data.dealerTypeGroup
          ? [data.dealerTypeGroup.value.dealerTypeGroupId]
          : [],
        assetMakeIds: data.assetMakeIds.map((item) => item.value.assetMakeId),
        dmsIds: data.dmsIds.map((item) => item.value.dmsId),
        dmsOtherName: data.dmsOtherName,
        fiMenuIds: data.fiMenuIds.map((item) => item.value.fiMenuId),
        fiMenuOtherName: data.fiMenuOtherName,
        userId: user?.userId,
        preferredIntegrationPartner: data.integrationPartner,
      },
    };

    try {
      await updateDealershipInfo(updatePayload);
      toast.success(t('dealershipinfo.update.success'));
      if (!navigateAfterDatalossDecision()) {
        setIsEdit(false);
      }
    } catch (err: any) {
      toast.error(err.response.data.message);
    }
  };

  return (
    <Grid item>
      {!isEdit && (isCompletedAgreement || isAgreementInProcess) && (
        <JustifyContentRightBox>
          <AppEditButton
            disabled={!isUpdateSellerPermission}
            onClick={() => {
              setIsEdit(true);
            }}
          />
        </JustifyContentRightBox>
      )}
      {isEdit && (
        <SpaceBetweenBox>
          <Box>
            <LabelRequired />
          </Box>
          <JustifyContentRightBox>
            <AppCancelButton
              onClick={() => {
                if (isUnsavedData) {
                  setCancelPopUp(true);
                } else {
                  setIsEdit(false);
                }
              }}
            />
            <AppResetButton
              onClick={() => {
                formRef.current?.dispatchEvent(
                  new Event('reset', { cancelable: true, bubbles: true }),
                );
              }}
            />
            <AppSaveButton
              disabled={isFormValid ? !isUnsavedData === isFormValid : true}
              onClick={() => {
                formRef.current?.dispatchEvent(
                  new Event('submit', { cancelable: true, bubbles: true }),
                );
              }}
            />
          </JustifyContentRightBox>
        </SpaceBetweenBox>
      )}
      <DealershipInfoForm
        isEdit={isEdit}
        orgData={dealershipInfo}
        formRef={formRef}
        onSubmit={onSubmit}
        setIsFormValid={setIsFormValid}
        setIsUnsavedChanges={setIsUnsavedData}
      />
      <CancelModal
        isOpened={cancelPopUp}
        modalTitle={t('security.roles.roleForm.role.warning')}
        onDiscardChanges={onDiscardChanges}
        onSaveChanges={onSaveChanges}
        onExit={onExit}
      />
    </Grid>
  );
};
