import { boolean, object } from 'yup';
import { commonSelectSchema, trimmedString } from '.';
import { getCommonErrorMessages, getCommonPattern } from '../utils';

const locationValidationSchema = object().shape({
  address1: trimmedString.required(getCommonErrorMessages('required')),
  address2: trimmedString.notRequired().nullable(),
  city: trimmedString.required(getCommonErrorMessages('required')),
  state: commonSelectSchema,
  zip: trimmedString
    .matches(getCommonPattern('zip'), getCommonErrorMessages('zipLength'))
    .required(getCommonErrorMessages('required')),
});

export const organizationValidationSchema = object().shape({
  legalName: trimmedString.required(getCommonErrorMessages('required')),
  legalOrganization: commonSelectSchema,
  doingBusinessAs: trimmedString.nullable(),
  fein: trimmedString.when('legalOrganization', {
    is: (legalOrganization?: { value: number }) => {
      return legalOrganization?.value === 5; // id of "Sole proprietorship"
    },
    then: trimmedString
      .matches(/^(\d{3}-?\d{2}-\d{4})?$/, getCommonErrorMessages('feinS'))
      .notRequired()
      .nullable(),
    otherwise: trimmedString
      .matches(/^(\d{2}-?\d{7})?$/, getCommonErrorMessages('feinS'))
      .notRequired()
      .nullable(),
  }),
  phone: object().shape({
    number: trimmedString.required(),
    countryCode: trimmedString,
    ext: trimmedString,
    isValid: boolean().oneOf([true], getCommonErrorMessages('required')),
  }),
  fax: trimmedString
    .matches(getCommonPattern('phoneAndFax'), getCommonErrorMessages('phoneLength'))
    .notRequired()
    .nullable(),
  website: trimmedString
    .matches(getCommonPattern('website'), getCommonErrorMessages('url'))
    .notRequired()
    .nullable(),
  physicalLocation: locationValidationSchema,
  mailingLocation: locationValidationSchema,
  isLocationsTheSame: boolean(),
});
