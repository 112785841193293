import { useState } from 'react';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import { MainGreen, MainRed } from '../../../Theme/colorsVariables';
import { FontSizeUltra } from '../../../Theme/screensVariables';
import { FullHeightContainer } from '../../../components/SellerGroupTabs/ProductLinesTab/styles';
import { AppActionButton } from '../../../components/shared/AppActionButton';
import { Heading } from '../../../components/shared/AppPageHeaderContentHost/styles';
import { AlignCenterBox, FlexBox } from '../../../components/shared/FlexBox';
import { useToast } from '../../../hooks/useToast';
import { AgreementsService } from '../../../services/agreementsService';
import { Agreement } from '../../../types/sellerGroup';
import ExistingRelationshipTable from './ExistingRelationshipTable';
import NewRelationshipTable from './NewRelationshipTable';
import RejectModal from '../../../components/modal/RejectModal';

export const MAGResolution = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  const [rejectModal, setRejectModal] = useState(false);

  const { refetchAgreementsList, refetchIsAgreementsInProgress } = useOutletContext<{
    refetchAgreementsList: () => void;
    refetchIsAgreementsInProgress: () => void;
  }>();

  const [newRelOrg, setNewRelOrg] = useState<Agreement | undefined>();

  const { mutate: approve } = AgreementsService.useApprove({
    onSuccess: (res) => {
      toast.success(res);
      navigate(location.pathname.slice(0, location.pathname.lastIndexOf('/')));
      refetchAgreementsList();
      refetchIsAgreementsInProgress();
    },
    onError: (error) => {
      toast.error(error.response.data.message);
    },
  });
  const { mutate: reject } = AgreementsService.useReject({
    onSuccess: (res) => {
      toast.success(res);
      navigate(location.pathname.slice(0, location.pathname.lastIndexOf('/')));
      refetchAgreementsList();
      refetchIsAgreementsInProgress();
    },
    onError: (error) => {
      toast.error(error.response.data.message);
    },
  });

  const onApprove = () => {
    approve({ agreementId: +(newRelOrg?.agreementId || 0) });
  };

  const onReject = (rejectReason: string) => {
    setRejectModal(false);
    reject({ agreementId: +(newRelOrg?.agreementId || 0), rejectReason });
  };

  return (
    <FlexBox sx={{ flexFlow: 'column', flex: '1 1 auto', height: '100%' }}>
      <Heading
        fontSize={FontSizeUltra}
        height={'34px'}
        flex={'0 1 auto'}
        position={'relative'}
        lineHeight={'34px'}
      >
        <AlignCenterBox>
          <>Review Agreements for {newRelOrg?.agent?.name || '...'}</>
        </AlignCenterBox>
        <AlignCenterBox sx={{ position: 'absolute', top: 0, right: 0 }}>
          <AppActionButton background={MainGreen} onClick={onApprove} variant="outlined">
            Approve
          </AppActionButton>
          <AppActionButton
            variant="outlined"
            background={MainRed}
            onClick={() => {
              setRejectModal(true);
            }}
          >
            Reject
          </AppActionButton>
          <AppActionButton
            onClick={() => {
              toast.success(`Review canceled for ${newRelOrg?.agent?.name}`);
              navigate(location.pathname.slice(0, location.pathname.lastIndexOf('/')));
            }}
          >
            Finish Review Later
          </AppActionButton>
        </AlignCenterBox>
      </Heading>

      <FullHeightContainer
        mb={1}
        flex={'1 1 auto'}
        style={{ display: 'grid', gridTemplateColumns: '1fr', rowGap: '38px' }}
      >
        <NewRelationshipTable setNewRelOrg={setNewRelOrg} />

        <ExistingRelationshipTable />
      </FullHeightContainer>
      <RejectModal popup={rejectModal} confirm={onReject} cancel={() => setRejectModal(false)} />
    </FlexBox>
  );
};
