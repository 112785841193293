import React, { useEffect } from 'react';
import { Collapse, List, ListItemText } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useLocation } from 'react-router-dom';
import NavItem from '../NavItem';
import { Items, Icon, Text } from '../styles';
import { NavCollapseProps, RouteToSelectedMap } from './types';

export default function NavCollapse({
  menu,
  level,
  isDrawerOpen,
  setActiveColor,
  activeColor,
  setSelected,
  selected,
}: NavCollapseProps) {
  const handleClick = () => {
    if (selected === menu.text) {
      setSelected('');
    } else {
      setSelected(menu.text);
    }
  };

  const location = useLocation();

  useEffect(() => {
    const routeToSelectedMap: RouteToSelectedMap = {
      '': '',
      dashboard: '',
      relationships: 'sidebarRelationships',
      security: 'sidebarSecurity',
    };

    const paths = location.pathname.split('/');
    const lastPath = paths[paths.length - 1];
    setSelected(routeToSelectedMap[lastPath] || '');
  }, [location, setSelected]);

  const hilight = menu.children?.map((res: { title: string }) => res.title === activeColor);
  const show = hilight.indexOf(true) !== -1;

  const menus = menu.children?.map((list: any) => {
    switch (list.type) {
      case 'collapse':
        return (
          <NavCollapse
            setSelected={setSelected}
            selected={selected}
            key={list.text}
            activeColor={activeColor}
            menu={list}
            setActiveColor={setActiveColor}
            level={level + 1}
            isDrawerOpen
          />
        );
      case 'item':
        return (
          <NavItem
            section="inner"
            activeColor={activeColor}
            item={list}
            key={list.text}
            level={level + 1}
            isDrawerOpen
          />
        );
      default:
        return (
          <Text key={menu.text} variant="h6" color="error" align="center">
            Menu Items Error
          </Text>
        );
    }
  });

  return (
    <>
      <Items
        sx={{
          alignItems: 'center',
          pl: level > 1 ? 6 : '',
        }}
        selected={selected === menu.id}
        onClick={handleClick}
        active={show ? 'true' : ''}
        level={level}
      >
        <Icon sx={{ minWidth: 'unset', marginRight: '16px' }}>{menu.icon}</Icon>
        <ListItemText
          primary={
            <Text
              variant={selected === menu.id ? 'h5' : 'body1'}
              sx={{
                my: 'auto',
                display: isDrawerOpen ? 'block' : 'none',
                fontFamily: 'Gotham-Medium',
              }}
            >
              {menu.title}
            </Text>
          }
        />
        {isDrawerOpen &&
          (selected == menu.text ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />)}
      </Items>
      <Collapse
        in={selected === menu.text}
        timeout="auto"
        sx={{ display: isDrawerOpen ? 'block' : 'none' }}
      >
        <List
          component="div"
          disablePadding
          sx={{
            position: 'relative',
          }}
        >
          {menus}
        </List>
      </Collapse>
    </>
  );
}
