import axios from 'axios';
import { useContext } from 'react';
import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import { QuickConfirmationModalContext } from '../context/QuickModal';
import usePermissions, { PermissionKeys } from '../hooks/usePermissions';
import { useToast } from '../hooks/useToast';
import { ApiError } from '../types';
import { ContactPayload, ContactResponse } from '../types/sellerGroup';
import {
  OrganizationContact,
  OrganizationContactsPaginationPayload,
  OrganizationContactsPaginationResponse,
} from '../types/sellerGroupContacts';

export const useOrganizationContacts = (
  props: Partial<OrganizationContactsPaginationPayload>,
  options?: Omit<
    UseQueryOptions<OrganizationContactsPaginationResponse, ApiError>,
    'queryKey' | 'queryFn'
  >,
) => {
  const toast = useToast();
  const isReadContact = usePermissions(PermissionKeys.ReadContact);

  const fetchContacts = async () => {
    const res = await axios.post<OrganizationContactsPaginationResponse>('/Contact/page', props);

    return res.data;
  };
  return useQuery<OrganizationContactsPaginationResponse, ApiError>(
    [
      'fetchSellerGroupContacts',
      props.email,
      props.fullName,
      props.orgId,
      props.pageNumber,
      props.pageSize,
      props.phone,
      props.sortColumn,
      props.sortDirection,
      props.title,
      props.status,
    ],
    fetchContacts,
    {
      enabled: isReadContact,
      onError(err) {
        toast.error(err.response.data.message);
      },
      refetchOnMount: true,
      ...options,
    },
  );
};

export const useOrganizationContactUpdate = (
  onSuccess?:
    | ((
        data: ContactResponse,
        variables: ContactPayload,
        context: unknown,
      ) => void | Promise<unknown>)
    | undefined,
) => {
  const toast = useToast();
  const { setQuickModal } = useContext(QuickConfirmationModalContext);

  const updateContact = async (payload: ContactPayload) => {
    const res = await axios.patch('/Contact', payload);

    return res.data;
  };
  return useMutation<ContactResponse, ApiError, ContactPayload>(
    'updateSellerGroupContact',
    updateContact,
    {
      onError(err, variables) {
        if (err.response.data.code === 40029) {
          setQuickModal({
            isOpen: true,
            message: `Contact ${variables.contact.firstName} ${variables.contact.lastName} already exists`,
            modalTitle: 'Duplicates!',
          });
        } else {
          toast.error(err.response.data.message);
        }
      },
      onSuccess,
    },
  );
};

export const useOrganizationContactDelete = (
  onSuccess?:
    | ((
        data: {
          contactId: number;
          message: string;
        },
        variables: number,
        context: unknown,
      ) => void | Promise<unknown>)
    | undefined,
  onError?:
    | ((error: ApiError, variables: number, context: unknown) => void | Promise<unknown>)
    | undefined,
) => {
  const toast = useToast();

  const deleteContact = async (id: number) => {
    const res = await axios.delete(`/Contact/${id}`);

    return res.data;
  };
  return useMutation<{ contactId: number; message: string }, ApiError, number>(
    'deleteSellerGroupContact',
    deleteContact,
    {
      onError,
      onSuccess(data, variables, context) {
        toast.success(data.message);
        if (onSuccess) {
          onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useSellerGroupContactsAll = ({
  sellerGroupId,
  options,
}: {
  sellerGroupId: string | number;
  options?:
    | Omit<UseQueryOptions<OrganizationContact[], ApiError>, 'queryKey' | 'queryFn'>
    | undefined;
}) => {
  const fetchContacts = async () => {
    const res = await axios.get<OrganizationContact[]>(`/SellerGroup/${sellerGroupId}/contacts`);
    return res.data;
  };
  return useQuery<OrganizationContact[], ApiError>(
    ['fetchAllSGContacts', sellerGroupId],
    fetchContacts,
    options,
  );
};
