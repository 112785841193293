import { createContext, Dispatch, SetStateAction } from 'react';

export interface IIsLoadingContext {
  isDataLoading: boolean;
  setIsDataLoading: Dispatch<SetStateAction<boolean>>;
}

export const IsDataLoadingContext = createContext<IIsLoadingContext>({
  isDataLoading: false,
  setIsDataLoading: () => {},
});
