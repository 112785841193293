import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UnsavedDataContext } from '../../../context/UnsavedData';
import { CancelModal } from '../CancelModal';
import { useNavigateAfterDatalossDecision } from '../../../hooks/useNavigateAfterDatalossDecision';

export default function DataLossModal() {
  const { t } = useTranslation();
  const { unsavedData, setUnsavedData } = useContext(UnsavedDataContext);
  const { isPopupOpen, onDiscardChanges, onExit, onSaveChanges, hideSaveDataButton } = unsavedData;
  const { navigateAfterDatalossDecision } = useNavigateAfterDatalossDecision();

  return (
    <CancelModal
      isOpened={isPopupOpen}
      modalTitle={t('security.roles.roleForm.role.warning')}
      onDiscardChanges={() => {
        onDiscardChanges();
        navigateAfterDatalossDecision();

        setUnsavedData((prevValue) => ({
          ...prevValue,
          isPopupOpen: false,
          returnUrl: '',
        }));
      }}
      onSaveChanges={() => {
        onSaveChanges();

        setUnsavedData((prevValue) => ({
          ...prevValue,
          isPopupOpen: false,
        }));
      }}
      onExit={() => {
        onExit();

        setUnsavedData((prevValue) => ({
          ...prevValue,
          isPopupOpen: false,
        }));
      }}
      hideSaveDataButton={hideSaveDataButton}
    />
  );
}
