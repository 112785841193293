import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { PageHeaderContainer } from '../../components/shared/AppPageHeaderContentHost/styles';
import { AlignCenterBox, SpaceBetweenBox } from '../../components/shared/FlexBox';
import PageHeaderContentHost from '../../components/shared/AppPageHeaderContentHost';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import { useTranslation } from 'react-i18next';
import { AppCancelButton } from '../../components/shared/AppCancelButton';
import { AppResetButton } from '../../components/shared/AppResetButton';
import { AppSaveButton } from '../../components/shared/AppSaveButton';
import { AppActionButton } from '../../components/shared/AppActionButton';
import { useToast } from '../../hooks/useToast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ContactForm from '../../components/Forms/ContactForm';
import {
  ContactFormValues,
  ContactReqObj,
  ContactResponse,
  ContactRole,
} from '../../types/sellerGroup';
import { Grid } from '@mui/material';
import { StyledTypography } from '../../components/Forms/RoleForm/noPermissionsText';
import {
  Organizations,
  useAddContact,
  useOrganizationInfo,
} from '../../services/sellerGroupServices';
import { ApiError } from '../../types/api';
import RoleType from '../../types/businessRoles';
import { SecondHeaderBox } from '../OrganizationContactInfoPage/styles';
import { useQuery } from '../../hooks/useQuery';
import { White } from '../../Theme/colorsVariables';
import { useAppRolesByType } from '../../services/sellerServices';
import { QuickConfirmationModalContext } from '../../context/QuickModal';
import { getPhonePayload } from '../../components/shared/AppPhoneField';
import { useNavigateAfterDatalossDecision } from '../../hooks/useNavigateAfterDatalossDecision';

export default function AddOrganizationContact({
  customOrgId,
  setShowContactsTable,
  customFormRef,
  setIsSaveEnabled: setIsSaveEnabledParent,
  customOrgName,
  setCustomUnsavedDataFlag,
  callbackOnContactAddSucess,
  nextStep,
  refetchParentContacts,
}: {
  customOrgId?: string;
  setShowContactsTable?: Dispatch<SetStateAction<boolean>>;
  setIsSaveEnabled?: Dispatch<SetStateAction<boolean>>;
  customFormRef?: React.RefObject<HTMLFormElement>;
  customOrgName?: Organizations;
  setCustomUnsavedDataFlag?: Dispatch<SetStateAction<boolean>>;
  callbackOnContactAddSucess?: () => void;
  nextStep?: number | null;
  refetchParentContacts?: () => void;
}) {
  const { state: addProductLineForm } = useLocation();
  const { t } = useTranslation();
  const toast = useToast();
  const { id, orgName } = useParams();

  const { setQuickModal } = useContext(QuickConfirmationModalContext);
  const { navigateAfterDatalossDecision } = useNavigateAfterDatalossDecision();

  const queryReturnUrl = useQuery('returnUrl');
  const isCreateContactPermission = usePermissions(PermissionKeys.CreateContact);
  const isViewSellerPermission = usePermissions(PermissionKeys.ViewSellers);
  const isViewAgentPermission = usePermissions(PermissionKeys.ReadAgent);

  const organization = useMemo(() => {
    return customOrgName
      ? customOrgName
      : orgName === 'SellerGroup'
      ? Organizations.SellerGroupOrg
      : orgName === 'Agent'
      ? Organizations.Agent
      : orgName === 'Seller'
      ? Organizations.Seller
      : '';
  }, [customOrgName, orgName]);

  const isViewOrganizationPermission =
    organization === Organizations.SellerGroupOrg || organization === Organizations.Seller
      ? isViewSellerPermission
      : organization === Organizations.Agent
      ? isViewAgentPermission
      : false;

  const { data: organizationData } = useOrganizationInfo({
    id: Number(customOrgId || id),
    isEnabled: isCreateContactPermission && isViewOrganizationPermission,
    organisation: organization,
  });
  const { data: agencyRolesForContacts } = useAppRolesByType(RoleType.agencyUser, {
    enabled: isCreateContactPermission && isViewOrganizationPermission,
  });
  const { data: businessRolesForContacts } = useAppRolesByType(RoleType.business, {
    enabled: isCreateContactPermission && isViewOrganizationPermission,
  });
  const { data: onwiseRoles } = useAppRolesByType(RoleType.onWiseUser, {
    enabled: isCreateContactPermission && isViewOrganizationPermission,
  });

  const navigate = useNavigate();
  const originalFormRef = useRef<HTMLFormElement>(null);
  const [cancelPopUp, setCancelPopUp] = useState<boolean>(false);
  const [isUnsavedData, setIsUnsavedData] = useState<boolean>(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(true);
  const [isAddNewContact, setIsAddNewContact] = useState<boolean>(false);

  const formRef = useMemo(() => {
    return customFormRef ? customFormRef : originalFormRef;
  }, [customFormRef]);

  const navigateToContactsOrContacById = useCallback(
    (contactId?: number) => {
      if (!customOrgId) {
        let baseUrl = `/dashboard/${organization}/${id}/Contact`;

        if (contactId) {
          baseUrl += `/${contactId}`;
        }

        navigate(baseUrl);
      }
    },
    [customOrgId, id, navigate, organization],
  );

  const onAddSuccess = (data: ContactResponse, variables: ContactReqObj) => {
    toast.success(data.message);
    setTimeout(() => {
      if (refetchParentContacts) refetchParentContacts();
    }, 500);
    if (callbackOnContactAddSucess && typeof nextStep === 'number') {
      callbackOnContactAddSucess();
      return;
    }
    if (!navigateAfterDatalossDecision()) {
      navigateToContactsOrContacById(data.contacts[0]);
      if (isAddNewContact) {
        formRef.current?.dispatchEvent(new Event('reset', { cancelable: true, bubbles: true }));
        setIsAddNewContact(false);
      }
      if (!isAddNewContact && setShowContactsTable) {
        setShowContactsTable(true);
      }
    }

    if (queryReturnUrl) {
      const contact = variables.contacts[0];

      navigate(queryReturnUrl, {
        state: {
          ...addProductLineForm,
          [(function () {
            switch (organization) {
              case Organizations.SellerGroupOrg:
                return 'signer';
              case Organizations.Seller:
                return 'signer';
              case Organizations.Agent:
                return 'agentRelationshipOwner';
              default:
                return 'contact';
            }
          })()]: {
            label: `${contact.firstName} ${contact.lastName}<span class="vl"></span>${
              contact.title || ''
            }<span class="vl"></span>${contact.email}`,
            value: data.contacts[0],
          },
        },
      });
    }
  };

  const onAddError = (error: ApiError, variables: ContactReqObj) => {
    if (error.response.data.code === 40029) {
      setQuickModal({
        isOpen: true,
        message: `Contact ${variables.contacts[0].firstName} ${variables.contacts[0].lastName} already exists`,
        modalTitle: 'Duplicates!',
      });
    } else {
      toast.error(error.response.data.message);
    }
  };

  const { mutate: addContacts } = useAddContact(
    (data, variables) => {
      onAddSuccess(data, variables);
    },
    (error, variables) => {
      onAddError(error, variables);
    },
  );

  const handleSubmitForm = (data: ContactFormValues) => {
    const contactReq: ContactReqObj = {
      orgId: parseInt(id || customOrgId || ''),
      orgType: 4,
      contactManagementOrgId:
        organization !== Organizations.Agent ? Number(data.contactOwner?.value) : null,

      contacts: [
        {
          businessRoles:
            data.contactClassification.map<ContactRole>((role) => ({
              roleId: role.addressStateId,
              description: role.value,
              roleName: role.label,
            })) || [],
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          ...getPhonePayload(data.phone),
          title: data.title,
          onWiseSystemRoles:
            data.onwiseSystemRole.map<ContactRole>((role) => ({
              roleId: role.addressStateId,
              description: role.value,
              roleName: role.label,
            })) || [],
          status: true,
          ...(organization === Organizations.SellerGroupOrg && !!data.onwiseSystemRole.length
            ? { sellerIds: data.associatedSellers.map((item) => item.value) || null }
            : {}),
        },
      ],
    };

    addContacts(contactReq);
  };

  useEffect(() => {
    if (setCustomUnsavedDataFlag) {
      setCustomUnsavedDataFlag(isUnsavedData);
    }
  }, [isUnsavedData, setCustomUnsavedDataFlag]);

  return (
    <>
      {isViewOrganizationPermission && isCreateContactPermission ? (
        <>
          {!customOrgId && (
            <PageHeaderContainer mb={3}>
              <SpaceBetweenBox>
                <PageHeaderContentHost
                  title={
                    organization === Organizations.SellerGroupOrg
                      ? 'Seller Group'
                      : organization === Organizations.Agent
                      ? 'Agent'
                      : organization === Organizations.Seller
                      ? 'Seller'
                      : ''
                  }
                  name={
                    organizationData?.legalName
                      ? organizationData?.legalName
                      : organizationData?.orgName
                  }
                  status={organizationData?.status}
                />
              </SpaceBetweenBox>
            </PageHeaderContainer>
          )}
          <SecondHeaderBox>
            <PageHeaderContentHost color={`${White}`} title={'Add Contact'} />
            <AlignCenterBox>
              {!queryReturnUrl && !customOrgId && (
                <AppActionButton
                  onClick={() => {
                    if (queryReturnUrl) {
                      navigate(queryReturnUrl, { state: addProductLineForm });
                    } else {
                      navigateToContactsOrContacById();
                    }
                  }}
                >
                  {t('relationships.sellergroup.buttons.close')}
                </AppActionButton>
              )}
              {customOrgId && (
                <AppActionButton
                  onClick={() => {
                    if (isUnsavedData) {
                      setCancelPopUp(true);
                    } else {
                      if (setShowContactsTable) setShowContactsTable(true);
                    }
                  }}
                >
                  {t('relationship.sellers.addsellers.linearstepper.cancelandlist')}
                </AppActionButton>
              )}
              {!customOrgId && (
                <AppCancelButton
                  onClick={() => {
                    if (isUnsavedData) {
                      setCancelPopUp(true);
                    } else {
                      if (queryReturnUrl) {
                        navigate(queryReturnUrl, { state: addProductLineForm });
                      } else {
                        navigateToContactsOrContacById();
                      }
                    }
                  }}
                />
              )}
              <AppResetButton
                onClick={() => {
                  formRef.current?.dispatchEvent(
                    new Event('reset', { cancelable: true, bubbles: true }),
                  );
                }}
              />
              {!customOrgId ? (
                <AppSaveButton
                  disabled={isSaveEnabled}
                  onClick={() => {
                    formRef.current?.dispatchEvent(
                      new Event('submit', { cancelable: true, bubbles: true }),
                    );
                  }}
                />
              ) : (
                <AppActionButton
                  disabled={isSaveEnabled}
                  onClick={() => {
                    formRef.current?.dispatchEvent(
                      new Event('submit', { cancelable: true, bubbles: true }),
                    );
                    setIsAddNewContact(true);
                  }}
                >
                  {t('relationship.sellers.addsellers.linearstepper.saveandaddnew')}
                </AppActionButton>
              )}
            </AlignCenterBox>
          </SecondHeaderBox>

          <ContactForm
            isEdit={true}
            contactStatus={false}
            contactData={null}
            businessRolesForContacts={
              organization === Organizations.Agent
                ? agencyRolesForContacts
                : businessRolesForContacts
            }
            onwiseRoles={onwiseRoles}
            onHandleResponseSubmit={handleSubmitForm}
            cancelPopUp={cancelPopUp}
            setCancelPopUp={setCancelPopUp}
            setIsUnsavedData={setIsUnsavedData}
            isUnsavedData={isUnsavedData}
            isSaveEnabled={isSaveEnabled}
            setIsSaveEnabled={(value) => {
              setIsSaveEnabled(value);
              if (setIsSaveEnabledParent) setIsSaveEnabledParent(value);
            }}
            customOrgId={customOrgId}
            customOrganization={organization}
            setShowContactsTable={setShowContactsTable}
            formRef={formRef}
          />
        </>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <StyledTypography>{t('relationships.organisation.view.permission')}</StyledTypography>
          </Grid>
        </Grid>
      )}
    </>
  );
}
