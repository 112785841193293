import { useTranslation } from 'react-i18next';
import { BoxTextRequire } from './styles';

export default function LabelRequired() {
  const { t } = useTranslation();
  return (
    <BoxTextRequire>
      <i>{t('relationship.sellers.addsellers.linearstepper.fieldsMarked')}</i> <span>*</span>
      <i>{t('relationship.sellers.addsellers.linearstepper.require')}</i>
    </BoxTextRequire>
  );
}
