import axios from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ApiError } from '../types';

export const useCheckOrgHasInProgressAgreements = (
  orgId: number,
  options?: Omit<UseQueryOptions<boolean, ApiError>, 'queryKey' | 'queryFn'>,
) => {
  const fecthInfo = async () => {
    const res = await axios.get(`/Organization/CheckOrgHasInProcessAgreements/${orgId}`);
    return res.data;
  };
  return useQuery<boolean, ApiError>('CheckOrgHasInProcessAgreements', fecthInfo, options);
};
