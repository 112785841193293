import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RoleForm } from '../../components/Forms/RoleForm';
import { AppActionButton } from '../../components/shared/AppActionButton';
import { AppCancelButton } from '../../components/shared/AppCancelButton';
import { AppEditButton } from '../../components/shared/AppEditButton';
import PageHeaderContentHost from '../../components/shared/AppPageHeaderContentHost';
import { PageHeaderContainer } from '../../components/shared/AppPageHeaderContentHost/styles';
import { AppResetButton } from '../../components/shared/AppResetButton';
import { AppSaveButton } from '../../components/shared/AppSaveButton';
import { FlexBox } from '../../components/shared/FlexBox';
import { useToast } from '../../hooks/useToast';
import {
  RolePayload,
  useCheckRolePermissionQuery,
  useUpdateRole,
} from '../../services/roleService';
import { useRoleInfo } from '../../services/roleService';
import { RoleFormType } from '../../types';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import DeleteRole from '../../components/modal/DeleteRole';
import { Grid } from '@mui/material';
import { StyledTypography } from '../../components/Forms/RoleForm/noPermissionsText';
import RoleHasDuplicatePermModal from '../../components/modal/RoleHasDuplicatePermModal';
import { StickyContainer } from '../../Theme/oldStylesInUse/oldAddSellerStyles';
import { QuickConfirmationModalContext } from '../../context/QuickModal';
import AppTooltip from '../../components/shared/AppTooltip';
import { useNavigateAfterDatalossDecision } from '../../hooks/useNavigateAfterDatalossDecision';
import { ModificationLog } from '../../components/ModificationLog';

export const RolePage = () => {
  const { t } = useTranslation();
  const { roleId } = useParams();
  const toast = useToast();
  const { navigateAfterDatalossDecision } = useNavigateAfterDatalossDecision();

  const isReadRoleAgenciesPermission = usePermissions(PermissionKeys.ReadAgentRoles);
  const isReadRoleProviderPermission = usePermissions(PermissionKeys.ReadProviderRoles);
  const isEditRoleAgenciesPermission = usePermissions(PermissionKeys.UpdateAgentRoles);
  const isEditRoleProviderPermission = usePermissions(PermissionKeys.UpdateProviderRoles);
  const isDeleteRoleAgenciesPermission = usePermissions(PermissionKeys.DeleteAgentRoles);
  const isDeleteRoleProviderPermission = usePermissions(PermissionKeys.DeleteProviderRoles);

  const [isEdit, setIsEdit] = useState(false);
  const [isUnsavedData, setIsUnsavedData] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [cancelPopup, setCancelPopUp] = useState(false);
  const [roleStatus, setRoleStatus] = useState(false);
  const [payload, setPayload] = useState<RolePayload>();
  const [roleHasDuplicatePerm, setRoleHasDuplicatePerm] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);
  const {
    data: roleData,
    isFetching: isRoleDataFetching,
    refetch: refetchRoleData,
  } = useRoleInfo(Number(roleId));

  const handleUpdateSuccess = () => {
    toast.success(t('security.roles.roleForm.role.updated'));
    if (navigateAfterDatalossDecision()) {
      return;
    }
    setIsEdit(false);
    refetchRoleData();
  };

  const { mutate: updateRole } = useUpdateRole({
    onSuccess: handleUpdateSuccess,
    onError(error) {
      toast.error(error.response.data.message);
    },
  });

  const { setQuickModal } = useContext(QuickConfirmationModalContext);

  const { mutate: validateRole } = useCheckRolePermissionQuery({
    onSuccess(data, reqPayload) {
      if (data) {
        updateRole(reqPayload);
      } else {
        setRoleHasDuplicatePerm(true);
      }
    },
    onError(error, variables) {
      if (error.response.data.code === 30106) {
        setQuickModal({
          message: `Role ${variables.roleName} already exists.`,
          modalTitle: 'Duplicates!',
          isOpen: true,
        });
      } else {
        toast.error(error.response.data.message);
      }
    },
  });

  const handleFormSubmit = (data: RoleFormType) => {
    const requestPayload: RolePayload = {
      associatedWith: data.assosiatedWith?.value === 'Agent' ? 'Agency' : 'Provider',
      roleType: 2,
      roleId: roleData?.roleId,
      roleName: data.roleName,
      roleDescription: data.roleDescription,
      isActive: data.roleStatus,
      permissionSetIds: data.permissionSetIds,
      restrictAccessToOwnAgents:
        data.assosiatedWith?.value === 'Provider' ? data.restrictAccessToOwnAgents : false,
    };
    setPayload(requestPayload);
    validateRole(requestPayload);
  };
  const viewRolePermission = useMemo(() => {
    if (roleData && roleData.associatedWith === 'Agency') {
      return isReadRoleAgenciesPermission;
    }
    if (roleData && roleData.associatedWith === 'Provider') {
      return isReadRoleProviderPermission;
    }
  }, [isReadRoleAgenciesPermission, isReadRoleProviderPermission, roleData]);
  const editRolePermission = useMemo(() => {
    if (roleData && roleData.associatedWith === 'Agency') {
      return isEditRoleAgenciesPermission;
    }
    if (roleData && roleData.associatedWith === 'Provider') {
      return isEditRoleProviderPermission;
    }
  }, [isEditRoleAgenciesPermission, isEditRoleProviderPermission, roleData]);

  const deleteRolePermission = useMemo(() => {
    if (roleData && roleData.associatedWith === 'Agency') {
      return isDeleteRoleAgenciesPermission;
    }
    if (roleData && roleData.associatedWith === 'Provider') {
      return isDeleteRoleProviderPermission;
    }
  }, [isDeleteRoleAgenciesPermission, isDeleteRoleProviderPermission, roleData]);
  const pagePermission = useMemo(() => {
    return roleData?.roleName !== 'Admin' && !!viewRolePermission;
  }, [roleData?.roleName, viewRolePermission]);

  useEffect(() => {
    if (roleData) {
      setRoleStatus(!!roleData.isActive);
    }
  }, [roleData]);

  if (isRoleDataFetching) {
    return <></>;
  }

  if (viewRolePermission !== true && viewRolePermission !== undefined) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <StyledTypography mt={10}>{t('dashboard.products.title')}</StyledTypography>
        </Grid>
      </Grid>
    );
  }
  return roleData ? (
    <>
      <StickyContainer>
        <PageHeaderContainer>
          <FlexBox>
            <PageHeaderContentHost
              title={'Role'}
              name={roleData?.roleName}
              status={roleStatus}
              showRequiredLabel={isEdit}
            />
            {isEdit && (
              <AppActionButton
                variant="outlined"
                onClick={() => {
                  setRoleStatus((prev) => !prev);
                }}
              >
                {!roleStatus ? (
                  <span>{t('security.users.userList.activate')}</span>
                ) : (
                  <span>{t('security.users.userList.deactivate')}</span>
                )}
              </AppActionButton>
            )}
          </FlexBox>
          {!isEdit && (
            <FlexBox>
              <AppTooltip arrow title={!pagePermission && t('admin.edit.tooltip')}>
                <span>
                  <AppEditButton
                    disabled={!editRolePermission || !pagePermission}
                    onClick={() => {
                      setIsEdit(true);
                    }}
                  />
                </span>
              </AppTooltip>
              <DeleteRole
                disable={!deleteRolePermission || !pagePermission}
                assign={roleData?.assignedUsers}
              />
            </FlexBox>
          )}
          {isEdit && (
            <FlexBox>
              <AppCancelButton
                onClick={() => {
                  if (isUnsavedData) {
                    setCancelPopUp(true);
                  } else {
                    setIsEdit(false);
                  }
                }}
              />
              <AppResetButton
                onClick={() => {
                  formRef.current?.dispatchEvent(
                    new Event('reset', { cancelable: true, bubbles: true }),
                  );
                }}
              />
              <AppSaveButton
                disabled={isSaveEnabled}
                onClick={() => {
                  formRef.current?.dispatchEvent(
                    new Event('submit', { cancelable: true, bubbles: true }),
                  );
                }}
              />
            </FlexBox>
          )}
        </PageHeaderContainer>
      </StickyContainer>
      <RoleForm
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        defaultValues={roleData}
        onSubmit={handleFormSubmit}
        formRef={formRef}
        isSaveEnabled={isSaveEnabled}
        setIsSaveEnabled={setIsSaveEnabled}
        isUnsavedData={isUnsavedData}
        setIsUnsavedData={setIsUnsavedData}
        roleStatus={roleStatus}
        setRoleStatus={setRoleStatus}
        cancelPopup={cancelPopup}
        setCancelPopup={setCancelPopUp}
      />

      {!isEdit && (
        <ModificationLog
          {...{
            createdBy: roleData.createdBy,
            createdOn: roleData.createdOn,
            updatedBy: roleData.updatedBy,
            updatedOn: roleData.updatedOn,
            enabled: true,
          }}
        />
      )}

      <RoleHasDuplicatePermModal
        popUp={roleHasDuplicatePerm}
        handleModal={() => {
          setRoleHasDuplicatePerm(false);
        }}
        handleConfirm={() => {
          setRoleHasDuplicatePerm(false);
          updateRole(payload!);
        }}
      />
    </>
  ) : (
    <></>
  );
};
