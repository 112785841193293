import React, { useEffect } from 'react';
import { AppActionButton } from '../../components/shared/AppActionButton';
import { AdminService } from '../../services/adminService';
import { useNavigate } from 'react-router-dom';
import { AlignCenterBox } from '../../components/shared/FlexBox';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import { useTranslation } from 'react-i18next';
import { SystemAdminText } from './styles';
import { Typography } from '@mui/material';

const SystemAdminPage = () => {
  const { t } = useTranslation();
  const isEnabled = usePermissions(PermissionKeys.SystemAdmin);
  const navigate = useNavigate();
  const { mutate: reindex } = AdminService.useReindex();
  const { mutate: updateGooglePlaceIds } = AdminService.useUpdateGooglePlaceIds();

  // Might be enabled in future
  // const { mutate: updateOFACresults } = AdminService.useUpdateOFACresults();
  const { mutate: updateOrgContacts } = AdminService.useUpdateOrgContacts();
  useEffect(() => {
    if (!isEnabled) {
      navigate('/NotFound', { state: { error: t('restrictions.tooltip') } });
    }
  }, [isEnabled, navigate, t]);

  return (
    <>
      <AlignCenterBox mt={2}>
        <AppActionButton
          onClick={() => {
            reindex();
          }}
        >
          Start Reindex
        </AppActionButton>
        <SystemAdminText ml={2}>Reindex will be applied to current environment.</SystemAdminText>
      </AlignCenterBox>
      {/* Might be enabled in future */}
      {/* <AlignCenterBox mt={2}>
        <AppActionButton
          onClick={() => {
            updateOFACresults();
          }}
        >
          Update OFAC Results
        </AppActionButton>
        <SystemAdminText ml={2}>Re-run OFAC check for all agreements</SystemAdminText>
      </AlignCenterBox> */}
      <AlignCenterBox mt={2}>
        <AppActionButton
          onClick={() => {
            updateOrgContacts();
          }}
        >
          Update Org Contacts
        </AppActionButton>
        <SystemAdminText ml={2}>
          Create/Update Org Contacts for users associated with Provider.
        </SystemAdminText>
      </AlignCenterBox>
      <AlignCenterBox mt={2}>
        <AppActionButton
          onClick={() => {
            updateGooglePlaceIds();
          }}
        >
          Update Google Place ID
        </AppActionButton>
        <Typography ml={2}>Mass update Google Place IDs for ALL addresses.</Typography>
      </AlignCenterBox>
    </>
  );
};

export default SystemAdminPage;
