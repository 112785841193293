import { Box } from '@mui/material';
import React from 'react';
import { Platinum, SecondaryTextColor, MainTextColor } from '../../../Theme/colorsVariables';

const AppFakeTextField = ({ label, value }: { label: JSX.Element; value: JSX.Element }) => {
  return (
    <>
      <Box sx={{ height: '56px', background: Platinum, position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            top: '-12px',
            left: '14px',
            fontFamily: 'Gotham-Book',
            fontSize: '15px',
            color: SecondaryTextColor,
          }}
        >
          {label}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '16px',
            left: '14px',
            fontFamily: 'Gotham-Medium',
            fontSize: '16px',
            color: MainTextColor,
          }}
        >
          {value}
        </Box>
      </Box>
    </>
  );
};

export default AppFakeTextField;
