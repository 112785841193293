import { InputLabel, InputProps, OutlinedInput } from '@mui/material';
import React, { forwardRef } from 'react';
import { FC } from 'react';
import { IMaskInput } from 'react-imask';
import { StyledFormControl } from '../AppPhoneField';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(function TextMaskCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={[{ mask: '00000' }, { mask: '00000-0000' }]}
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

interface ZipFieldProps extends InputProps {
  label: string;
  errorMessage?: string;
}

export const AppZipField: FC<ZipFieldProps> = forwardRef((props, ref) => {
  const { errorMessage, ...otherProps } = props;
  return (
    <StyledFormControl
      variant="outlined"
      fullWidth
      error={!!errorMessage}
      required={props.required}
    >
      <InputLabel htmlFor="formatted-text-mask-input">
        {errorMessage ? `${props.label} - ${errorMessage}` : props.label}
      </InputLabel>
      <OutlinedInput
        {...otherProps}
        ref={ref}
        inputComponent={TextMaskCustom as any}
        label={errorMessage ? `${props.label} - ${errorMessage}` : props.label}
      />
    </StyledFormControl>
  );
});
