import { Box, Typography, Paper } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styled from 'styled-components';
import { breakPointSmall } from '../../utils/MediaBreakPoint';
import {
  DeepElectricBlue,
  HoverColor,
  MainGreen,
  MainColor,
  MainTextColor,
  Nickel,
  White,
} from '../../Theme/colorsVariables';

export const HomeHeading = styled(Box)`
  font-size: 20px;
  font-family: Gotham-Medium;
  color: ${MainTextColor};
  ${breakPointSmall('font-size: 21px')}
`;

export const TimeStyle = styled(Typography)`
  font-size: 12px;
  font-family: Gotham-Book;
  font-weight: normal;
  min-width: 80px;
  color: ${Nickel};
`;

export const SubLabelHead = styled(Typography)`
  font-size: 16px;
  text-decoration: underline;
  font-family: Gotham-Medium;
  color: ${DeepElectricBlue};
  cursor: pointer;
  ${breakPointSmall('font-size: 15px')}
`;

export const SellerLabel = styled(Box)`
  font-size: 16px;
  color: ${MainTextColor};
  ${breakPointSmall('font-size: 15px')}
`;

export const ActivityDetail = styled(Typography)`
  font-size: 14px;
  font-family: Gotham-Book;
  color: ${MainTextColor};
  ${breakPointSmall('font-size: 13px')}
`;

export const InMyCourtLabel = styled(Typography)`
  font-size: 16px;
  font-family: Gotham-Medium;
  color: ${MainTextColor};
  ${breakPointSmall('font-size: 15px')}
`;

export const IconStyle = styled(CheckCircleIcon)`
  font-size: 16px;
  color: ${MainGreen};
  ${breakPointSmall('font-size: 15px')}
`;

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: `${White}`,
  ...theme.typography.body2,
  padding: '12px',
  boxShadow: `none`,
  borderRadius: '0',
  height: 'calc(100vh - 114px)',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  position: 'relative',
}));

export const ActivityContainer = styled(Box)`
  overflow: auto;
`;

export const ArrowContainer = styled(Box)`
  position: absolute;
  bottom: 45px;
  right: 40px;
  width: 40px;
  height: 40px;
  background: ${MainColor};
  border-radius: 50%;
  color: ${White};
  cursor: pointer;
  svg {
    color: ${White};
  }
  &:hover {
    background: ${HoverColor};
  }
`;

export const ArrowContainerUp = styled(ArrowContainer)`
  bottom: 90px;
`;
