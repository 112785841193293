import { Step, StepLabel, Stepper, Typography } from '@mui/material';
import styled from 'styled-components';
import { FlashWhite, MainTextColor, SecondaryBackground, White } from '../../Theme/colorsVariables';
import { FlexBox } from '../../components/shared/FlexBox';

export const BoxStepper = styled(FlexBox)`
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  background: ${SecondaryBackground};
  padding: 0 8px;
  border-radius: 8px;
`;

export const BoxMain = styled(FlexBox)`
  margin-bottom: 20px;
  flex-direction: column;
`;

export const TypographyTooltip = styled(Typography)`
  font-family: Gotham-Bold;
  font-size: 20px;
`;

export const MainStepper = styled(Stepper)`
  justify-content: center;
  background: ${SecondaryBackground};
`;

export const MainSteps = styled(Step)<{ props: number[] }>`
  padding: 8px 12px;
  height: 100%;
  text-align: center;
  transition: 0.3s all linear;

  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    display: none;
  }
  cursor: pointer !important;
  background: ${(props) => (props.props[0] === props.props[1] ? FlashWhite : SecondaryBackground)};

  border-radius: 16px 16px 0 0;
  position: relative;
  ${(props) => {
    if (props.props[0] === props.props[1]) {
      return `
      border-top: 8px solid ${SecondaryBackground};
      border-left: 8px solid ${SecondaryBackground};
      border-right: 8px solid ${SecondaryBackground};
        &:after{
        content: "";
        position: absolute;
        background-color: ${SecondaryBackground};
        bottom: 0px;
        left: -10px;
        height: 38px;
        width: 10px;
        border-bottom-right-radius: 10px;
        border-top-left-radius: 10px;
        box-shadow: 0px 10px 0px 0px ${FlashWhite};
        z-index: 1;
      }
      &:before{
        content: "";
        position: absolute;
        background-color: ${SecondaryBackground};
        bottom: 0px;
        right: -10px;
        height: 38px;
        width: 10px;
        z-index:1;
        border-bottom-left-radius: 10px;
        box-shadow: 0px 10px 0px 0px ${FlashWhite};
      }`;
    }
  }}
`;

export const Label = styled(StepLabel)<{ isactive: string }>`
  padding-right: 10px;
  & .MuiStepLabel-label {
    color: ${(props) => {
      let style = '';
      if (props.isactive) {
        style = MainTextColor;
      } else {
        style = White;
      }
      return style;
    }};
    font-size: 15px;
    font-family: Gotham-Medium;
    word-break: break-word;
  }
  cursor: pointer !important;
  @media only screen and (max-width: 1500px) {
    & .MuiStepLabel-label {
      font-size: 12px;
    }
  }
`;
