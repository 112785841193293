import axios from 'axios';
import { useMutation } from 'react-query';
import { ApiError } from '../types';
import { QuickConfirmationModalContext } from '../context/QuickModal';
import { useContext } from 'react';

export const AdminService = {
  useReindex: () => {
    const { setQuickModal } = useContext(QuickConfirmationModalContext);
    const req = async () => {
      const res = await axios.get('/admin/reindex');
      return res.data;
    };
    return useMutation<unknown, ApiError, void>('reindex-query', req, {
      onError(error) {
        setQuickModal({
          isOpen: true,
          modalTitle: 'Error',
          message: `API failed - ${error.response.data.message}`,
        });
      },
      onSuccess() {
        setQuickModal({
          isOpen: true,
          modalTitle: 'Success',
          message: 'Reindex finished for this Environment.',
        });
      },
    });
  },
  useUpdateOFACresults: () => {
    const { setQuickModal } = useContext(QuickConfirmationModalContext);

    const req = async () => {
      const res = await axios.get('/admin/ofac-recheck');
      return res.data;
    };

    return useMutation<unknown, ApiError, void>('useUpdateOFACresults-query', req, {
      onError(error) {
        setQuickModal({
          isOpen: true,
          modalTitle: 'Error',
          message: `API failed - ${error.response.data.message}`,
        });
      },
      onSuccess() {
        setQuickModal({
          isOpen: true,
          modalTitle: 'Success',
          message: `OFAC Results updated`,
        });
      },
    });
  },
  useUpdateOrgContacts: () => {
    const { setQuickModal } = useContext(QuickConfirmationModalContext);

    const req = async () => {
      const res = await axios.get('/admin/create-account-owners');
      return res.data;
    };

    return useMutation<unknown, ApiError, void>('useUpdateOrgContacts-query', req, {
      onError(error) {
        setQuickModal({
          isOpen: true,
          modalTitle: 'Error',
          message: `API failed - ${error.response.data.message}`,
        });
      },
      onSuccess() {
        setQuickModal({
          isOpen: true,
          modalTitle: 'Success',
          message: `Org Contacts Created`,
        });
      },
    });
  },
  useUpdateGooglePlaceIds: () => {
    const { setQuickModal } = useContext(QuickConfirmationModalContext);
    const req = async () => {
      const res = await axios.get('/admin/update-google-place-id');
      return res.data;
    };
    return useMutation<unknown, ApiError, void>('UpdateGooglePlaceIds-query', req, {
      onError() {
        setQuickModal({
          isOpen: true,
          message: 'Google Place ID update failed',
          modalTitle: 'Error',
        });
      },
      onSuccess() {
        setQuickModal({ isOpen: true, message: 'Google Place IDs updated', modalTitle: 'Success' });
      },
    });
  },
};
