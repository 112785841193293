import { t } from 'i18next';

const errorMap: Record<string, string> = {
  charExceeded: 'max limit exceeded.',
  maxLength: 'cannot be greater than ',
  lessChar: 'Insufficient characters.',
  required: 'required',
  invalidEntry: 'Invalid Entry',
  commonRequired: 'Fields marked * are required.',
  long: t('formErrors.long'),
  // users on add seller
  firstName: 'Invalid Characters.',
  lastName: 'Invalid Characters.',
  phone: 'Invalid',
  email: 'must be valid',
  //Bussines info add seller
  agencyName: 'Invalid characters.',
  sellerGroupName: 'Invalid characters.',
  'sellerGroup.legalName': 'Invalid characters.',
  'sellerGroup.doingBusinessName': 'Invalid characters.',
  legalNameS: 'Invalid characters.',
  doingBusinessAsS: 'Invalid characters.',
  website: 'Invalid characters.',
  addressLine1M: 'Invalid characters.',
  addressLine2M: 'Invalid characters.',
  addressLine1: 'Invalid characters.',
  addressLine2: 'Invalid characters.',
  'sellerGroup.address[0].streetAddressLine1': 'Invalid characters.',
  'sellerGroup.address[0].streetAddressLine2': 'Invalid characters.',
  city: 'Invalid characters.',
  state: 'Invalid characters.',
  cityM: 'Invalid characters.',
  stateM: 'Invalid characters.',
  'sellerGroup.address[0].city': 'Invalid characters.',
  'sellerGroup.address[0].state': 'Invalid characters.',
  'sellerGroup.feinName': 'Only numbers allowed.',
  feinS: 'Must be a 9 digit number.',
  mainFax: 'Only numbers allowed.',
  mainPhone: 'Please enter valid phone number.',
  zipCode: 'Please enter valid ZIP code.',
  zipCodeM: 'Please enter valid ZIP code.',
  'sellerGroup.address[0].zipCode': 'Please enter valid ZIP code.',
  // dealer info add seller
  otherDms: 'Invalid characters.',
  menu: 'Invalid characters.',
  info: 'Invalid characters.',
  phoneLength: 'Must be a 10 digit number.',
  permission: 'Select at least one Permission',
  productLine: 'Select at least one Product Line',
  exists: 'already exists',
  zipLength: 'Must be 5 or 9 digits',
  url: 'Should be valid.',
  helperTextMessage: 'At least 3 characters are needed to perform the search.',
} as const;

type Keys = keyof typeof errorMap;

export const getCommonErrorMessages = (str: Keys): string => {
  return errorMap[str] || '';
};
