import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
export const reactPlugin = new ReactPlugin();
//TBD - replace with dynamic value from env
let instrumentationKey = process.env.REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY;
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: instrumentationKey,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
});
appInsights.loadAppInsights();
export const getAppInsights = () => appInsights;
