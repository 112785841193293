import { useContext } from 'react';
import { UserContext } from '../context/User';

type ExistingFeatureFlags = 'MODLOG';

export const useFeatureFlag = (flagName: ExistingFeatureFlags) => {
  const user = useContext(UserContext);
  const isFlagEnabled = !!user?.features.find((feature) => feature.name === flagName)?.value;
  return !!isFlagEnabled;
};
