import React from 'react';

export default function DeleteIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 20 22">
      <path
        id="delete_icon"
        d="M1610.379,849a1.873,1.873,0,0,1-1.873-1.786l-.515-10.822a.2.2,0,0,1,.2-.2h13.878a.2.2,0,0,1,.195.2l-.516,10.822A1.872,1.872,0,0,1,1619.87,849Zm7.246-11.25v8.125a.625.625,0,0,0,1.25,0V837.75a.625.625,0,1,0-1.25,0Zm-3.124,0v8.125a.625.625,0,1,0,1.249,0V837.75a.625.625,0,1,0-1.249,0Zm-3.125,0v8.125a.625.625,0,0,0,1.25,0V837.75a.625.625,0,1,0-1.25,0Zm-3.75-2.812a.625.625,0,0,1-.625-.625v-1.25a1.562,1.562,0,0,1,1.562-1.563H1612v-.625a1.875,1.875,0,0,1,1.875-1.875h2.5a1.875,1.875,0,0,1,1.875,1.875v.625h3.437a1.563,1.563,0,0,1,1.562,1.563v1.25a.625.625,0,0,1-.625.625Zm5.625-4.063v.625H1617v-.625a.626.626,0,0,0-.625-.625h-2.5A.626.626,0,0,0,1613.25,830.875Z"
        transform="translate(-1607.001 -829)"
        fill="#77868f"
      />
    </svg>
  );
}
