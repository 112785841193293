import styled from 'styled-components';
import { White } from '../../../Theme/colorsVariables';
import { breakPointMedium } from '../../../utils';
import { Box } from '@mui/material';

export const BlockHeader = styled(Box)`
  background: ${White};
  padding-top: 10px;
  font-family: Gotham-MonoSpace-Numbers, sans-serif;
  font-weight: 600;
  font-size: 16px;
  ${breakPointMedium('font-size: 14px;')}
`;
