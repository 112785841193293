import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { useTranslation } from 'react-i18next';
import { AppActionButton, AppActionButtonProps } from '../AppActionButton';

export const AppSaveButton: React.FC<AppActionButtonProps> = (props) => {
  const { t } = useTranslation();
  return (
    <AppActionButton {...props} variant="outlined" startIcon={<SaveOutlinedIcon />}>
      {t('app.action.button.save')}
    </AppActionButton>
  );
};
