import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationOptions } from 'react-query';
import { useToast } from '../hooks/useToast';
import { ApiError } from '../types';
import { DocuSignSendForSignaturesPayload } from '../types/docusign';
import { downloadBase64ToPdf } from '../utils/downloadBase64ToPdf';

interface AgreementPreviewPayload {
  tempOrEnvId: string;
  redirectUri: string;
}
interface GenerateSigningUrlPayload {
  agreementId: number | null;
  redirectUrl?: string;
}

export interface SigningUrl {
  lockMessage: null | string;
  lockUser: null | string;
  lockerUserEmail: null | string;
  signingUrl: string | null;
}

export const GetGeneratedAgreementPreview = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const fetchLink = async ({ tempOrEnvId, redirectUri }: AgreementPreviewPayload) => {
    const fetch = await axios.post(`DocuSign/SigningAgreements`, {
      lookupId: tempOrEnvId,
      redirectUri,
    });
    return fetch?.data;
  };

  return useMutation<SigningUrl, Error, AgreementPreviewPayload>(
    'GetGeneratedAgreementPreview',
    fetchLink,
    {
      onError: () => {
        toast.error(t('iframe.error'));
      },
    },
  );
};

export const GenerateReadOnlyView = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const fetchLink = async ({ agreementId, redirectUrl }: GenerateSigningUrlPayload) => {
    const fetch = await axios.post<string>(`/DocuSign/GenerateReadOnlyView`, {
      agreementId,
      redirectUrl,
    });
    return fetch?.data;
  };

  return useMutation<string, Error, GenerateSigningUrlPayload>('GenerateReadOnlyView', fetchLink, {
    onError: () => {
      toast.error(t('iframe.error'));
    },
  });
};

export const GenerateSigningUrl = (
  options?: Omit<
    UseMutationOptions<SigningUrl, ApiError, GenerateSigningUrlPayload>,
    'mutationKey' | 'mutationFn'
  >,
) => {
  const toast = useToast();
  const fetchLink = async ({ agreementId, redirectUrl }: GenerateSigningUrlPayload) => {
    const fetch = await axios.get(`DocuSign/GenerateSigningUrl`, {
      params: {
        agreementId,
        redirectUrl,
      },
    });

    return fetch?.data;
  };

  return useMutation<SigningUrl, ApiError, GenerateSigningUrlPayload>(
    ['GenerateSigningUrl'],
    fetchLink,
    {
      onError(error) {
        toast.error(error.response.data.message);
      },
      ...options,
    },
  );
};

export interface EnvelopeId {
  envelopeId: string;
}

export interface EnvelopeAuditLog {
  time: string;
  user: string;
  action: string;
  activity: string;
  status: string;
}

export const GetEnvelopeAuditLogDetails = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const fetchLink = async ({ envelopeId }: EnvelopeId) => {
    const fetch = await axios.get(`DocuSign/GetEnvelopeAuditLogDetails`, {
      params: {
        envelopeId,
      },
    });
    return fetch?.data;
  };

  return useMutation<EnvelopeAuditLog[], Error, EnvelopeId>(
    ['GetEnvelopeAuditLogDetails'],
    fetchLink,
    {
      onError: () => {
        toast.error(t('envelope.not.exist'));
      },
    },
  );
};

export const useDownloadAgreementByEnvelopeId = (
  options?: Omit<
    UseMutationOptions<string, ApiError, { envelopeId: string; fileName: string }>,
    'mutationKey' | 'mutationFn'
  >,
) => {
  const toast = useToast();
  const downloadAgreement = async ({ envelopeId }: { envelopeId: string }) => {
    const res = await axios.get(`/DocuSign/DownloadAgreement`, {
      params: {
        envelopeId,
      },
    });
    return res.data;
  };
  return useMutation<string, ApiError, { envelopeId: string; fileName: string }>(
    'useDownloadAgreementByEnvelopeId',
    downloadAgreement,
    {
      onSuccess(data, variables) {
        downloadBase64ToPdf(data, variables.fileName);
      },
      onError(error) {
        toast.error(error.response.data.message);
      },
      ...options,
    },
  );
};

export const useDocuSignSendForSignatures = ({
  options,
}: {
  options?:
    | Omit<
        UseMutationOptions<
          { message: string },
          ApiError,
          DocuSignSendForSignaturesPayload,
          unknown
        >,
        'mutationFn' | 'mutationKey'
      >
    | undefined;
}) => {
  const sendForSignatures = async (data: DocuSignSendForSignaturesPayload) => {
    const res = await axios.post('/DocuSign/SendForSignatures', data);
    return res.data;
  };
  return useMutation<{ message: string }, ApiError, DocuSignSendForSignaturesPayload>(
    'sendForSignatures',
    sendForSignatures,
    options,
  );
};
