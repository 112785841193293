export const allowNumbersOnly = (event: React.KeyboardEvent<HTMLDivElement>) => {
  // INFO: we need this to allow form submit on enter key press

  if (
    event.key === 'Enter' ||
    event.key === 'Backspace' ||
    event.key === 'Tab' ||
    event.ctrlKey ||
    event.metaKey
  ) {
    return;
  }
  // INFO: prevents to enter not numbers into input field
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};
