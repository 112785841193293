export const trimInputValue = (val: string): string => {
  let trimmedArray = val.split('');
  trimmedArray = trimmedArray.filter((value, index) => {
    if (value === ' ' && trimmedArray[index + 1] === ' ') {
      return false;
    } else {
      return true;
    }
  });
  return trimmedArray.join('');
};
