import axios from 'axios';
import { useContext } from 'react';
import { useQuery, useMutation } from 'react-query';
import { UserContext } from '../context/User';
import {
  DocumentDropdownTypes,
  PaginationResponse,
  DocumentsData,
  PayloadDocument,
  TemplateDocumentResponse,
  TemplateDocumentPayload,
  ApiError,
  EditTemplateDocumentPayload,
} from '../types';
import { DeleteDocPayload } from '../types/document';
import { downloadBase64ToPdf } from '../utils/downloadBase64ToPdf';

function getCookie(cname: string) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

const AgreementTemplateService = {
  useAll: (payload: PayloadDocument) => {
    const {
      pageNumber,
      agreementType,
      pageSize,
      productId,
      entityType,
      sortColumn,
      sortDirection,
      name,
    } = payload;

    const filterParams = {
      pageNumber: pageNumber,
      pageSize,
      sortColumn,
      sortDirection,
      agreementType,
      productId,
      name,
      usedForEntity: entityType,
    };

    const fetchDocument = async () => {
      const fetch = await axios.post<PaginationResponse<DocumentsData>>(
        '/Document/page',
        filterParams,
      );
      return fetch.data;
    };

    const state = useQuery<PaginationResponse<DocumentsData>, Error>(
      ['DocumentPagination', payload],
      fetchDocument,
    );
    return state;
  },
  useDocumentTypes: () => {
    const user = useContext(UserContext);
    const fetchDocumentTypes = async () => {
      const fetch = await axios.get('/Document/DocumentType');
      return fetch.data;
    };
    const state = useQuery<DocumentDropdownTypes, Error>('documentType', fetchDocumentTypes, {
      retry: true,
      cacheTime: 900000,
      enabled: !!user,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity,
    });
    return state;
  },
  useAddTemplate: (
    onSuccess?:
      | ((
          data: TemplateDocumentResponse,
          variables: TemplateDocumentPayload,
          context: unknown,
        ) => void | Promise<unknown>)
      | undefined,
    onError?:
      | ((
          error: ApiError,
          variables: TemplateDocumentPayload,
          context: unknown,
        ) => void | Promise<unknown>)
      | undefined,
  ) => {
    const addDocument = async (obj: TemplateDocumentPayload) => {
      const fetch = await axios.post('/DocuSign/Templates/Upsertview', obj);
      return fetch?.data;
    };
    return useMutation<TemplateDocumentResponse, ApiError, TemplateDocumentPayload>(
      'templateAdd',
      addDocument,
      { onSuccess, onError },
    );
  },
  useEditTemplateOrgId: (
    onSuccess?: (
      data: unknown,
      variables: TemplateDocumentPayload,
      context: unknown,
    ) => void | Promise<unknown>,
    onError?: (
      error: ApiError,
      variables: TemplateDocumentPayload,
      context: unknown,
    ) => void | Promise<unknown>,
  ) => {
    const req = async (obj: TemplateDocumentPayload) => {
      const fetch = await axios.post('/DocuSign/Templates/Upsertview', obj);
      return fetch?.data;
    };
    return useMutation<unknown, ApiError, TemplateDocumentPayload>('templateEditOrgId', req, {
      onSuccess,
      onError,
    });
  },
  useEditTemplate: (
    onSuccess?:
      | ((
          data: string,
          variables: EditTemplateDocumentPayload,
          context: unknown,
        ) => void | Promise<unknown>)
      | undefined,
    onError?:
      | ((
          error: ApiError,
          variables: EditTemplateDocumentPayload,
          context: unknown,
        ) => void | Promise<unknown>)
      | undefined,
  ) => {
    const editTemplate = async (obj: any) => {
      const fetchDocument = await axios.post(`/DocuSign/Editview`, obj);
      return fetchDocument.data;
    };
    return useMutation<string, ApiError, EditTemplateDocumentPayload>(
      'editTemplate',
      editTemplate,
      {
        onSuccess,
        onError,
      },
    );
  },
  useViewTemplate: (onSuccess: any, onError: any) => {
    const viewTemplate = async (obj: any) => {
      const fetchDocument = await axios.get(`/Document/templateDocument/${obj?.documentId}`, {
        responseType: 'blob',
      });
      return fetchDocument.data;
    };
    return useMutation('viewTemplate', viewTemplate, { onSuccess, onError });
  },
  useDeleteTemplate: (
    onSuccess: (
      data: TemplateDocumentResponse,
      variables: DeleteDocPayload,
      context: unknown,
    ) => void | Promise<unknown>,
    onError: (
      error: ApiError,
      variables: DeleteDocPayload,
      context: unknown,
    ) => void | Promise<unknown>,
  ) => {
    const deleteTemplate = async (payload: DeleteDocPayload) => {
      const fetch = await axios.delete('/Document/DeleteDocument', {
        data: payload,
      });
      return fetch.data;
    };

    const state = useMutation<TemplateDocumentResponse, ApiError, DeleteDocPayload>(
      deleteTemplate,
      {
        mutationKey: ['delete-template'],
        onError,
        onSuccess,
      },
    );

    return state;
  },
  useDownload: () => {
    const downloadTemplate = async ({
      templateId,
    }: {
      templateId: string | number;
      fileName: string;
    }) => {
      const fetch = await axios.get('/Document/DownloadAgreementTemplate', {
        params: { templateId },
      });
      return fetch.data;
    };

    const state = useMutation<string, ApiError, { templateId: string | number; fileName: string }>(
      downloadTemplate,
      {
        mutationKey: ['download-template'],
        onSuccess(data, variables) {
          downloadBase64ToPdf(data, variables.fileName);
        },
      },
    );

    return state;
  },
  useUpdateTemplateDetails: () => {
    const updateTemplateDetails = async (envelopeId: string) => {
      const res = await axios.post(`/Document/update-template/${envelopeId}`, '', {
        headers: { Authorization: `Bearer ${getCookie('accessToken')}` },
      });
      return res.data;
    };
    return useMutation<unknown, ApiError, string>('editTemplate', updateTemplateDetails);
  },
};

export default AgreementTemplateService;
