import { TableCell, TableBody, Box, InputAdornment, ListItem, TableRow } from '@mui/material';
import PageHeaderContentHost from '../../../components/shared/AppPageHeaderContentHost';
import { useNavigate } from 'react-router-dom';
import { SearchIcon } from '../../../components/AppSearch/styles';
import {
  CustomSearch,
  ClearIconEndAdornment,
  SingleSearchBox,
} from '../../../components/shared/CustomTableSingleSearch/styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomTableHead from '../../../components/shared/CustomTableHead';
import { EntityType, Order } from '../../../types/query-params';
import { AlignCenterBox, FlexBox, SpaceBetweenBox } from '../../../components/shared/FlexBox';
import DataTable from '../../../components/table/DataTable';
import InfoIcon from '@mui/icons-material/Info';
import { useAppRolesByType } from '../../../services/sellerServices';
import RoleType from '../../../types/businessRoles';
import { BusinessRoleParams, SelectItem } from '../../../types';
import { AppAutocomplete } from '../../../components/shared/AppAutocomplete';
import { AppTextField } from '../../../components/shared/AppTextField';
import AppTooltip from '../../../components/shared/AppTooltip';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';

const RoleTypeName = {
  1: 'Seller/Seller Group Contact Classification',
  3: 'OnWise User Role',
  4: 'Agent Contact Classification',
  5: 'Provider Contact Classification',
};

export default function RoleTypeRole() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isEnabled = usePermissions(PermissionKeys.ViewConfiguration);
  const { data: rolesByType1 } = useAppRolesByType(RoleType.business, { enabled: isEnabled });
  const { data: rolesByType3 } = useAppRolesByType(RoleType.onWiseUser, { enabled: isEnabled });
  const { data: rolesByType4 } = useAppRolesByType(RoleType.agencyUser, { enabled: isEnabled });
  const { data: rolesByType5 } = useAppRolesByType(RoleType.businessWithOther, {
    enabled: isEnabled,
  });

  const tableHeadingsArray = useMemo(() => {
    return [
      { 'Role Type Name': 'roleTypeName' },
      { 'Role Name': 'roleName' },
      { Description: 'description' },
    ] as Record<string, string>[];
  }, []);

  const allRoles = useMemo(() => {
    if (rolesByType1 && rolesByType3 && rolesByType4 && rolesByType5) {
      return [
        ...rolesByType1.map((item) => ({ ...item, roleTypeName: RoleTypeName[1], roleTypeId: 1 })),
        ...rolesByType3.map((item) => ({ ...item, roleTypeName: RoleTypeName[3], roleTypeId: 3 })),
        ...rolesByType4.map((item) => ({ ...item, roleTypeName: RoleTypeName[4], roleTypeId: 4 })),
        ...rolesByType5.map((item) => ({ ...item, roleTypeName: RoleTypeName[5], roleTypeId: 5 })),
      ];
    }
    return [];
  }, [rolesByType1, rolesByType3, rolesByType4, rolesByType5]);

  const roleTypeOptions = [
    { label: EntityType.Any, value: EntityType.Any },
    ...Object.keys(RoleTypeName)
      .map((key) => {
        const val = RoleTypeName[key as unknown as keyof typeof RoleTypeName];
        return { label: val, value: key };
      })
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())),
  ];

  const [searchValue, setSearchValue] = useState('');
  const [order, setOrder] = useState(Order.ASC);
  const [orderBy, setOrderBy] = useState('roleTypeName');
  const [tableContainerRef, setTableContainerRef] = useState<HTMLDivElement | null>(null);
  const [roleType, setRoleType] = useState<SelectItem<number | string> | null>({
    label: EntityType.Any,
    value: EntityType.Any,
  });

  const onHandleSetOrder = useCallback(
    (newOrder: Order, newOrderBy: string): void => {
      setOrder(newOrder);
      setOrderBy(newOrderBy);
    },
    [setOrder, setOrderBy],
  );

  const sortedData = useMemo(() => {
    const filteredValues =
      searchValue.length > 2
        ? allRoles?.filter(
            (value) =>
              value.roleName.toLowerCase().includes(searchValue.toLowerCase()) ||
              value.roleTypeName.toLowerCase().includes(searchValue.toLowerCase()),
          )
        : allRoles;

    const sortedByFilter =
      roleType?.value && roleType?.value !== EntityType.Any
        ? filteredValues?.filter((value) => value.roleTypeId === +roleType?.value)
        : filteredValues;
    const sortedValues = sortedByFilter?.sort((a, b) => {
      if (order === Order.ASC) {
        return `${a[orderBy as keyof BusinessRoleParams]}`.localeCompare(
          `${b[orderBy as keyof BusinessRoleParams]}`,
        );
      }
      if (order === Order.DESC) {
        return `${b[orderBy as keyof BusinessRoleParams]}`.localeCompare(
          `${a[orderBy as keyof BusinessRoleParams]}`,
        );
      }
      return `${a[orderBy as keyof BusinessRoleParams]}`.localeCompare(
        `${b[orderBy as keyof BusinessRoleParams]}`,
      );
    });
    return sortedValues;
  }, [allRoles, order, orderBy, roleType, searchValue]);

  const handleFilter = (val: SelectItem<number | string> | null) => {
    setRoleType(val);
  };

  useEffect(() => {
    if (!isEnabled) {
      navigate('/NotFound', { state: { error: t('restrictions.tooltip') } });
    }
  }, [isEnabled, navigate, t]);

  return (
    <>
      <Box my={1}>
        <PageHeaderContentHost name="Role Type Role" />
      </Box>
      <AlignCenterBox mb={2}>
        <SpaceBetweenBox sx={{ width: '100%' }}>
          <SingleSearchBox>
            <CustomSearch
              sx={{ width: '300px' }}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              placeholder={'Search'}
              aria-describedby="outlined-weight-helper-text"
              size="small"
              InputProps={{
                'aria-label': 'weight',
                endAdornment: (
                  <InputAdornment position="end">
                    {searchValue && (
                      <ClearIconEndAdornment
                        onClick={() => {
                          setSearchValue('');
                        }}
                      />
                    )}
                    {!searchValue && <SearchIcon />}
                  </InputAdornment>
                ),
              }}
              error={searchValue.length < 3 && searchValue !== ''}
              helperText={
                searchValue.length < 3 && searchValue !== '' && t('appTextField.helperText')
              }
            />
            <AppTooltip title={'Enter Role Type Name or Role Name to search'} placement="bottom">
              <InfoIcon fontSize="inherit" color="disabled" />
            </AppTooltip>
          </SingleSearchBox>
          <AppAutocomplete
            sx={{ maxWidth: '350px' }}
            fullWidth
            isOptionEqualToValue={(option, value) => {
              return option.value === value.value;
            }}
            disablePortal
            options={roleTypeOptions}
            forcePopupIcon
            value={roleType}
            renderInput={(params) => (
              <AppTextField {...params} label={'Role Type'} fullWidth size="small" />
            )}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => {
              if (option.label) {
                return (
                  <ListItem {...props} key={JSON.stringify(option)}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: option.label,
                      }}
                    ></span>
                  </ListItem>
                );
              }
            }}
            onChange={(e, val) => {
              handleFilter(val);
            }}
          />
        </SpaceBetweenBox>
      </AlignCenterBox>
      <FlexBox
        className="MAINTABLE"
        sx={{ flex: '1 1 auto', flexDirection: 'column', height: '100%' }}
        ref={setTableContainerRef}
      >
        <DataTable tableMaxHeight={`${tableContainerRef?.getBoundingClientRect().height}px`}>
          <CustomTableHead
            heading={tableHeadingsArray}
            order={order}
            orderBy={orderBy}
            onRequestSort={onHandleSetOrder}
          />
          <TableBody>
            {sortedData?.map((item) => (
              <TableRow key={item.roleId}>
                <TableCell>{item.roleTypeName}</TableCell>
                <TableCell>{item.roleName}</TableCell>
                <TableCell>{item.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </DataTable>
      </FlexBox>
    </>
  );
}
