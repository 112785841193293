import { createContext, Dispatch, SetStateAction } from 'react';

export interface IQuickModalProps {
  isOpen: boolean;
  message: string;
  modalTitle: string;
  yesCallBack?: () => void;
  noCallBack?: () => void;
}

export interface IQuickModalPropsContext {
  quickModal: IQuickModalProps;
  setQuickModal: Dispatch<SetStateAction<IQuickModalProps>>;
}

export const QuickConfirmationModalContext = createContext<IQuickModalPropsContext>({
  quickModal: {
    isOpen: false,
    message: '',
    modalTitle: '',
  },
  setQuickModal: () => {},
});
