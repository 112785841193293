import React from 'react';
import ProtectedRoute from '../auth0/auth';
import MainLayout from '../layout/MainLayout';
import ProductLineList from '../pages/ProductLineList';
import Dashboard from '../pages/Dashboard';
import { ProtectedAgreementTemplateList } from '../pages/AgreementTemplateList';
import Terms from '../components/Terms';
import AddProductLinePage from '../pages/AddProductLine';
import { ProtectedProductLinePage } from '../pages/ViewProduct';
import ViewUserProfile from '../pages/ViewProfile';
import ChangePassword from '../pages/ChangePassword';
import ViewGeneratedAgreement from '../components/generatedAgreement';
import UserList from '../pages/UserList';
import AgentsList from '../pages/AgentsList';
import SellerGroups from '../pages/SellerGroupList';
import { ProtectedSellerGroupPage } from '../pages/ViewSellerGroup';
import AddOrganizationContact from '../pages/AddOrganizationContact';
import { RouteObject } from 'react-router-dom';
import { SellerGroupInfoTab } from '../components/SellerGroupTabs/InfoTab';
import { OrganizationContactsTab } from '../pages/ContactsTab';
import { ProductLinesTab } from '../components/SellerGroupTabs/ProductLinesTab';
import { AgreementsTab } from '../components/SellerGroupTabs/AgreementsTab';
import { AddProductLinesProtected } from '../components/SellerGroupTabs/ProductLinesTab/AddProductLines';
import { ProtectedAgentPage } from '../pages/ViewAgent';
import { AgentInfoTab } from '../pages/AgencyInfo/InfoTab';
import ReadOnlyAgreement from '../pages/ReadOnlyAgreement';
import { AddRolePage } from '../pages/AddRole';
import { UserPage } from '../pages/User';
import { AddUser } from '../pages/AddUser';
import { RoleListProtected } from '../pages/RoleList';
import { ProtectedSellerPage } from '../pages/SellerInfo';
import { RolePage } from '../pages/Role';
import { ProtectedOrganizationContactInfoPage } from '../pages/OrganizationContactInfoPage';
import { AddProductLinesSellerProtected } from '../components/SellerTabs/ProductLines/addProductLine';
import { AddFinishSeller } from '../pages/AddFinishSeller';
import { SellerList } from '../pages/SellerList';
import { AddFinishSellerGroup } from '../pages/AddFinishSellerGroup';
import { SellerProductLinesTab } from '../components/SellerTabs/ProductLines';
import { SellerAgreementsTab } from '../components/SellerTabs/Agreements';
import { SellerBusinessInfo } from '../components/SellerTabs/BusinessInfo';
import { DealershipInfoTab } from '../components/SellerTabs/DealershipInfo';
import { SellersTab } from '../components/SellerGroupTabs/Sellers';
import LegalOrganizationPage from '../pages/ConfigurationPages/LegalOrganization';
import DMSProviderPage from '../pages/ConfigurationPages/DMSProvider';
import AverageTotalInventoryRangePage from '../pages/ConfigurationPages/AverageTotalInventory';
import FIMenuPage from '../pages/ConfigurationPages/FIMenu';
import RoleTypeRole from '../pages/ConfigurationPages/RoleTypeRole';
import DealerTypeGroups from '../pages/ConfigurationPages/DealerTypeGroups';
import AssetMakes from '../pages/ConfigurationPages/AssetMakes';
import ConfigurationPages from '../pages/ConfigurationPages';
import SystemAdminPage from '../pages/SystemAdmin';
import { TermsOfUse } from '../pages/ConfigurationPages/TermsOfUse';
import ProtectedGateResolutionPage from '../pages/OrgGateResolution';
import TenantSetupPage from '../pages/ConfigurationPages/TenantSetup';

const MainRoutes: RouteObject = {
  path: '/dashboard',
  element: <ProtectedRoute component={MainLayout} />,
  children: [
    {
      path: '/dashboard',
      element: <ProtectedRoute component={Dashboard} />,
    },
    // AGENT
    {
      path: '/dashboard/Agent',
      element: <ProtectedRoute component={AgentsList} />,
    },
    {
      path: '/dashboard/Agent/:id',
      element: <ProtectedRoute component={ProtectedAgentPage} />,
      children: [
        {
          path: '',
          element: <ProtectedRoute component={AgentInfoTab} />,
        },
        {
          path: '/dashboard/Agent/:id/Contact',
          element: <ProtectedRoute component={OrganizationContactsTab} />,
        },
      ],
    },
    // SELLER
    {
      path: '/dashboard/Seller',
      element: <ProtectedRoute component={SellerList} />,
    },
    {
      path: '/dashboard/Seller/Add',
      element: <ProtectedRoute component={AddFinishSeller} />,
    },
    {
      path: '/dashboard/Seller/:sellerId/Finish',
      element: <ProtectedRoute component={AddFinishSeller} />,
    },
    {
      path: '/dashboard/Seller/:id/AddProductLine',
      element: <ProtectedRoute component={AddProductLinesSellerProtected} />,
    },
    {
      path: '/dashboard/Seller/:id',
      element: <ProtectedRoute component={ProtectedSellerPage} />,
      children: [
        {
          path: '/dashboard/Seller/:id',
          element: <ProtectedRoute component={SellerBusinessInfo} />,
        },
        {
          path: '/dashboard/Seller/:id/DealershipInformation',
          element: <ProtectedRoute component={DealershipInfoTab} />,
        },
        {
          path: '/dashboard/Seller/:id/Contact',
          element: <ProtectedRoute component={OrganizationContactsTab} />,
        },
        {
          path: '/dashboard/Seller/:id/ProductLine',
          element: <ProtectedRoute component={SellerProductLinesTab} />,
        },
        {
          path: '/dashboard/Seller/:id/Agreement',
          element: <ProtectedRoute component={SellerAgreementsTab} />,
        },
        // MAG pages
        {
          path: '/dashboard/Seller/:id/Agreement/Review',
          element: <ProtectedRoute component={ProtectedGateResolutionPage} />,
        },
      ],
    },
    // SELLERGROUP
    {
      path: '/dashboard/SellerGroup',
      element: <ProtectedRoute component={SellerGroups} />,
    },
    {
      path: '/dashboard/SellerGroup/Add',
      element: <ProtectedRoute component={AddFinishSellerGroup} />,
    },
    {
      path: '/dashboard/SellerGroup/:sellerGroupId/Finish',
      element: <ProtectedRoute component={AddFinishSellerGroup} />,
    },
    {
      path: '/dashboard/SellerGroup/:id/AddProductLine',
      element: <ProtectedRoute component={AddProductLinesProtected} />,
    },
    {
      path: '/dashboard/SellerGroup/:id',
      element: <ProtectedRoute component={ProtectedSellerGroupPage} />,
      children: [
        {
          path: '/dashboard/SellerGroup/:id',
          element: <ProtectedRoute component={SellerGroupInfoTab} />,
        },
        {
          path: '/dashboard/SellerGroup/:id/Seller',
          element: <ProtectedRoute component={SellersTab} />,
        },
        {
          path: '/dashboard/SellerGroup/:id/Contact',
          element: <ProtectedRoute component={OrganizationContactsTab} />,
        },
        {
          path: '/dashboard/SellerGroup/:id/ProductLine',
          element: <ProtectedRoute component={ProductLinesTab} />,
        },
        {
          path: '/dashboard/SellerGroup/:id/Agreement',
          element: <ProtectedRoute component={AgreementsTab} />,
        },
        // MAG pages
        {
          path: '/dashboard/SellerGroup/:id/Agreement/Review',
          element: <ProtectedRoute component={ProtectedGateResolutionPage} />,
        },
      ],
    },
    // PRODUCT LINE
    {
      path: '/dashboard/ProductLine',
      element: <ProtectedRoute component={ProductLineList} />,
    },
    {
      path: '/dashboard/ProductLine/Add',
      element: <ProtectedRoute component={AddProductLinePage} />,
    },
    {
      path: '/dashboard/ProductLine/:id',
      element: <ProtectedRoute component={ProtectedProductLinePage} />,
    },
    // AGREEMENT TEMPLATE
    {
      path: '/dashboard/AgreementTemplate',
      element: <ProtectedRoute component={ProtectedAgreementTemplateList} />,
    },
    // USER
    {
      path: '/dashboard/User',
      element: <ProtectedRoute component={UserList} />,
    },
    {
      path: '/dashboard/User/Add',
      element: <ProtectedRoute component={AddUser} />,
    },
    {
      path: '/dashboard/User/:userId',
      element: <ProtectedRoute component={UserPage} />,
    },
    // ROLE
    {
      path: '/dashboard/Role',
      element: <ProtectedRoute component={RoleListProtected} />,
    },
    {
      path: '/dashboard/Role/Add',
      element: <ProtectedRoute component={AddRolePage} />,
    },
    {
      path: '/dashboard/Role/:roleId',
      element: <ProtectedRoute component={RolePage} />,
    },
    // CONTACT
    {
      path: '/dashboard/:orgName/:id/AddContact',
      element: <ProtectedRoute component={AddOrganizationContact} />,
    },
    {
      path: '/dashboard/:orgName/:id/Contact/:contactId',
      element: <ProtectedRoute component={ProtectedOrganizationContactInfoPage} />,
    },
    // TERMS
    {
      path: '/dashboard/terms',
      element: <ProtectedRoute component={Terms} />,
    },
    // MY PROFILE
    {
      path: '/dashboard/MyProfile',
      element: <ProtectedRoute component={ViewUserProfile} />,
    },
    {
      path: '/dashboard/change-password',
      element: <ProtectedRoute component={ChangePassword} />,
    },
    // Configuration
    {
      path: '/dashboard/Configuration',
      element: <ProtectedRoute component={ConfigurationPages} />,
    },
    {
      path: '/dashboard/Configuration/LegalOrganization',
      element: <ProtectedRoute component={LegalOrganizationPage} />,
    },
    {
      path: '/dashboard/Configuration/DMSProvider',
      element: <ProtectedRoute component={DMSProviderPage} />,
    },
    {
      path: '/dashboard/Configuration/AverageTotalInventory',
      element: <ProtectedRoute component={AverageTotalInventoryRangePage} />,
    },
    {
      path: '/dashboard/Configuration/FIMenu',
      element: <ProtectedRoute component={FIMenuPage} />,
    },
    {
      path: '/dashboard/Configuration/RoleTypeRole',
      element: <ProtectedRoute component={RoleTypeRole} />,
    },
    {
      path: '/dashboard/Configuration/DealerGroupType',
      element: <ProtectedRoute component={DealerTypeGroups} />,
    },
    {
      path: '/dashboard/Configuration/DealerGroupType/:dealerGroupTypeId/AssetMake',
      element: <ProtectedRoute component={AssetMakes} />,
    },
    {
      path: '/dashboard/Configuration/TermsOfUse',
      element: <ProtectedRoute component={TermsOfUse} />,
    },
    {
      path: '/dashboard/Configuration/TenantSetup',
      element: <ProtectedRoute component={TenantSetupPage} />,
    },
    {
      path: '/dashboard/SystemAdmin',
      element: <ProtectedRoute component={SystemAdminPage} />,
    },
    // Agreement
    {
      path: '/dashboard/generated-agreement/:id',
      element: <ProtectedRoute component={ViewGeneratedAgreement} />,
    },

    {
      path: '/dashboard/ReadOnlyAgreement',
      element: <ProtectedRoute component={ReadOnlyAgreement} />,
    },
  ],
};

export default MainRoutes;
