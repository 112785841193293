import React from 'react';

export default function RefreshIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        id="refreshing"
        d="M511.93,1285.677l-2.475-6.293a1.436,1.436,0,0,0-2.5-.228l-1.333,1.969a11.621,11.621,0,0,0-15.762,3.671,11.507,11.507,0,0,0,3.544,15.924,11.6,11.6,0,0,0,15.243-2.5,1.358,1.358,0,0,0-.21-1.914l-.095-.069a1.365,1.365,0,0,0-1.834.275,8.886,8.886,0,1,1-2.421-13.132l-1.471,2.172a1.424,1.424,0,0,0,.394,1.979,1.464,1.464,0,0,0,.78.243l6.774-.117a1.5,1.5,0,0,0,1.026-.381,1.416,1.416,0,0,0,.453-.994A1.388,1.388,0,0,0,511.93,1285.677Zm-3.868,10.981Z"
        transform="translate(-488.044 -1278.53)"
        fill="#f90"
      />
    </svg>
  );
}
