export default function ViewIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 32 32" width="24">
      <circle cx="22" cy="24" r="2" />
      <path
        d="m22 28a4 4 0 1 1 4-4 4.0039 4.0039 0 0 1 -4 4zm0-6a2 2 0 1 0 2 2 2.0027 2.0027 0 0 0 -2-2z"
        fill="none"
      />
      <path d="m29.7769 23.4785a8.64 8.64 0 0 0 -7.7769-5.4785 8.64 8.64 0 0 0 -7.7769 5.4785l-.2231.5215.2231.5215a8.64 8.64 0 0 0 7.7769 5.4785 8.64 8.64 0 0 0 7.7769-5.4785l.2231-.5215zm-7.7769 4.5215a4 4 0 1 1 4-4 4.0045 4.0045 0 0 1 -4 4z" />
      <path d="m12 28h-4v-24h8v6a2.0058 2.0058 0 0 0 2 2h6v4h2v-6a.9092.9092 0 0 0 -.3-.7l-7-7a.9087.9087 0 0 0 -.7-.3h-10a2.0058 2.0058 0 0 0 -2 2v24a2.0058 2.0058 0 0 0 2 2h4zm6-23.6 5.6 5.6h-5.6z" />
      <path d="m0 0h32v32h-32z" fill="none" />
    </svg>
  );
}
