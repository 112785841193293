import { Box } from '@mui/material';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { StyledLink, TabTitle } from './styles';
import { AddNewLink } from '../../../types/customTable';
import { useTranslation } from 'react-i18next';
import { AppActionButton } from '../AppActionButton';
import AppTooltip from '../AppTooltip';

export default function AddNewButton({
  tabTitle,
  addNewLink,
}: {
  tabTitle: string;
  addNewLink: AddNewLink;
}) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TabTitle mr={3}>{`${tabTitle}`}</TabTitle>
        {addNewLink.link && (
          <AppTooltip
            title={
              addNewLink && addNewLink.link && addNewLink.state === 'active'
                ? null
                : t('customtable.button.tooltip.title')
            }
          >
            <StyledLink to={addNewLink.link} state={addNewLink.state}>
              <AppActionButton
                variant="outlined"
                startIcon={<AddCircleOutlineRoundedIcon />}
                disabled={addNewLink && addNewLink.state === 'disabled'}
                id={'add-entity-button'}
              >
                Add
              </AppActionButton>
            </StyledLink>
          </AppTooltip>
        )}
      </Box>
    </Box>
  );
}
