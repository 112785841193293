import { useMemo } from 'react';
import { trimInputValue } from '../utils/trimInputValue';

function useTrim(value = '') {
  return useMemo(() => {
    return trimInputValue(value);
  }, [value]);
}

export default useTrim;
