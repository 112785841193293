import { Box, TableRow, TableCell, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOfacDetails } from '../../../services/sellerServices';
import { Ids, OfacItem } from '../../../types';
import {
  ModalContainer,
  ModalTitleContainer,
  OfacTitle,
  BackContainer,
  StyledCancelRoundedIcon,
  StyledOfac,
  StyledScrollBox,
  EntityLabel,
  NoDataText,
  HeaderTypography,
  StyledOfacBox,
  LabelTypography,
  StyledOfacIdentificationBox,
  ModalFlexBox,
} from './styles';
import { FlexBox } from '../../shared/FlexBox';
import CustomTableFixedHeight from '../../table/CustomTableFixedHeight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { linkColor } from '../../../Theme/colorsVariables';

interface OfacProps {
  isOpen: boolean;
  agreementId: number | null;
  onClose: () => void;
}

export default function OfacModal({ isOpen, agreementId, onClose }: OfacProps) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));
  const { t } = useTranslation();
  const { data: OfacDetail, isFetching } = useOfacDetails({
    agreementId,
    options: { enabled: !!agreementId },
  });
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [entityDetail, setEntityDetail] = useState<OfacItem | null>(null);
  const tableHeadingsArray: string[] = t('relationship.addSeller.agreement.ofac', {
    returnObjects: true,
  });

  const idHeadingsArray: string[] = t('relationship.addSeller.agreement.entity.identity', {
    returnObjects: true,
  });

  const addressHeadingsArray: string[] = t('relationship.addSeller.agreement.entity.address', {
    returnObjects: true,
  });

  const handleOpenDetail = (item: OfacItem) => {
    setEntityDetail(item);
    setOpenDetail(!openDetail);
  };

  const handleCloseDetail = () => {
    setEntityDetail(null);
    setOpenDetail(!openDetail);
  };

  if (isFetching) {
    return <></>;
  }

  return (
    <ModalFlexBox open={isOpen} onClose={onClose}>
      <ModalContainer>
        <ModalTitleContainer>
          <OfacTitle>{t<string>('relationship.addSeller.agreement.ofacTitle')}</OfacTitle>
          <FlexBox>
            {openDetail ? (
              <BackContainer onClick={() => handleCloseDetail()}>
                <ArrowCircleLeftIcon />
              </BackContainer>
            ) : (
              ''
            )}
            <StyledCancelRoundedIcon onClick={onClose} />
          </FlexBox>
        </ModalTitleContainer>
        <Box>
          <StyledOfac>
            {!openDetail ? (
              <StyledScrollBox>
                <CustomTableFixedHeight tableHeadingsArray={tableHeadingsArray}>
                  <>
                    {OfacDetail?.length ? (
                      OfacDetail.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell>
                            <EntityLabel onClick={() => handleOpenDetail(item)}>
                              {item.id}
                            </EntityLabel>
                          </TableCell>
                          <TableCell
                            sx={
                              item.addresses.length > 1
                                ? {
                                    color: `${linkColor} !important`,
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                  }
                                : {}
                            }
                            onClick={() => {
                              if (item.addresses.length > 1) {
                                handleOpenDetail(item);
                              }
                            }}
                          >
                            {item.addresses.length > 1
                              ? `${item.addresses[0].address} and ${
                                  item.addresses.length - 1
                                } more...`
                              : item.addresses.length === 1
                              ? item.addresses[0].address
                              : '-'}
                          </TableCell>
                          <TableCell>{item.type ? item.type : '-'}</TableCell>
                          <TableCell
                            sx={
                              item.programs.length > 1
                                ? {
                                    color: `${linkColor} !important`,
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                  }
                                : {}
                            }
                            onClick={() => {
                              if (item.programs.length > 1) {
                                handleOpenDetail(item);
                              }
                            }}
                          >
                            {item.programs.length > 1
                              ? `${item.programs[0]} and ${item.programs.length - 1} more...`
                              : item.programs.length === 1
                              ? item.programs[0]
                              : '-'}
                          </TableCell>
                          <TableCell>{item.list ? item.list : '-'}</TableCell>
                          <TableCell>{item.score}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <NoDataText>{t('relationship.addSeller.agreement.noData')}</NoDataText>
                    )}
                  </>
                </CustomTableFixedHeight>
              </StyledScrollBox>
            ) : (
              <Box p={1}>
                <HeaderTypography>{t('relationship.addSeller.agreement.detail')}</HeaderTypography>
                <StyledOfacBox>
                  <Grid container spacing={isSmall ? 1 : 2} p={1}>
                    <Grid item xs={6} md={6}>
                      <Grid container spacing={isSmall ? 1 : 2}>
                        <Grid item xs={4} md={4}>
                          <HeaderTypography>
                            {t('relationship.addSeller.agreement.type')}
                          </HeaderTypography>
                        </Grid>
                        <Grid item xs={8} md={8}>
                          <LabelTypography>{entityDetail?.type}</LabelTypography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Grid container spacing={isSmall ? 1 : 2}>
                        <Grid item xs={4} md={4}>
                          <HeaderTypography>
                            {t('relationship.addSeller.agreement.list')}
                          </HeaderTypography>
                        </Grid>
                        <Grid item xs={8} md={8}>
                          <LabelTypography>{entityDetail?.list}</LabelTypography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Grid container spacing={isSmall ? 1 : 2}>
                        <Grid item xs={4} md={4}>
                          <HeaderTypography>
                            {t('relationship.addSeller.agreement.entityName')}
                          </HeaderTypography>
                        </Grid>
                        <Grid item xs={8} md={8}>
                          <LabelTypography>{entityDetail?.name}</LabelTypography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Grid container spacing={isSmall ? 1 : 2}>
                        <Grid item xs={4} md={4}>
                          <HeaderTypography>
                            {t('relationship.addSeller.agreement.programRemark')}
                          </HeaderTypography>
                        </Grid>
                        <Grid item xs={8} md={8}>
                          <LabelTypography>{entityDetail?.programs.join(', ')}</LabelTypography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </StyledOfacBox>
                <HeaderTypography>
                  {t('relationship.addSeller.agreement.identification')}
                </HeaderTypography>
                <StyledOfacIdentificationBox>
                  <CustomTableFixedHeight tableHeadingsArray={idHeadingsArray}>
                    <>
                      {entityDetail?.ids && entityDetail?.ids?.length > 0 ? (
                        entityDetail?.ids.map((item: Ids, index: number) => {
                          return (
                            <TableRow key={'id-' + index}>
                              <TableCell align="left">{item.type ? item.type : '-'}</TableCell>
                              <TableCell>{item.number ? item.number : '-'}</TableCell>
                              <TableCell>{item.country ? item.country : '-'}</TableCell>
                              <TableCell>{item.issue_date ? item.issue_date : '-'}</TableCell>
                              <TableCell>
                                {item.expiration_date ? item.expiration_date : '-'}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell>
                            <NoDataText>{t('relationship.addSeller.agreement.noData')}</NoDataText>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      )}
                    </>
                  </CustomTableFixedHeight>
                </StyledOfacIdentificationBox>
                <HeaderTypography>{t('relationship.addSeller.agreement.address')}</HeaderTypography>
                <StyledOfacIdentificationBox>
                  <CustomTableFixedHeight tableHeadingsArray={addressHeadingsArray}>
                    <>
                      {entityDetail?.addresses && entityDetail?.addresses?.length > 0 ? (
                        entityDetail.addresses.map((item, index) => {
                          return (
                            <TableRow key={'address-' + index}>
                              <TableCell align="left">
                                {item.address ? item.address : '-'}
                              </TableCell>
                              <TableCell>{item.city ? item.city : '-'}</TableCell>
                              <TableCell>{item.state ? item.state : '-'}</TableCell>
                              <TableCell>{item.postal_code ? item.postal_code : '-'}</TableCell>
                              <TableCell>{item.country ? item.country : '-'}</TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell>
                            <NoDataText>{t('relationship.addSeller.agreement.noData')}</NoDataText>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      )}
                    </>
                  </CustomTableFixedHeight>
                </StyledOfacIdentificationBox>
              </Box>
            )}
          </StyledOfac>
        </Box>
      </ModalContainer>
    </ModalFlexBox>
  );
}
