import { Box } from '@mui/material';
import styled from 'styled-components';
import { LightSlateGray, ThirdBackground } from '../../Theme/colorsVariables';
import { breakPointMedium } from '../../utils/MediaBreakPoint';

const FooterStyle = styled(Box)`
  font-family: Gotham-Medium;
  font-size: 14px;
  color: ${LightSlateGray};
  text-align: center;
  font-weight: normal;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${ThirdBackground};
  width: 100%;
  ${breakPointMedium('font-size: 8px; bottom: -8px;')}
`;

export default FooterStyle;
