import {
  Typography,
  Card,
  CardActionArea,
  CardContent,
  Box,
  FormHelperText,
  Radio,
} from '@mui/material';
import {
  Address,
  Frame,
  GoogleValidationResponse,
  ValidateAddressReturnType,
} from '../../../../types/googleAddressValidation';
import {
  getGoogleAddressFromGooglesAddressComponents,
  getMissingTypeErrors,
} from '../../../../utils/getGoogleAddressFromGooglesAddressComponents';
import CustomModal from '../../../modal/Modal';
import { AppActionButton } from '../../../shared/AppActionButton';
import { AppTextField } from '../../../shared/AppTextField';
import { AlignCenterBox, CenterCenterBox } from '../../../shared/FlexBox';
import { OrganizationFormType } from '../types';
import { useEffect, useState } from 'react';
import { RenderTextDiff } from './textHighlighter';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

export const useValidateAddress = () => {
  const validateAddress = async ({
    data,
    addressType,
  }: {
    data: Pick<OrganizationFormType, 'physicalLocation' | 'mailingLocation'>;
    addressType: 'physicalLocation' | 'mailingLocation';
  }): Promise<ValidateAddressReturnType> => {
    const response = await fetch(
      `https://addressvalidation.googleapis.com/v1:validateAddress?key=${process.env.REACT_APP_APP_GOOGLE_PLACES_API_KEY}`,
      {
        method: 'POST',
        body: JSON.stringify({
          address: {
            addressLines: data[addressType].address2
              ? [data[addressType].address1, data[addressType].address2]
              : [data[addressType].address1],
            locality: data[addressType].city,
            administrativeArea: data[addressType].state?.value?.stateCode,
            postalCode: data[addressType].zip,
            regionCode: 'US',
          },
          enableUspsCass: true,
        }),
      },
    );

    const validationResponse: GoogleValidationResponse = await response.json();
    const enteredAddress = [
      data[addressType].address1.trim() +
        (data[addressType].address2?.trim() ? `, ${data[addressType].address2?.trim()}` : '') +
        ' \n ' +
        data[addressType].city.trim(),
      data[addressType].state?.value?.stateCode.trim() + ' ' + data[addressType].zip.trim(),
      'USA',
    ].join(', ');
    const formattedAddress = getGoogleAddressFromGooglesAddressComponents(
      validationResponse.result.address.addressComponents,
    );
    const suggestedAddress = [
      formattedAddress.streetOne.trim() +
        (formattedAddress.streetTwo ? `, ${formattedAddress.streetTwo}` : '') +
        ' \n ' +
        formattedAddress.locality,
      formattedAddress.administrativeArea +
        ' ' +
        validationResponse.result.address.postalAddress.postalCode,
      'USA',
    ].join(', ');

    // perfect as is
    if (
      validationResponse?.result?.verdict?.addressComplete === true &&
      !validationResponse?.result?.verdict?.hasInferredComponents &&
      (validationResponse?.result?.verdict?.validationGranularity === 'SUB_PREMISE' ||
        validationResponse?.result?.verdict?.validationGranularity === 'PREMISE') &&
      (validationResponse?.result?.verdict?.inputGranularity === 'SUB_PREMISE' ||
        validationResponse?.result?.verdict?.inputGranularity === 'PREMISE') &&
      !validationResponse?.result?.address?.missingComponentTypes &&
      enteredAddress === suggestedAddress
    ) {
      return {
        verdict: 'ADDRESS_COMPLETE',
        suggestedAddress,
        enteredAddress,
        missingComponents: {},
        validationResponse,
      };
    } else if (
      // google suggested changes
      enteredAddress !== suggestedAddress &&
      !validationResponse.result.verdict.hasUnconfirmedComponents
    ) {
      return {
        verdict: 'CHOOSE_VALID_ADDRESS',
        suggestedAddress,
        enteredAddress,
        missingComponents: {},
        validationResponse,
      };
    } else if (
      validationResponse?.result?.address?.missingComponentTypes &&
      validationResponse?.result?.address?.missingComponentTypes?.length > 0 &&
      !validationResponse.result.address.unconfirmedComponentTypes
    ) {
      const missingData = getMissingTypeErrors(
        validationResponse.result.address.missingComponentTypes,
      );

      return {
        verdict: 'MISSING_INFORMATION',
        missingComponents: missingData,
        suggestedAddress,
        enteredAddress,
        validationResponse,
      };
    } else {
      const missingData = getMissingTypeErrors(
        validationResponse?.result?.address?.missingComponentTypes || [],
      );
      const unconfirmedData = getMissingTypeErrors(
        validationResponse?.result?.address?.unconfirmedComponentTypes || [],
      );
      // could not validate

      return {
        verdict: 'COULDNT_VALIDATE',
        suggestedAddress,
        enteredAddress,
        missingComponents: { ...missingData, ...unconfirmedData },
        validationResponse,
      };
    }
  };
  return [validateAddress];
};

export type AddressValidationModalProps = {
  verdict: Frame;
  enteredAddress: string;
  suggestedAddress: string;
  missingComponents: Partial<
    Omit<Address, 'regionCode'> & {
      regionCode: string;
    }
  >;
  addressType: 'physicalLocation' | 'mailingLocation';
  onCancel: () => void;
  onSubmit: (data?: {
    selectedAddress?: 'entered' | 'suggested';
    missingData?: {
      address1: string;
      apt: string;
    };
  }) => void;
};
export const AddressValidationModals = ({
  verdict,
  enteredAddress,
  suggestedAddress,
  addressType,
  missingComponents,
  onCancel,
  onSubmit,
}: AddressValidationModalProps) => {
  const [selectedAddress, setSelectedAddress] = useState<'entered' | 'suggested'>('suggested');
  const [address1, setAddress1] = useState('');
  const [apt, setApt] = useState('');

  useEffect(() => {
    const adress1 = enteredAddress.slice(0, enteredAddress.indexOf(','));
    setAddress1(adress1.slice(0, enteredAddress.indexOf('\n')));
  }, [enteredAddress]);

  return (
    <>
      <CustomModal
        open={verdict === 'CHOOSE_VALID_ADDRESS'}
        containerWidth="700px"
        modalTitle={`Confirm your ${
          addressType === 'physicalLocation' ? 'Physical Address' : 'Mailing Address'
        }`}
        modalProps={{ onClose: onCancel }}
      >
        <Box width={'100%'} style={{ whiteSpace: 'pre-line' }}>
          <Typography variant="h6">Review the verification issues</Typography>
          <Card sx={{ width: '100%', marginTop: '10px' }} raised={selectedAddress === 'entered'}>
            <CardActionArea
              onClick={() => {
                setSelectedAddress('entered');
              }}
            >
              <CardContent>
                <AlignCenterBox>
                  <Radio
                    checked={selectedAddress === 'entered'}
                    sx={{
                      '&.Mui-checked': {
                        color: 'green',
                      },
                    }}
                  />
                  <Box>
                    <Typography variant="h6">What you entered</Typography>

                    <Typography style={{ whiteSpace: 'pre-line' }}>{enteredAddress}</Typography>
                  </Box>
                </AlignCenterBox>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card
            sx={{
              width: '100%',
              marginTop: '10px',
            }}
            raised={selectedAddress === 'suggested'}
          >
            <CardActionArea
              onClick={() => {
                setSelectedAddress('suggested');
              }}
            >
              <CardContent>
                <AlignCenterBox>
                  <Radio
                    checked={selectedAddress === 'suggested'}
                    sx={{
                      '&.Mui-checked': {
                        color: 'green',
                      },
                    }}
                  />
                  <Box>
                    <Typography variant="h6">Recommended</Typography>

                    <Typography>
                      {RenderTextDiff(enteredAddress, suggestedAddress).resultB}
                    </Typography>
                  </Box>
                </AlignCenterBox>
              </CardContent>
            </CardActionArea>
          </Card>

          <CenterCenterBox mt={2}>
            <AppActionButton
              variant="outlined"
              onClick={() => {
                onSubmit({ selectedAddress });
                setSelectedAddress('suggested');
              }}
            >
              Confirm
            </AppActionButton>
          </CenterCenterBox>
        </Box>
      </CustomModal>

      <CustomModal
        open={verdict === 'MISSING_INFORMATION'}
        containerWidth="700px"
        modalTitle={`Missing Information for ${
          addressType === 'physicalLocation' ? 'Physical Address' : 'Mailing Address'
        }`}
        modalProps={{ onClose: onCancel }}
      >
        <Box width={'100%'}>
          <Typography variant="h6">What you entered</Typography>

          <Card sx={{ width: '100%' }}>
            <CardActionArea>
              <CardContent>
                <Typography style={{ whiteSpace: 'pre-line' }}>{enteredAddress}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Box my={1}></Box>
          {missingComponents.streetOne && (
            <>
              <Typography variant="h6" mb={1}>
                Missing or incomplete information
              </Typography>
              <AppTextField
                variant="outlined"
                label={'Street Address 1'}
                focused
                rounded="true"
                value={address1}
                onChange={(e) => {
                  setAddress1(e.target.value);
                }}
              />
              <FormHelperText>
                Leave blank if you want to keep current changes and click Continue.
              </FormHelperText>
            </>
          )}
          {missingComponents.streetTwo && (
            <>
              <Typography variant="h6" mb={1}>
                Missing or incomplete information
              </Typography>
              <AppTextField
                variant="outlined"
                label={'Street Address 2'}
                focused
                rounded="true"
                value={apt}
                onChange={(e) => {
                  setApt(e.target.value);
                }}
              />
              <FormHelperText>
                Leave blank if you want to keep current changes and click Continue.
              </FormHelperText>
            </>
          )}

          <CenterCenterBox mt={2}>
            <AppActionButton
              variant="outlined"
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </AppActionButton>
            <AppActionButton
              variant="outlined"
              onClick={() => {
                onSubmit({
                  missingData: {
                    apt,
                    address1,
                  },
                });
                setApt('');
                setAddress1('');
              }}
            >
              Continue
            </AppActionButton>
          </CenterCenterBox>
        </Box>
      </CustomModal>

      <CustomModal
        open={verdict === 'COULDNT_VALIDATE'}
        containerWidth="700px"
        modalTitle={`We couldn't verify ${
          addressType === 'physicalLocation' ? 'Physical Address' : 'Mailing Address'
        }`}
        modalProps={{ onClose: onCancel }}
      >
        <Box width={'100%'}>
          <Typography variant="h6">Review the verification issues</Typography>
          <Card sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}>
            <CardActionArea>
              <CardContent>
                <AlignCenterBox>
                  <Box>
                    <Typography variant="h6">What you entered</Typography>

                    <Typography style={{ whiteSpace: 'pre-line' }}>{enteredAddress}</Typography>
                  </Box>
                </AlignCenterBox>
              </CardContent>
            </CardActionArea>
          </Card>

          <Typography variant="h6">Verification issues</Typography>

          {Object.keys(missingComponents)
            .filter((item) => item !== 'administrativeArea')
            .map((missingComponentKey) => {
              const missingComponentValue =
                missingComponents[missingComponentKey as keyof typeof missingComponents];
              const missingFieldNames = {
                streetOne: 'Street Address 1',
                streetTwo: 'Apt, Suite or Unit ...',
                locality: 'City', // city
                administrativeArea: 'State', // state
                regionCode: 'State',
                postalCode: 'ZIP',
              };
              return (
                <Card
                  sx={{
                    width: '100%',
                    marginTop: '10px',
                    background: '#fff0e6',
                  }}
                  key={missingComponentKey}
                >
                  <CardActionArea>
                    <CardContent>
                      <AlignCenterBox>
                        <WarningAmberRoundedIcon color="warning" />
                        <Box ml={2}>
                          <Typography variant="h6">
                            {
                              missingFieldNames[
                                missingComponentKey as keyof typeof missingFieldNames
                              ]
                            }
                          </Typography>

                          <Typography>{missingComponentValue}</Typography>
                        </Box>
                      </AlignCenterBox>
                    </CardContent>
                  </CardActionArea>
                </Card>
              );
            })}

          <CenterCenterBox mt={2}>
            <AppActionButton
              variant="outlined"
              onClick={() => {
                onSubmit({ selectedAddress: 'entered' });
                setSelectedAddress('suggested');
              }}
            >
              Use Entered
            </AppActionButton>
            <AppActionButton
              variant="outlined"
              onClick={() => {
                onCancel();
                setSelectedAddress('suggested');
              }}
            >
              Make Changes
            </AppActionButton>
          </CenterCenterBox>
        </Box>
      </CustomModal>
    </>
  );
};
