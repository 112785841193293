import { Box, Button, Container, Typography } from '@mui/material';
import styled from 'styled-components';
import { AlignCenterBox } from '../../components/shared/FlexBox';
import { LightOrange, WhiteSmoke } from '../../Theme/colorsVariables';

export const StyledBox = styled(AlignCenterBox)`
  background: ${WhiteSmoke};
  padding: 60px;
  max-width: 480px;
  width: 100%;
  position: absolute;
  right: 150px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 30px;
  box-shadow: 0px 0px 20px #00000029;
  box-sizing: border-box;
  flex-direction: column;
  max-height: 320px;

  @media (max-width: 1200px) {
    position: relative;
    top: 0;
    left: 0;
    transform: translateY(0);
  }
`;

export const CustomContainer = styled(Box)`
  @media (max-width: 1200px) {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  width: 100%;
  height: 100vh;
  position: relative;
`;

export const RootContainer = styled(Container)`
  min-height: 100vh;
  background-size: cover;
  background-image: url(${process.env.REACT_APP_PUBLIC_STORAGE_URL}/logos/login_bg.jpg);
  background-repeat: no-repeat;
`;

export const AbsoluteBoxS = styled(Box)`
  position: absolute;
  left: 50px;
  top: 50px;
  @media (max-width: 1200px) {
    left: 20px;
    top: 30px;
  }
`;

export const CenterTextContainer = styled(Box)`
  text-align: center;
  font-weight: bold;
`;

export const TextS = styled(Typography)`
  font-weight: bold;
  font-size: 18px;
`;

export const SignInButtonS = styled(Button)`
  width: 100%;
  backgroud-color: ${LightOrange};
  background: ${LightOrange};
  margin-top: 20px;
  font-size: 24px;
  text-transform: capitalize;
`;
