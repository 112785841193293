import {
  Address,
  AddressComponent,
  BrokenDownAddress,
  FormattedAddress,
} from '../types/googleAddressValidation';

// Parse google api details address components to our Address Object
export const getGoogleAddressFromGooglesAddressComponents = (
  addressComponents: AddressComponent[],
): FormattedAddress => {
  const addressComponentCategories: BrokenDownAddress = {
    home: ['street_number'],
    street: ['street_address', 'route'],
    streetLineTwo: ['subpremise'],
    locality: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
      'sublocality_level_5',
    ],
    administrativeArea: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5',
      'administrative_area_level_6',
      'administrative_area_level_7',
    ],
    postalCode: ['postal_code'],
    country: ['country'],
  };

  const brokenDownAddress = {
    home: '',
    street: '',
    streetLineTwo: '',
    locality: '',
    administrativeArea: '',
    postalCode: '',
    country: '',
  };

  addressComponents.forEach((component) => {
    for (const category in addressComponentCategories) {
      if (
        addressComponentCategories[category as keyof typeof addressComponentCategories]?.indexOf(
          component.componentType,
        ) !== -1
      ) {
        brokenDownAddress[category as keyof typeof brokenDownAddress] =
          component.componentName?.text;
      }
    }
  });

  return {
    streetOne: `${brokenDownAddress.home} ${brokenDownAddress.street}`,
    streetTwo: brokenDownAddress.streetLineTwo
      ? !isNaN(parseInt(brokenDownAddress.streetLineTwo || ''))
        ? `#${brokenDownAddress.streetLineTwo}`
        : `${brokenDownAddress.streetLineTwo}`
      : '',
    locality: brokenDownAddress.locality as string,
    administrativeArea: brokenDownAddress.administrativeArea as string,
    postalCode: brokenDownAddress.postalCode as string,
    country: brokenDownAddress.country as string,
  };
};

export type MissingType =
  | 'street_address'
  | 'street_number'
  | 'route'
  | 'intersection'
  | 'political'
  | 'country'
  | 'administrative_area_level_1'
  | 'administrative_area_level_2'
  | 'administrative_area_level_3'
  | 'administrative_area_level_4'
  | 'administrative_area_level_5'
  | 'administrative_area_level_6'
  | 'administrative_area_level_7'
  | 'colloquial_area'
  | 'locality'
  | 'sublocality'
  | 'neighborhood'
  | 'premise'
  | 'subpremise'
  | 'plus_code'
  | 'postal_code'
  | 'natural_feature'
  | 'airport'
  | 'park'
  | 'point_of_interest';

export const getMissingTypeErrors = (missingTypes: MissingType[]) => {
  let errors: Partial<
    Omit<Address, 'regionCode'> & {
      regionCode: string;
    }
  > = {};

  const streetMissing: MissingType[] = ['street_address', 'street_number', 'premise', 'route'];
  const streetTwoMissing: MissingType[] = ['subpremise'];
  const localityMissing: MissingType[] = ['locality', 'sublocality'];
  const administrativeAreaMissing: MissingType[] = [
    'administrative_area_level_1',
    'administrative_area_level_2',
    'administrative_area_level_3',
    'administrative_area_level_4',
    'administrative_area_level_5',
    'administrative_area_level_6',
    'administrative_area_level_7',
  ];
  const postalCodeMissing: MissingType[] = ['postal_code'];
  const countryMissing: MissingType[] = ['country', 'political'];

  if (missingTypes.find((type) => streetMissing.includes(type))) {
    errors = { ...errors, streetOne: 'Not found' };
  }

  if (missingTypes.find((type) => streetTwoMissing.includes(type))) {
    errors = { ...errors, streetTwo: 'Not found' };
  }

  if (missingTypes.find((type) => localityMissing.includes(type))) {
    errors = { ...errors, locality: 'Not found' };
  }

  if (missingTypes.find((type) => administrativeAreaMissing.includes(type))) {
    errors = {
      ...errors,
      administrativeArea: 'Not found',
    };
  }

  if (missingTypes.find((type) => countryMissing.includes(type))) {
    errors = { ...errors, regionCode: 'Not found' };
  }

  if (missingTypes.find((type) => postalCodeMissing.includes(type))) {
    errors = { ...errors, postalCode: 'Not found' };
  }

  return errors;
};
