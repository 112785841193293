import React, { useEffect } from 'react';
import { useQuery } from '../../hooks/useQuery';
import { GenerateReadOnlyView, GetGeneratedAgreementPreview } from '../../services/DocuSignService';
import { AgreementContainer } from './styles';

export default function DocumentPreview() {
  const lookupId = useQuery('lookup');
  const readOnlyView = useQuery('readonlyView');
  const agreementId = useQuery('agreementId');
  const agreementType = atob(useQuery('agreementType'));
  const providerName = atob(useQuery('providerName'));

  // Info: gets docusign agreement link
  const { mutate: generateSigningUrl, data } = GetGeneratedAgreementPreview();
  const { mutate: generateReadOnlyView, data: readOnlyData } = GenerateReadOnlyView();

  useEffect(() => {
    if (data?.signingUrl) {
      window.open(data.signingUrl, '_self', 'noreferrer');
    }
  }, [data]);

  useEffect(() => {
    if (readOnlyData) {
      window.open(readOnlyData, '_self', 'noreferrer');
    }
  }, [readOnlyData]);

  useEffect(() => {
    if (readOnlyView === 'true' && agreementId) {
      const searchParams = new URLSearchParams();
      searchParams.append('agreementId', agreementId);
      searchParams.append('agreementType', agreementType);
      searchParams.append('providerName', providerName);
      searchParams.append('type', 'signer');
      searchParams.append('lookupId', lookupId);

      generateReadOnlyView({
        agreementId: +agreementId,
        redirectUrl: `${process.env.REACT_APP_DOCUSIGN_REDIRECT_URL}?` + searchParams.toString(),
      });
    } else if (lookupId) {
      const searchParams = new URLSearchParams();
      searchParams.append('agreementId', agreementId);
      searchParams.append('agreementType', agreementType);
      searchParams.append('providerName', providerName);
      searchParams.append('type', 'signer');
      searchParams.append('lookupId', lookupId);

      generateSigningUrl({
        tempOrEnvId: lookupId,
        redirectUri: `${process.env.REACT_APP_DOCUSIGN_REDIRECT_URL}?` + searchParams.toString(),
      });
    }
  }, [
    agreementId,
    agreementType,
    generateReadOnlyView,
    generateSigningUrl,
    lookupId,
    providerName,
    readOnlyView,
  ]);

  return <AgreementContainer></AgreementContainer>;
}
