import React, { useState } from 'react';
import CustomModal from '../Modal';
import { AppActionButton } from '../../shared/AppActionButton';
import { ModalBox, ModalButtonsBox } from '../Modal/styles';
import { AppTextField } from '../../shared/AppTextField';
import { Box } from '@mui/material';

interface PropType {
  popup: boolean;
  confirm: (voidReason: string) => void;
  cancel: () => void;
}

export default function VoidAgreementModal(props: PropType) {
  const { popup, confirm, cancel } = props;
  const [voidReason, setVoidReason] = useState('');

  return (
    <ModalBox>
      <CustomModal open={popup} containerWidth="500px" modalTitle={'Void Agreement'}>
        <>
          <Box mt={2}></Box>
          <AppTextField
            fullWidth
            focused
            label={'Please provide a reason for voiding'}
            required
            variant="outlined"
            value={voidReason}
            multiline
            minRows={2}
            onChange={(e) => {
              setVoidReason(e.target.value);
            }}
          />
          <ModalButtonsBox>
            <AppActionButton
              variant="outlined"
              onClick={() => {
                confirm(voidReason);
              }}
              disabled={!voidReason}
            >
              Void Agreement
            </AppActionButton>
            <AppActionButton variant="outlined" onClick={cancel}>
              Cancel
            </AppActionButton>
          </ModalButtonsBox>
        </>
      </CustomModal>
    </ModalBox>
  );
}
