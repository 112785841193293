import React from 'react';

export default function EditIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.501" height="20.5" viewBox="0 0 24 24">
      <path
        id="edit_ic"
        d="M-4254-1.5a3,3,0,0,1-3-3V-16.168a3,3,0,0,1,3-3h6.667A1.336,1.336,0,0,1-4246-17.834a1.335,1.335,0,0,1-1.334,1.333H-4254a.334.334,0,0,0-.333.333V-4.5a.334.334,0,0,0,.333.333h11.667A.334.334,0,0,0-4242-4.5v-6.667a1.334,1.334,0,0,1,1.333-1.333,1.334,1.334,0,0,1,1.333,1.333V-4.5a3,3,0,0,1-3,3Zm4.724-7.723a.424.424,0,0,1-.114-.377l.59-2.947a.425.425,0,0,1,.114-.213l6.594-6.594,2.947,2.947-6.594,6.593a.407.407,0,0,1-.212.114l-2.947.59a.424.424,0,0,1-.082.009A.419.419,0,0,1-4249.276-9.223Zm8.067-11.014,1.154-1.154a2.085,2.085,0,0,1,2.945,0,2.071,2.071,0,0,1,.61,1.473,2.071,2.071,0,0,1-.61,1.473l-1.154,1.153Z"
        transform="translate(4257 22)"
        fill="#77868f"
      />
    </svg>
  );
}
