import { InputAdornment } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { CustomSearch, SingleSearchBox, ClearIconEndAdornment } from './styles';
import { SingleSearchProps } from '../../../types/customTable';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';
import AppTooltip from '../AppTooltip';

function SingleSearch({
  searchValue,
  setSearchValue,
  placeholder,
  advancedSearchVisibility,
  total,
  tableTitle,
}: SingleSearchProps) {
  const { t } = useTranslation();

  const changeSearchValue = useCallback(
    (item: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(item.target.value);
    },
    [setSearchValue],
  );

  return (
    <SingleSearchBox>
      {!advancedSearchVisibility && (
        <>
          {' '}
          <CustomSearch
            id="table-search"
            sx={{ mr: 3 }}
            value={searchValue}
            onChange={changeSearchValue}
            placeholder={placeholder}
            aria-describedby="outlined-weight-helper-text"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchValue && (
                    <ClearIconEndAdornment
                      onClick={() => {
                        setSearchValue('');
                      }}
                    />
                  )}
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            error={searchValue.length < 3 && searchValue !== ''}
            helperText={
              searchValue.length < 3 && searchValue !== '' && t('appTextField.helperText')
            }
          />
          {[
            'Seller Groups',
            'Sellers',
            'Agreement Templates',
            'Agents',
            'Product Lines',
            'Roles',
            'Users',
          ].includes(tableTitle) && (
            <AppTooltip
              title={
                total === 0
                  ? 'No results found'
                  : tableTitle === 'Sellers'
                  ? 'Enter Name or Seller ID or Phone Number to search'
                  : tableTitle === 'Seller Groups'
                  ? 'Enter Name or Phone Number to search'
                  : tableTitle === 'Agreement Templates'
                  ? 'Enter Agreement Type to search'
                  : tableTitle === 'Agents'
                  ? 'Enter Name or Agent ID or Phone Number to search'
                  : tableTitle === 'Users'
                  ? 'Enter Name or Email to search'
                  : 'Enter Name to search'
              }
              placement="bottom"
            >
              <InfoIcon fontSize="inherit" color="disabled" />
            </AppTooltip>
          )}
        </>
      )}
    </SingleSearchBox>
  );
}

export default React.memo(SingleSearch);
