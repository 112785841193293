import { Box, InputLabel, Typography } from '@mui/material';
import styled from 'styled-components';
import {
  MainBackground,
  MainTextColor,
  Red,
  SecondaryBackground,
  White,
} from '../../../Theme/colorsVariables';

const ErrorMessageLabel = styled(Typography)`
  color: ${Red};
  text-align: left;
  letter-spacing: 0px;
  margin-top: 5px;
  margin-left: 8px;
  font-size: 16px;
  font-family: Gotham-Book;
  font-style: italic;
`;

const JsonFileChip = styled(Box)`
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  margin-left: 8px;
`;

const AddTemplateLabel = styled(Typography)`
  background: ${SecondaryBackground};
  color: ${White};
  padding: 10px;
  font-weight: bold;
  font-size: 20px;
  font-family: Gotham-Bold;
`;

const ModalClose = styled(Box)`
  position: absolute;
  top: 14px;
  right: 30px;
  color: white;
  cursor: pointer;
`;

const InputLabelS = styled(InputLabel)`
  color: ${MainTextColor} !important;
  font-family: Gotham-Book;
  font-size: 16px;
  font-weight: 500;
`;

const boxSxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 850,
  bgcolor: MainBackground,
  boxShadow: 24,
};

export { ErrorMessageLabel, JsonFileChip, AddTemplateLabel, ModalClose, InputLabelS, boxSxStyle };
