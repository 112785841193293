import { useContext, useEffect } from 'react';
import { useQuery } from '../../hooks/useQuery';
import { GenerateSigningUrl } from '../../services/DocuSignService';
import { NoPermissionMessage } from '../shared/NoPermissionMessage';
import { QuickConfirmationModalContext } from '../../context/QuickModal';

export default function ViewGeneratedAgreement() {
  const { setQuickModal } = useContext(QuickConfirmationModalContext);

  const agreementId = Number(useQuery('agreementId'));

  const { mutate: generateSigningUrl, data: signingUrl } = GenerateSigningUrl({
    onError: (err) => {
      setQuickModal({ isOpen: true, message: err.response.data.message, modalTitle: 'Error' });
    },
  });

  useEffect(() => {
    if (signingUrl && signingUrl.signingUrl) {
      window.location.replace(signingUrl.signingUrl);
    }
  }, [signingUrl]);

  useEffect(() => {
    if (agreementId) {
      generateSigningUrl({
        agreementId: agreementId,
      });
    }
  }, [agreementId, generateSigningUrl]);

  return <>{signingUrl && <NoPermissionMessage message={signingUrl?.lockMessage || ''} />}</>;
}
