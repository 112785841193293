import { Checkbox, FormGroup, Grid, useMediaQuery, useTheme } from '@mui/material';
import { Control, Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import AppAutocompleteSingleSelect from '../../shared/AppAutocompleteSingleSelect';
import { AppTextField } from '../../shared/AppTextField';
import { CenterCenterBox } from '../../shared/FlexBox';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { OrgType, RoleFormProps, RoleFormType } from '../../../types';
import { PermissionSets, TempPermissionSetNamesWithExtraFunctionality } from '../../PermissionSets';
import { usePermissionSets } from '../../../services/roleService';
import { yupResolver } from '@hookform/resolvers/yup';
import { roleValidationSchema } from '../../../Resolvers/roleValidationSchema';
import { FeinBox } from '../../common/styles/feinBox';
import { PermissionSet } from '../../../types/permission';
import { isEqual } from 'lodash';
import { CancelModal } from '../../modal/CancelModal';
import { useDataloss } from '../../../hooks/useDataloss';
import { useLocation, useNavigate } from 'react-router-dom';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
import { BlockTitle, AsterikSpan } from '../UserForm/styles';
import { CheckboxMain } from '../../PermissionSets/styles';

export const RoleForm: FC<RoleFormProps> = ({
  isEdit,
  defaultValues,
  onSubmit,
  formRef,
  setIsUnsavedData,
  setIsSaveEnabled,
  roleStatus,
  setRoleStatus,
  cancelPopup,
  setCancelPopup,
  setIsEdit,
  isUnsavedData,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const isAddNewRole = useMemo(() => {
    return location.pathname.includes('/Add');
  }, [location.pathname]);

  const [duplicateAssosiatedWithValue, setDuplicateAssosiatedWithValue] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const isManageRoleProviderPermission = usePermissions(PermissionKeys.CreateProviderRoles);
  const isManageRoleAgenciesPermission = usePermissions(PermissionKeys.CreateAgentRoles);

  const { data: permissionSets } = usePermissionSets({
    orgType: duplicateAssosiatedWithValue?.label === 'Provider' ? OrgType.Provider : OrgType.Agent,
  });

  const approveAgentSellerRelationshipPermissionSetId = useMemo(() => {
    return (
      permissionSets?.find(
        (item) =>
          item.name === TempPermissionSetNamesWithExtraFunctionality.ApproveAgentSellerRelationship,
      )?.permissionSetId || 0
    );
  }, [permissionSets]);

  const signerPermissionSetId = useMemo(() => {
    return (
      permissionSets?.find(
        (item) => item.name === TempPermissionSetNamesWithExtraFunctionality.Signer,
      )?.permissionSetId || 0
    );
  }, [permissionSets]);

  const formDefaultValues = useMemo<RoleFormType>(() => {
    if (defaultValues) {
      return {
        roleName: defaultValues.roleName,
        assosiatedWith: {
          label: defaultValues.associatedWith === 'Agency' ? 'Agent' : defaultValues.associatedWith,
          value: defaultValues.associatedWith === 'Agency' ? 'Agent' : defaultValues.associatedWith,
        },
        roleDescription: defaultValues.roleDescription,
        permissionsSearchTerm: '',
        permissionSetIds: defaultValues.permissionSetIds,
        fakePermissionArrayForPermissionsValidation: [],
        roleStatus: !!defaultValues.isActive,
        restrictAccessToOwnAgents: !!defaultValues?.restrictAccessToOwnAgents,
      };
    }
    return {
      roleName: '',
      assosiatedWith: isManageRoleProviderPermission
        ? { label: 'Provider', value: 'Provider' }
        : isManageRoleAgenciesPermission
        ? { label: 'Agent', value: 'Agent' }
        : { label: 'Provider', value: 'Provider' },
      roleDescription: '',
      permissionsSearchTerm: '',
      permissionSetIds: [],
      fakePermissionArrayForPermissionsValidation: [],
      roleStatus: roleStatus,
      restrictAccessToOwnAgents: false,
    };
  }, [defaultValues, isManageRoleAgenciesPermission, isManageRoleProviderPermission, roleStatus]);

  const { control, handleSubmit, watch, reset, formState, setValue } = useForm<RoleFormType>({
    mode: 'onChange',
    defaultValues: formDefaultValues,
    resolver: yupResolver(roleValidationSchema),
  });

  const { errors, isValid } = formState;

  const {
    assosiatedWith,
    permissionSetIds,
    permissionsSearchTerm,
    roleName,
    roleDescription,
    roleStatus: roleIsActive,
    restrictAccessToOwnAgents,
  } = watch();

  const handleReset = useCallback(() => {
    reset();
    setRoleStatus(!!defaultValues?.isActive);
    window.scrollTo(0, 0);
  }, [defaultValues?.isActive, reset, setRoleStatus]);

  const filteredPermissionSetsBySearchTerm = useMemo(() => {
    const permissionBlocks: PermissionSet[] = [];

    permissionSets?.forEach((permissionBlock) => {
      if (permissionBlock.name.toLowerCase().indexOf(permissionsSearchTerm.toLowerCase()) !== -1) {
        permissionBlocks.push({ ...permissionBlock });
      }
    });

    return permissionBlocks;
  }, [permissionSets, permissionsSearchTerm]);

  // merging two arrays into one to handle validation
  useEffect(() => {
    setValue('fakePermissionArrayForPermissionsValidation', [...permissionSetIds], {
      shouldValidate: true,
    });
  }, [permissionSetIds, setValue]);

  useEffect(() => {
    if (location.pathname.includes('/Add')) {
      setValue('permissionSetIds', [], { shouldValidate: true });
    }
  }, [assosiatedWith, location.pathname, setValue]);

  // INFO: applying default values to form
  useEffect(() => {
    reset(formDefaultValues);
  }, [formDefaultValues, reset]);

  //INFO: checking for unsaved changes to disable action buttons
  useEffect(() => {
    const initialValues = {
      roleName: formDefaultValues.roleName,
      assosiatedWith: formDefaultValues.assosiatedWith?.value,
      roleDescription: formDefaultValues.roleDescription,
      permissionSetIds: formDefaultValues.permissionSetIds.sort((a, b) => a - b),
      roleStatus: formDefaultValues.roleStatus,
      restrictAccessToOwnAgents: formDefaultValues.restrictAccessToOwnAgents,
    };
    const currentValues = {
      roleName: roleName,
      assosiatedWith: assosiatedWith?.value,
      roleDescription: roleDescription,
      permissionSetIds: permissionSetIds.sort((a, b) => a - b),
      roleStatus: roleIsActive,
      restrictAccessToOwnAgents: restrictAccessToOwnAgents,
    };
    const isValuesEqual = isEqual(initialValues, currentValues);

    setIsUnsavedData(!isValuesEqual);
    setIsSaveEnabled(isValid ? isValuesEqual === isValid : true);
  }, [
    assosiatedWith?.value,
    defaultValues,
    formDefaultValues.assosiatedWith?.value,
    formDefaultValues.permissionSetIds,
    formDefaultValues.restrictAccessToOwnAgents,
    formDefaultValues.roleDescription,
    formDefaultValues.roleName,
    formDefaultValues.roleStatus,
    isValid,
    permissionSetIds,
    restrictAccessToOwnAgents,
    roleDescription,
    roleIsActive,
    roleName,
    setIsSaveEnabled,
    setIsUnsavedData,
  ]);

  useEffect(() => {
    setValue('roleStatus', roleStatus, { shouldValidate: true });
  }, [roleStatus, setValue]);

  useEffect(() => {
    setDuplicateAssosiatedWithValue(assosiatedWith);
  }, [assosiatedWith]);

  const onDiscardChanges = useCallback(() => {
    if (isAddNewRole) {
      setCancelPopup(false);
      navigate({
        pathname: `/dashboard/Role`,
      });
    } else {
      setIsEdit(false);
      setCancelPopup(false);
      formRef.current?.dispatchEvent(new Event('reset', { cancelable: true, bubbles: true }));
    }
  }, [formRef, setCancelPopup, setIsEdit, isAddNewRole, navigate]);

  const onSaveChanges = useCallback(() => {
    setCancelPopup(false);
    if (formRef && formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }, [formRef, setCancelPopup]);

  const onExit = useCallback(() => {
    setCancelPopup(false);
  }, [setCancelPopup]);

  useDataloss(isUnsavedData && isEdit, onDiscardChanges, onSaveChanges, onExit);
  const isDisabled = () => {
    if (location.pathname.includes('/Add')) {
      if (isManageRoleAgenciesPermission && isManageRoleProviderPermission) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));

  return (
    <form onSubmit={handleSubmit(onSubmit)} onReset={handleReset} ref={formRef}>
      <Grid container spacing={isSmall ? 1 : 2} mt={1}>
        <Grid item xs={4}>
          <Controller
            control={control}
            name="roleName"
            render={({ field }) => (
              <AppTextField
                {...field}
                label={t('security.roles.roleForm.role.name')}
                disabled={!isEdit}
                required={isEdit}
                error={!!errors.roleName?.message}
                errorMessage={errors.roleName?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <AppAutocompleteSingleSelect
            control={control as unknown as Control<FieldValues>}
            name={'assosiatedWith'}
            label={t('security.users.userForm.associated')}
            options={[
              { label: 'Agent', value: 'Agent' },
              { label: 'Provider', value: 'Provider' },
            ]}
            disabled={isDisabled()}
            rules={{ required: isEdit }}
            errorMessage={errors.assosiatedWith?.message}
          />
        </Grid>
        <Grid item xs={4}>
          {assosiatedWith?.value === 'Provider' && (
            <FormGroup>
              <Controller
                name="restrictAccessToOwnAgents"
                control={control}
                render={({ field }) => (
                  <CheckboxMain
                    label={'Restrict Access to Own Agents'}
                    disabled={!isEdit}
                    control={
                      <Checkbox
                        color="success"
                        value={restrictAccessToOwnAgents}
                        checked={!!restrictAccessToOwnAgents}
                        onChange={(event, checked) => {
                          field.onChange(checked);
                        }}
                      />
                    }
                  />
                )}
              />
            </FormGroup>
          )}
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="roleDescription"
            render={({ field }) => (
              <AppTextField
                {...field}
                label={t('security.roles.roleForm.role.description')}
                multiline
                minRows={4}
                disabled={!isEdit}
                errorMessage={errors.roleDescription?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <CenterCenterBox sx={{ maxWidth: '500px' }}>
            <BlockTitle>
              {t('security.roles.roleForm.role.permissions')}
              {isEdit && <AsterikSpan>*</AsterikSpan>}
            </BlockTitle>
            <Controller
              control={control}
              name="permissionsSearchTerm"
              render={({ field }) => (
                <AppTextField
                  {...field}
                  label={'Permission Name'}
                  InputProps={{ endAdornment: <SearchIcon /> }}
                />
              )}
            />
          </CenterCenterBox>
        </Grid>
        {isEdit && (
          <Grid item xs={12}>
            <FeinBox>{errors.fakePermissionArrayForPermissionsValidation?.message}</FeinBox>
          </Grid>
        )}

        <Grid item xs={12}>
          <Grid container>
            <PermissionSets
              disabledPermissionSets={
                permissionSetIds.includes(signerPermissionSetId)
                  ? [approveAgentSellerRelationshipPermissionSetId]
                  : []
              }
              disabled={!isEdit}
              permissionSets={filteredPermissionSetsBySearchTerm || []}
              control={control as unknown as Control<{ permissionSetIds: number[] }>}
            />
          </Grid>
        </Grid>
      </Grid>
      <CancelModal
        isOpened={cancelPopup}
        modalTitle={t('security.roles.roleForm.role.warning')}
        onDiscardChanges={onDiscardChanges}
        onSaveChanges={onSaveChanges}
        onExit={onExit}
      />
    </form>
  );
};
