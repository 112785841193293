import React, { useMemo, useState } from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';
import { defaultToastDuration } from './hooks/useDocuSignEvents';
import { IconButton } from '@mui/material';
import { StyledCancelRoundedIcon } from './components/modal/OfacModal/styles';
import DeploymentStatusConnector from './hooks/useDeploymentStatus';
import { useEffect } from 'react';
import { DeploymentStatus, DeploymentStatusContext } from './context/DeploymentStatus';
import { IQuickModalProps, QuickConfirmationModalContext } from './context/QuickModal';
import { AppProviders } from './providers';

const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: 0,
    },
    mutations: {
      retry: false,
    },
  },
});

function SnackbarCloseButton({ snackbarKey }: { snackbarKey: SnackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <StyledCancelRoundedIcon />
    </IconButton>
  );
}

export const App = () => {
  const [isDeploymentRunning, setIsDeploymentRunning] = useState(false);
  const { events } = DeploymentStatusConnector();

  const deploymentStatusProviderValue = useMemo<DeploymentStatus>(
    () => ({ isDeploymentRunning, setIsDeploymentRunning }),
    [isDeploymentRunning],
  );

  useEffect(() => {
    events((message) => {
      setIsDeploymentRunning(message === 'under_maintenance');
    });
  }, [events]);

  const [quickModal, setQuickModal] = useState<IQuickModalProps>({
    isOpen: false,
    message: '',
    modalTitle: '',
  });
  const quickModalProps = useMemo(() => ({ quickModal, setQuickModal }), [quickModal]);

  useEffect(() => {
    if (window && document) {
      const existingScript = document.getElementById('placeAutocomplete');
      if (!existingScript) {
        const script = document.createElement('script');
        const body = document.getElementsByTagName('body')[0];
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_APP_GOOGLE_PLACES_API_KEY}&loading=async&libraries=places&callback=initMap`;
        script.id = 'placeAutocomplete';
        body.appendChild(script);
      }
    }
  }, []);

  return (
    <DeploymentStatusContext.Provider value={deploymentStatusProviderValue}>
      <QueryClientProvider client={reactQueryClient}>
        <SnackbarProvider
          maxSnack={2}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          autoHideDuration={defaultToastDuration}
          hideIconVariant
          preventDuplicate
          action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
        >
          <QuickConfirmationModalContext.Provider value={quickModalProps}>
            <AppProviders />
          </QuickConfirmationModalContext.Provider>
        </SnackbarProvider>
      </QueryClientProvider>
    </DeploymentStatusContext.Provider>
  );
};

export default App;
