import { StepButton, Grid, Typography, useMediaQuery, useTheme, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  AppActionButtonsContainer,
  AppActionButton,
} from '../../components/shared/AppActionButton';
import PageHeaderContentHost from '../../components/shared/AppPageHeaderContentHost';
import { FlexBox, SpaceBetweenBox } from '../../components/shared/FlexBox';
import {
  StickyContainer,
  BoxStyledMain,
  Lable,
  Arrow,
  MainStepper,
  MainSteps,
} from '../../Theme/oldStylesInUse/oldAddSellerStyles';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { CreateOrganizationForm } from '../../components/Forms/CreateOrganizationForm';
import { OrganizationType } from '../../types/query-params';
import { OrganizationService } from '../../services/organizationService';
import { useToast } from '../../hooks/useToast';
import { FeinSource, OrganizationPayload } from '../../types/organization';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  DealershipInfoFormType,
  SellerAddress,
  UpdateSellerPayload,
  UpdateSellerRequestPayload,
} from '../../types';
import {
  useUpdateDealershipInfoQuery,
  useDealershipInfo,
  useSellerInfo,
  useUpdateSellerBusinessInfo,
} from '../../services/sellerServices';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import { OrganizationFormType } from '../../components/Forms/CreateOrganizationForm/types';
import { useDataloss } from '../../hooks/useDataloss';
import { DealershipInfoForm } from '../../components/Forms/DealerShipInfoForm';
import { UpdateDealershipinfoRequestPayload } from '../../components/Forms/DealerShipInfoForm/types';
import { OrganizationContactsTab } from '../ContactsTab';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddOrganizationContact from '../AddOrganizationContact';
import { OrganizationContact } from '../../types/sellerGroupContacts';
import { OrganizationContactInfoPage } from '../OrganizationContactInfoPage';
import { AddProductLinesSeller } from '../../components/SellerTabs/ProductLines/addProductLine';
import { useQuery } from '../../hooks/useQuery';
import { Organizations } from '../../services/sellerGroupServices';
import { CancelModal } from '../../components/modal/CancelModal';
import { useOrganizationContacts } from '../../services/sellerGroupContactsService';
import { UserContext } from '../../context/User';
import { UnsavedDataContext } from '../../context/UnsavedData';
import { getPhonePayload } from '../../components/shared/AppPhoneField';
import { useNavigateAfterDatalossDecision } from '../../hooks/useNavigateAfterDatalossDecision';
import { patchFormatter } from '../../utils/organizationPatchFormatter';
import LabelRequired from '../../components/shared/LabelRequired';

enum AddSellerSteps {
  BusinessInfo = 0,
  DealerInfo = 1,
  Contacts = 2,
  ProductLines = 3,
}

export const AddFinishSeller = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));

  const { sellerId } = useParams();
  const sellerGroupId = useQuery('sellerGroupId');
  const sellerGroupName = useQuery('sellerGroupName');
  const isReturnToSellerGroupView = !!useQuery('isReturnToView');
  const location = useLocation();
  const { t } = useTranslation();
  const toast = useToast();
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const stepsAll: string[] = t('addSeller.steps', { returnObjects: true });
  const steps = useMemo(() => {
    if (sellerGroupId) {
      return stepsAll.slice(0, stepsAll.length - 1);
    }
    return stepsAll;
  }, [sellerGroupId, stepsAll]);
  const { unsavedData, setUnsavedData } = useContext(UnsavedDataContext);

  const businessInfoFormRef = useRef<HTMLFormElement>(null);
  const dealerInfoFormRef = useRef<HTMLFormElement>(null);
  const contactFormRef = useRef<HTMLFormElement>(null);

  const isAddSellerPermission = usePermissions(PermissionKeys.CreateOrganization);
  const isUpdateSellerPermission = usePermissions(PermissionKeys.UpdateOrganization);
  const isViewPagePermission = useMemo(() => {
    if (location.pathname.includes('/Finish')) {
      return isUpdateSellerPermission;
    }
    return isAddSellerPermission;
  }, [isAddSellerPermission, isUpdateSellerPermission, location.pathname]);

  const [activeStep, setActiveStep] = useState(AddSellerSteps.BusinessInfo);
  const [createdSellerId, setCreatedSellerId] = useState(0);
  const [customFieldError, setCustomFieldError] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [isUnsavedData, setIsUnsavedData] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [nextStep, setNextStep] = useState<AddSellerSteps | null>(null);
  const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(true);
  const [showContactsTable, setShowContactsTable] = useState<boolean>(false);
  const [contactToEdit, setContactToEdit] = useState<OrganizationContact | undefined>(undefined);

  const { data: sellerData, refetch: refetchSellerData } = useSellerInfo(createdSellerId, {
    enabled: createdSellerId !== 0,
    retry: false,
    onError: (err) => {
      toast.error(err.response.data.message);
    },
  });

  const { data: dealershipInfo, refetch: refetchDealerInfo } = useDealershipInfo(createdSellerId, {
    enabled: activeStep === AddSellerSteps.DealerInfo,
  });

  const { data: organizationContacts, refetch: refetchContactsCount } = useOrganizationContacts(
    {
      pageNumber: 1,
      pageSize: 5,
      orgId: Number(createdSellerId),
      shouldMatchAllCriteria: false,
    },
    { enabled: !!createdSellerId },
  );

  const { navigateAfterDatalossDecision } = useNavigateAfterDatalossDecision();

  const { mutate: createSeller } = OrganizationService.useCreateSeller({
    onSuccess(data, variables) {
      toast.success(t('seller.success', { name: variables.payload.legalName }));

      if (!navigateAfterDatalossDecision()) {
        setActiveStep(AddSellerSteps.DealerInfo);
        setCreatedSellerId(data);
      }
    },
    setCustomFieldError,
  });
  const { mutate: updateSeller } = useUpdateSellerBusinessInfo(setCustomFieldError, {
    onSuccess(data, variables) {
      toast.success(t('seller.update.success', { name: variables.payload.legalName }));
      refetchSellerData();

      if (!navigateAfterDatalossDecision()) {
        if (typeof nextStep === 'number') {
          setActiveStep(nextStep);
          setNextStep(null);
          return;
        }
        setActiveStep(AddSellerSteps.DealerInfo);
      }
    },
  });
  const { mutateAsync: updateDealershipInfo } = useUpdateDealershipInfoQuery();

  const handleAddUpdateBusinessInfo = async (formValues: OrganizationFormType) => {
    const data: OrganizationPayload = {
      // empty values should be null on ADD
      legalName: formValues.legalName,
      legalID: formValues.fein?.replaceAll('-', '') || null,
      legalEntityTypeId: formValues.legalOrganization?.value,
      organizationType: 4,
      doingBusinessAs: formValues.doingBusinessAs || null,
      addresses: [
        {
          addressType: 1,
          streetAddressLine1: formValues.physicalLocation.address1,
          streetAddressLine2: formValues.physicalLocation.address2 || null,
          city: formValues.physicalLocation.city,
          country: 'US',
          stateId: formValues.physicalLocation.state?.value?.addressStateId,
          zipCode: formValues.physicalLocation.zip,
          sameAsAddressTypeId: null,
          googlePlaceId: formValues.physicalLocation.googlePlaceId,
        },
        {
          addressType: 2,
          streetAddressLine1: formValues.mailingLocation.address1,
          streetAddressLine2: formValues.mailingLocation.address2 || null,
          city: formValues.mailingLocation.city,
          country: 'US',
          stateId: formValues.mailingLocation.state?.value?.addressStateId,
          zipCode: formValues.mailingLocation.zip,
          sameAsAddressTypeId: formValues.isLocationsTheSame ? 1 : null,
          googlePlaceId: formValues.mailingLocation.googlePlaceId,
        },
      ],
      ...getPhonePayload(formValues.phone),
      websiteURL: formValues.website || null,
      fax: formValues.fax?.replaceAll('-', '') || null,
      createdBy: user?.userId || 0,
      updatedBy: user?.userId || 0,
      sellerGroupId: formValues.sellerGroup?.value || null,
      feinSource: formValues.isSellerGroupFein ? FeinSource.SellerGroup : FeinSource.Seller,
    };
    if (createdSellerId === 0) {
      // Create Business Info (Create Seller)
      createSeller({
        payload: data,
      });
    } else {
      const originalData: Partial<UpdateSellerPayload> = {
        legalName: sellerData?.legalName,
        legalId: sellerData?.fein?.replaceAll('-', '') || '',
        legalEntityTypeId: sellerData?.legalEntityTypeId,
        organizationType: 4,
        doingBusinessAs: sellerData?.doingBusinessAs,
        addresses: [
          {
            addressId: sellerData?.addresses[0].addressId,
            addressType: sellerData?.addresses[0].addressTypeId,
            streetAddressLine1: sellerData?.addresses[0].streetAddressLine1,
            streetAddressLine2: sellerData?.addresses[0].streetAddressLine2,
            city: sellerData?.addresses[0].city,
            stateId: sellerData?.addresses[0].stateId,
            zipCode: sellerData?.addresses[0].zipCode,
            sameAsAddressTypeId: sellerData?.addresses[0].sameAsAddressTypeId,
            googlePlaceId: sellerData?.addresses[0].googlePlaceId || '',
          },
          {
            addressId: sellerData?.addresses[1].addressId,
            addressType: sellerData?.addresses[1].addressTypeId,
            streetAddressLine1: sellerData?.addresses[1].streetAddressLine1,
            streetAddressLine2: sellerData?.addresses[1].streetAddressLine2,
            city: sellerData?.addresses[1].city,
            stateId: sellerData?.addresses[1].stateId,
            zipCode: sellerData?.addresses[1].zipCode,
            sameAsAddressTypeId: sellerData?.addresses[1].sameAsAddressTypeId,
            googlePlaceId: sellerData?.addresses[1].googlePlaceId || '',
          },
        ],
        phoneNumber: sellerData?.addresses[0]?.phoneNumber,
        phoneNumberCountryCode: sellerData?.addresses[0]?.phoneNumberCountryCode,
        phoneNumberExt: sellerData?.addresses[0]?.phoneNumberExt,
        websiteURL: sellerData?.addresses[0].website,
        fax: sellerData?.addresses[0].mainFax?.replaceAll('-', ''),
        sellerGroupId: sellerData?.sellerGroupId,
        feinSource: sellerData?.feinSource,
      };
      const updateDataPayload: Partial<UpdateSellerPayload> = {
        legalName: data.legalName,
        legalId: data.legalID?.replaceAll('-', '') || '',
        legalEntityTypeId: data.legalEntityTypeId,
        organizationType: 4,
        doingBusinessAs: data.doingBusinessAs || '',
        addresses: [
          {
            addressId: sellerData?.addresses[0].addressId,
            addressType: 1,
            streetAddressLine1: data.addresses[0].streetAddressLine1,
            streetAddressLine2: data.addresses[0].streetAddressLine2 || '',
            city: data.addresses[0].city,
            country: 'US',
            stateId: data.addresses[0].stateId,
            zipCode: data.addresses[0].zipCode,
            sameAsAddressTypeId: null,
            googlePlaceId: data?.addresses[0].googlePlaceId || '',
          },
          {
            addressId: sellerData?.addresses[1].addressId,
            addressType: 2,
            streetAddressLine1: data.addresses[1].streetAddressLine1,
            streetAddressLine2: data.addresses[1].streetAddressLine2 || '',
            city: data.addresses[1].city,
            country: 'US',
            stateId: data.addresses[1].stateId,
            zipCode: data.addresses[1].zipCode,
            sameAsAddressTypeId: data.addresses[1].sameAsAddressTypeId,
            googlePlaceId: data?.addresses[1].googlePlaceId || '',
          },
        ],
        phoneNumber: data.phoneNumber,
        phoneNumberCountryCode: data.phoneNumberCountryCode,
        phoneNumberExt: data.phoneNumberExt || '',
        websiteURL: data.websiteURL || '',
        fax: data.fax?.replaceAll('-', ''),
        sellerGroupId: data.sellerGroupId || null,
        feinSource: data.feinSource,
      };

      const updatePayload: UpdateSellerRequestPayload = {
        id: createdSellerId || 0,
        payload: patchFormatter(originalData, updateDataPayload),
      };

      updateSeller(updatePayload);
    }
  };

  const handleAddUpdateDealershipInfo = async (formValues: DealershipInfoFormType) => {
    const updatePayload: UpdateDealershipinfoRequestPayload = {
      orgId: createdSellerId,
      payload: {
        dealerLicenseId: formValues?.dealerLicenseId,
        averageAssetInventoryRangeId: formValues.averageAssetInventoryRangeId?.value
          ? formValues.averageAssetInventoryRangeId?.value.averageAssetInventoryRangeId
          : null,
        dealerTypeGroupIds: formValues.dealerTypeGroup
          ? [formValues.dealerTypeGroup.value.dealerTypeGroupId]
          : [],
        assetMakeIds: formValues.assetMakeIds.map((item) => item.value.assetMakeId),
        dmsIds: formValues.dmsIds.map((item) => item.value.dmsId),
        dmsOtherName: formValues.dmsOtherName,
        fiMenuIds: formValues.fiMenuIds.map((item) => item.value.fiMenuId),
        fiMenuOtherName: formValues.fiMenuOtherName,
        userId: user?.userId,
        preferredIntegrationPartner: formValues.integrationPartner,
      },
    };
    try {
      await updateDealershipInfo(updatePayload);
      toast.success(t('dealershipinfo.update.success'));

      if (!navigateAfterDatalossDecision()) {
        if (typeof nextStep === 'number') {
          setActiveStep(nextStep);
          setNextStep(null);
          return;
        }
        setActiveStep(AddSellerSteps.Contacts);
        refetchDealerInfo();
      }
    } catch (err: any) {
      toast.error(err.response.data.message);
    }
  };

  const onDiscardChanges = useCallback(() => {
    if (sellerGroupId) {
      if (isReturnToSellerGroupView) {
        navigate(`/dashboard/SellerGroup/${sellerGroupId}/Seller`);
        return;
      }
      navigate(`/dashboard/SellerGroup/${sellerGroupId}/Finish`);
      return;
    }

    navigate(`/dashboard/Seller`);
  }, [isReturnToSellerGroupView, navigate, sellerGroupId]);

  const onSaveChanges = useCallback(() => {
    if (activeStep === AddSellerSteps.BusinessInfo) {
      businessInfoFormRef.current?.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      );
    }
    if (activeStep === AddSellerSteps.DealerInfo) {
      dealerInfoFormRef.current?.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      );
    }
  }, [activeStep]);

  const onDiscardStepChange = useCallback(() => {
    if (typeof nextStep === 'number') setActiveStep(nextStep);
    setNextStep(null);
    setCancelModalOpen(false);
  }, [nextStep]);

  const onSaveStepChange = useCallback(() => {
    setCancelModalOpen(false);
    if (activeStep === AddSellerSteps.BusinessInfo) {
      businessInfoFormRef.current?.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      );
    }
    if (activeStep === AddSellerSteps.DealerInfo) {
      dealerInfoFormRef.current?.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      );
    }
    if (activeStep === AddSellerSteps.Contacts && !showContactsTable) {
      contactFormRef.current?.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      );
    }
  }, [activeStep, showContactsTable]);

  const onExit = useCallback(() => {
    setCancelModalOpen(false);
    setNextStep(null);
  }, []);

  useDataloss(
    isUnsavedData &&
      activeStep !== AddSellerSteps.Contacts &&
      activeStep !== AddSellerSteps.ProductLines,
    onDiscardChanges,
    onSaveChanges,
    onExit,
  );

  useEffect(() => {
    if (sellerId && location.state) {
      const stepToOpen = location.state.step as AddSellerSteps;
      setCreatedSellerId(Number(sellerId));

      if (stepToOpen === AddSellerSteps.DealerInfo) {
        setActiveStep(stepToOpen);
      }
      if (stepToOpen === AddSellerSteps.Contacts) {
        setActiveStep(stepToOpen);
        setShowContactsTable(location.state.isTable);
      }
    }
  }, [location.state, sellerId]);

  useEffect(() => {
    setTimeout(() => {
      refetchContactsCount();
    }, 1000);
  }, [refetchContactsCount, showContactsTable]);

  if (!isViewPagePermission) {
    return (
      <Grid container textAlign="center">
        <Grid item md={12} xs={12}>
          <Typography fontFamily="Gotham-Bold" fontSize="20px" mt={10}>
            {t('restrictions.tooltip')}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <StickyContainer>
        <SpaceBetweenBox mt={1}>
          <PageHeaderContentHost
            title={createdSellerId ? 'Seller' : ''}
            name={
              (sellerGroupName || sellerData?.sellerGroupName) && sellerData?.legalName
                ? `${sellerData?.legalName} - for Seller Group: ${
                    sellerGroupName || sellerData?.sellerGroupName
                  }`
                : sellerGroupName && !sellerData
                ? `Add Seller - for Seller Group: ${sellerGroupName}`
                : !sellerGroupName && sellerData
                ? sellerData.legalName
                : t('relationship.sellers.addsellers.linearstepper.add_seller')
            }
          />
        </SpaceBetweenBox>
      </StickyContainer>

      <BoxStyledMain my={1}>
        <MainStepper>
          {steps.map((step, index) => (
            <MainSteps
              props={[activeStep, index]}
              key={(Math.random() + 1).toString(36).substring(7)}
            >
              <StepButton
                onClick={() => {
                  if (isUnsavedData && !showContactsTable) {
                    setCancelModalOpen(true);
                    setNextStep(index);

                    return;
                  }
                  setActiveStep(index);
                  if (AddSellerSteps.Contacts === index && organizationContacts?.totalCount) {
                    if (organizationContacts?.totalCount > 0) {
                      setShowContactsTable(true);
                    } else {
                      setShowContactsTable(false);
                    }
                  }
                }}
                disabled={activeStep === AddSellerSteps.BusinessInfo && !createdSellerId}
                style={{
                  cursor:
                    activeStep === AddSellerSteps.BusinessInfo && !createdSellerId
                      ? 'default'
                      : 'pointer',
                }}
              >
                <Lable
                  StepIconComponent={() => <></>}
                  active={activeStep.toString()}
                  position={index.toString()}
                  style={{
                    cursor:
                      activeStep === AddSellerSteps.BusinessInfo && !createdSellerId
                        ? 'default'
                        : 'pointer',
                  }}
                >
                  {index === AddSellerSteps.Contacts
                    ? `Contacts (${organizationContacts?.totalCount || 0})`
                    : step}
                </Lable>
              </StepButton>
              <Arrow len={steps.length} active={activeStep + 1} setpPosition={index + 1} />
            </MainSteps>
          ))}
        </MainStepper>
      </BoxStyledMain>

      {activeStep !== AddSellerSteps.ProductLines && (
        <SpaceBetweenBox width={'100%'}>
          {((activeStep === AddSellerSteps.Contacts && !showContactsTable) ||
            activeStep !== AddSellerSteps.Contacts) && (
            <Box>
              <LabelRequired />
            </Box>
          )}
          <AppActionButtonsContainer>
            <AppActionButton
              onClick={() => {
                if (isUnsavedData && !showContactsTable) {
                  setUnsavedData({
                    ...unsavedData,
                    returnUrl: sellerGroupId
                      ? isReturnToSellerGroupView
                        ? `/dashboard/SellerGroup/${sellerGroupId}/Seller`
                        : `/dashboard/SellerGroup/${sellerGroupId}/Finish`
                      : `/dashboard/Seller${createdSellerId ? `/${createdSellerId}` : ''}`,
                    isPopupOpen: true,
                  });
                  return;
                }
                if (sellerGroupId) {
                  if (isReturnToSellerGroupView) {
                    navigate(`/dashboard/SellerGroup/${sellerGroupId}/Seller`);
                    return;
                  }
                  navigate(`/dashboard/SellerGroup/${sellerGroupId}/Finish`);
                  return;
                }
                if (createdSellerId) {
                  navigate(`/dashboard/Seller/${createdSellerId}`);
                  return;
                }
                navigate(`/dashboard/Seller`);
              }}
            >
              {createdSellerId
                ? t('relationship.sellers.addsellers.linearstepper.finishLater')
                : t('relationship.sellers.addsellers.linearstepper.cancel')}
            </AppActionButton>

            <AppActionButton
              variant="outlined"
              sx={{ minWidth: '165px !important' }}
              disabled={
                activeStep === AddSellerSteps.BusinessInfo
                  ? !isFormValid
                  : activeStep === AddSellerSteps.DealerInfo
                  ? !isFormValid
                  : activeStep === AddSellerSteps.Contacts && !showContactsTable
                  ? isSaveEnabled
                  : activeStep === AddSellerSteps.Contacts && showContactsTable
                  ? false
                  : false
              }
              onClick={() => {
                setUnsavedData({ ...unsavedData, returnUrl: '' });
                if (activeStep === AddSellerSteps.BusinessInfo) {
                  if (isUnsavedData) {
                    businessInfoFormRef.current?.dispatchEvent(
                      new Event('submit', { cancelable: true, bubbles: true }),
                    );
                  } else {
                    setActiveStep(AddSellerSteps.DealerInfo);
                  }
                }
                if (activeStep === AddSellerSteps.DealerInfo) {
                  if (isUnsavedData) {
                    dealerInfoFormRef.current?.dispatchEvent(
                      new Event('submit', { cancelable: true, bubbles: true }),
                    );
                  } else {
                    setActiveStep(AddSellerSteps.Contacts);
                    if (organizationContacts?.totalCount && organizationContacts?.totalCount > 0) {
                      setShowContactsTable(true);
                    }
                  }
                }
                if (activeStep === AddSellerSteps.Contacts && !showContactsTable && sellerGroupId) {
                  contactFormRef.current?.dispatchEvent(
                    new Event('submit', { cancelable: true, bubbles: true }),
                  );
                  if (!navigateAfterDatalossDecision()) {
                    if (isReturnToSellerGroupView) {
                      navigate(`/dashboard/SellerGroup/${sellerGroupId}/Seller`);
                      return;
                    }
                    navigate(`/dashboard/SellerGroup/${sellerGroupId}/Finish`);
                  }
                  return;
                }
                if (activeStep === AddSellerSteps.Contacts && showContactsTable && sellerGroupId) {
                  if (isReturnToSellerGroupView) {
                    navigate(`/dashboard/SellerGroup/${sellerGroupId}/Seller`);
                    return;
                  }
                  navigate(`/dashboard/SellerGroup/${sellerGroupId}/Finish`);
                  return;
                }
                if (activeStep === AddSellerSteps.Contacts && !showContactsTable) {
                  contactFormRef.current?.dispatchEvent(
                    new Event('submit', { cancelable: true, bubbles: true }),
                  );
                  setNextStep(AddSellerSteps.ProductLines);
                  navigateAfterDatalossDecision();
                }
                if (activeStep === AddSellerSteps.Contacts && showContactsTable) {
                  setActiveStep(AddSellerSteps.ProductLines);
                }
              }}
            >
              {(activeStep === AddSellerSteps.Contacts && showContactsTable) ||
              (!isUnsavedData &&
                [AddSellerSteps.BusinessInfo, AddSellerSteps.DealerInfo].includes(activeStep))
                ? t('relationship.sellers.addsellers.linearstepper.continue')
                : t('relationship.sellers.addsellers.linearstepper.saveandcontinue')}
            </AppActionButton>
          </AppActionButtonsContainer>
        </SpaceBetweenBox>
      )}
      {activeStep === AddSellerSteps.ProductLines && (
        <SpaceBetweenBox>
          <Box>
            <LabelRequired />
          </Box>
          <AppActionButtonsContainer>
            <AppActionButton
              onClick={() => {
                if (isUnsavedData) {
                  setUnsavedData({
                    ...unsavedData,
                    returnUrl: sellerGroupId
                      ? isReturnToSellerGroupView
                        ? `/dashboard/SellerGroup/${sellerGroupId}/Seller`
                        : `/dashboard/SellerGroup/${sellerGroupId}/Finish`
                      : '/dashboard/Seller',
                    isPopupOpen: true,
                  });
                  return;
                }
                navigate(
                  sellerGroupId
                    ? isReturnToSellerGroupView
                      ? `/dashboard/SellerGroup/${sellerGroupId}/Seller`
                      : `/dashboard/SellerGroup/${sellerGroupId}/Finish`
                    : '/dashboard/Seller',
                );
              }}
              variant="outlined"
            >
              {t('relationship.sellers.addsellers.linearstepper.finishLater')}
            </AppActionButton>
          </AppActionButtonsContainer>
        </SpaceBetweenBox>
      )}

      <Grid
        container
        spacing={isSmall ? 1 : 2}
        mt={activeStep === AddSellerSteps.ProductLines ? 0 : 1}
      >
        <Grid item xs={12}>
          {AddSellerSteps.BusinessInfo === activeStep && (
            <CreateOrganizationForm
              editForm
              formRef={businessInfoFormRef}
              onSubmit={handleAddUpdateBusinessInfo}
              setIsFormValid={setIsFormValid}
              setIsUnsavedChanges={setIsUnsavedData}
              setCustomFieldError={setCustomFieldError}
              customFieldError={customFieldError}
              orgType={OrganizationType.Seller}
              orgData={sellerData}
              defaultSellerGroupId={sellerGroupId}
            />
          )}
          {AddSellerSteps.DealerInfo === activeStep && (
            <DealershipInfoForm
              isEdit
              orgData={dealershipInfo}
              formRef={dealerInfoFormRef}
              onSubmit={handleAddUpdateDealershipInfo}
              setIsFormValid={setIsFormValid}
              setIsUnsavedChanges={setIsUnsavedData}
            />
          )}
          {AddSellerSteps.Contacts === activeStep && !showContactsTable && !contactToEdit && (
            <AddOrganizationContact
              customOrgName={Organizations.Seller}
              customOrgId={`${createdSellerId}`}
              customFormRef={contactFormRef}
              setShowContactsTable={setShowContactsTable}
              setIsSaveEnabled={setIsSaveEnabled}
              setCustomUnsavedDataFlag={setIsUnsavedData}
              callbackOnContactAddSucess={() => {
                if (typeof nextStep === 'number') {
                  setActiveStep(nextStep);
                  setNextStep(null);
                }
              }}
              nextStep={nextStep}
              refetchParentContacts={refetchContactsCount}
            />
          )}
          {AddSellerSteps.Contacts === activeStep && !showContactsTable && contactToEdit && (
            <OrganizationContactInfoPage
              customContactId={`${contactToEdit.contactId}`}
              customOrgId={`${createdSellerId}`}
              setShowContactsTable={setShowContactsTable}
              setContactToEdit={(val) => {
                setContactToEdit(val);
                setTimeout(() => {
                  refetchContactsCount();
                }, 1000);
              }}
              customFormRef={contactFormRef}
              customOrganization={Organizations.Seller}
            />
          )}
          {AddSellerSteps.ProductLines === activeStep && (
            <AddProductLinesSeller
              customOrgId={`${createdSellerId}`}
              setIsUnsavedData={setIsUnsavedData}
            />
          )}
        </Grid>
      </Grid>

      {AddSellerSteps.Contacts === activeStep && showContactsTable && (
        <>
          <FlexBox sx={{ justifyContent: 'end', width: '100%' }}>
            <AppActionButton
              variant="outlined"
              sx={{ maxHeight: '34px', minWidth: '100px !important' }}
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => {
                setShowContactsTable(false);
                setContactToEdit(undefined);
              }}
            >
              {t('app.action.button.add')}
            </AppActionButton>
          </FlexBox>
          <OrganizationContactsTab
            orgId={createdSellerId}
            viewContactOptionalCallback={(contact) => {
              setShowContactsTable(false);
              setContactToEdit(contact);
            }}
          />
        </>
      )}

      <CancelModal
        isOpened={cancelModalOpen}
        modalTitle={t('security.roles.roleForm.role.warning')}
        onDiscardChanges={onDiscardStepChange}
        onSaveChanges={onSaveStepChange}
        onExit={onExit}
        hideSaveDataButton={activeStep === AddSellerSteps.ProductLines}
      />
    </>
  );
};
