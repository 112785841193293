import forge from 'node-forge';

export default function encryptPassword(value: string): string {
  const publicKey = process.env.REACT_APP_PASSWORD_PUBLIC_KEY;

  if (!publicKey) {
    throw new Error('Public key is missing');
  }
  // Convert value to bytes
  const bytes = forge.util.encodeUtf8(value);

  // Parse the public key
  const publicKeyObj = forge.pki.publicKeyFromPem(publicKey);

  // Encrypt the bytes
  const encryptedBytes = publicKeyObj.encrypt(bytes, 'RSA-OAEP');

  // Convert and return encrypted bytes to Base64 string.
  return forge.util.encode64(encryptedBytes);
}
