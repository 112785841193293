import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoleForm } from '../../components/Forms/RoleForm';
import { AppCancelButton } from '../../components/shared/AppCancelButton';
import PageHeaderContentHost from '../../components/shared/AppPageHeaderContentHost';
import { PageHeaderContainer } from '../../components/shared/AppPageHeaderContentHost/styles';
import { AppResetButton } from '../../components/shared/AppResetButton';
import { AppSaveButton } from '../../components/shared/AppSaveButton';
import { FlexBox } from '../../components/shared/FlexBox';
import { useToast } from '../../hooks/useToast';
import { RolePayload, useCheckRolePermissionQuery, useAddRole } from '../../services/roleService';
import { ApiError, RoleFormType } from '../../types';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import { Typography, Grid } from '@mui/material';
import RoleHasDuplicatePermModal from '../../components/modal/RoleHasDuplicatePermModal';
import { StickyContainer } from '../../Theme/oldStylesInUse/oldAddSellerStyles';
import { QuickConfirmationModalContext } from '../../context/QuickModal';
import { useNavigateAfterDatalossDecision } from '../../hooks/useNavigateAfterDatalossDecision';

export const AddRolePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const { navigateAfterDatalossDecision } = useNavigateAfterDatalossDecision();

  const isCreateRoleAgenciesPermission = usePermissions(PermissionKeys.CreateAgentRoles);
  const isCreateRoleProviderPermission = usePermissions(PermissionKeys.CreateProviderRoles);
  const [isEdit, setIsEdit] = useState(true);
  const [isUnsavedData, setIsUnsavedData] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [cancelPopup, setCancelPopUp] = useState(false);
  const [payload, setPayload] = useState<RolePayload>();
  const [roleHasDuplicatePerm, setRoleHasDuplicatePerm] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  const handleSuccess = (response: any) => {
    toast.success(t('security.roles.roleForm.role.added'));
    if (navigateAfterDatalossDecision()) {
      return;
    }
    navigate({
      pathname: `/dashboard/Role/${response.roleId}`,
    });
  };

  const handleError = (error: ApiError) => {
    toast.error(error.response.data.message);
  };
  const { mutate: addCreate } = useAddRole(handleSuccess, handleError);

  const { setQuickModal } = useContext(QuickConfirmationModalContext);
  const { mutate: validateRole } = useCheckRolePermissionQuery({
    onSuccess(data, reqPayload) {
      if (data) {
        addCreate(reqPayload);
      } else {
        setRoleHasDuplicatePerm(true);
      }
    },
    onError(error, variables) {
      if (error.response.data.code === 30106) {
        setQuickModal({
          message: `Role ${variables.roleName} already exists.`,
          modalTitle: 'Duplicates!',
          isOpen: true,
        });
      } else {
        toast.error(error.response.data.message);
      }
    },
  });

  const handleFormSubmit = (data: RoleFormType) => {
    const requestPayload: RolePayload = {
      associatedWith: data.assosiatedWith?.value === 'Agent' ? 'Agency' : 'Provider',
      roleType: 2,
      roleName: data.roleName,
      roleDescription: data.roleDescription,
      isActive: data.roleStatus,
      permissionSetIds: data.permissionSetIds,
      restrictAccessToOwnAgents:
        data.assosiatedWith?.value === 'Provider' ? data.restrictAccessToOwnAgents : false,
    };
    setPayload(requestPayload);
    validateRole(requestPayload);
  };

  if (!isCreateRoleAgenciesPermission && !isCreateRoleProviderPermission) {
    return (
      <Grid container textAlign="center">
        <Grid item md={12} xs={12}>
          <Typography fontFamily="Gotham-Bold" fontSize="20px" mt={10}>
            {t('restrictions.tooltip')}
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <>
      <StickyContainer>
        <PageHeaderContainer>
          <FlexBox>
            <PageHeaderContentHost name={'Add Role'} showRequiredLabel={isEdit} />
          </FlexBox>
          <FlexBox>
            <AppCancelButton
              onClick={() => {
                if (isUnsavedData) {
                  setCancelPopUp(true);
                } else {
                  navigate({
                    pathname: `/dashboard/Role`,
                  });
                }
              }}
            />
            <AppResetButton
              onClick={() => {
                formRef.current?.dispatchEvent(
                  new Event('reset', { cancelable: true, bubbles: true }),
                );
              }}
            />
            <AppSaveButton
              disabled={isSaveEnabled}
              onClick={() => {
                formRef.current?.dispatchEvent(
                  new Event('submit', { cancelable: true, bubbles: true }),
                );
              }}
            />
          </FlexBox>
        </PageHeaderContainer>
      </StickyContainer>
      <RoleForm
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        onSubmit={handleFormSubmit}
        formRef={formRef}
        isSaveEnabled={isSaveEnabled}
        setIsSaveEnabled={setIsSaveEnabled}
        isUnsavedData={isUnsavedData}
        setIsUnsavedData={setIsUnsavedData}
        roleStatus={true}
        setRoleStatus={() => {}}
        cancelPopup={cancelPopup}
        setCancelPopup={setCancelPopUp}
      />
      <RoleHasDuplicatePermModal
        popUp={roleHasDuplicatePerm}
        handleModal={() => {
          setRoleHasDuplicatePerm(false);
        }}
        handleConfirm={() => {
          setRoleHasDuplicatePerm(false);
          addCreate(payload!);
        }}
      />
    </>
  );
};
