import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { AssociatedProducts } from '../../modal/AssociatedProducts';
import CustomModal from '../../modal/Modal';
import OfacModal from '../../../components/modal/OfacModal';
import EnvelopeAuditLogTable from '../../EnvelopeAuditLogTable';
import InfoTooltip from '../../InfoTooltip';
import { AgreementStatus } from '../../../types/agreements';
import { IconWrapper, StatusText } from '../../SellerTabs/Agreements/styles';
import CustomTable from '../../shared/CustomTable';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
import { useSaveSearchCriteria } from '../../../hooks/useSaveSearchCriteria';
import { useToast } from '../../../hooks/useToast';
import {
  EnvelopeAuditLog,
  EnvelopeId,
  GenerateSigningUrl,
  GetEnvelopeAuditLogDetails,
  useDownloadAgreementByEnvelopeId,
} from '../../../services/DocuSignService';
import { useSGAgreements } from '../../../services/sellerGroupServices';
import { VoidAgreementQuery } from '../../../services/sellerServices';
import { BrightGreen, linkColor, MainRed } from '../../../Theme/colorsVariables';
import { ApiError, EditVoidAgreementsType } from '../../../types';
import { Cell, CellType } from '../../../types/customTable';
import { Order } from '../../../types/query-params';
import { Agreement, AgreementProductLine, CommonOrganization } from '../../../types/sellerGroup';
import { formatUtcToLocalTime } from '../../../utils/formatUtcToLocalTime';
import { UserContext } from '../../../context/User';
import { AlignCenterBox, FlexBox } from '../../shared/FlexBox';
import DownloadIcon from '../../assets/DownloadIcon';
import VoidIcon from '../../assets/VoidIcon';
import ViewIcon from '../../assets/ViewIcon';
import ReviewDocumentIcon from '../../assets/ReviewDocumentIcon';
import AppTooltip from '../../shared/AppTooltip';
import VoidAgreementModal from '../../modal/VoidAgreementModal';

const SEARCH_KEY = 'sellerGroupAgreementList.search';

export const AgreementsTab = () => {
  const navigate = useNavigate();
  const {
    refetchAgreementsList: refetchParentAgreementsList,
    refetchIsAgreementsInProgress,
    sellerGroupInformation,
  } = useOutletContext<{
    refetchAgreementsList: () => void;
    refetchIsAgreementsInProgress: () => void;
    sellerGroupInformation?: CommonOrganization;
  }>();
  const { t } = useTranslation();
  const { id } = useParams();
  const toast = useToast();
  const user = useContext(UserContext);
  const isLoggedInUserProvider = useMemo(() => user?.userType === 'Provider', [user?.userType]);

  const isApproveAgentSellerRelPemission = usePermissions(
    PermissionKeys.ApproveAgentSellerRelationship,
  );
  const isReadContactPermission = usePermissions(PermissionKeys.ReadContact);
  const isSendForSignaturesPermission = usePermissions(PermissionKeys.SendForSignatures);
  const isViewSellerPermission = usePermissions(PermissionKeys.ViewSellers);
  const isReadProductLinePermission = usePermissions(PermissionKeys.ViewProducts);
  const isReadAgentPermission = usePermissions(PermissionKeys.ReadAgent);

  const [agreementRows, setAgreementRows] = useState<Cell[][]>([]);
  const [isAuditLogOpened, setIsAuditLogOpened] = useState(false);
  const [envelopeAuditLogDetails, setEnvelopeAuditLogDetails] = useState<EnvelopeAuditLog[]>([]);
  const [voidAgreementConfirmation, setVoidAgreementConfirmation] = useState(false);
  const [selectedAssociatedProductLines, setSelectedAssociatedProductLines] = useState<
    AgreementProductLine[] | null
  >(null);
  const [ofacModalState, setOfacModalState] = useState<{
    isOpen: boolean;
    agreementId: number | null;
  }>({ isOpen: false, agreementId: null });

  const tableHeadingsArray = useMemo(() => {
    if (isLoggedInUserProvider) {
      return [
        { 'Agreement Type': 'agreementType' },
        { 'Product Lines': '' },
        { Agent: 'agent' },
        { 'Agent Relationship Owner': 'agentContact' },
        { 'Effective Date': 'effectiveDate' },
        { Signer: 'signer' },
        { 'Sent By': 'sendBy' },
        { 'Sent On': 'sendOn' },
        { 'OFAC Status': '' },
        { 'DS Status': 'status' },
        { Actions: '' },
      ] as Record<string, string>[];
    }
    return [
      { 'Agreement Type': 'agreementType' },
      { 'Product Lines': '' },
      { 'Effective Date': 'effectiveDate' },
      { Signer: 'signer' },
      { 'Sent By': 'sendBy' },
      { 'Sent On': 'sendOn' },
      { 'OFAC Status': '' },
      { 'DS Status': 'status' },
      { Actions: '' },
    ] as Record<string, string>[];
  }, [isLoggedInUserProvider]);

  const {
    searchTerm,
    setSearchTerm,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    resetSearch,
  } = useSaveSearchCriteria({ searchKey: SEARCH_KEY });

  const {
    data: agreementsResponse,
    isFetching: isAgreementsFetching,
    refetch: refetchAgreementsList,
  } = useSGAgreements({
    sellerGroupId: Number(id),
    payload: {
      pageNumber: page,
      pageSize: rowsPerPage,
      sortColumn: orderBy,
      sortDirection: order,
      shouldMatchAllCriteria: true,
    },
    options: {
      enabled: isViewSellerPermission,
      retry: false,
      onError(err) {
        toast.error(err.response.data.message);
      },
    },
  });

  const onVoidSuccess = (data: EditVoidAgreementsType) => {
    toast.success(data.message);
    refetchAgreementsList();
    refetchParentAgreementsList();
    refetchIsAgreementsInProgress();
  };

  const onVoidError = (err: ApiError) => {
    toast.error(err.response.data.message);
  };

  const { mutate: VoidAgreement } = VoidAgreementQuery(onVoidSuccess, onVoidError);

  const { mutateAsync: getEnvelopeAuditLogDetails } = GetEnvelopeAuditLogDetails();

  const { mutateAsync: generateSigningUrl } = GenerateSigningUrl();

  const { mutate: downloadAgreementAsync } = useDownloadAgreementByEnvelopeId();

  const openAuditLogModal = useCallback(
    async ({ envelopeId }: EnvelopeId) => {
      const envelopeAuditLog = await getEnvelopeAuditLogDetails({ envelopeId });
      if (envelopeAuditLog && envelopeAuditLog.length && typeof envelopeAuditLog !== 'string') {
        setIsAuditLogOpened(true);
        setEnvelopeAuditLogDetails(envelopeAuditLog);
      }
    },
    [getEnvelopeAuditLogDetails],
  );

  const openTabToViewAgreement = useCallback(
    async ({ agreementId }: { agreementId?: number }) => {
      if (!agreementId) {
        return;
      }
      const { signingUrl } = await generateSigningUrl({
        agreementId: agreementId,
        redirectUrl: process.env.REACT_APP_DOCUSIGN_REDIRECT_URL,
      });

      if (signingUrl && signingUrl.indexOf('ApiException') === -1) {
        window.open(signingUrl, '_blank', 'noreferrer');
      }
    },
    [generateSigningUrl],
  );

  const agreementRowsMapper = useCallback(
    (data: Agreement[]): void => {
      const truncateProducts = (words: string[], maxlength: number, openMore: () => void) => {
        if (!words.length) {
          return <span>Unavailable</span>;
        }
        if (words.length === 1) {
          return words[0];
        }
        const productLines = `${words.slice(0, maxlength).join(', ')}, ${
          words.length - maxlength
        } more....`;
        return (
          <u style={{ color: linkColor, cursor: 'pointer' }}>
            <span onClick={openMore}>{productLines}</span>
          </u>
        );
      };

      const mappedRow = data.map<Cell[]>((agreement) => {
        // Additional columns For Provider
        const getProviderCells = (): Cell[] => {
          if (isLoggedInUserProvider) {
            return [
              {
                data: (
                  <>
                    {isReadAgentPermission && agreement.agent ? (
                      <Link
                        to={`/dashboard/Agent/${agreement.agent?.id}`}
                        target={'_blank'}
                        style={{ color: linkColor }}
                      >
                        {agreement.agent?.name || '-'}
                      </Link>
                    ) : (
                      <>{agreement.agent?.name || '-'}</>
                    )}
                  </>
                ),
                type: CellType.Action,
              },
              {
                data: (
                  <>
                    {isReadAgentPermission &&
                    isReadContactPermission &&
                    agreement.agent &&
                    agreement.agentContact ? (
                      <Link
                        to={`/dashboard/Agent/${agreement.agent?.id}/Contact/${agreement.agentContact?.id}`}
                        target={'_blank'}
                        style={{ color: linkColor }}
                      >
                        {agreement.agentContact?.name || '-'}
                      </Link>
                    ) : (
                      <>{agreement.agentContact?.name || '-'}</>
                    )}
                  </>
                ),
                type: CellType.Action,
              },
            ];
          }
          return [];
        };

        const providerCells = getProviderCells();

        return [
          {
            data: agreement.agreementType,
            type: CellType.Info,
          },
          {
            data: (
              <>
                {agreement.productLines.length === 1 ? (
                  <>
                    {isReadProductLinePermission && (
                      <Link
                        to={`/dashboard/ProductLine/${agreement.productLines[0].productId}`}
                        target={'_blank'}
                        style={{ color: linkColor }}
                      >
                        {agreement.productLines[0].productName}
                      </Link>
                    )}
                    {!isReadProductLinePermission && <>{agreement.productLines[0].productName}</>}
                  </>
                ) : (
                  truncateProducts(
                    agreement.productLines.map((pr) => pr.productName),
                    1,
                    () => {
                      setSelectedAssociatedProductLines(agreement.productLines);
                    },
                  )
                )}
              </>
            ),
            type: CellType.Action,
          },
          ...providerCells,
          {
            data: agreement.envelopeId
              ? formatUtcToLocalTime(`${agreement.effectiveDate}`, false)
              : '-',
            type: CellType.Info,
          },
          {
            data: (
              <>
                {isReadContactPermission && !!agreement.envelopeId && !!agreement.signer?.id && (
                  <Link
                    to={`/dashboard/SellerGroup/${id}/Contact/${agreement.signer?.id}`}
                    target={'_blank'}
                    style={{ color: linkColor }}
                  >
                    {agreement.signer?.name || '-'}
                  </Link>
                )}
                {(!isReadContactPermission || !agreement.envelopeId) && (
                  <>{agreement.signer?.name || '-'}</>
                )}
              </>
            ),
            type: CellType.Action,
          },
          {
            data: agreement.sendBy?.name || '-',
            type: CellType.Info,
          },
          {
            data: agreement.envelopeId ? formatUtcToLocalTime(`${agreement.sendOn}`) : '-',
            type: CellType.Info,
          },
          {
            data: (
              <>
                {agreement.ofacStatus ? (
                  <div
                    style={{ color: MainRed, textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                      setOfacModalState({ isOpen: true, agreementId: agreement.agreementId });
                    }}
                  >
                    {'Matches found'}
                  </div>
                ) : (
                  <div style={{ color: BrightGreen }}>{'No matches found'}</div>
                )}
              </>
            ),
            type: CellType.Action,
          },
          {
            data: (
              // DocuSign statuses https://support.docusign.com/s/document-item?language=en_US&rsc_301&bundleId=oeq1643226594604&topicId=wdm1578456348227.html&_LANG=enus
              <AlignCenterBox>
                <StatusText
                  active={!!agreement.envelopeId}
                  onClick={() => {
                    if (!!agreement.envelopeId) {
                      openAuditLogModal({ envelopeId: agreement.envelopeId });
                    }
                  }}
                >
                  {agreement.status}
                </StatusText>
                {(!!agreement.declinedBy ||
                  !!agreement.deliveryFailureEmail ||
                  !!agreement.voidedBy ||
                  !!agreement.reviewedBy) && (
                  <InfoTooltip
                    reasons={{
                      declined: agreement.declinedBy
                        ? {
                            firstLine: t('agreement.declined.person', {
                              declinedBy:
                                agreement.declinedBy?.firstName +
                                ' ' +
                                agreement.declinedBy?.lastName +
                                ' (' +
                                agreement.declinedBy?.email +
                                ')',
                            }),
                            secondLine: t('agreement.declined.reason', {
                              declinedReason: agreement.statusReasonMessage,
                            }),
                          }
                        : null,
                      deliveryFailure: agreement.deliveryFailureEmail
                        ? {
                            firstLine: t('agreement.delivery.reason', {
                              email: agreement?.deliveryFailureEmail,
                            }),
                          }
                        : null,
                      reviewed:
                        agreement.reviewedBy &&
                        agreement?.agreementStatusReason?.agreementStatusReason !==
                          'Review Declined'
                          ? {
                              firstLine: t('agreement.reviewed.person', {
                                approvedBy: agreement?.reviewedBy.name,
                              }),
                            }
                          : null,
                      rejected:
                        !agreement.voidedBy &&
                        agreement.reviewedBy?.name &&
                        agreement?.agreementStatusReason?.agreementStatusReason ===
                          'Review Declined'
                          ? {
                              firstLine: t('agreement.declined.person', {
                                declinedBy: agreement?.reviewedBy?.name,
                              }),
                              secondLine: t('agreement.declined.reason', {
                                declinedReason: agreement?.statusReasonMessage,
                              }),
                            }
                          : null,
                      voided: agreement.voidedBy
                        ? {
                            firstLine: t('agreement.voided.person', {
                              voidedBy:
                                agreement.voidedBy?.firstName +
                                ' ' +
                                agreement.voidedBy?.lastName +
                                ' (' +
                                agreement.voidedBy?.email +
                                ')',
                            }),
                            secondLine: t('agreement.reason.for.void', {
                              voidedReason: agreement?.statusReasonMessage,
                            }),
                          }
                        : null,
                    }}
                  />
                )}
              </AlignCenterBox>
            ),
            type: CellType.Action,
          },
          {
            data: (
              <>
                {agreement.envelopeId ? (
                  <FlexBox>
                    {isLoggedInUserProvider &&
                      agreement?.agreementStatusReason?.agreementStatusReason ===
                        'Review Pending' &&
                      agreement.reviewType && (
                        <ReviewDocumentIcon
                          onClick={() => {
                            navigate(
                              `/dashboard/SellerGroup/${id}/Agreement/Review?ReviewType=${agreement.reviewType}`,
                            );
                          }}
                          isActionable={isApproveAgentSellerRelPemission}
                          signingGroupMessage={''}
                          reviewType={agreement.reviewType}
                        />
                      )}
                    {![
                      AgreementStatus.Correcting,
                      AgreementStatus.PurgingSoon,
                      AgreementStatus.Purged,
                      AgreementStatus.Voided,
                      AgreementStatus.Expired,
                    ].includes(agreement.status) && (
                      <AppTooltip title={'Download Agreement'}>
                        <IconWrapper
                          iconlabel="Download Icon"
                          onClick={() => {
                            downloadAgreementAsync({
                              envelopeId: agreement.envelopeId,
                              fileName: `${
                                sellerGroupInformation?.legalName
                              }_Agreement_${formatUtcToLocalTime(
                                `${agreement.effectiveDate}`,
                                false,
                              )}`,
                            });
                          }}
                          isallowed="true"
                        >
                          <DownloadIcon />
                        </IconWrapper>
                      </AppTooltip>
                    )}
                    {![
                      AgreementStatus.Correcting,
                      AgreementStatus.PurgingSoon,
                      AgreementStatus.Purged,
                    ].includes(agreement.status) ? (
                      <AppTooltip title={'View Agreement'}>
                        <IconWrapper
                          iconlabel="View Icon"
                          onClick={() => {
                            openTabToViewAgreement({ agreementId: agreement.agreementId });
                          }}
                          isallowed="true"
                        >
                          <ViewIcon />
                        </IconWrapper>
                      </AppTooltip>
                    ) : (
                      <StatusText active={false}>No possible actions</StatusText>
                    )}

                    {[
                      AgreementStatus.Draft,
                      AgreementStatus.Sent,
                      AgreementStatus.Delivered,
                      AgreementStatus.WaitingForOthers,
                      AgreementStatus.NeedsToSign,
                      AgreementStatus.NeedsToView,
                    ].includes(agreement.status) &&
                      isSendForSignaturesPermission && (
                        <AppTooltip title={'Void Agreement'}>
                          <IconWrapper
                            iconlabel="Void Icon"
                            onClick={() => {
                              setVoidAgreementConfirmation(true);
                            }}
                            isallowed="true"
                          >
                            <VoidIcon />
                          </IconWrapper>
                        </AppTooltip>
                      )}
                  </FlexBox>
                ) : (
                  <StatusText active={false}>No possible actions</StatusText>
                )}
              </>
            ),
            type: CellType.Action,
          },
        ];
      });
      setAgreementRows(mappedRow);
    },
    [
      downloadAgreementAsync,
      id,
      isApproveAgentSellerRelPemission,
      isLoggedInUserProvider,
      isReadAgentPermission,
      isReadContactPermission,
      isReadProductLinePermission,
      isSendForSignaturesPermission,
      navigate,
      openAuditLogModal,
      openTabToViewAgreement,
      sellerGroupInformation?.legalName,
      t,
    ],
  );

  const onHandleSetOrder = useCallback(
    (newOrder: Order, newOrderBy: string): void => {
      setOrder(newOrder);
      setOrderBy(newOrderBy);
    },
    [setOrder, setOrderBy],
  );

  const onVoidConfirm = (voidReason: string) => {
    if (id) {
      VoidAgreement({ voidReason, sellerId: Number(id) });
    }
    setVoidAgreementConfirmation(false);
  };

  useEffect(() => {
    if (agreementsResponse) {
      agreementRowsMapper(agreementsResponse.data);
    }
  }, [agreementRowsMapper, agreementsResponse]);

  useEffect(() => {
    resetSearch();
  }, [resetSearch]);

  return (
    <>
      <CustomTable
        hideMainSearch
        displayOptions={{ paginationTop: true, paginationBottom: true, tableOnly: true }}
        page={page}
        rowsPerPage={rowsPerPage}
        data={agreementRows}
        valueRef={undefined}
        goError={false}
        handlePageChange={(e, newPage) => {
          setPage(newPage - 1);
        }}
        handleRowsPerPageChange={(newLimit) => {
          setPage(0);
          setRowsPerPage(newLimit);
        }}
        tableHeadingsArray={tableHeadingsArray}
        advancedSearchVisibility={false}
        setAdvancedSearchVisibility={() => {}}
        setPagination={() => {}}
        onSubmitAdvancedSearch={() => {}}
        onResetAdvancedSearch={() => {}}
        searchValue={searchTerm}
        handleSearchValue={(value) => {
          setSearchTerm(value);
          setPage(0);
        }}
        setPage={setPage}
        tableTitle={''}
        placeHolder={t('security.roles.advance.user.search.role')}
        addNewLink={{ link: '', state: undefined }}
        order={order}
        orderBy={orderBy}
        handleRequestSort={onHandleSetOrder}
        InputsComponent={() => <></>}
        isDataLoading={isAgreementsFetching}
        total={agreementsResponse?.totalCount || 0}
        openAdvancedSearch={() => {}}
        isAdvanceSearch={false}
        noResultsMessage="No agreements have been sent yet."
        unsortableColumns={['OFAC Status']}
      />
      <CustomModal
        modalTitle={'Audit Log'}
        open={isAuditLogOpened}
        containerWidth={'1000px'}
        modalProps={{
          onClose: () => {
            setIsAuditLogOpened(false);
            setEnvelopeAuditLogDetails([]);
          },
        }}
      >
        {envelopeAuditLogDetails.length && (
          <EnvelopeAuditLogTable envelopeAuditLogDetails={envelopeAuditLogDetails} />
        )}
      </CustomModal>
      <VoidAgreementModal
        popup={voidAgreementConfirmation}
        confirm={onVoidConfirm}
        cancel={() => setVoidAgreementConfirmation(false)}
      />
      <AssociatedProducts
        isOpened={!!selectedAssociatedProductLines}
        productLines={selectedAssociatedProductLines}
        setIsOpen={() => {
          setSelectedAssociatedProductLines(null);
        }}
      />
      <OfacModal
        isOpen={ofacModalState.isOpen}
        agreementId={ofacModalState.agreementId}
        onClose={() => {
          setOfacModalState({ isOpen: false, agreementId: null });
        }}
      />
    </>
  );
};
