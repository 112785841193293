import { useContext } from 'react';
import { UserContext } from '../context/User';

export enum PermissionKeys {
  // Agent permissions
  ReadAgent = 'Read Agent',
  // Agreement Template Permissions
  SignerSellerAgreements = 'Signer - Seller Agreements',
  ApproveAgentSellerRelationship = 'Approve Agent-Seller Relationship',
  SendForSignatures = 'Send For Signatures',
  // Agreement Permissions
  AddAgreement = 'Add Agreement',
  ViewAgreement = 'View Agreement',
  ModifyAgreement = 'Modify Agreement',
  DeleteAgreement = 'Delete Agreement',
  // Contact Permissions
  ReadContact = 'Read Contact',
  CreateContact = 'Create Contact',
  UpdateContact = 'Update Contact',
  DeleteContact = 'Delete Contact',
  // Product Line Permissions
  AddProducts = 'Add Products',
  ViewProducts = 'View Product Lines',
  ModifyProducts = 'Modify Products',
  DeleteProducts = 'Delete Products',
  // Security Permissions
  ReadAgentRoles = 'Read Agent Roles',
  CreateAgentRoles = 'Create Agent Roles',
  UpdateAgentRoles = 'Update Agent Roles',
  DeleteAgentRoles = 'Delete Agent Roles',
  ReadProviderRoles = 'Read Provider Roles',
  CreateProviderRoles = 'Create Provider Roles',
  UpdateProviderRoles = 'Update Provider Roles',
  DeleteProviderRoles = 'Delete Provider Roles',

  ReadAgentUser = 'Read Agent User',
  CreateAgentUser = 'Create Agent User',
  UpdateAgentUser = 'Update Agent User',
  DeleteAgentUser = 'Delete Agent User',
  ReadProviderUser = 'Read Provider User',
  CreateProviderUser = 'Create Provider User',
  UpdateProviderUser = 'Update Provider User',
  DeleteProviderUser = 'Delete Provider User',
  // Seller/Seller Group Permissions
  ReadOrganization = 'Read Organization',
  CreateOrganization = 'Create Organization',
  UpdateOrganization = 'Update Organization',
  DeleteOrganization = 'Delete Organization',

  AddSellers = 'Add Sellers',
  ViewSellers = 'View Sellers',
  ModifySellers = 'Modify Sellers',
  DeleteSellers = 'Delete Sellers',

  // Configuration
  ViewConfiguration = 'View Configuration',
  ModifyConfiguration = 'Modify Configuration',
  // System Administration
  SystemAdmin = 'System Admin',
}

export const permissionsDictionary: Record<PermissionKeys, Array<string>> = {
  // Agent permissions
  [PermissionKeys.ReadAgent]: ['view:agency'],

  // Agreement Template Permissions
  [PermissionKeys.SignerSellerAgreements]: ['signer'],
  [PermissionKeys.ApproveAgentSellerRelationship]: ['approve;agent-seller;relationship'],
  [PermissionKeys.SendForSignatures]: ['send:agreement'],

  // Agreement Permissions
  [PermissionKeys.AddAgreement]: ['add:agreement'],
  [PermissionKeys.ViewAgreement]: ['view:agreement'],
  [PermissionKeys.ModifyAgreement]: ['modify:agreement'],
  [PermissionKeys.DeleteAgreement]: ['delete:agreement'],

  // Contact Permissions
  [PermissionKeys.ReadContact]: ['read:contact'],
  [PermissionKeys.CreateContact]: ['create:contact'],
  [PermissionKeys.UpdateContact]: ['update:contact'],
  [PermissionKeys.DeleteContact]: ['delete:contact'],

  // Product Line Permissions
  [PermissionKeys.ViewProducts]: ['view:product'],
  [PermissionKeys.AddProducts]: ['add:product'],
  [PermissionKeys.ModifyProducts]: ['modify:product'],
  [PermissionKeys.DeleteProducts]: ['delete:product'],

  // Security Permissions
  [PermissionKeys.ReadAgentRoles]: ['read:agent:role'],
  [PermissionKeys.CreateAgentRoles]: ['create:agent:role'],
  [PermissionKeys.UpdateAgentRoles]: ['update:agent:role'],
  [PermissionKeys.DeleteAgentRoles]: ['delete:agent:role'],
  [PermissionKeys.ReadProviderRoles]: ['read:provider:role'],
  [PermissionKeys.CreateProviderRoles]: ['create:provider:role'],
  [PermissionKeys.UpdateProviderRoles]: ['update:provider:role'],
  [PermissionKeys.DeleteProviderRoles]: ['delete:provider:role'],

  [PermissionKeys.ReadAgentUser]: ['read:agent:user'],
  [PermissionKeys.CreateAgentUser]: ['create:agent:user'],
  [PermissionKeys.UpdateAgentUser]: ['update:agent:user'],
  [PermissionKeys.DeleteAgentUser]: ['delete:agent:user'],
  [PermissionKeys.ReadProviderUser]: ['read:provider:user'],
  [PermissionKeys.CreateProviderUser]: ['create:provider:user'],
  [PermissionKeys.UpdateProviderUser]: ['update:provider:user'],
  [PermissionKeys.DeleteProviderUser]: ['delete:provider:user'],

  // Seller/Seller Group Permissions
  [PermissionKeys.ReadOrganization]: ['read:organization'],
  [PermissionKeys.CreateOrganization]: ['create:organization'],
  [PermissionKeys.UpdateOrganization]: ['update:organization'],
  [PermissionKeys.DeleteOrganization]: ['delete:organization'],

  [PermissionKeys.ViewSellers]: ['view:seller'],
  [PermissionKeys.AddSellers]: ['add:seller'],
  [PermissionKeys.ModifySellers]: ['modify:seller'],
  [PermissionKeys.DeleteSellers]: ['delete:seller'],

  // System Admin
  [PermissionKeys.ViewConfiguration]: ['create:configuration', 'read:configuration'],
  [PermissionKeys.ModifyConfiguration]: ['modify:configuration'],

  // System Admin
  [PermissionKeys.SystemAdmin]: ['manage:system'],
};
function usePermissions(permissionKey: PermissionKeys) {
  const user = useContext(UserContext);

  return !!user?.allowedPermissions[permissionKey];
}

export default usePermissions;
