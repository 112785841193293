const errorMap: Record<string, number> = {
  agencyName: 255,
  sellerGroupName: 255,
  'sellerGroup.legalName': 255,
  'sellerGroup.doingBusinessName': 255,
  legalNameS: 255,
  doingBusinessAsS: 255,
  website: 255,
  addressLine1M: 150,
  addressLine2M: 150,
  addressLine1: 150,
  addressLine2: 150,
  'sellerGroup.address[0].streetAddressLine1': 150,
  'sellerGroup.address[0].streetAddressLine2': 150,
  city: 75,
  state: 75,
  cityM: 75,
  stateM: 75,
  'sellerGroup.address[0].city': 75,
  'sellerGroup.address[0].state': 75,
  mainPhone: 12,
  mainFax: 12,
  'sellerGroup.feinName': 50,
  feinS: 10,
  zipCode: 5,
  zipCodeM: 5,
  'sellerGroup.address[0].zipCode': 5,
  otherDms: 150,
  menu: 150,
  integrationPartner: 150,
  dealerLicenseId: 18,
  firstName: 40,
  lastName: 80,
  email: 150,
  phone: 12,
  fax: 12,
  ext: 6,
  phyZip: 5,
  mailZip: 5,
  physicalLocationZip: 5,
  physicalZipCode: 5,
  mailingZipCode: 5,
  mailingZip: 5,
  phyAddress: 150,
  mailAddress: 150,
  productName: 50,
  description: 512,
  title: 128,
  legalOrganization: 75,
  documentOrgId: 10,
  rejectReason: 150,
} as const;

type Keys = keyof typeof errorMap;

export const getCommonMaxLengthValue = (keyName: Keys): number => {
  return errorMap[keyName] || 255;
};
