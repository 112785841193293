import { TextField, Autocomplete, Select } from '@mui/material';
import styled from 'styled-components';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
  SecondaryTextColor,
  MainTextColor,
  MainRed,
  White,
  Platinum,
} from '../../Theme/colorsVariables';
import { breakPointMedium } from '../../utils';

export const SearchAutocomplete = styled(Autocomplete)`
  height: 44px;
  line-height: 16px;
  .MuiInputBase-root {
    height: 44px;
    line-height: 16px;
    border-radius: 0;
    border: none;
    fieldset {
      border: none;
    }
    input {
      line-height: 16px;
      height: 10px;
    }
  }
`;

export const StyledInputBase = styled(TextField)``;

export const SearchIcon = styled(SearchOutlinedIcon)`
  position: absolute;
  right: 10px;
  color: ${MainRed};
  top: 20px;
  opacity: 0.7;
  transform: translateY(-50%);
  ${breakPointMedium('top: 18px;')}
`;

export const StyledSelect = styled(Select)`
  background: ${White};
  border-radius: 0;
  line-height: 22px;
  height: 44px;
  input {
    font-size: 10px;
  }
  fieldset {
    border: none;
  }
  select {
    font-family: Gotham-Medium;
  }
  label {
    &:focus {
      color: ${MainTextColor};
    }
  }
  &.MuiInputBase-root.Mui-disabled {
    background: ${Platinum};
  }
`;

export const GroupHeader = styled('div')({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: SecondaryTextColor,
  background: White,
});

export const GroupItems = styled('ul')({
  padding: 0,
});

export const DropdownOption = styled('li')({
  p: {
    margin: 0,
  },
  '.vl': {
    borderLeft: `2px solid ${MainRed}`,
    margin: '0 5px',
  },
});
