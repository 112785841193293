import axios from 'axios';
import { useInfiniteQuery } from 'react-query';
import { AgreementsActivity, InMyCourtResponse } from '../types/dashboard';
import { ApiError, Pagination } from '../types';
import dayjs from 'dayjs';
import { getTimeLine } from '../pages/Dashboard/recent-activity/helper';

const getInMyCourt = async ({ pageParam = 1 }: { pageParam: number }) => {
  const activityData = await axios.get(`/Dashboard/to-do?pageNumber=${pageParam}&pageSize=100`);
  return activityData.data;
};

export const useInMyCourtQuery = () => {
  return useInfiniteQuery<InMyCourtResponse, ApiError>(
    'InMyCourt',
    ({ pageParam }) => getInMyCourt({ pageParam }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const maxPages = Math.ceil(lastPage.totalCount / 100);
        const nextPage = allPages.length + 1;
        return nextPage <= maxPages ? nextPage : undefined;
      },
      enabled: false,
    },
  );
};

const getAgreementActivity = async ({ pageParam = 1 }: { pageParam: number }) => {
  const activityData = await axios.get(
    `/Dashboard/agreements-activity?pageNumber=${pageParam}&pageSize=100`,
  );
  return activityData.data;
};

export const useAgreementActivity = () => {
  return useInfiniteQuery<Pagination<AgreementsActivity>, ApiError>(
    'AgreementActivity',
    ({ pageParam }) => getAgreementActivity({ pageParam }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const maxPages = Math.ceil(lastPage.totalCount / 100);
        const nextPage = allPages.length + 1;
        return nextPage <= maxPages ? nextPage : undefined;
      },
      enabled: false,
    },
  );
};

const getMyRecentActivity = async ({ pageParam = 1 }: { pageParam: number }) => {
  const res = await axios.get(`/Dashboard/recent-activity`, {
    params: {
      pageNumber: pageParam,
      pageSize: 100,
    },
  });
  return res.data;
};

export type RecentActivityItem = {
  agreementId: number;
  agreementType: string;
  agentId: number;
  agentName: string;
  orgType: string;
  orgName: string;
  orgId: number;
  event: 'Sent' | 'Completed' | 'Review Approved' | 'Review Declined' | 'Declined' | 'Voided';
  lastModifiedOn: string;
  gateMessageParameter?: string;
};

export const useMyRecentActivity = () => {
  const infiniteQuery = useInfiniteQuery<Pagination<RecentActivityItem>, ApiError>(
    'my-recent-activity',
    ({ pageParam }) => getMyRecentActivity({ pageParam }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const maxPages = Math.ceil(lastPage.totalCount / 100);
        const nextPage = allPages.length + 1;
        return nextPage <= maxPages ? nextPage : undefined;
      },
      enabled: false,
    },
  );

  return {
    ...infiniteQuery,
    data: {
      ...infiniteQuery.data,
      data: infiniteQuery.data?.pages.map((page) => ({
        ...page,
        data: page.data.map((item) => ({
          ...item,
          timeRange: getTimeLine(dayjs(item.lastModifiedOn).toDate()),
        })),
      })),
    },
  };
};
