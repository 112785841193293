import { Typography } from '@mui/material';
import styled from 'styled-components';
import { Black, SecondaryTextColor } from '../../../Theme/colorsVariables';

export const ModalMessageText = styled(Typography)({
  color: `${Black}`,
  width: '100%',
  wordBreak: 'break-word',
  '& span': {
    color: SecondaryTextColor,
  },
});
