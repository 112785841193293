import React, { useEffect, useState } from 'react';
import { Box, Modal, Grid, useMediaQuery, useTheme } from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { useForm, SubmitHandler } from 'react-hook-form';
import CloseIcon from '../../assets/CloseIcon';

import { useTranslation } from 'react-i18next';
import { AppTextField } from '../../shared/AppTextField';
import { AppActionButton } from '../../shared/AppActionButton';
import { allowNumbersOnly } from '../../../utils';
import { AddTemplateLabel, ModalClose, boxSxStyle } from '../AddTemplate/styles';
import { EditTemplateProps, FormValues } from '../AddTemplate/types';
import { getCommonMaxLengthValue } from '../../../utils/inputMaxLength';

export default function EditTemplateModal({
  open,
  onClose,
  documentData,
  onHandleFormSubmit,
  isLoading,
}: EditTemplateProps) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));
  const { t } = useTranslation();
  const { handleSubmit, reset } = useForm<FormValues>();
  const [documentTemplateId, setDocumentTemplateId] = useState<string>('');

  const handleCloseHandler = () => {
    reset();
    onClose();
  };

  const onSubmit: SubmitHandler<FormValues> = () => {
    onHandleFormSubmit({
      ...documentData,
      documentOrgId: documentTemplateId && documentTemplateId !== '0' ? +documentTemplateId : null,
    });
  };

  useEffect(() => {
    if (open) {
      setDocumentTemplateId(documentData.documentOrgId?.toString() || '');
    } else {
      setDocumentTemplateId('');
    }
  }, [documentData.documentOrgId, open]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseHandler}
        aria-labelledby="modal-modal-title"
        disableAutoFocus
      >
        <Box sx={boxSxStyle}>
          <AddTemplateLabel id="modal-modal-title" variant="h6">
            {t('agreement.edit.template.modal.title')}
            <ModalClose onClick={handleCloseHandler}>
              <CloseIcon />
            </ModalClose>
          </AddTemplateLabel>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={isSmall ? 1 : 2} p={2}>
              {documentData?.product && (
                <Grid item xs={6}>
                  <AppTextField
                    type="text"
                    disabled
                    label="Product Line"
                    variant="outlined"
                    value={documentData?.product || ''}
                  />
                </Grid>
              )}

              <Grid item xs={6}>
                <AppTextField
                  type="text"
                  disabled
                  label={'Used for Entity'}
                  variant="outlined"
                  value={documentData?.['used for entity'] || ''}
                />
              </Grid>
              <Grid item xs={6}>
                <AppTextField
                  type="text"
                  disabled
                  label={'Agreement Type'}
                  variant="outlined"
                  value={documentData?.['document type'] || ''}
                />
              </Grid>
              <Grid item xs={6}>
                <AppTextField
                  type="text"
                  label={t('documents.advancedSearchInputParams.label.agreementOrgId')}
                  variant="outlined"
                  onKeyPress={allowNumbersOnly}
                  value={documentTemplateId}
                  error={documentTemplateId.length > getCommonMaxLengthValue('documentOrgId')}
                  helperText={
                    documentTemplateId.length > getCommonMaxLengthValue('documentOrgId')
                      ? `Must be ${getCommonMaxLengthValue('documentOrgId')} digits or less`
                      : ''
                  }
                  onChange={(e) => {
                    setDocumentTemplateId(e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <AppActionButton
                  variant="outlined"
                  size="large"
                  type="submit"
                  startIcon={<CheckOutlinedIcon />}
                  disabled={
                    `${documentData.documentOrgId}` === documentTemplateId ||
                    documentTemplateId.length > 10
                  }
                >
                  Submit
                </AppActionButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
