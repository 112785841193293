import { PermissionKeys } from '../hooks/usePermissions';
import { HttpStatusCode } from './http-status-code.enum';
import { Order } from './query-params';

export type UserInfo = {
  consentId?: number;
  consentUrl?: string;
  isProvider: boolean;
  legalName: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  phoneNumberExt: string | null;
  phoneNumberCountryCode: string | null;
  ext: string;
  contactClassification: number[];
  title: string;
  role: string[];
  showTerms: boolean;
  userId: number;
  userType: 'Provider' | 'Agency';
  orgId: number;
  orgTypeId: number;
  businessRole: string;
  tenantName: string;
  permissions: {
    permissionId: number;
    userPolicy: string;
  }[];
  allowedPermissions: Record<PermissionKeys, boolean>;
  features: Feature[];
  refetchUserData: () => void;
  accountOwnerEmail: string | null;
  accountOwnerLabel: string | null;
  accountOwnerPhone: string | null;
  restrictAccessToOwnAgents: boolean;
};

export type Feature = {
  name: string;
  value: boolean;
};

export type AddressState = {
  addressStateId: number;
  stateName: string;
  stateCode: string;
};

export type StateForAutocomplete = {
  addressStateId: number;
  label: string;
  value: string;
};

export type RoleType = {
  roleDescription: string;
  roleId: number;
  roleName: string;
  roleType: number;
  userId: number;
};

export type CommonConstant = {
  type: string;
  value: string;
  active: boolean;
};

export interface PayloadAdvanceSearch<T> {
  pageNumber?: number | null;
  pageSize?: number | null;
  sortColumn?: string;
  sortDirection?: Order;
  name?: string;
  agentId?: string;
  agreementType?: string;
  status?: string;
  entityType?: string;
  associatedWith?: string;
  assignedRoles?: number;
  advancedSearch?: T;
  isEnabled?: boolean;
  shouldMatchAllCriteria?: boolean;
  accountOwnerId?: string;
}

export interface AdvanceSearchParams {
  name?: string;
  phone?: string;
  physicalAddress?: string;
  physicalCity?: string;
  physicalStateId?: number;
  physicalZipCode?: string;
  mailingAddress?: string;
  mailingCity?: string;
  mailingStateId?: number;
  mailingZipCode?: string;
  status?: string;
  agent?: string;
  agentId?: string;
  legalEntityTypeId?: number;
}

export interface ApiError {
  response: {
    data: { code: number; errorUserTitle: string; message: string };
    status: HttpStatusCode;
  };
}

export enum OrgType {
  'Provider' = 1,
  'Agent' = 2,
  // 3,4 could be added later
}

export type SignUpPayload = {
  email: string;
  password: string;
  confirmPassword: string;
};
