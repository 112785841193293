import React from 'react';

export default function CloseIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
      <path
        id="input_close"
        d="M12,24A12,12,0,1,1,24,12,12.014,12.014,0,0,1,12,24ZM7.85,6.75a1.1,1.1,0,0,0-.778,1.878L10.443,12,7.073,15.371a1.1,1.1,0,1,0,1.555,1.557L12,13.555l3.371,3.372a1.1,1.1,0,0,0,1.557-1.557L13.555,12l3.372-3.372a1.1,1.1,0,1,0-1.557-1.555L12,10.443,8.628,7.073A1.091,1.091,0,0,0,7.85,6.75Z"
        fill="#fff"
      />
    </svg>
  );
}
